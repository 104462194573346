<div class="ald-repeater-item">

    <div class="ald-repeater-item__border"></div>

    <!-- HEADER -->
    <div *ngIf="header" class="ald-repeater-item__header">
        <h1 class="ald-repeater-item__heading">
            {{ header }}
        </h1>
        <div *ngIf="hasRemoveBtn && removeBtnPos === 'header'" class="ald-repeater-item__action">
            <ald-button
                variant="ghost"
                size="sm"
                label="Remove"
                icon="close"
                (click)="didRemoveItem.emit()">
            </ald-button>
        </div>
    </div>

    <!-- ROW -->
    <div class="ald-repeater-item-row">

        <!-- Content -->
        <div class="ald-repeater-item-row__content">
            <ng-content></ng-content>
        </div>

        <!-- Button -->
        <div class="ald-repeater-item-row__action" *ngIf="hasRemoveBtn &&removeBtnPos === 'inline'">
            <ald-button
                variant="ghost"
                size="sm"
                icon="close"
                (click)="didRemoveItem.emit()">
            </ald-button>
        </div>

    </div>

</div>