import { getTimeZones } from '@vvo/tzdb';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { SelectItem, TreeNode } from 'primeng-lts/api';
import { AlAssetsQueryClient } from '@al/assets-query';
import { AlSession } from '@al/core';
import { AlIrisClient, AlIncidentFilterDictionary } from '@al/iris';
import { AlKalmClient, StandardKalmResponse } from '@al/kalm';
import {
    AlResponderAction, 
    AlResponderActionLong, 
    AlResponderClient, 
    AlResponderIncidentTrigger, 
    AlResponderPlaybook, 
    AlResponderPlaybookParameter, 
    AlResponderPlaybookTrigger, 
    AlResponderTriggerActionParameter, 
    AlResponderTriggerPlaybookParameter, 
    AlResponderWorkflowTask, 
    AlResponderMitre, 
    AlResponderRoles
} from '@al/responder';
import { AlBottomSheetComponent, AlBottomSheetHeaderOptions, AlBottomSheetFooterOptions, AlViewHelperComponent } from '@al/ng-generic-components';
import { AlDynamicFormComponent } from '../al-dynamic-form/al-dynamic-form.component';
import { AlWizardStepperComponent } from '../al-wizard-stepper/al-wizard-stepper.component';
import { WizardStep } from '../al-wizard-stepper/wizard.types';
import { AlDynamicFormUtilityService } from '../services/al-dynamic-form-utility.service';
import { AlParametersUtilityService } from '../services/al-parameters-utility.service';
import { AlPlaybookEditorTreeUtilityService } from '../services/al-playbook-editor-tree-utility.service';
import { AlFormElementBase } from '../types/al-form-element-base';
import { AlDynamicFormControlOption, AlDynamicFormElementDescriptor } from '../types/al-form.types';
import { AlParameterConfiguration, AlPlaybookPaletteItem, AlTaskPaletteItem } from '../types/playbook-action';


type ErrorTypes = 'incidents-conditions-form' | "triggers-form" | "save" | "action-params";

@Component({
    selector: 'al-trigger-form',
    templateUrl: './al-trigger-form.component.html',
    styleUrls: ['./al-trigger-form.component.scss']
})
export class AlTriggerFormComponent {

    @Output() onMessage: EventEmitter<{ type: string; msg: string, action: string }> = new EventEmitter<{ type: string; msg: string, action: string }>();
    @Output() onTriggerTypeChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() onFormClosed: EventEmitter<string> = new EventEmitter<any>();

    public trigger: AlResponderPlaybookTrigger | null = null;
    public triggerRoles: AlDynamicFormControlOption[] = [];
    public defaultTriggerRole: string = "";
    public runPlaybooksOrActions: 'playbooks' | 'actions' = 'playbooks';
    // Playbooks selection
    public playbooksLoaded = false;
    public playbooksFiltered: AlResponderPlaybook[] = [];
    public playbooks: SelectItem[] = [];
    public playbookPaletteItems: AlPlaybookPaletteItem[] = [];
    public playbookLookup: { [key: string]: AlPlaybookPaletteItem } = {};
    // Actions selection
    public availableActions: AlTaskPaletteItem[] = [];
    public actionsLookup: { [key: string]: AlTaskPaletteItem } = {};

    public isLoading = false;
    public editMode = false;
    public triggerTypeFixed = false;
    public loadingFormParams = false;
    public showTaskPalette = false;
    // Forms
    public triggerFormElements: AlDynamicFormElementDescriptor[] = [];
    public incidentConditionFormElements: AlFormElementBase<any>[] = [];
    public scheduleConditionFormElements: AlFormElementBase<any>[] = [];
    public scheduleIntervalFormElements : AlFormElementBase<any>[] = [];
    public scheduleDatetimeFormElements : AlFormElementBase<any>[] = [];
    public primaryBtn: {disabled: boolean, label: string} = {disabled: true, label: "Next"};
    public stepStatus: { step: number, isValid: boolean }[] = [
        { step: 0, isValid: true },
        { step: 1, isValid: true },
        { step: 2, isValid: true },
    ];


    @ViewChild('alWizard') alWizard: AlWizardStepperComponent;
    @ViewChild('bottomSheet') bottomSheet: AlBottomSheetComponent;
    @ViewChild('viewHelper', {static:false}) viewHelper: AlViewHelperComponent;
    @ViewChild("playbookParamsForm", {static: false}) public playbookParamsForm?: AlDynamicFormComponent;
    @ViewChild("triggerDynamicForm", {static: false}) public triggerDynamicForm: AlDynamicFormComponent;
    @ViewChild("incidentDynamicForm", {static: false}) public incidentDynamicForm: AlDynamicFormComponent;
    @ViewChild("scheduleDynamicForm", {static: false}) public scheduleDynamicForm: AlDynamicFormComponent;
    @ViewChild("scheduleIntervalDynamicForm", { static: false }) public scheduleIntervalDynamicForm: AlDynamicFormComponent;
    @ViewChild("scheduleDatetimeDynamicForm", { static: false }) public scheduleDatetimeDynamicForm: AlDynamicFormComponent;

    public headerOptions: AlBottomSheetHeaderOptions  = {
        icon:  'bolt',
        title:  'Add Trigger', // 'Edit Trigger'
        collapsibleFromTitle: true,
        secondaryAction:{
            text:  'Cancel',
            disabled:  false
        }
    };

    public footerOptions: AlBottomSheetFooterOptions = {
        primaryAction: {
            text: 'Next',
            disabled: true,
            hidden: false
        },
        secondaryAction: {
            text: 'Back',
            disabled: false,
            hidden: false,
            icon: 'ui-icon-chevron-left'
        }
    };

    public units: SelectItem[] = [
        {label: 'Seconds', value: 'seconds'},
        {label: 'Minutes', value: 'minutes'},
        {label: 'Hours', value: 'hours'},
        {label: 'Days', value: 'days'},
        {label: 'Weeks', value: 'weeks'}
    ];

    public timezones: AlDynamicFormControlOption[] = [];
    public triggerMethod: string = "incident"; // 'incident', 'observation', 'schedule'
    public triggerType: string = 'interval'; // 'incident', 'observation', 'interval', 'cron', 'datetime'

    public actionParamsFormElements: AlFormElementBase<any>[] = [];
    public playbookParamsFormElements: AlFormElementBase<any>[] = [];
    public cronValues: any = {};
    public cronValuescharge: boolean = false;
    public mitreSelectedValues: string[] = [];

    public parametersWithProperties: any; // unused? Type?
    public selectedActionRef = '';
    public selectedActionDisplayName = '';
    public selectedPlaybookId = '';
    public selectedPlaybookDisplayName = '';
    public selectedTriggerPlaybook: AlResponderTriggerPlaybookParameter;
    public selectedTriggerAction: AlResponderTriggerActionParameter;
    public currentTriggerActionsValidity: { [key: string]: boolean } = {};
    public currentTriggerPlaybooksValidity: { [key: string]: boolean } = {};

    private actingAccountId: string;
    private playbooksAll: AlResponderPlaybook[] = [];
    private defaultErrorMsgs: { [key: string]: string } = {
        "incidents-conditions-form": "An internal error occurred while generating Incident conditions form. Please try again later. If this error continues, contact Alert Logic Support.",
        "triggers-form": "An internal error occurred while generating triggers form. Please try again later. If this error continues, contact Alert Logic Support.",
        "save": "An internal error occurred while saving the trigger. Please try again later. If this error continues, contact Alert Logic Support.",
        "action-params": "An internal error occurred while loading the actions. Please try again later. If this error continues, contact Alert Logic Support."
    };

    constructor(
        private editorTreeService: AlPlaybookEditorTreeUtilityService,
        private formUtility: AlDynamicFormUtilityService,
        private parametersUtility: AlParametersUtilityService
    ) {

    }

    onTriggerSetStepState(index: number, isValid?: boolean) {
        if (isValid !== undefined) {
            this.alWizard.steps[index].complete = isValid;
            this.stepStatus[index].isValid = isValid;
            this.onTriggerFormValid(isValid);
        }
    }

    validateStepState(index: number) {
        this.stepStatus.forEach((stepElement) => {
            if (stepElement.step === index) {
                this.onTriggerFormValid(stepElement.isValid);
            }
        });
    }

    onTriggerFormValid(isValid?: boolean) {
        if (this.footerOptions?.primaryAction) {
            this.footerOptions.primaryAction.disabled = isValid === undefined ? this.footerOptions.primaryAction.disabled : !isValid;
        }
    }

    open(trigger: AlResponderPlaybookTrigger | null, editMode: boolean, triggerTypeFixed: boolean = false) {
        this.trigger = {...trigger};
        this.editMode = editMode;
        this.triggerTypeFixed = triggerTypeFixed;
        if (editMode) {
            this.headerOptions.title = 'Edit Trigger';
        }
        this.bottomSheet.open();
        this.init();
    }

    async init() {
        this.isLoading = true;
        this.actingAccountId = AlSession.getActingAccountId();
        this.resetValues();
        this.updateFooterButtons(0);
        this.timezones = getTimeZones().map(tz => {
            return {
                label: `${tz.name} (${tz.abbreviation})`,
                value: tz.name
            };
        });

        await this.generateFormValues();
        AlResponderClient.getAllPlaybooks(this.actingAccountId, {deleted: false}).then(playbooks => {
            this.playbooksAll = playbooks;
            this.determinePlaybooksForTriggerType();
        });

        this.getActions();
        this.isLoading = false;
        this.onTriggerFormValid();
    }

    resetValues() {
        this.mitreSelectedValues = [];
        this.incidentConditionFormElements = [];
        this.playbookParamsFormElements = [];
        this.selectedPlaybookDisplayName = '';
        this.selectedPlaybookId = '';
        this.runPlaybooksOrActions = 'playbooks';
        this.playbooksLoaded = false;
    }

    generateDynamicFormValues(formValues: AlDynamicFormElementDescriptor[]): AlFormElementBase<any>[] {
        const formElements: AlFormElementBase<any>[] = [];
        formValues.forEach((formElement) => {
            let base:AlDynamicFormElementDescriptor = this.formUtility.generateBaseProperties(formElement);
            const element:AlFormElementBase|undefined = this.formUtility.generateDynamicElement(base, formElement.type);
            if (element) {
                formElements.push(element);
            }
        });
        return formElements;
    }

    async generateFormValues() {
        this.cronValuescharge = false;
        this.triggerType = this.trigger?.type ?? 'incident';
        this.triggerMethod = ['incident', 'observation'].includes(this.triggerType) ? this.triggerType : 'schedule';
        await this.generateTriggerForm();
        await this.generateIncidentConditionsForm();
        this.generateScheduleConditionsForm();
    }

    /**
     * Generate trigger form
     */
    async generateTriggerForm() {
        try {
            if (this.triggerRoles.length === 0) {
                const triggerRoles = await AlResponderClient.getTriggerRoles(this.actingAccountId) as AlResponderRoles;
                this.triggerRoles = triggerRoles?.allowed_values?.role_ids as AlDynamicFormControlOption[];
                this.defaultTriggerRole = this.triggerRoles?.find(option => option.label === "Child Account Read Permissions")?.value;
            }
            this.triggerFormElements = [
                {
                    type: "string/input",
                    property: "name",
                    label: "Name",
                    required: true,
                    options: [],
                    value: this.trigger?.name ?? "",
                },
                {
                    type: "boolean/inputSwitch",
                    property: "enabled",
                    label: "Trigger Enabled",
                    options: [],
                    value: this.trigger?.enabled ?? true,
                },
                {
                    type: "string/textarea",
                    property: "description",
                    label: "Description",
                    value: this.trigger?.description ?? "",
                },
                {
                    type: "string/radio",
                    property: "type",
                    label: "Trigger Type",
                    options: [
                        { label: 'Incident', value: 'incident' },
                        { label: 'Observation', value: 'observation' },
                        { label: 'Schedule', value: 'schedule' }
                    ],
                    disabled: this.editMode || this.triggerTypeFixed,
                    value: this.triggerMethod,
                },
                {
                    type: "string/radio",
                    property: "role",
                    label: "Role permission",
                    options: this.triggerRoles,
                    value: this.trigger?.role_id ?? this.defaultTriggerRole,
                },
            ];
        } catch (err:any) {
            console.error(err);
            this.notifyError("triggers-form", err);
        }
    }

    /**
     * Generate incident condition form
     */
    async generateIncidentConditionsForm() {
        // Aux function to parse from incident filter value to multiselectOptions
        const filterToMultiSelectOption = (element: {value: string, caption: string}) => {
            return { label: element.caption, value: element.value };
        };
        try{
            const incidentFilters: AlIncidentFilterDictionary = await AlIrisClient.getIncidentFilterDictionary();
            const assetGroups = await AlAssetsQueryClient.listAssetGroups(this.actingAccountId);
            const rawMitreData = await AlKalmClient.startSimpleQuery(this.actingAccountId, 'tic_mitre_classification');
            const analytics = await AlKalmClient.startSimpleQuery(this.actingAccountId, 'tic_analytics') as StandardKalmResponse;
            let mitreClassification: TreeNode[] = this.rawMitreToTreeNodes(rawMitreData);
            const analyticsRows = analytics.rows as string[][];
            const nameIndex = analytics.column_info.findIndex(col => col.name === "name");
            const idIndex = analytics.column_info.findIndex(col => col.name === "id");
            const summaryIndex = analytics.column_info.findIndex(col => col.name === "summary");
            const analyticsOptions = analyticsRows.map((element: string[]) => {
                return {
                    label: element[nameIndex],
                    description: element[summaryIndex],
                    value: element[idIndex]
                };
            });
            const threatLevelOptions = Object.values(incidentFilters.threatLevels).map(filterToMultiSelectOption);
            const detectionSourcesOptions = Object.values(incidentFilters.detectionSources).map(filterToMultiSelectOption);
            const assetGroupsOptions = assetGroups.groups.map(ag => {
                return {label: ag.key, value: ag.key};
            });
            // Fill default values in edit mode
            const selectedThreatLevels = this.trigger?.threat_levels;
            const selectedDetectionSources = this.trigger?.detection_sources;
            const triggerMitreValues = this.trigger?.mitre_classifications;
            const selectedMitreNodes = this.getInitialSelectedMitre(mitreClassification, triggerMitreValues);
            const selectedAssetGroups = this.trigger?.asset_groups?.groups;
            const selectedAnalytics = this.trigger?.analytics;
            const incidentConditionFormValues: AlDynamicFormElementDescriptor[] = [
                {
                    type: "string[]/multiSelectList",
                    property: "threat_levels",
                    multiSelectOptions: threatLevelOptions,
                    label: "Threat Level",
                    placeholder: "Select",
                    defaultValue: selectedThreatLevels ?? [],
                },
                {
                    type: "string[]/treeSelectList",
                    property: "mitre_classifications",
                    label: "MITRE Classification",
                    placeholder: "Select",
                    treeSelectOptions: mitreClassification,
                    defaultValue: selectedMitreNodes,
                    columns: [{ field: 'name', header: 'Name' }],
                    onNodeSelected: this.onMitreSelected,
                    onNodeUnselected: this.onMitreUnselected,
                },
                {
                    type: "string[]/multiSelectList",
                    property: "detection_sources",
                    multiSelectOptions: detectionSourcesOptions,
                    label: "Detection Source",
                    placeholder: "Select",
                    defaultValue: selectedDetectionSources,
                },
                {
                    type: "string[]/multiSelectList",
                    property: "analytics",
                    multiSelectOptions: analyticsOptions,
                    label: "Analytics",
                    placeholder: "Select",
                    defaultValue: selectedAnalytics,
                },
                {
                    type: "string[]/multiSelectList",
                    property: "asset_groups",
                    multiSelectOptions: assetGroupsOptions,
                    label: "Asset Groups",
                    placeholder: "Select",
                    defaultValue: selectedAssetGroups,
                },
            ];
            this.incidentConditionFormElements = this.generateDynamicFormValues(incidentConditionFormValues);
        } catch(error:any) {
            console.error("Error generating Incident conditions form", error);
            this.notifyError("incidents-conditions-form", error);
        }
    }

    /**
     * Generate schedule condition form
     */
    generateScheduleConditionsForm() {
        const scheduleOptions = [
            { label: "Interval", value: 'interval' },
            { label: "Run Once", value: 'datetime' },
            { label: "Recurring", value: 'cron' },
        ];
        const scheduleConditionForm = [
            {
                type: "string/radio",
                property: "scheduleType",
                options: scheduleOptions,
                label: "Time Based Trigger",
                defaultValue: this.triggerMethod === 'schedule' ? this.triggerType : 'interval',
            },
        ];
        this.scheduleConditionFormElements = this.generateDynamicFormValues(scheduleConditionForm);

        const scheduleIntervalForm: AlDynamicFormElementDescriptor[] = [
            {
                type: "number/input",
                property: "delta",
                label: "Repeat Every",
                optional: false,
                options: [],
                defaultValue: this.trigger?.delta ?? 1,
                minValue: 1,
                cssClass: 'u-col-6'
            },
            {
                type: "string/dropdown",
                property: "unit",
                label: "",
                optional: false,
                options: [
                    { label: 'Seconds', value: 'seconds' },
                    { label: 'Minutes', value: 'minutes' },
                    { label: 'Hours', value: 'hours' },
                    { label: 'Days', value: 'days' },
                    { label: 'Weeks', value: 'weeks' }
                ],
                defaultValue: this.trigger?.unit ?? 'hours',
                cssClass: 'u-col-6'
            },
        ];
        this.scheduleIntervalFormElements = this.generateDynamicFormValues(scheduleIntervalForm);

        const scheduleDatetimeForm: AlDynamicFormElementDescriptor[] = [
            {
                type: "object/calendar",
                property: "selectedDateTime",
                label: "Run On",
                placeholder: "Choose...",
                optional: false,
                options: [],
                defaultValue: (this.trigger?.type === 'datetime' && this.trigger?.date)? new Date(this.trigger.date.trim().replace(/ /g,"T")): undefined,
                minValue: 1,
                dateFormat: "dd M yy",
                showTime: true,
                cssClass: 'u-col-6'
            },
            {
                type: "string/dropdown",
                property: "timezone",
                label: "",
                optional: false,
                options: this.timezones,
                defaultValue: this.trigger?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
                cssClass: 'u-col-6'
            },
        ];
        this.scheduleDatetimeFormElements = this.generateDynamicFormValues(scheduleDatetimeForm);

        /**
         * Init default values for schedule form
         */
        this.cronValues = {
            day: this.trigger?.day ?? '*', // default to daily
            hour: this.trigger?.hour ?? '0',
            minute: this.trigger?.minute ?? '0',
            second: this.trigger?.second ?? '0',
            // detect users current timezone
            timezone: this.trigger?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        this.cronValuescharge=true;
    }

    /**
     * getInitialSelectedMitre takes the tree of MITRE options and the initial selected values in Responder format
     * and returns the corresponding selected nodes from the tree
     */
    getInitialSelectedMitre(mitreNodes: TreeNode[], initialValues: AlResponderMitre[] = []): TreeNode[] {
        let selectedNodes: TreeNode[] = [];
        let parentNodesToCheck: TreeNode[] = [];
        for (const element of initialValues) {
            const parentNode = mitreNodes.find((node: TreeNode) => node.label === element.tactic);
            if (element?.technique && parentNode?.children) {
                const child = parentNode.children.find((child: TreeNode) => child.label === element.technique);
                if (child) {
                    selectedNodes.push(child);
                    if (!parentNodesToCheck.some((node: TreeNode) => node.key === parentNode.key)) {
                        parentNodesToCheck.push(parentNode);
                    }
                }
            } else if (parentNode) {
                selectedNodes.push(parentNode);
            }
        }

        selectedNodes.forEach((node: TreeNode) => {
            this.onMitreSelected({ node: node });
        });

        // Add parents nodes (evaluate partial selection)
        this.checkNodes(parentNodesToCheck, selectedNodes);
        return selectedNodes;
    }

    /**
     * takes a list of TreeNodes to infer their partialSelected status and add them to the target list
     */
    checkNodes(nodes: TreeNode[], target: TreeNode[]) {
        for (const node of nodes) {
            let siblingsCounter = 0;
            let partialSelected = false;
            target.forEach((selectedNode) => {
                if (selectedNode?.parent?.key === node.key) {
                    siblingsCounter = siblingsCounter + 1;
                    partialSelected = partialSelected || (selectedNode?.parent?.partialSelected ?? true);
                }
            });
            node.partialSelected = !(node?.children?.length === siblingsCounter) && partialSelected;
            target.push(node);
        }
    }

    onMitreUnselected = (event: {node: TreeNode}) => {
        let unselectedItems: string[] = [];
        this.getMitreStringValues(event.node, unselectedItems);
        this.mitreSelectedValues = this.mitreSelectedValues.filter((item) => {
            return !unselectedItems.includes(item);
        });
    }

    onMitreSelected = (event: {node: TreeNode}) => {
        this.getMitreStringValues(event.node, this.mitreSelectedValues);
    }

    rawMitreToTreeNodes(rawMitre: any): TreeNode[] {
        let nodes: TreeNode[] = [];
        if (rawMitre?.rows && rawMitre.rows.length > 0) {
            for (const element of rawMitre.rows) {
                const tacticName = element[0]; // tactic
                const tacticValue: { [key: string]: {} } = element[1]; // technique
                let tacticChildren: TreeNode[] = [];

                if (tacticName === 'None') {
                    continue;
                }

                let node = {
                    key: `${tacticName}`,
                    label: tacticName,
                    data: tacticName,
                    children: tacticChildren
                };

                for (const [techName] of Object.entries(tacticValue)) {
                    let technique: TreeNode = {
                        key: `${tacticName}:${techName}`,
                        label: techName,
                        data: techName,
                        parent: node
                    };
                    tacticChildren.push(technique);
                }
                nodes.push(node);
            }
        }
        return nodes;
    }

    primaryAction() {
        if (this.alWizard.getIndex() === 2) {
            this.save();
        } else {
            this.alWizard.stepForward();
            this.onStepSelected();
        }
    }

    async save() {
        try {
            let payload: AlResponderPlaybookTrigger = {};
            const inputsTrigger = this.triggerDynamicForm.onSubmit();
            payload = {
                name: inputsTrigger.name as string ?? '',
                description: inputsTrigger.description as string ?? '',
                type: this.triggerType,
                role_id: inputsTrigger.role,
                enabled: inputsTrigger.enabled as boolean,
            };

            if (this.triggerType === 'incident') {
                const inputsIncidents = this.incidentDynamicForm.onSubmit();
                payload.escalated = inputsIncidents?.escalated;
                if (Array.isArray(inputsIncidents?.threat_levels) && inputsIncidents?.threat_levels.length > 0) {
                    payload.threat_levels = inputsIncidents.threat_levels;
                }
                if (Array.isArray(inputsIncidents?.asset_groups) && inputsIncidents?.asset_groups.length > 0) {
                    payload.asset_groups = {
                        member_of: true,
                        groups: inputsIncidents.asset_groups
                    };
                }
                if (Array.isArray(inputsIncidents?.detection_sources) && inputsIncidents?.detection_sources.length > 0) {
                    payload.detection_sources = inputsIncidents.detection_sources;
                }
                if (Array.isArray(inputsIncidents?.mitre_classifications) && inputsIncidents?.mitre_classifications.length > 0) {
                    payload.mitre_classifications = this.getMitreResponderValues();
                }
                if (Array.isArray(inputsIncidents?.analytics) && inputsIncidents?.analytics.length > 0) {
                    payload.analytics = inputsIncidents.analytics;
                }
            } else if (this.triggerType === 'interval') {
                const inputsInterval = this.scheduleIntervalDynamicForm.onSubmit();
                if (inputsInterval?.delta && inputsInterval?.delta.length > 0) {
                    payload.delta = Number(inputsInterval?.delta) as number;
                }
                if (inputsInterval?.unit && inputsInterval?.unit.length > 0) {
                    payload.unit = inputsInterval.unit as string;
                }
            } else if (this.triggerType === 'datetime') {
                const inputsDatetime = this.scheduleDatetimeDynamicForm.onSubmit();
                if (inputsDatetime?.timezone && inputsDatetime?.timezone.length > 0) {
                    payload.timezone = inputsDatetime.timezone;
                }
                if (inputsDatetime?.selectedDateTime) {
                    payload.date = `${inputsDatetime.selectedDateTime.getFullYear()}-${("0" + (inputsDatetime.selectedDateTime.getMonth() + 1)).slice(-2)}-${("0" + inputsDatetime.selectedDateTime.getDate()).slice(-2)} `
                        + `${("0" + inputsDatetime.selectedDateTime.getHours()).slice(-2)}:${("0" + inputsDatetime.selectedDateTime.getMinutes()).slice(-2)}:${("0" + inputsDatetime.selectedDateTime.getSeconds()).slice(-2)}`;
                }
            } else if (this.triggerType === 'cron') {
                payload = {...payload, ...this.cronValues};
            }

            if (this.trigger?.playbooks && this.trigger?.playbooks.length > 0) {
                payload = {...payload, playbooks: this.trigger.playbooks};
            }

            if (this.triggerMethod === 'schedule' && this.trigger?.actions && this.trigger?.actions.length > 0) {
                payload = {...payload, actions: this.trigger.actions};
            }

            this.isLoading = true;
            if (this.trigger?.id && this.editMode) {
                await AlResponderClient.updateTrigger(this.actingAccountId, this.trigger.id, payload);
            } else if (payload) {
                await AlResponderClient.createTrigger(this.actingAccountId, payload);
            }
            this.onClose();
            this.onMessage.emit({type: 'success', msg: `Trigger ${this.editMode ? 'Edited' : 'Created'} Successfully`, action: this.editMode ? 'update' : 'create'});
        } catch (err:any) {
            console.log(err);
            this.notifyError('save', err);
        } finally {
            this.isLoading = false;
        }
    }

    onClose() {
        this.viewHelper.cleanNotifications();
        this.alWizard?.reset();
        this.resetValues();
        this.bottomSheet.hide();
        this.onFormClosed.emit();
    }

    onScheduleTypeChange(changes: {scheduleType: string}) {
        this.triggerType = changes.scheduleType;
    }

    onValidateTrigger(isValid?: boolean) {
        let validity = isValid ?? true;
        if (this.trigger?.actions) {
            validity = !Object.values(this.currentTriggerActionsValidity).includes(false);
        }
        if (this.trigger?.playbooks) {
            validity = validity && !Object.values(this.currentTriggerPlaybooksValidity).includes(false);
        }
        if ((this.trigger?.actions?.length === undefined || this.trigger?.actions.length === 0)
            && (this.trigger?.playbooks === undefined || this.trigger?.playbooks.length === 0)) {
            validity = false;
        }
        this.onTriggerSetStepState(2, validity);
    }

    onAssetGroupsSelected(event: { originalEvent: Event, value: string[] }) {
        if (this.trigger && this.isIncidentTrigger(this.trigger)) {
            if (event.value.length > 0) {
                this.trigger.asset_groups = {
                    member_of: true,
                    groups: event.value
                };
            } else {
                delete this.trigger.asset_groups;
            }
        }
    }

    onStepSelected(step?: WizardStep) {
        const stepIndex = this.alWizard.getIndex();
        this.updateFooterButtons(stepIndex);
        // playbooks or actions should be selected
        const inputs = this.triggerDynamicForm.onSubmit();
        const type = inputs.type as string ?? 'incident';
        this.triggerMethod = type;
        if (this.triggerMethod === 'schedule') {
            this.triggerType = !['cron','interval','datetime'].includes(this.triggerType) ? 'interval' : this.triggerType;
        } else {
            this.triggerType = this.triggerMethod;
            this.onTriggerSetStepState(stepIndex, true);
        }

        if (step !== undefined) {
            this.validateStepState(stepIndex);
        }
        if (stepIndex === 2) {
            this.determinePlaybooksForTriggerType();
            this.onValidateTrigger();
        }
        if (stepIndex === 1 && type === 'incident') {
            this.addEscalatedControl();
        }
    }

    updateFooterButtons(stepIndex: number) {
        if (this.footerOptions?.secondaryAction) {
            this.footerOptions.secondaryAction.hidden = stepIndex === 0;
        }
        if (this.footerOptions.primaryAction) {
            if (stepIndex === 2) {
                this.footerOptions.primaryAction.text = this.editMode ? "Update" : "Save";
            } else {
                this.footerOptions.primaryAction.text = "Next";
            }
        }
    }

    onAddAction(task: AlResponderAction | string) {
        if (typeof task === 'string') {
            return;
        }
        if (this.trigger && !this.trigger?.actions) {
            this.trigger.actions = [];
        }

        const ref = task.action.ref || '';

        if (this.trigger && this.trigger?.actions) {
            this.trigger.actions.push({
                ref,
                parameters: []
            });
        }
        this.selectedActionRef = ref;
        this.selectedActionDisplayName = this.determineActionDisplayName(task.action) || '';
        this.loadTriggerActionParams();
        this.showTaskPalette = false;
    }

    onAddPlaybook(playbook: AlResponderPlaybook | string) {
        if (typeof playbook === 'string') {
            return;
        }
        if (this.trigger && !this.trigger?.playbooks) {
            this.trigger.playbooks = [];
        }

        this.trigger?.playbooks?.push({
            playbook_id: playbook.id ?? '',
            parameters: []
        });
        this.selectedPlaybookId = playbook.id ?? '';
        this.selectedPlaybookDisplayName = playbook.name || '';
        this.generatePlaybookParamsForm();
        this.showTaskPalette = false;
    }

    onSelectTriggerAction(action: AlTaskPaletteItem) {
        const responderAction = (<AlResponderAction>action.value).action;
        this.selectedActionRef = responderAction.ref || '';
        this.selectedActionDisplayName = this.determineActionDisplayName(responderAction) || '';
        this.generatePlaybookParamsForm();
    }

    onSelectTriggerPlaybook(playbook: AlPlaybookPaletteItem) {
        const responderPlaybook = <AlResponderPlaybook>playbook.value;
        this.selectedPlaybookId = responderPlaybook.id || '';
        this.selectedPlaybookDisplayName = responderPlaybook.name || '';
        // this.loadTriggerActionParams();
        this.generatePlaybookParamsForm();
    }

    generatePlaybookParamsForm() {
        this.loadingFormParams = true;
        this.playbookParamsFormElements = [];
        this.selectedTriggerPlaybook = (this.trigger?.playbooks || []).find(act => act.playbook_id === this.selectedPlaybookId) as AlResponderTriggerPlaybookParameter;
        // Get current values
        const currentPlaybookValues: { [key: string]: any } = {};
        this.selectedTriggerPlaybook?.parameters?.forEach(param => {
            currentPlaybookValues[param.name] = param.value;
        });
        try {
            // const params = {payload_type: this.trigger?.type ?? ''};
            const playbookDefinition = this.playbooksAll.find(pb => pb.id === this.selectedPlaybookId);
            const config: AlParameterConfiguration = {
                alertDefaults: true,
                responderInputs: false,
                suggestions: []
            };
            // if (playbookDefinition?.allowed_values) {
            //     config.allowedValues = playbookDefinition.allowed_values;
            // }
            // Exclude special params
            const specialParams = ["account_id", "payload_type", "payload"];
            const inputParams: { [key: string]: AlResponderPlaybookParameter } = {};
            if (playbookDefinition?.parameters) {
                Object.keys(playbookDefinition.parameters).forEach(key => {
                    if (!specialParams.includes(key) && playbookDefinition.parameters && playbookDefinition.parameters[key]) {
                        inputParams[key] = playbookDefinition.parameters[key];
                    }
                });
            }
            this.playbookParamsFormElements = this.parametersUtility.createGeneralElements(
                inputParams,
                {input: currentPlaybookValues} as AlResponderWorkflowTask,
                config
            );
            setTimeout(() => {
                this.loadingFormParams = false;
            });
        } catch (error) {
            this.loadingFormParams = false;
        }
    }

    async loadTriggerActionParams() {

        this.loadingFormParams = true;
        this.actionParamsFormElements = [];
        this.selectedTriggerAction = (this.trigger?.actions || []).find(act => act.ref === this.selectedActionRef) as AlResponderTriggerActionParameter;
        const currentActionValues: { [key: string]: any } = {};
        (this.selectedTriggerAction.parameters || []).forEach(param => {
            currentActionValues[param.name] = param.value;
        });
        try {
            const params = {payload_type: this.trigger?.type ?? ''};
            const actionDefinition: AlResponderAction = await AlResponderClient.getActionByRef(this.actingAccountId, this.selectedActionRef, params);
            const config: AlParameterConfiguration = {
                alertDefaults: true,
                responderInputs: false,
                suggestions: [] // check this with Maryit\Bryan
            };
            if (actionDefinition.allowed_values) {
                config.allowedValues = actionDefinition.allowed_values;
            }
            this.actionParamsFormElements = this.parametersUtility.createGeneralElements(
                actionDefinition.action.parameters as { [key: string]: AlResponderPlaybookParameter },
                {input: currentActionValues} as AlResponderWorkflowTask, // not sure about this, check this with Maryit\Bryan
                config
            );
            const actionsParamsValid = (this.selectedTriggerAction.parameters || []).length > 0;
            this.currentTriggerActionsValidity[this.selectedActionRef] = actionsParamsValid;
            this.isTriggerActionParamsValid(Object.keys((actionDefinition.action.parameters || {})).length > 0 ? actionsParamsValid : true);
        } catch (error:any) {
            console.error(error);
            this.notifyError("action-params", error);
        } finally {
            this.loadingFormParams = false;
        }
    }

    onSwitchPlaybooksOrActions() {
        if (this.trigger) {
            // Reset actions params form
            this.trigger.actions = [];
            this.actionParamsFormElements = [];
            this.selectedActionRef = '';
            this.selectedActionDisplayName = '';
            // Reset playbooks params form
            this.trigger.playbooks = [];
            this.playbookParamsFormElements = [];
            this.selectedPlaybookId = '';
            this.selectedPlaybookDisplayName = '';
        }
    }

    isTriggerActionParamsValid(valid: boolean) {
        this.currentTriggerActionsValidity[this.selectedActionRef] = valid;
    }

    isTriggerPlaybookParamsValid(valid: boolean) {
        this.onValidateTrigger(valid);
        this.currentTriggerPlaybooksValidity[this.selectedPlaybookId] = valid;
    }

    onTriggerPlaybookParamChanges(event: { [key: string]: any }) {
        // review below, this is overkill
        this.selectedTriggerPlaybook.parameters = [];
        Object.keys(event).forEach(k => {
            this.selectedTriggerPlaybook.parameters.push({
                name: k,
                value: event[k]
            });
        });
        this.onValidateTrigger();
    }

    onTriggerActionParamChanges(event: { [key: string]: any }) {
        this.selectedTriggerAction.parameters = [];
        Object.keys(event).forEach(k => {
            this.selectedTriggerPlaybook.parameters.push({
                name: k,
                value: event[k]
            });
        });
    }

    removeTriggerAction(action: AlTaskPaletteItem) {
        const triggerAction = this.findTriggerAction(action);
        if (triggerAction) {
            if (this.trigger && !this.trigger?.actions) {
                this.trigger.actions = [];
            }
            const idx = this.trigger?.actions?.findIndex(act => act.ref === triggerAction.ref) ?? -1;
            if (idx > -1) {
                this.trigger?.actions?.splice(idx, 1);
            }
            this.actionParamsFormElements = [];
            this.selectedActionRef = '';
            this.selectedActionDisplayName = '';
            delete this.currentTriggerActionsValidity[triggerAction.ref];
        } else {
            console.error('Something broke - cannot find existing trigger action to be removed');
        }
    }

    removeTriggerPlaybook(playbook: AlPlaybookPaletteItem) {
        // const triggerAction = this.findTriggerAction(action);
        const triggerPlaybook = this.trigger?.playbooks?.find(p => p.playbook_id === playbook.value?.id);
        if (triggerPlaybook) {
            if (this.trigger && !this.trigger?.playbooks) {
                this.trigger.playbooks = [];
            }
            const idx = this.trigger?.playbooks?.findIndex(playb => playb.playbook_id === triggerPlaybook.playbook_id) ?? -1;
            if (idx > -1) {
                this.trigger?.playbooks?.splice(idx, 1);
            }
            this.playbookParamsFormElements = [];
            this.selectedPlaybookId = '';
            this.selectedPlaybookDisplayName = '';
            delete this.currentTriggerPlaybooksValidity[triggerPlaybook.playbook_id];
        } else {
            console.error('Something broke - cannot find existing trigger action to be removed');
        }
        this.onValidateTrigger();
    }

    isIncidentTrigger(trigger: AlResponderPlaybookTrigger): trigger is AlResponderIncidentTrigger {
        return (trigger as AlResponderIncidentTrigger).hasOwnProperty('escalated');
    }

    addEscalatedControl() {
        const hasEscalatedControl = this.incidentConditionFormElements.find((c) => { return c.property === 'escalated'; });
        if (hasEscalatedControl === undefined) {
            const escalateControl = {
                type: "boolean/inputSwitch",
                property: "escalated",
                label: "Escalated",
                optional: true,
                value: this.trigger?.escalated ?? true,
            };
            this.incidentConditionFormElements.push(this.generateDynamicFormValues([escalateControl])[0]);
            this.incidentConditionFormElements = [... this.incidentConditionFormElements];
        }
    }

    private findTriggerAction(action: AlTaskPaletteItem) {
        const actionRef = (<AlResponderAction>action.value).action.ref;
        return (this.trigger?.actions || []).find(act => act.ref === actionRef);
    }

    private determineActionDisplayName(action: AlResponderActionLong) {
        const displayName = ((action.tags || []).find(tag => tag.name === 'display_name') || {}).value;
        const vendorName = ((action.tags || []).find(tag => tag.name === 'vendor') || {}).value;
        if (displayName) {
            if (vendorName) {
                return `${vendorName}: ${displayName}`;
            }
            return displayName;
        }
        return action.ref;
    }

    private determinePlaybooksForTriggerType() {
        // TODO: handle playbooksAll empty - zero state (?)
        if (this.triggerMethod && ['incident', 'observation'].includes((this.triggerMethod ?? ''))) {
            this.playbooks = this.playbooksAll.filter(pb => {
                return pb.type === this.triggerMethod;
            }).map(pb => this.convertPlaybookToSelectItem(pb));
            this.playbooksFiltered = [...this.playbooksAll.filter(pb => { return pb.type === this.triggerMethod; })];
            this.playbookPaletteItems = this.playbooksFiltered.map(pb => this.editorTreeService.createTaskPaletteItemFromPlaybook(pb));
        } else {
            this.playbooks = this.playbooksAll.map(pb => this.convertPlaybookToSelectItem(pb));
            this.playbooksFiltered = [...this.playbooksAll];
            this.playbookPaletteItems = this.playbooksAll.map(pb => this.editorTreeService.createTaskPaletteItemFromPlaybook(pb));
        }
        if (this.trigger?.playbooks?.length && this.trigger?.playbooks?.length > 0) {
            this.trigger?.playbooks.forEach((tgPlaybook) => {
                const idx = this.playbookPaletteItems.findIndex((item) => {
                    return item.value?.id === tgPlaybook.playbook_id;
                });
                if (idx < 0) {
                    const paletItem = {
                        name: 'Unknown playbook',
                        value: {id: tgPlaybook.playbook_id}
                    };
                    this.playbookPaletteItems.push(paletItem);
                }
            });
        }
        this.playbookPaletteItems.map(playbook => {
            this.playbookLookup[ playbook?.value?.id || ''] = playbook;
        });
        this.playbooksLoaded = true;
    }

    private convertPlaybookToSelectItem(pb: AlResponderPlaybook) {
        return {label: pb.name, value: {playbook_id: pb.id}} as SelectItem;
    }

    private async getActions() {
        // todo - get correct actions...
        try {
            const actions = await AlResponderClient.getActions(this.actingAccountId);
            this.availableActions = this.editorTreeService.getAddOptions(actions);
            this.availableActions.map(action => {
                const responderAction = <AlResponderAction>action.value;
                this.actionsLookup[responderAction.action.ref || ''] = action;
            });
        } catch (err:any) {
            console.error(err);
            this.notifyError("action-params", err);
        }
    }

    /**
     * This method receives a (leaf) node from the MITRE classification tree
     * and returns its string representation for Responder API.
     */
    private getMitreResponderFormat(node: TreeNode): string {
        if (node?.parent) {
            return `${this.getMitreResponderFormat(node.parent)}:${node.label}`;
        } else {
            return node?.label ?? '';
        }
    }

    /**
     * This method takes a node from the MITRE classification TreeNode (it might be a tactic or a technique)
     * and a array (target) to push the leaves of the node in string format.
     */
    private getMitreStringValues(node: TreeNode, target: string[]): void {
        if (node?.children) {
            node.children.forEach((child) => {
                target.push(this.getMitreResponderFormat({parent: node, ...child}));
            });
        } else {
            target.push(this.getMitreResponderFormat(node));
        }
    }


    private getMitreResponderValues(): AlResponderMitre[]  {
        let mitreResponder: AlResponderMitre[] = [];
        for (const mitre of this.mitreSelectedValues) {
            const [tactic, technique] = mitre.split(":");
            let mitreObj: AlResponderMitre = {tactic: tactic};
            if (technique) {
                mitreObj.technique = technique;
            }
            mitreResponder.push(mitreObj);
        }
        return mitreResponder;
    }

    private getMessageError(typeError: ErrorTypes): string {
        return this.defaultErrorMsgs[typeError];
    }

    private notifyError(typeError: ErrorTypes, error: { data: any }): void {
        let errorMsg = error?.data?.errorinfo?.description ?? error?.data?.message ?? this.getMessageError(typeError);
        this.viewHelper.notifyError(errorMsg, 10000, true);
    }

}
