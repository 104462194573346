/**
 * Know the elements to build (input, hidden, password and textarea)
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 * @author Maryit
 *
 * @copyright Alert Logic, Inc 2020
 */

import { Component, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlFormElementBase } from '../../types/al-form-element-base';


@Component({
    selector: 'al-dynamic-form-group',
    templateUrl: './al-dynamic-form-group.component.html',
    styleUrls: ['./al-dynamic-form-group.component.scss']
})
export class AlDynamicFormGroupComponent {
    @Input() elements: AlFormElementBase[];
    @Input() form: FormGroup;
    @Input() customTemplates:{[property:string]:TemplateRef<any>} = {};
}
