<al-defender-session-link></al-defender-session-link>

<ng-container *ngIf="!userLoggedIn">
    <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</ng-container>

<ng-container *ngIf="userLoggedIn">

    <div class="app-layout" [ngClass]="appLayoutClasses">

        <ng-container *ngIf="experience === 'default' ">
            <al-archipeligo17-header *ngIf="!disableNavigationHeader">
                <div class="user-menu-item" *ngIf="!disablePrimaryMenu">
                    <al-archipeligo17-user-menu [menu]="userMenu"></al-archipeligo17-user-menu>
                </div>
                <div class="application-menu" *ngIf="!disablePrimaryMenu">
                    <al-archipeligo17-primary-menu [menu]="primaryMenu"></al-archipeligo17-primary-menu>
                </div>
            </al-archipeligo17-header>
            <ng-container *ngTemplateOutlet="mainContent"></ng-container>
        </ng-container>

        <ng-container *ngIf="experience === 'beta' ">

            <div class="app-layout__header" data-cy="application-header" *ngIf="!disableNavigationHeader">

                <al-archipeligo19-app-header
                    [userMenu]="userMenu"
                    (toggleButtonClick)="toggleNav()">
                </al-archipeligo19-app-header>

                <al-archipeligo19-nav-header
                    [breadcrumbs]="breadcrumbs"
                    [headerActionsMenus]="headerActionsMenus"
                    [addendumToNavTitle]="addendumToNavTitle"
                    [allowUnauthenticatedMenus]="true">
                </al-archipeligo19-nav-header>

            </div>

            <al-archipeligo19-sidenav
                *ngIf="!disablePrimaryMenu"
                [menu]="primaryMenu"
                (toggleButtonClick)="toggleNav()"
                (onHide)="displayNav=false"
                [(displayNav)]="displayNav">
            </al-archipeligo19-sidenav>

            <ng-container *ngTemplateOutlet="mainContent">
            </ng-container>

        </ng-container>

    </div>

</ng-container>

<ng-template #mainContent>
    <div class="page" [ngClass]="{'page--has-sidebar': showTertiaryMenu}">
        <aside class="page__sidebar" *ngIf="showTertiaryMenu && !disableTertiaryMenu">
            <al-tertiary-menu
                [menu]="sidenavMenu"
                [customSidenavRef]="sidenavContentRef"
                [customSidenavBelowRef]="sidenavContentBelowRef"
                [showQuaternaryMenu]="experience !== 'beta'">
            </al-tertiary-menu>
        </aside>

        <main class="page__content">
            <al-content-menu *ngIf="!disableContentMenu && showContentMenu" [menu]="contentMenu" class="u-block"></al-content-menu>
            <ng-container *ngIf="accessAllowed !== false && ! applicationError; else errorContent">
                <ng-content></ng-content>
            </ng-container>
        </main>
    </div>
</ng-template>

<ng-template #errorContent>
    <al-zero-state *ngIf="applicationError">
        <i class="material-icons" zero-state-icon>{{applicationError.icon || 'report_problem'}}</i>
        <span zero-state-title>{{applicationError.title}}</span>
        <p zero-state-description>{{applicationError.description}}</p>
        <div below class="u-py-10">
            <button icon="uk-icon-chevron-right" iconPos="right" label="Try Again" type="button" class="primaryAction" pButton (click)="clearApplicationError()"></button>
        </div>
    </al-zero-state>
</ng-template>

<al-navigation-overlays></al-navigation-overlays>
