import {
    HostBinding, 
    OnInit, 
    AfterContentInit, 
    Component, 
    TemplateRef, 
    Type
} from '@angular/core';
import { ModalRef } from '../modal-ref';
import { ModalConfig } from '../types';


@Component({
  selector: 'ald-modal',
  templateUrl: './ald-modal.component.html',
  styleUrls: ['./ald-modal.component.scss']
})
export class AldModalComponent implements OnInit, AfterContentInit {

  @HostBinding('class') class = 'u-w-100%';

  content: TemplateRef<any> | Type<any>;  // the modal content - is either a TemplateRef or component
  contentType: 'template' | 'component';  // supported content types
  contentIsTemplateRef: TemplateRef<any>; // variable for a TemplateRef
  contentIsComponent: Type<any>;          // variable for a component type
  modal: ModalConfig;                     // Modal Configuration
  modalBackgroundOpen: boolean;
  modalBodyOpen: boolean;

  constructor(private modalRef: ModalRef) {}

  ngOnInit(): void {
    this.content = this.modalRef.content;

    if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.contentIsTemplateRef = this.modalRef.content as TemplateRef<any>;
    } else if (this.content instanceof Type) {
        this.contentType = 'component';
        this.contentIsComponent = this.modalRef.content as Type<any>;
    } else {
      console.error('Modal Content type is not supported:', this.content);
    }

    this.modalRef.triggerClosing().subscribe(() => {
      this.close();
    });

    this.modalRef.togglePrimaryActionSubject$.subscribe((isDisabled: boolean) => {
      this.modal.disablePrimaryButton = isDisabled;
    });

    this.modalRef.headerSubject$.subscribe((header: string) => {
      this.modal.header = header;
    });

    this.modal = {
      primaryAction: true,
      secondaryAction: true
    };
    this.modal = {... this.modal, ...this.modalRef.modalConfig };
    this.modalRef.modalConfig = this.modal;
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.modalBackgroundOpen = true;
      this.modalBodyOpen = true;
    });
  }

  public primaryAction() {
    this.modalRef.didPrimaryAction();

    if (this.modalRef.modalConfig.primaryActionDoesClose) {
      this.close();
    }
  }

  public secondaryAction() {
    this.modalRef.didSecondaryAction();

    if (this.modalRef.modalConfig.secondaryActionDoesClose) {
      this.close();
    }
  }

  /**
   * Closes the modal
   */
  public close(event?: Event) {
    if (event) event.stopPropagation();

    // Starts the transition
    this.modalBackgroundOpen = false;
    this.modalBodyOpen = false;

    // close the modal
    this.modalRef.close();
  }
}