<div class="ald-tree-l-search-bar" [hidden]="!showContentToolbar">
    <!-- chore(*): bad types -->
    <al-content-toolbar 
        [config]="toolbarConfig"
        (onSearched)="onSearch($event)"
        (onFiltersSelection)="onSelectedFilters($any($event))"
        class="remove-top-margin">
    </al-content-toolbar>
</div>
<div class="ald-tree-l-wrapper">
    <div class="ald-tree-l-wrapper__inner">
        <table class="ald-tree">
            <thead>
                <tr>
                    <th *ngFor="let th of columns; let isFirst = first" [style]="isFirst ? 'width: 100%;' : ''">
                        <div *ngIf="isFirst;else thCell" class="ald-tree__expand-control">
                            <button
                                *ngIf="isFirst"
                                (click)="toggleAll($event, filteredData)"
                                type="button"
                                class="ald-tree__btn">
                                <i class="material-icons">{{expanded ? 'unfold_less' : 'unfold_more'}}</i>
                            </button>
                            <span>{{ th.header}}</span>
                        </div>
                        <ng-template #thCell>
                                <div class="ald-tree__cell-wrap">
                                <ng-container *ngIf="th.headerIcon; else headerLabel">
                                        <i  [pTooltip]="th.tooltip" tooltipPosition="top"
                                            [ngClass]="[th.headerIconClass ? th.headerIconClass : 'material-icons']">
                                            {{ th.headerIcon }}
                                        </i>
                                </ng-container>
                                <ng-template #headerLabel>
                                    {{ th.header}}
                                </ng-template>
                            </div>
                        </ng-template>
                    </th>
                </tr>
            </thead>
            <tbody>
                <al-view-helper #alTreeTableViewHelper [loading]="loading"></al-view-helper>
                <ng-container *ngIf="!loading">
                    <ng-container *ngFor="let item of filteredData; let index = index;">
                        <ng-container *ngTemplateOutlet="aldTableRow; context: {index: index, item: item, depth: 0}"></ng-container>
                    </ng-container>
                </ng-container>
                <tr *ngIf="filteredData.length === 0">
                    <td colspan="100%">
                        <al-zero-state flavor="default">
                            <i zero-state-icon [ngClass]="zeroStateClassIcon">{{zeroStateIcon}}</i>
                            <p zero-state-description>{{zeroStateText}}</p>
                        </al-zero-state>
                    </td>
                </tr>
            </tbody>
        </table>
    
        <ng-template #aldTableRow let-index="index" let-node="item" let-depth="depth">
            <tr class="ald-tree__row" [attr.id]="node.id"
                [ngClass]="[node?.selected ? 'ald-tree__row--selected': '']"
                tabindex="0"
                (click)="selectRow($any($event), node, index, depth)"
                (keyup.enter)="selectRow($any($event), node, index, depth)">
                <td>
                    <div class="ald-tree__cell-wrap" [style.padding-left.px]="depth * 8">
                        <div class="ald-tree-item">
                            <div class="ald-tree-toggle">
                                <button
                                    *ngIf="node.items?.length > 0"
                                    (click)="toggleExpand($event, node)"
                                    type="button"
                                    class="ald-tree__btn">
                                        <i class="material-icons">
                                            {{ node.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                                        </i>
                                </button>
                            </div>
                            <div class="ald-tree-item__name">
                                <div *ngIf="node.icon" class="ald-tree-item__icon">
                                    <i  [pTooltip]="node.tooltip" tooltipPosition="top"
                                        [ngClass]="[node.iconClass ? node.iconClass : 'material-icons']">
                                        {{ node.icon }}
                                    </i>
                                </div>
                                <div class="ald-tree-item__text">
                                    {{ node.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
    
                <ng-container *ngFor="let col of columns; let isFirst = first">
                    <td *ngIf="!isFirst" class="ald-tree__no">
                        <div class="ald-tree__cell-wrap">
                            <ng-container *ngIf="col.type === treeTableColumnType.icon; else text">
                                <div class="ald-tree-item__icon">
                                    <i [ngClass]="[
                                            col.fieldIconClass ? col.fieldIconClass : 'material-icons',
                                            col.fieldIconColor
                                        ]"
                                        [style.opacity]="node | alObjectValue: col.fieldIconOpacity">
                                        {{ node | alObjectValue: col.field }}
                                    </i>
                                </div>
                            </ng-container>
                            <ng-template #text><div class="ald-tree-item__text">{{ node | alObjectValue: col.field }}</div></ng-template>
                        </div>
                    </td>
                </ng-container>
            </tr>
    
            <ng-container *ngIf="node.expanded">
                <ng-container *ngFor="let item of node.items; let index = index">
                    <ng-container
                        *ngTemplateOutlet="aldTableRow; context: {index: index, item: item, depth: depth + 1}">
                    </ng-container>
                </ng-container>
            </ng-container>
    
        </ng-template>
    </div>
    <ng-content select="[detailPanel]"></ng-content>
</div>
