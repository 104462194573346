import { Component, HostBinding, OnInit, AfterContentInit } from '@angular/core';
import { ToastRef } from '../toast-ref';
import { ToastConfig } from './../types/al-toast.types';


@Component({
    selector: 'ald-toast',
    templateUrl: './ald-toast.component.html'
})
export class AldToastComponent implements OnInit, AfterContentInit {
    
    toast: ToastConfig; // Toast Configuration
    toastStyle: string;
    toastOpen: boolean = false;

    constructor(private toastRef: ToastRef) {}
    
    ngOnInit(): void {
        this.toast = this.toastRef.toastConfig;
        this.toastStyle = this.toastStyling(this.toast.variant);

        if (this.toast.closeOnTimeout > 0) {

            setTimeout(() => {
                this.close();
            }, this.toast.closeOnTimeout);
        }
        
    }

    ngAfterContentInit(): void {
        setTimeout(() => {
            this.toastOpen = true;
        }, 0);
    }

    /**
     *  Sets the flavour of the Toast
     */ 
    toastStyling(variant): string {
        switch (variant) {
            case 'default':
                return 'u-bg-gray-800 u-text-white';
            case 'success':
                return 'u-bg-success u-text-white';
            case 'warning':
                return 'u-bg-warning u-text-primary';
            case 'danger':
                return 'u-bg-danger u-text-white';
            default:
                return 'u-bg-gray-800 u-text-white';
        }
    }
    
    /**
    * The toast's primary action button
    */
    public primaryAction() {
        this.toastRef.didPrimaryAction();
        
        if (this.toastRef.toastConfig.closeOnPrimaryButtonClick) {
            this.close();
        }
    }
    
    /**
    * The toast's secondary action button
    */
    public secondaryAction() {
        this.toastRef.didSecondaryAction();
        
        if (this.toastRef.toastConfig.closeOnSecondaryButtonClick) {
            this.close();
        }
    }
    
    /**
    * Closes the toast
    */
    public close() {

        // Starts the transition
        this.toastOpen = false;

        // Delay the disposal of the toastRef
        setTimeout(() => {
            this.toastRef.close();
        }, 300);

        
    }
}