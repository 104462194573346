import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AldCommonModule } from '@al/design-patterns/common';
import { AldTooltipModule } from '@al/design-patterns/tooltip';
import * as manifest from './manifest';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        A11yModule,
        AldCommonModule,
        AldTooltipModule,
        PortalModule
    ],
    declarations: [
        ...manifest.MODULE_COMPONENTS,
    ],
    exports: [
        ...manifest.MODULE_COMPONENTS
    ],
})
export class AldFormsModule {}
