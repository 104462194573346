import { FormControl } from '@angular/forms';


export class AlFormValidators {

    /**
     * Validates an Azure subscription id to match the expected regExp
     * @param {FormControl} control
     * @returns Object
     */
     static azureSubscriptionIdValidator(control: FormControl) {
        if(control.value) {
            let regExp = /^[0-9A-Fa-f]{8}(-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/g;
            const isValid = regExp.test(control.value);
            return isValid? null : { pattern: regExp }; 
        }
        return null;
    }
}