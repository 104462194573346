/**
 * A general purpose service to centrally handle event tracking requirements across One Console features
 * This was previously the AlGoogleTagManager service but has now been stripped back since we are no longer 
 * using Google Analytics and are awaiting details of the new provider and how to integrate
 *
 * @author Robert Parker <robert.parker@alertlogic.com>
 *
 */
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class AlUsageTrackingService {

    public trackPageViewEvent(pageTitle: string, pagePath: string) {
        // Intentionally empty
    }

    public trackCustomEvent(eventName: string, eventProps: {[k:string]:any}) {
        // Intentionally empty
    }
    
}
