<span
    class="ald-badge"
    [title]="title"
    [ngClass]="[badgeStyles, sizeStyles, roundedStyles, iconStyles]"
>
    <ald-icon
        *ngIf="icon"
        [icon]="icon"
        [iconClass]="iconClass"
        color="inherit"
        size="sm"
        class="ald-badge__icon"
    ></ald-icon>

    <span *ngIf="label" class="ald-badge__label">
        {{ label }}
    </span>

</span>
