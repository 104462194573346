<div class="notice-container">

    <ng-container *ngIf="visible">
        <div class="notice">
            <div class="icon"><i class="material-icons">{{config.icon}}</i></div>
            <div class="text">
                <strong *ngIf="config.name">{{config.name}}: </strong>
                <ng-container *ngIf="config.description">
                    {{config.description}}
                </ng-container>
                <ng-container *ngIf="config.linkDescription">
                    <a *ngIf="config.redirectable"
                       [style]="{'cursor': 'pointer'}"
                       [href]="config.link"
                       [title]="config.linkTitle">{{config.linkDescription}}</a>.
                    <a *ngIf="!config.redirectable"
                       [style]="{'cursor': 'pointer'}"
                       (click)="linkClicked.emit()"
                       [title]="config.linkTitle">{{config.linkDescription}}</a>.
                </ng-container>
                <div *ngIf="config.contentResourceId" [alExternalHtml]="config.contentResourceId"></div>
            </div>
            <span class="material-icons dismiss"
                  (click)="dissmissClicked()">
                close
            </span>
        </div>
    </ng-container>

</div>
