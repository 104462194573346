import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';


@Component({
    selector: 'ald-form-group-errors',
    templateUrl: './ald-form-group-errors.component.html',
    styleUrls: ['./ald-form-group-errors.component.scss'],
})
export class AldFormGroupErrorsComponent {
    @Input() control?: AbstractControl;
    @Input() label = '';
    @Input() requiredErrorMessage?: string; // custom error message to pass for the errors.required
    // @Input() validationPattern?: string;
    @Input() patternErrorMessage?: string; // custom error message to pass for the errors.pattern
    // @Input() customErrorMessage?: string; // custom error message to pass for the errors.customvalidator
    @Input() minValue?: number | string;
    @Input() maxValue?: number | string;
    @Input() minLength?: number;
    @Input() maxLength?: number;
}
