<al-bottom-sheet #bottomSheet
    [allowContent]="false"
    [footerOptions]="isLoading ? undefined : footerOptions"
    (onPrimaryFooterAction)="primaryAction()"
    (onSecondaryFooterAction)="alWizard.stepBack()"
    >
    <ng-template alHeaderTemplate>
        <al-view-helper #viewHelper [notifyPanel]="true"></al-view-helper>
            <al-wizard-stepper #alWizard *ngIf="bottomSheet.visible" [headerOptions]="headerOptions" [loading]="isLoading"
                (onStepSelected)="onStepSelected($event)" (onCancelButton)="onClose()">

                <!-- First step: Triggers -->
                <al-step stepTitle="Trigger">
                    <div class="firs-step">
                        <al-dynamic-form #triggerDynamicForm [elements]="triggerFormElements"
                            (isValid)="onTriggerSetStepState(0,$event)">
                        </al-dynamic-form>
                    </div>
                </al-step>

                <!-- Second step: Condition -->
                <al-step stepTitle="Condition">
                    <al-dynamic-form #incidentDynamicForm
                        *ngIf="triggerMethod === 'incident' && incidentConditionFormElements.length > 0"
                        [elements]="incidentConditionFormElements">
                    </al-dynamic-form>
                    <ng-container *ngIf="triggerMethod === 'observation'">
                        <div>
                            <p class="observation-conditional-text">Click NEXT to proceed. An observation trigger does not require a condition.</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="triggerMethod === 'schedule' && scheduleConditionFormElements.length > 0">
                        <al-dynamic-form #scheduleDynamicForm [elements]="scheduleConditionFormElements"
                            (onChanges)="onScheduleTypeChange($event)">
                        </al-dynamic-form>
                        <div *ngIf="triggerType === 'interval' && scheduleIntervalFormElements.length > 0">
                            <al-dynamic-form #scheduleIntervalDynamicForm [elements]="scheduleIntervalFormElements"
                                (isValid)="onTriggerSetStepState(1,$event)">
                            </al-dynamic-form>
                        </div>
                        <div *ngIf="triggerType === 'datetime' && scheduleDatetimeFormElements.length > 0">
                            <al-dynamic-form #scheduleDatetimeDynamicForm [elements]="scheduleDatetimeFormElements"
                                (isValid)="onTriggerSetStepState(1,$event)">
                            </al-dynamic-form>
                        </div>
                        {{cronValues.length}}
                        <div *ngIf="triggerType === 'cron' && cronValuescharge" class="cron-scheduler">
                            <al-trigger-cron-scheduler [trigger]="cronValues" [timezones]="timezones"
                                (onValidateTriggerSchedule)="onTriggerSetStepState(1,$event)">
                            </al-trigger-cron-scheduler>
                        </div>
                    </ng-container>
                </al-step>

                <!-- Third step: Response -->
                <al-step stepTitle="Response">
                    <div class="response-step">
                        <ng-container *ngIf="triggerMethod === 'schedule'">
                            <div class="u-grid u-grid-nogutter">
                                <div class="form-row">
                                    <label>Run playbooks or actions</label>
                                </div>
                                <div class="u-col-12 form-row">
                                    <p-radioButton name="group1" value="playbooks" label="Playbooks" [(ngModel)]="runPlaybooksOrActions" (onClick)="onSwitchPlaybooksOrActions()"></p-radioButton>
                                </div>
                                <div class="u-col-12 form-row">
                                    <p-radioButton name="group1" value="actions" label="Actions" [(ngModel)]="runPlaybooksOrActions" (onClick)="onSwitchPlaybooksOrActions()"></p-radioButton>
                                </div>
                            </div>
                        </ng-container>
                        <!-- Run Playbooks -->
                        <ng-container *ngIf="runPlaybooksOrActions === 'playbooks'">
                            <div class="u-grid u-grid-nogutter" *ngIf="playbooksLoaded">
                                <div class="u-col-6">
                                    <h4>Playbooks</h4>
                                    <div *ngFor="let playbook of trigger?.playbooks"
                                        (click)="onSelectTriggerPlaybook(playbookLookup[playbook.playbook_id])"
                                        class="u-grid u-grid-nogutter trigger-action"
                                        [class.active]="selectedTriggerPlaybook && selectedTriggerPlaybook.playbook_id === playbook.playbook_id">
                                        <al-task-palette-item [item]="$any(playbookLookup[playbook.playbook_id])"
                                            [removeable]="true" (onRemoveClick)="removeTriggerPlaybook($any($event))"
                                            [hasError]="currentTriggerPlaybooksValidity[playbook.playbook_id] === false"
                                            errorTooltipMessage="Action parameter values are invalid">
                                        </al-task-palette-item>
                                    </div>
                                    <button type="button" pButton class="flat" iconPos="right"
                                        icon="ui-icon-chevron-down" label="Add Playbook"
                                        (click)="showTaskPalette = !showTaskPalette">
                                    </button>
                                    <al-task-palette *ngIf="showTaskPalette" title="Add Playbook"
                                        [listOptions]="$any(playbookPaletteItems)" (selected)="onAddPlaybook($any($event))"
                                        (closePalette)="showTaskPalette = false;">
                                    </al-task-palette>
                                </div>

                                <div class="u-col-6">
                                    <div *ngIf="selectedPlaybookId !== ''">
                                        <h4>{{selectedPlaybookDisplayName}}</h4>
                                        <div>
                                            <al-view-helper [loading]="loadingFormParams"></al-view-helper>
                                            <al-dynamic-form #playbookParamsForm
                                                [elements]="playbookParamsFormElements"
                                                (isValid)="isTriggerPlaybookParamsValid($event)"
                                                (onChanges)="onTriggerPlaybookParamChanges($event)">
                                            </al-dynamic-form>
                                            <div *ngIf="!loadingFormParams && playbookParamsFormElements.length === 0">
                                                This Playbook requires no additional parameters to be configured.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Run Actions -->
                        <ng-container *ngIf="runPlaybooksOrActions === 'actions'">
                            <div class="u-grid u-grid-nogutter">
                                <div class="u-col-6">
                                    <h4>Actions</h4>
                                    <div *ngFor="let action of trigger?.actions" (click)="onSelectTriggerAction(actionsLookup[action.ref])"
                                        class="u-grid u-grid-nogutter trigger-action"
                                        [class.active]="selectedTriggerAction && selectedTriggerAction.ref === action.ref">
                                        <al-task-palette-item [item]="actionsLookup[action.ref]" [removeable]="true"
                                            (onRemoveClick)="removeTriggerAction($event)"
                                            [hasError]="currentTriggerActionsValidity[action.ref] === false"
                                            errorTooltipMessage="Action parameter values are invalid">
                                        </al-task-palette-item>
                                    </div>
                                    <button type="button" pButton class="flat" iconPos="right" icon="ui-icon-chevron-down" label="Add Action"
                                        (click)="showTaskPalette = !showTaskPalette">
                                    </button>
                                    <al-task-palette *ngIf="showTaskPalette" title="Add Action" [listOptions]="availableActions"
                                        (selected)="onAddAction($event)" (closePalette)="showTaskPalette = false;">
                                    </al-task-palette>
                                </div>
                                <div class="u-col-6 form-row">
                                    <div *ngIf="selectedActionRef !== ''">
                                        <h4>{{selectedActionDisplayName}}</h4>
                                        <div>
                                            <al-view-helper [loading]="loadingFormParams"></al-view-helper>
                                            <al-dynamic-form #actionParamsForm *ngIf="actionParamsFormElements.length > 0" [elements]="actionParamsFormElements"
                                                (isValid)="isTriggerActionParamsValid($event)" (onChanges)="onTriggerActionParamChanges($event)">
                                            </al-dynamic-form>
                                            <div *ngIf="!loadingFormParams && actionParamsFormElements.length === 0">
                                                This action requires no additional parameters to be configured.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </al-step>
            </al-wizard-stepper>
    </ng-template>
</al-bottom-sheet>
