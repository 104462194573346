<al-layout-options pageClass="white"></al-layout-options>
<div class="pseudo-authentication-view " [ngClass]="{'authenticated': !unauthenticated}">
    <div class="pseudo-authentication-view__contents">
        <div>

            <div class="header" *ngIf="unauthenticated">
                <div class="circle-logo"><i class="icon-logo al al-logo"></i></div>
                <h2 class="title">Authentication Method</h2>
            </div>

            <al-view-helper [loading]="!ready"></al-view-helper>

            <form *ngIf="ready">

                <div class="explanatory" class="u-grid">
                    <div class="u-col-12" *ngIf="unauthenticated">
                        You are currently not logged in.
                    </div>
                    <ng-container *ngIf="!unauthenticated">
                        <div class="u-col-12" *ngIf="fortraIdP">
                            You are currently using your FORTRA login credentials.
                        </div>
                        <div class="u-col-12" *ngIf="!fortraIdP && fortraEligible">
                            You are currently using Alert Logic login credentials but are eligible to use 
                            <a href="https://support.alertlogic.com/hc/en-us/articles/22949290594075-Fortra-Single-Login-and-Identity-Provider-Frequently-Asked-Questions" target="_blank">Fortra single login authentication.</a>
                        </div>
                        <div class="u-col-12" *ngIf="!fortraIdP && ! fortraEligible">
                            You are currently using your Alert Logic login credentials.
                        </div>
                    </ng-container>
                    <div class="u-col-12 chonky" *ngIf="splash">
                        <ol class="u-py-3">
                            <li>
                                Ensure <a href="https://www.agari.com/blog/transport-layer-security-tls-emailencryption" target="_blank">TLS encryption</a> 
                                is enabled on your e-mail server(s) to receive secure messages from Fortra.
                            </li>
                            <li>Only select the Microsoft or Google option if you have an existing account that uses the same username as your Alert Logic username.</li>
                            <li>
                                If not, select the "Continue with email" option.
                                <ul class="u-py-3">
                                    <li>
                                        Ensure one-time password authenticator app (such as Google Authenticator or 1Password) is installed on your mobile device.
                                    </li>
                                    <li>
                                        Use the 'Forgot Password?' link to create your password and continue.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Configure your browser to 
                                <a href="https://support.alertlogic.com/hc/en-us/articles/360018127132-Allow-Exceptions-for-Third-Party-Cookies" target="_blank">allow exceptions for third-party cookies.</a>
                            </li>
                        </ol>
                        <p>
                            Click the <strong>REGISTER WITH FORTRA</strong> button to create Fortra login credentials for this user.
                        </p>
                    </div>
                </div>

                <div class="action-buttons u-grid u-pt-8">
                    <div class="u-col-4" *ngIf="unauthenticated || ! fortraIdP || splash">
                        <button pButton
                                type="button"
                                class="u-mb-2 u-mr-2 full-width"
                                [ngClass]="{ secondaryAction: ! splash, primaryAction: splash }"
                                [label]="splash ? 'Register with FORTRA' : 'Use Fortra Authentication'"
                                (click)="useFortraAuthentication()"></button>
                    </div>
                    <div class="u-col-4" *ngIf="unauthenticated || fortraIdP">
                        <button pButton
                                type="button"
                                class="secondaryAction u-mb-2 u-mr-2 full-width"
                                label="Use Alert Logic/AIMS Authentication"
                                (click)="useLegacyAuthentication()"></button>
                    </div>
                    <div class="u-col-4" *ngIf="splash && ! fortraIdP">
                        <button pButton
                                type="button"
                                class="secondaryAction u-mb-2 u-mr-2 full-width"
                                label="Skip For Now"
                                (click)="skipForNow()"></button>
                    </div>
                </div>

            </form>

        </div>

    </div>
</div>
