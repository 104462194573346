import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AldTooltipComponent } from './ald-tooltip/ald-tooltip-container/ald-tooltip-container.component';
import { AldTooltipDirective } from './ald-tooltip/ald-tooltip.directive';


@NgModule({
  declarations: [AldTooltipDirective, AldTooltipComponent],
  imports: [CommonModule, OverlayModule],
  exports: [AldTooltipDirective],
})
export class AldTooltipModule {}