export interface AlTreeTableColumnDef {
    header: string;
    headerTitle?: string; // Column Header title
    headerIcon?: string;
    headerIconClass?: string;
    tooltip?: string;
    type?: AlTreeTableColumnType; // Data type
    field: string; // field value for column per row
    fieldTitle?: string; // Field title
    fieldIconClass?: string;
    fieldIconColor?: string;
    fieldIconOpacity?: string;
    filters?: AlTreeOptionItem[];
}

export enum AlTreeTableColumnType {
    text = 'text',
    icon = 'icon'
}

export interface AlTreeOptionItem {
    id: string | number;
    value?: any;
    label?: string;
    description?: string;
    icon?: string;
    tooltip?: string;
    iconClass?: string;
    selected?: boolean;
    expanded?: boolean;
    indeterminate?: boolean;
    highlighted?: boolean;
    disabled?: boolean;
    items?: AlTreeOptionItem[];
    data?: any;
}
