import {
    Component, 
    EventEmitter, 
    Input, 
    OnChanges, 
    OnInit, 
    Output, 
    SimpleChanges, 
    ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AldOptionItem, PaginationType } from '@al/design-patterns/common';
import { AldDropdownComponent } from '@al/design-patterns/forms';


export interface AldPaginationParams {
    offset: number,
    limit: number,
    page?: number,
    sortField?: string,
    sortOrder?: string
}

@Component({
    selector: 'ald-pagination',
    templateUrl: './ald-pagination.component.html',
    styleUrls: ['./ald-pagination.component.scss']
})
export class AldPaginationComponent implements OnInit, OnChanges {

    @ViewChild('resultsPerPage') resultsPerPage: AldDropdownComponent;

    @Input() totalResults?: number = 0;
    @Input() totalPages?: number = 1;
    @Input() rowsPerPage?: number = 25;
    @Input() currentPage?: number = 1;
    @Input() showResultsPerPageSelector?: boolean = true;
    @Input() summary?: string;
    @Input() paginationType?: PaginationType = 'offset';
    @Input() isLoading = false;

    @Output() didChangeResultsPerPage: EventEmitter<number> =new EventEmitter();
    @Output() didNextPage: EventEmitter<void> = new EventEmitter();
    @Output() didBackPage: EventEmitter<void> = new EventEmitter();
    @Output() didFirstPage: EventEmitter<void> = new EventEmitter();
    @Output() didLastPage: EventEmitter<void> = new EventEmitter();
    @Output() didSelectPageNumber: EventEmitter<number> = new EventEmitter();
    @Output() paginationAction: EventEmitter<AldPaginationParams> = new EventEmitter();

    currentPage$: Subject<number> = new Subject<number>();
    paginationParams: AldPaginationParams;
    paginationOptions: AldOptionItem[] = [
        {label: '25 per page', value: 25},
        {label: '50 per page', value: 50},
        {label: '100 per page', value: 100}
    ];

    noOfPages: number;

    ngOnInit() {
        // Current Page Subject debounces the input value
        this.currentPage$.pipe(
            debounceTime(300),
            distinctUntilChanged()
            ).subscribe(value => {
                this.currentPage = value;
                this.setPaginationParams();
                this.didSelectPageNumber.emit(value);
            });

        this.setPaginationParams(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.totalResults?.currentValue !== changes?.totalResults?.previousValue) {
            this.setTotalPages();
        }
    }

    /**
     * Pagination Next Page
     */
     public goToNextPage() {
        this.currentPage++;
        this.setPaginationParams();
        this.didNextPage.emit();
    }

    /**
     * Pagination back page
     */
    public goToPreviousPage() {
        this.currentPage--;
        this.setPaginationParams();
        this.didBackPage.emit();
    }

    /**
     * Pagination first page
     */
    public goToFirstPage() {
        this.currentPage = 1;
        this.setPaginationParams();
        this.didFirstPage.emit();
    }

    /**
     * Pagination last page
     */
    public goToLastPage() {
        this.currentPage = this.totalPages;
        this.setPaginationParams();
        this.didLastPage.emit();
    }

    /**
     * Emits the didChangeResultsPerPage event with the selected value
     * @param selected The selected number of results per page
     */
    public selectNumberOfResultsPerPage(selected) {
        this.resultsPerPage.close();
        this.resultsPerPage.label = selected.option.value + ' per page';
        this.rowsPerPage = selected.option.value;
        this.setTotalPages();
        this.setPaginationParams(this.paginationType === 'offset' ? false : true);
        this.didChangeResultsPerPage.emit(selected.option.value);
    }

    /**
     * @param pageNumber
     */
    public currentPageInputChange(pageNumber: number) {
        // limit the current page number to the number of pages
        const page: number = pageNumber > this.totalPages ? +this.totalPages : +pageNumber;
        this.currentPage$.next(page);
    }

    private setPaginationParams(skipEventEmit = false) {
        this.paginationParams = {
            limit: this.rowsPerPage,
            offset: (this.currentPage-1) * this.rowsPerPage,
            page: this.currentPage
        }

        if(!skipEventEmit) {
            this.paginationAction.emit(this.paginationParams);
        }
    }

    private setTotalPages(){
        this.totalPages = Math.ceil(this.totalResults / this.rowsPerPage);
    }

}
