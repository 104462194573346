<div class="password-reset">
    <al-layout-options [disableHeader]="true" pageClass="white"></al-layout-options>
    <al-view-helper #viewHelper
                    [notifyPanel]="true"
                    [loading]="loading"></al-view-helper>

    <p-card class="card" *ngIf="!invalidParameters && state !== 'sent' && !loading">

        <ng-template pTemplate="header">
            <div class="header">
                <div class="circle-logo"><i class="icon-logo al al-logo"></i></div>
                <div class="title">
                    <h2>Reset Your Password</h2>
                </div>
            </div>
          
        </ng-template>


        <ng-template pTemplate="content">
            <div class="reset-text">
               {{resetText}}
            </div>
            <div>
                <al-dynamic-form #dynamicForm
                                 (onChanges)="onDynamicFormChanges()"
                                 [elements]="dynamicFormElements"
                                 (isValid)="validate($event)"></al-dynamic-form>
                <div class="new-password-matched-error" *ngIf="newPasswordMatchedError">
                    Your new password and confirmation password do not match
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="footer">
                <button type="button"
                [disabled]="!isDynamicFormValid"
                pButton
                class="primaryAction"
                [ngClass]="isDynamicFormValid ? 'allowed' : 'not-allowed'"
                (click)="submit()"
                type="submit">RESET PASSWORD</button>
            </div>
           
        </ng-template>

    </p-card>

    <p-card class="card" *ngIf="state === 'sent'">

        <ng-template pTemplate="header">
            <div class="header">
                <div class="circle-ok">
                    <i class="material-icons done-icon">done</i>
                </div>
                <div class="title">
                    <h2>{{backToLoginHeaderTitle}}!</h2>
                </div>
            </div>
           
        </ng-template>


        <ng-template pTemplate="content">
            <div class="reset-text">
                <p>
                   {{backToLoginMessage}}
                </p>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <div class="footer">
                <button type="button"
                        pButton
                        class="primaryAction"
                        (click)="returnToLogin()"
                        type="submit">
                        BACK TO LOGIN SCREEN
                </button>
            </div>
        </ng-template>
    </p-card>



</div>