/**
 * It allows the construction a radio button element
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementRadio extends AlFormElementBase<string> {

    public controlTemplate = "radio";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
    }

    public createFormControl() {
        let formControl = new FormControl( this.value );
        if(this.disabled) {
            formControl.disable();
        }
        return formControl;
    }

    /**
     * This enumerates through the raw controls (one per radio item) and returns the value of the first selected item.
     */
    getAnswer() {
        return this.getControlValue();
    }
}
