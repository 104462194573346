import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AlScoreCountDescriptor } from '../types/al-score-count-descriptor.type';


@Component({
    selector: 'al-score-count',
    templateUrl: './al-score-count.component.html',
    styleUrls: ['./al-score-count.component.scss']
})
export class AlScoreCountComponent {

    @Input() config: AlScoreCountDescriptor;
    @Input() displayMode?: string = "big";
    @Input() name?: string = '';
    @Input() showCount?: boolean = false;
    @Input() limit4Levels?: boolean = false;
    @Input() selected?: string = '';
    @Input() threatiness:number = 0;
    @Output() onSelected: EventEmitter<string> = new EventEmitter();

    selectItem(severity: string): void {
        this.onSelected.emit(severity);
    }
}
