import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe( {
    name: 'aldFormatNumber'
} )
export class AldFormatNumberPipe implements PipeTransform {
    transform(data?:number): string|number {
        const formatsMap = [
            { suffix: 't', threshold: 1e12 },
            { suffix: 'b', threshold: 1e9  },
            { suffix: 'm', threshold: 1e6  },
            { suffix: 'k', threshold: 1e3  },
        ];

        if (data === 0 ){
            return data;
        }

        const found = formatsMap.find((x) => Math.abs(data) >= x.threshold);
        let formatted:string|number;
        if (found) {
            formatted = (data / found.threshold).toFixed(1) + found.suffix;
        } else {
            formatted = data
        }
        return formatted;
    }
}
