<ng-template cdkPortal #dialogTemplate="cdkPortal">
    <div class="al-dialog has-ald-components u-font-system" [ngClass]="verticalPosition === 'center' ? 'al-dialog--center' : ''">

      <div class="al-dialog__box"
        [ngClass]="[
          width === 'xs' ? 'al-dialog__box--xs' :
          width === 'sm' ? 'al-dialog__box--sm' :
          width === 'md' ? 'al-dialog__box--md' :
          width === 'lg' ? 'al-dialog__box--lg' :
          'al-dialog__box--md'
        ]">

        <!-- Modal Wrapper -->
        <div class="al-dialog__wrapper">

          <!-- Head -->
          <div class="al-dialog__head">
            <div class="al-dialog-header">
              <div class="al-dialog-header__heading">
                  <ng-container *ngIf="headerTpl; else defaultHeader" [ngTemplateOutlet]="headerTpl"></ng-container>
              </div>
              <ald-button icon="close" variant="ghost" size="sm" (click)="close($event)"></ald-button>
            </div>
          </div>

          <!-- Body -->
          <div class="al-dialog__body" [ngClass]="[background ? 'u-background' : 'u-foreground']">
            <ng-container [ngTemplateOutlet]="contentTpl"></ng-container>
          </div>

          <!-- Footer -->
          <div class="al-dialog__footer">
              <ng-container *ngIf="footerTpl; else defaultFooter" [ngTemplateOutlet]="footerTpl"></ng-container>
          </div>

        </div>

      </div>

      <!-- Backdrop -->
      <div
        class="al-dialog__overlay"
        [ngClass]="modalBackgroundOpen ? 'al-dialog__overlay--open' : 'al-dialog__overlay--close'"
        (click)="close($event)">
      </div>

    </div>
</ng-template>
<ng-template #defaultHeader>
    <ald-icon *ngIf="headerIcon" [icon]="headerIcon" [iconClass]="$any(headerIconClass)" size="sm"></ald-icon>
    <h1>{{headerTitle}}</h1>
</ng-template>
<ng-template #defaultFooter>
    <ald-button *ngIf="secondaryButton" 
                [label]="secondaryButton.label" 
                [icon]="secondaryButton.icon || null"
                [iconClass]="$any(secondaryButton.iconClass)"
                [variant]="$any(secondaryButton.variant || 'secondary')" 
                (click)="emitAction('secondary')"></ald-button>
    <ald-button *ngIf="primaryButton" 
                [label]="primaryButton.label" 
                [icon]="primaryButton.icon || null"
                [iconClass]="$any(primaryButton.iconClass)"
                [variant]="$any(primaryButton.variant || 'primary')" 
                [disabled]="primaryActionDisabled" 
                (click)="emitAction('primary')"></ald-button>
</ng-template>
