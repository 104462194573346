<div class="al-search-bar"
  data-cy="search-bar">
  <div class="bar-content p-float-label" [ngStyle]="{'width': width}" >
    <input #searchBar [id]="inputId" type="text" [placeholder]="showLabel?'':placeholder" [formControl]="searchControl"
      (focusout)="onFocusOutEvent($event)" pInputText (keyup.enter)="executeSearch()"
      data-cy="search-bar-input" data-e2e="search-bar-input">
    <label *ngIf="showLabel" for="al-search-input">{{placeholder}}</label>
    <i class="material-icons" [ngClass]="searchIcon" (click)="executeSearch()" data-e2e="search-bar-icon">search</i>
    <div class="input-error" *ngIf="invalidPattern">
      {{invalidSearchPatternText}}
    </div>
  </div>
</div>
