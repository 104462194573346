import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { MarkdownModule } from 'ngx-markdown';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng-lts/accordion';
import { AutoCompleteModule } from 'primeng-lts/autocomplete';
import { ButtonModule } from 'primeng-lts/button';
import { CalendarModule } from 'primeng-lts/calendar';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { DropdownModule } from 'primeng-lts/dropdown';
import { InputNumberModule } from 'primeng-lts/inputnumber';
import { InputSwitchModule } from 'primeng-lts/inputswitch';
import { InputTextModule } from 'primeng-lts/inputtext';
import { InputTextareaModule } from 'primeng-lts/inputtextarea';
import { ListboxModule } from 'primeng-lts/listbox';
import { MultiSelectModule } from 'primeng-lts/multiselect';
import { OverlayPanelModule } from 'primeng-lts/overlaypanel';
import { RadioButtonModule } from 'primeng-lts/radiobutton';
import { SelectButtonModule } from 'primeng-lts/selectbutton';
import { TabViewModule } from 'primeng-lts/tabview';
import { TooltipModule } from 'primeng-lts/tooltip';
import { TreeModule } from 'primeng-lts/tree';
import { TreeTableModule } from 'primeng-lts/treetable';
import { VirtualScrollerModule } from 'primeng-lts/virtualscroller';
import { NgGenericComponentsModule } from '@al/ng-generic-components';
import * as manifest from './manifest';


@NgModule({
  declarations: [
    ...manifest.MODULE_COMPONENTS,
    ...manifest.MODULE_DIRECTIVES,
    ...manifest.MODULE_PIPES,
  ],
  imports: [
    AccordionModule,
    AutoCompleteModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
    ListboxModule,
    MonacoEditorModule,
    NgGenericComponentsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    TooltipModule,
    MarkdownModule.forRoot(),
    MultiSelectModule,
    CalendarModule,
    SelectButtonModule,
    TabViewModule,
    TreeModule,
    TreeTableModule,
    OverlayPanelModule,
    VirtualScrollerModule
  ],
  exports: [
    ...manifest.MODULE_COMPONENTS,
    ...manifest.MODULE_DIRECTIVES,
    ...manifest.MODULE_PIPES,
    FormsModule, 
    ReactiveFormsModule,
  ],
  providers: [
    ...manifest.MODULE_SERVICES
  ]
})
export class NgFormsComponentsModule {
}
