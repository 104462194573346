<div class="al-header">
  <div class="header-container">
    <div class="navbar-left">
      <a href="/#/" >
        <img width="254" src="/assets/images/logos/al-defender-logo.svg" alt="Alert Logic logo" class="al-logo">
        <img width="254" src="/assets/images/logos/al-fta-green-logo.svg" alt="Alert Logic logo" class="al-fta-logo">
      </a>
      <ng-content select="h1" class="header-title"></ng-content>
    </div>
    <div>
      <ng-content select=".user-menu-item"></ng-content>
    </div>
  </div>

  <div class="menu">
    <ng-content select=".application-menu"></ng-content>
  </div>
</div>
