import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ModalConfig } from './types';


export class ModalRef<T=any> {

    private triggerClosingSubject$ = new Subject<any>();
    private afterClosingSubject$ = new Subject<any>();
    private primaryActionSubject$ = new Subject<any>();
    private secondaryActionSubject$ = new Subject<any>();
    togglePrimaryActionSubject$ = new Subject<boolean>();
    headerSubject$ = new Subject<string>();

    constructor(
        private overlayRef: OverlayRef,
        public content: TemplateRef<any> | Type<any>,
        public modalConfig: ModalConfig
    ) {
        overlayRef.keydownEvents().subscribe(event => {
            if (event.key === "Escape") {
                this.triggerClosingSubject$.next();
            };
        });

        // Only neccessary if the CDK backdrop is applied
        overlayRef.backdropClick().subscribe(() => {
            this.triggerClosingSubject$.next();
        });
    }

    public close(result?: any) {

        // delay the disposal of the overlay to ally time for animations to complete
        setTimeout(() => {
            this.overlayRef.dispose();
            this.afterClosingSubject$.next(result);
            this.afterClosingSubject$.complete();
            this.primaryActionSubject$.complete();
            this.triggerClosingSubject$.complete();
            this.secondaryActionSubject$.complete();
            this.headerSubject$.complete();
        }, 150);
    }

    public togglePrimaryAction(isDisabled: boolean) {
        this.togglePrimaryActionSubject$.next(isDisabled);
    }

    public updateHeader(header: string) {
        this.headerSubject$.next(header);
    }

    public triggerClosing(): Observable<any> {
        return this.triggerClosingSubject$.asObservable();
    }

    public afterClosed(): Observable<any> {
        return this.afterClosingSubject$.asObservable();
    }

    public didPrimaryAction() {
        this.primaryActionSubject$.next();
    }

    public primaryAction(): Observable<any> {
        return this.primaryActionSubject$.asObservable();
    }

    public didSecondaryAction() {
        this.secondaryActionSubject$.next();
    }

    public secondaryAction(): Observable<any> {
        return this.secondaryActionSubject$.asObservable();
    }
}