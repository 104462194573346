/**
 * Allows the construction of textarea type element.
 *
 * @author Andres Echeverri <andres.echeverri@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { AbstractControl, FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementTitle extends AlFormElementBase<any> {
    public controlTemplate = "title";

    constructor(public properties: AlDynamicFormElementDescriptor) {
        super(properties);
    }

    createFormControl(): AbstractControl {
        return new FormControl(this.value || '');
    }

    getAnswer() {
        return this.value;
    }
}
