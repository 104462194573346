<al-zero-state flavor="default">
    <span zero-state-icon><i class="material-icons">report_problem</i></span>
    <span zero-state-title>Not Found</span>
    <div zero-state-description>
        <p>
        The page you're looking for isn't here.  If you're sure the URL is right, please <a href="https://support.alertlogic.com" target="_blank" title="Alert Logic Customer Support">contact Support</a> for help.
        </p>
        <p>
            <button pButton type="button" class="primaryAction" (click)="reenterFeature()" label="Return to Console"></button>
        </p>
    </div>
</al-zero-state>
