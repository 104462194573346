import { Component, Input } from '@angular/core';


@Component({
    selector: 'ald-label',
    templateUrl: './ald-label.component.html',
    styleUrls: ['./ald-label.component.scss'],
})
export class AldLabelComponent {
    @Input() label = 'Label Text';
    @Input() for = 'labelFor';
    @Input() id?: string;
    @Input() name?: string;
}
