import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AlSelectItem, AlSelectableListConfig, AlSelectableListItem } from '@al/ng-generic-components';


@Component({
    selector: 'al-selectable-list-v2',
    templateUrl: './al-selectable-list-v2.component.html',
    styleUrls: ['./al-selectable-list-v2.component.scss']
})
export class AlSelectableListV2Component {

    @Input() config: AlSelectableListConfig = {
        zeroStateIcon: '',
        zeroStateMessage: 'No items chosen yet',
        height: '350px',
        sortDirection: 'asc',
        sortBy: 'title',
        action: {}
    };
    @Input() data: AlSelectableListItem[] = [];

    @Output() onActionedItem: EventEmitter<AlSelectableListItem> = new EventEmitter<AlSelectableListItem>();
    @Output() onActionedSubItem: EventEmitter<AlSelectItem> = new EventEmitter<AlSelectItem>();

    actionItem(element: AlSelectableListItem): void {
        this.onActionedItem.emit(element);
    }

    onSubItemAction(subItem: AlSelectItem): void {
        this.onActionedSubItem.emit(subItem);
    }

}
