import {
    Component, 
    EventEmitter, 
    Input, 
    OnInit, 
    Output, 
    OnChanges, 
    SimpleChanges
} from '@angular/core';
import { isThreatRating, ThreatRating } from '../types';


@Component({
    selector: 'ald-threat-summary',
    templateUrl: './ald-threat-summary.component.html',
})
export class AldThreatSummaryComponent implements OnInit, OnChanges {

    @Input() threat?: {[key: string]: number} | string | ThreatRating;
    @Input() noThreatText? = 'No Current Threats';
    @Input() display?: 'summary' | 'breakdown' = 'breakdown';
    @Input() iconOnly?: boolean = false;
    @Input() inline?: boolean = true;

    threatSummary: ThreatRating;
    threatBreakdown: {[key: string]: number};

    ngOnInit() {
        /**
         * Guards to check that this.threat is an object, string, or ThreatRating
         * then handles the appropriate case.
         */
        if (this.display === 'summary' && this.threat) {
            if (typeof this.threat === 'object') {
                this.threatSummary = this.summariseThreat(this.threat);
            } else if (typeof this.threat === 'string') {
                const threatString = this.threat.toLowerCase();
                if (isThreatRating(threatString)) {
                    this.threatSummary = threatString;
                }
            } else if (isThreatRating(this.threat)) {
                this.threatSummary = this.threat;
            }
        } else if (typeof this.threat === 'object') {
            this.threatBreakdown = this.threat;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                const change = changes[propName];
                if (propName === 'threat') {
                   this.threatSummary = this.summariseThreat(change.currentValue);
                }
            }
        }
    }

    // Returns the highest priority threat rating.
    summariseThreat(threat: {[key: string]: number}): ThreatRating {
        if (!threat) return null;
        if (threat.critical) {
            return 'critical';
        } else if (threat.high) {
            return 'high';
        } else if (threat.medium) {
            return 'medium';
        } else if (threat.low) {
            return 'low';
        } else if (threat.info) {
            return 'info';
        } else {
            return null;
        }
    }
}
