/**
 *  AlProtectedContentComponentEx provides a simple way to project content whose visibility is predicated on a combination of entitlements, experience mode,
 *  environment, and authentication state, without the need for the host view to know anything about services that expose those conditions.
 *
 *  Please see README.md for complete documentation.
 *
 *  @author McNielsen <knielsen@alertlogic.com>
 *  @copyright Alert Logic Inc, 2020
 */

import {
    Component, 
    EventEmitter, 
    Input, 
    NgZone, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    Output, 
    SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import {
    AIMSAccount, 
    AlActingAccountChangedEvent, 
    AlActingAccountResolvedEvent, 
    AlBehaviorPromise, 
    AlConduitClient, 
    AlDatacenterSessionEstablishedEvent, 
    AlExperienceMapping, 
    AlRoute, 
    AlRouteCondition, 
    AlSession, 
    AlSessionEndedEvent, 
    AlSessionStartedEvent, 
    AlStopwatch, 
    AlSubscriptionGroup
} from '@al/core';
import { AlExperiencePreferencesService } from '../services/al-experience-preferences.service';
import { AlNavigationService } from '../services/al-navigation.service';
import { EntitlementGroup } from '../types/entitlement-group.class';
import { AlContentUnavailable, AlNavigationContextChanged } from '../types/navigation.types';
import { AccountChangeNotification } from '../types/protected-content.types';


@Component({
    selector: 'al-protected-content-ex',
    templateUrl: './al-protected-content-ex.component.html'
})

export class AlProtectedContentExComponent implements OnInit, OnChanges, OnDestroy, AlContentUnavailable
{
    @Input()    authentication:boolean|null                                     =   null;       //  default: any authentication state
    @Input()    entitlements:string|string[]|null                               =   null;       //  default: any entitlements allowed
    @Input()    primaryEntitlements:string|string[]|null                        =   null;       //  default: any primary entitlements allowed
    @Input()    accounts:string[]|null                                          =   null;       //  default: any accounts
    @Input()    primaryAccounts:string[]|null                                   =   null;       //  default: any primary account
    @Input()    accountProps:{ name:string, values:any[] }[]|null               =   null;
    @Input()    primaryAccountProps:{ name:string, values:any[] }[]|null        =   null;
    @Input()    experiences:string|string[]|null                                =   null;       //  default: any experience
    @Input()    experiencesRule: 'any' | 'all' | 'none'                         =   'any';      //  all: applies AND to flags in the experiences array. any: applies OR. none applies NOT OR.
    @Input()    environments:string|string[]|null                               =   null;       //  default: any environment
    @Input()    requiresDefender                                                =   false;      //  default: defender session is not required

    @Input()    rerouteActingAccount:boolean                                    =   true;       //  if true, acting account changes will use routing metadata to change to an account-specific route (if available)
    @Input()    verbose:boolean                                                 =   false;      //  if true, the component will be helpfully verbose

    @Input()    experienceId:string;                                                            //  optional feature/experience identifier (future use).  This should be in the form "topLevelFeatureCode.childFeatureCode#variant".

    @Input()    unentitledRoute?:string|string[]|boolean;                                       //  provided for compatibility with al-protected-content

    @Output()   onDisallowed                                                    =   new EventEmitter<AlContentUnavailable>();
    @Output()   onDisplay:EventEmitter<void>                                    =   new EventEmitter<void>();
    @Output()   onHide:EventEmitter<void>                                       =   new EventEmitter<void>();
    @Output()   onAccountChange:EventEmitter<AccountChangeNotification>         =   new EventEmitter<AccountChangeNotification>();

    public      aboveContentCrosslink?:AlRoute;
    public      contentVisible:boolean|undefined;
    public      condition:AlRouteCondition                                     =   {};
    public      unavailableZeroState?:{
        title:string;
        description:string;
        iconClass:string;
        iconText:string;
    };

    protected   calculatingState                                                =   new AlBehaviorPromise( true );      //  used as a mutex to prevent multiple asyncronous checks from running at the same time
    protected   subscriptions:AlSubscriptionGroup                               =   new AlSubscriptionGroup();
    protected   previousAccountId:string                                        =   "00000000";
    protected   previousAccount?:AIMSAccount;
    protected   capturedRoute:AlRoute;

    protected   featureId:string;
    protected   variantId:string;
    protected   isInitialAccount                                                =   true;
    protected   redirected:boolean                                              =   false;
    protected   featureVariantId:string;
    protected   experienceMapping:AlExperienceMapping|null                      =   null;

    constructor( public router:Router,
                 public navigation:AlNavigationService,
                 public experiencePreferences: AlExperiencePreferencesService,
                 public zone:NgZone ) {
        this.subscriptions.manage(
            AlSession.notifyStream.attach( AlSessionStartedEvent, this.onSessionStarted ),
            AlSession.notifyStream.attach( AlSessionEndedEvent, this.onSessionEnded ),
            AlSession.notifyStream.attach( AlActingAccountChangedEvent, this.onAccountChanged ),
            AlSession.notifyStream.attach( AlActingAccountResolvedEvent, this.onAccountResolved ),
            AlConduitClient.events.attach( AlDatacenterSessionEstablishedEvent, this.onDatacenterSessionEstablished ),
            this.navigation.events.attach( AlNavigationContextChanged, this.onNavigationContextChanged )
        );
    }

    ngOnInit() {
        this.navigation.incrementAuthenticationRequisite();
    }

    ngOnChanges( changes:SimpleChanges ) {
        let criteriaChanged = 0;
        if ( 'accounts' in changes ) {
            this.condition.accounts = changes.accounts.currentValue;
        }
        if ( 'primaryAccounts' in changes ) {
            this.condition.primaryAccounts = changes.primaryAccounts.currentValue;
        }
        if ( 'entitlements' in changes ) {
            this.condition.entitlements = this.normalizeEntitlementInput( changes.entitlements.currentValue );
            criteriaChanged++;
        }
        if ( 'primaryEntitlements' in changes ) {
            this.condition.primaryEntitlements = this.normalizeEntitlementInput( changes.primaryEntitlements.currentValue );
            criteriaChanged++;
        }
        if ( 'accountProps' in changes ) {
            this.condition.accountProps = changes.accountProps.currentValue;
            criteriaChanged++;
        }
        if ( 'primaryAccountProps' in changes ) {
            this.condition.primaryAccountProps = changes.primaryAccountProps.currentValue;
            criteriaChanged++;
        }
        if ( 'experiences' in changes ) {
            this.condition.experiences = typeof( changes.experiences.currentValue ) === 'string' ? [ changes.experiences.currentValue ] : changes.experiences.currentValue;
            criteriaChanged++;
        }
        if ( 'environments' in changes ) {
            this.condition.environments = typeof( changes.environments.currentValue ) === 'string' ? [ changes.environments.currentValue ] : changes.environments.currentValue;
            criteriaChanged++;
        }
        if ( 'authentication' in changes ) {
            this.condition.authentication = changes.authentication.currentValue === null ? null : !!changes.authentication.currentValue;       //  force to boolean type if not null
            criteriaChanged++;
        }
        if ( 'experienceId' in changes ) {
            if ( this.condition.experiences ) {
                this.condition.experiences.push( changes.experienceId.currentValue );
            } else {
                this.condition.experiences = [ changes.experienceId.currentValue ];
            }
            criteriaChanged++;
        }
        if ( 'requiresDefender' in changes ) {
            criteriaChanged++;
        }
        if ( criteriaChanged > 0 ) {
            this.evaluateAccessibility("criteria changed");
        }
    }

    ngOnDestroy() {
        this.subscriptions.cancelAll();
        this.navigation.decrementAuthenticationRequisite();
    }

    /**
     * Imperatively redirects to a location/path combination, an AlRoute href, and fully qualified URL,
     * or a local route.  Optionally accepts query parameters to be merged into target URL.
     */
    public async redirect( target:string|string[]|AlRoute|{location:string,path:string;}, parameters:{[p:string]:string} = {}, options:any = {} ):Promise<void> {
        this.redirected = true;
        return await this.navigation.navigate.to( target, parameters );
    }

    /**
     * Outer method to evaluate whether content should be shown or not.
     */
    public async evaluateAccessibility( triggerName?:string ):Promise<boolean> {

        await this.calculatingState;     //  only one evaluation at a time, please

        this.calculatingState.rescind();

        await AlSession.ready();
        await this.navigation.ready();

        if ( this.verbose && triggerName ) {
            this.log(`Evaluating accessibility after trigger: ${triggerName}` );
        }

        let flags = [];
        if (this.requiresDefender) {
            // Add the presence of a defender session as a flag
            const externalSessionPresent = this.navigation.conduit.checkExternalSession();
            flags.push(externalSessionPresent);
        }
        let experiencesBackup;
        if (this.condition?.experiences) {
            experiencesBackup = this.condition.experiences.slice();
            // Let's separate the experiences from the rest of the settings to evaluate them separately.
             const expCondition: AlRouteCondition = {
                experiences: experiencesBackup,
                rule: this.experiencesRule
            };
            delete this.condition.experiences;
            const expFlag = this.navigation.evaluateCondition(expCondition);
            flags.push(expFlag)
        }
        const mainFlag = this.navigation.evaluateCondition( this.condition );
        // Combine the experience flag with the other flag for the other settings
        flags.push(mainFlag);

        // Let's AND all of the flags
        const allowed = flags.every(res => res);

        this.condition.experiences = experiencesBackup;

        if ( allowed ) {
            if ( ! this.contentVisible || this.contentVisible === undefined ) {
                this.log(`accessibility evaluation yields VISIBLE state.` );
                //  Content has changed from hidden/initial to visible
                this.zone.run( () => {
                    this.contentVisible = true;
                    this.log("emitting onDisplay/setting visibility to true" );
                    this.onDisplay.emit();
                } );
            }
            this.emitAccountChange( AlSession.getActingAccount() );
        } else {
            if ( this.contentVisible || this.contentVisible === undefined ) {
                this.log(`accessibility evaluation yields PROTECTED state` );
                //  Content is changed from visible/initial to hidden
                this.zone.run( () => {
                    this.contentVisible = false;
                    this.log("emitting onHide/onDisallowed and setting visibility to false" );
                    this.onHide.emit();
                    this.onDisallowed.emit( this );     //  pass self as emitted object
                    if ( ! this.redirected && this.unentitledRoute ) {
                        if ( typeof( this.unentitledRoute ) === 'string' || Array.isArray( this.unentitledRoute ) ) {
                            this.redirect( this.unentitledRoute );
                        } else if ( typeof( this.unentitledRoute ) === 'boolean' && this.unentitledRoute ) {
                            this.redirect( '/' );
                        }
                    }
                } );
            }
        }
        this.calculatingState.resolve( true );
        return this.contentVisible;
    }

    normalizeEntitlementInput( input:string|string[]|null ):string[]|null {

        input = this.toArray( input );
        let output:string[] = [];

        input.forEach( entitlementExpression => {
            if ( entitlementExpression === '@schema' ) {
                output = output.concat( this.toArray( this.getEntitlementsFromSchema() ) );
            } else if ( entitlementExpression.startsWith( "EntitlementGroup." ) ) {
                const groupId = entitlementExpression.substring( 17 );
                if ( EntitlementGroup.hasOwnProperty( groupId ) && typeof( EntitlementGroup[groupId] ) === 'string' ) {
                    const groupValue = EntitlementGroup[groupId] as string;
                    output.push( groupValue );
                } else {
                    console.error(`Warning: "EntitlementGroup.${groupId}" is not a valid entitlement group reference; ignoring` );
                }
            } else if ( entitlementExpression === '*' ) {
                return;
            } else {
                output = output.concat( this.toArray( entitlementExpression ) );
            }
        } );

        if ( output.length === 0 ) {
            return null;
        }

        return output;
    }

    onSessionStarted = ( ) => {
        AlStopwatch.once( () => this.evaluateAccessibility( `session started` ) );
    }

    onAccountChanged = ( event:AlActingAccountChangedEvent ) => {
        //  Capture the previous account ID for later use
        if ( event.previousAccount?.id !== event.actingAccount?.id ) {
            this.previousAccount = event.previousAccount;
            this.previousAccountId = event.previousAccount ? event.previousAccount.id : "00000000";
        }
    }

    onAccountResolved = async ( event:AlActingAccountResolvedEvent ) => {
        if ( await this.evaluateAccessibility( `account change resolution` ) ) {                 //  content is still accessible
            this.emitAccountChange( event.actingAccount );
        }
        if ( this.rerouteActingAccount ) {
            if ( this.navigation.activatedRoute && this.navigation.activatedRoute.toHref() !== window.location.href ) {
                this.zone.run( () => this.navigation.navigate.to( this.navigation.activatedRoute ) );
            }
        }
    }

    protected emitAccountChange( actingAccount:AIMSAccount ) {
        if ( ! this.contentVisible ) {
            return; //  never emit account change information to child views when the content is hidden
        }
        if ( this.isInitialAccount || actingAccount.id !== this.previousAccountId ) {
            let notification = Object.assign(   {},
                                                actingAccount,
                                                {
                                                    isInitial: this.isInitialAccount,
                                                    previous: this.previousAccount 
                                                } );
            this.onAccountChange.emit( notification );
            this.previousAccountId = actingAccount.id;
            this.previousAccount = actingAccount;
            this.isInitialAccount = false;
        }
    }

    onSessionEnded = ( ) => {
        AlStopwatch.once( () => this.evaluateAccessibility( `session ended` ) );
    }

    onDatacenterSessionEstablished = ( ) => {
        if ( this.requiresDefender ) {
            AlStopwatch.once( () => this.evaluateAccessibility("datacenter session established") );
        }
    }

    onNavigationContextChanged = ( ) => {
        AlStopwatch.once( () => {
            this.evaluateAccessibility("navigation context changed");
        } );
    }

    /**
     * Attempts to retrieve a valid entitlement expression from the current activated route.
     */
    getEntitlementsFromSchema():string|string[] {
        if ( ! this.navigation.activatedRoute ) {
            console.warn("Warning: al-protected-content cannot extract entitlements from schema; no activated route is currently set." );
            return "void_entitlement";      //  think defensively; match nothing by default!
        }
        let route = this.navigation.activatedRoute;
        while( route ) {
            if ( typeof( route.definition.visible ) === 'object' ) {
                const entitlementExpression = this.getEntitlementsFromRouteCondition( route.definition.visible );
                if ( entitlementExpression ) {
                    this.capturedRoute = route;
                    return entitlementExpression;
                }
            }
            route = route.parent;
        }

        console.warn("Warning: al-protected-content cannot extract entitlements from schema; activated route hierarchy does not contain any entitlement conditions." );
        return "void_entitlement";
    }

    /**
     * Uses `AlNavigationService.activatedRoute` to redirect to the deepest route in the current route's lineage that is visible
     * AND entitled.  Hypothetically, this should ascend to the top level application's root route  in cases where there is
     * no entitled route.
     */
    findDeepestAccessibleRoute():AlRoute {
        const cursor:AlRoute = this.capturedRoute || this.navigation.activatedRoute;
        if ( ! cursor ) {
            return null;
        }
        let route = cursor.parent;      //  start with next item up
        while ( route ) {
            if ( route.visible && route.definition.action ) {
                //  visible implies it is either hardcoded visible or its entitlements and other conditions evaluated as truthy
                //  action means it can actually do something
                //  both are required
                return route;
            }
            route = route.parent;
        }
        //  No viable candidate routes?  SO SAD.
        return null;
    }

    /**
     * Attempts to retrieve a valid entitlement expression from a route condition (or nested condition).  PLEASE NOTE
     * that this will not yield the desired results in cases of complex or compound route conditions.
     */
    getEntitlementsFromRouteCondition( condition:AlRouteCondition ):string|string[] {
        if ( condition.entitlements ) {
            return condition.entitlements;
        }
        if ( condition.conditions && condition.rule === 'all' ) {
            for ( let i = 0; i < condition.conditions.length; i++ ) {
                const entitlementExpression = this.getEntitlementsFromRouteCondition( condition.conditions[i] );
                if ( entitlementExpression ) {
                    return entitlementExpression;
                }
            }
        }
        return null;
    }

    protected toArray( input?:string|string[] ):string[] {
        if ( ! input ) {
            return [];
        }
        if ( typeof( input ) === 'object' && input.hasOwnProperty( "length" ) ) {
            return input;
        }
        return [ input as string ];
    }

    protected log( message:string ) {
        if ( this.verbose ) {
            console.log("AlProtectedContentEx: " + message );
        }
    }
}
