import  ipaddrJs from 'ipaddr.js';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';


/**
 * nestedGet
 * Safely retrieves a deep property from a nested object.
 *
 * @param {any} object The object to begin retrieval from;
 * @param {string} propertySequence A period-delimited list of properties to retrieve;
 * @param {any} defaultValue The value to return if the property does not exist.
 *
 * @return {any} The value stored in the property sequence
 * Example usage: var value = nestedGet( deploymentConfig, "config.aws.scope.include", [] );
 * If deploymentConfig contains deploymentConfig.config.aws.scope.include, that property will be returned.
 * If it does not, the empty array will be returned instead.
 */
export function nestedGet(object: any, propertySequence: string, defaultValue: any): any {
    const properties: string[] = propertySequence.split(".");
    let cursor = object;
    for (const i in properties) {
        if (properties[i]) {
            if (typeof (cursor) !== 'object') {
                return defaultValue;
            }
            if (!cursor.hasOwnProperty(properties[i])) {
                return defaultValue;
            }
            cursor = cursor[properties[i]];
        }
    }
    return cursor;
}

   /**
     *
     * Allows a condition to be validated and handles unexpected condition results to be
     * handled gracefully using several predefined methods.
     *
     * @param {mixed} assertion A literal value, function, or object containing multiple literal values or functions, to be evaluated.
     * @param {mixed} onAssertFailure Instructino on how to handle assertion failures.
     *                  If all of the conditions are met, the method will simply return true.
     *                  If it is a function, it will be called on assertion failure and its result will be returned to the caller.
     *                  If it is a string literal, an exception will be thrown with that text.
     *                  Otherwise, the function will return false.
     *
     *  Examples:
     *
     *  Throws an error if var value is false
     *  assert( value !== false, "Expected value not to be false" );
     *
     *  Calls a logging function with a parameter if var value is false, using a callback to test.
     *  assert( function( result ) { return value !== false; }, function( parameter ) { console.log("Expected %s not to be false", parameter ); }, "value" );
     *
     *  Executes a series of tests and handles a false response manually.
     *  var expectations = {
     *       "value should not be false": value !== false,
     *       "value should be true": value === true,
     *       "Kevin should equal 'kevin'": 'kevin' === 'kevin'
     *  };
     *
     *  if ( ! assert( expectations ) ) {
     *      //  handle error here
     *  }
     */
    export function assert (assertion, onAssertionFailure, asset): boolean {
        let assertValue;
        let matrix = {};
        if (typeof (assertion) === 'object') {
            let result = true;
            for (let testKey in assertion) {
                if (assertion.hasOwnProperty(testKey)) {
                    let test = assertion[testKey];
                    matrix[testKey] = assert(test, false, asset);
                    result = result && matrix[testKey];
                }
            }
            assertValue = result;
        } else if (typeof (assertion) === 'function') {
            assertValue = assertion();
        } else {
            assertValue = assertion;
        }
        if (!assertValue) {
            if (typeof (onAssertionFailure) === 'function') {
                // let parameters = Array.prototype.slice.call(arguments, 2);
                let parameters = [asset];
                if (typeof (assertion) === 'object') {
                    parameters.push(matrix);
                }
                return onAssertionFailure.apply(null, ...parameters);
            } else if (typeof (onAssertionFailure) === 'string') {
                throw new Error(onAssertionFailure);
            } else {
                return false;
            }
        }
        return true;
    };

/**
 * Returns a function that sets an "exit fullscreen handler" for a given angular component
 */
export function eventHandlerWhenExitingFullScreen(): (p: any) => void {
    return (component: any) => {
        const exitHandler = () => {
            if (!document['fullscreenElement']) {
                component.fullScreen = false;
                const overlayElement = document.getElementsByClassName('cdk-overlay-container')[0];
                const bodyElement = document.getElementsByTagName('body')[0];
                if (overlayElement) {
                    bodyElement.appendChild(overlayElement);
                }
            }
        };
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
    };
}

/**
 * sets the fullscreen mode for agiven HTML element.
 */
export function fullScreen(element: any, isFullScreen: boolean): boolean {
    if (!isFullScreen) {
        const callback = element['requestFullScreen']
            || element['webkitRequestFullScreen']
            || element['mozRequestFullscreen']
            || element['msRequestFullscreen'];
        if (callback) {
            callback.call(element);
        }
        return true;
    } else {
        const callback = document['exitFullscreen'];
        if (callback) {
            callback.call(document);
        }
        return false;
    }
}


/**
 * trimValidator
 * FormControl validator which checks if a text input is an empty string
 */
export function trimValidator(control: FormControl): { emptyString: { value: boolean } } | null {
    if (typeof control.value === 'string') {
        return (control.value.trim() === '') ? { emptyString: { value: true } } : null;
    } else {
        return { emptyString: { value: true } };
    }
}

export function ipValidator(control: FormControl): { validIp: { value: true } } | null {
    /* tslint:disable */
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(control.value)) {
        return null;
    } else {
        return { validIp: { value: true } };
    }
    /* tslint:enable */
}

export function ipValidator2(control: FormControl): {invalidIp: {value: true }} | null
{
      /* tslint:disable */
    if (control.value) {
        // Validating IPV4
        let ipv4Pattern: RegExp = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/;
        let ipv4Pieces = ipv4Pattern.exec(control.value);
        if (ipv4Pieces) {
            if (ipv4Pieces.length === 5) {
                for (let i = 1; i < ipv4Pieces.length; i++) {
                    if (parseInt(ipv4Pieces[i], 10) > 255) {
                        return { invalidIp: { value: control.value } };
                    }
                }
                let ip2: string = ipv4Pieces.join('.');
                if ((ip2 === '0.0.0.0') || (ip2 === '255.255.255.255')) {
                    return { invalidIp: { value: control.value } };
                }
                return null;
            }
        }
        // Validating IPV6
        let ip = control.value;
        if (ip.length < 3) {
            if (ip === "::") {
                return null;
            } else {
                return { invalidIp: { value: ip } };
            }
        }
        if (ip.indexOf('.') > 0) {
            let lastcolon = ip.lastIndexOf(':');
            let data = control.value.substr(lastcolon + 1);
            let isValidIPv4 = () => {
                if (data) {
                    let ipv6Pattern = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/;
                    let ipv6Pieces = ipv6Pattern.exec(data);
                    if (ipv6Pieces) {
                        if (ipv6Pieces.length === 5) {
                            for (let i = 1; i < ipv6Pieces.length; i++) {
                                if (parseInt(ipv6Pieces[i], 10) > 255) {
                                    return { invalidIpv4: { value: data } };
                                }
                            }
                            ip = ipv6Pieces.join('.');
                            if ((ip === '0.0.0.0') || (ip === '255.255.255.255')) {
                                return { invalidIpv4: { value: data } };
                            }
                            return null;
                        }
                    }
                    return { invalidIpv4: { value: data } };
                } else {
                    return null;
                }
            };
            if (!(lastcolon && isValidIPv4)){
                return { invalidIp: { value: ip } };
            }
            ip = ip.substr(0, lastcolon) + ':0:0';
        }
        if (ip.indexOf('::') < 0) {
            let match = ip.match(/^(?:[a-f0-9]{1,4}:){7}[a-f0-9]{1,4}$/i);
            if (match !== null){
                return null;
            } else {
                return { invalidIp: { value: ip } };
            }
        }
        if (ip.split(':').length < 9) {
            let match = ip.match(/^(?::|(?:[a-f0-9]{1,4}:)+):(?:(?:[a-f0-9]{1,4}:)*[a-f0-9]{1,4})?$/i);
            if (match !== null){
                return null;
            } else {
                return { invalidIp: { value: ip } };
            }
        }

        return { invalidIp: { value: ip } };

    }
    return null;
     /* tslint:enable */
}

export function dnsNameValidator(control: FormControl): { validDnsName: { value: boolean } } | null {
    /* tslint:disable */
    const dnsName: string = control.value ? control.value : '';
    if (/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(dnsName) && (dnsName.indexOf('.') !== -1)) {
        return null;
    } else {
        return { validDnsName: { value: true } };
    }
    /* tslint:enable */
}

export function portsValidator(control: FormControl, multiValues: boolean = false): { invalidPort: { value: boolean } } | null {
    // Regex for: range or single ports (eg. 443, 1024:3305, 1024-3305)
    /* tslint:disable */
    const regex = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])((:|-)([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/g;
    let portsList: string[] = [];
    let rawValue: string = "";
    let valid: boolean = true;
    if (control && control.value) {
        rawValue = control.value;
        portsList = rawValue.split(',');
    }
    /* tslint:enable */

    const getValidityPortRange = (portRange: string): boolean => {
        const isRange: boolean = portRange.includes(':') || portRange.includes('-');
        if (isRange) {
            const separator: string = portRange.includes(':') ? ':' : '-';
            const value1: number = parseInt(portRange.split(separator)[0], 10);
            const value2: number = parseInt(portRange.split(separator)[1], 10);
            return value1 < value2;
        }
        return true;
    };

    if (portsList.length > 0 && multiValues) {
        portsList.forEach(rawPort => {
            if (!(rawPort && rawPort.trim().match(regex) && valid)) {
                valid = false;
            } else {
                valid = getValidityPortRange(rawPort);
            }
        });
        return valid ? null : { invalidPort: { value: true } };
    }

    if (!multiValues) {
        const port: string = portsList.length === 1 ? portsList[0].trim() : "";
        valid = (port.match(regex) !== null && getValidityPortRange(port)) || port === "*";
    }

    return valid ? null : { invalidPort: { value: true } };
}

/**
 * cidrValidator
 * FormControl validator for checking a valid CIDR
 */
export function cidrValidator(allowAsterisk: boolean = false, allowComas: boolean = false, required: boolean = true) {
    return function (control: AbstractControl): ValidationErrors  | null {
        if (!control.value && !required) {
            return null;
        }
        if (typeof control.value === 'string') {
            const cidrFormatRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
            const cidr = control.value;
            let validFormat: boolean = false;
            let cidrs: Array<string> = [];
            let ip: string = '';
            let networkCIDR: string = "";
            if (cidr === '*' && allowAsterisk) {
                return null;
            }
            const verifyCIDR =  (cidrParam: string): boolean =>  {
                validFormat = cidrFormatRegex.test(cidrParam) && (/^(.+)\/(\d+)$/).test(cidrParam);
                if (cidrParam && validFormat) {
                    ip = String(ipaddrJs.IPv4.parseCIDR(cidrParam)[0]);
                    networkCIDR = String(ipaddrJs.IPv4.networkAddressFromCIDR(cidrParam));
                    return (ip === networkCIDR);
                } else {
                    return false;
                }
            };
            if (allowComas && cidr.search(',') !== -1) {
                cidrs = cidr.split(',');
                for (const cidrItem of cidrs) {
                    if (!verifyCIDR(cidrItem.trim())) {
                        return { cidrValidity: { value: 'invalid' } };
                    }
                }
                return null;
            } else {
                const isCidr = verifyCIDR(cidr);
                return isCidr ? null : { cidrValidity: { value: 'invalid' } };
            }
        } else {
            return { cidrValidity: { value: 'invalid' } };
        }
    };
}

/**
 * daysOfMonth It validate all of separated values by comma,
 * those must be a number between 1 and 31
 */
export function daysOfMonth(control: FormControl): { daysOfMonth: boolean, items: string[] } | null {
    if (typeof control.value === 'string') {
        const pieces = (control.value as string).split(',');
        let valid = true;
        pieces.forEach((piece: string) => {
            const number = Number(piece.trim());
            valid = valid && ((number > 0) && (number <= 31));
        });
        const result = {
            daysOfMonth: true,
            items: pieces
        };
        if (!valid) {
            return result;
        } else {
            return null;
        }
    }
    return null;
}

/**
 * Convert bytes to KB, MB, GB, TB, PB
 *
 * @param bytes Bytes to convert
 * @param decimals Numbers of decimals to convert, 2 decimal by default
 */
export function bytesToSize(bytes: number): string {
    const sz = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const factor = Math.floor(((bytes.toString().length) - 1) / 3);
    return (bytes / Math.pow(1024, factor)).toFixed(2) + sz[factor];
}

/**
 *  @method getCsvData
 *
 *  It builds a CSV structure data, transforming the data received into an array to
 *  start creating the csv string data.
 *
 *  @param {any} data The json data that it will be translated into CSV.
 *
 *  @returns {string} rawCsv The csv file in string format.
 */
export function getCsvData(data: any): string {
    const arrayData = typeof data !== 'object' ? JSON.parse(data) : data;
    let rawCsv = '';
    let header = "";
    // Getting header
    for (const index in arrayData[0]) {
        if (arrayData[0].hasOwnProperty(index)) {
            header += index + ',';
        }
    }
    header = header.slice(0, -1);
    rawCsv += header + '\r\n';
    // Getting results
    for (let result = 0; result < arrayData.length; result++) {
        let line = '';
        line = '"' + Object.keys(arrayData[result]).map(key => arrayData[result][key].toString().replace(/"/g, '""')).join('","') + '"';
        rawCsv += line + '\r\n';
    }
    return rawCsv;
}

/**
 * nestedGetByKey
 * Gets value from nested object by key (deep search).
 *
 * @param {any} object The object containing the search key;
 * @param {string} searchKey The key to be searched;
 * @param {any} defaultValue The value to return if the property does not exist.
 *
 * @return {any} The value stored in the search key otherwise defaultValue
 */
export function nestedGetByKey(object: any, searchKey: string, defaultValue?: any): any {
    const searchByKeyRecursive = (key: string, obj: any, acc: any[] = []): any => {
        if (key in obj) {
            return obj[key];
        }
        if (acc.length > 0) {
            acc.shift();
        }
        const temp = [];
        // for (let k of Object.keys(obj)) {
        for (const [, element] of Object.entries(obj)) {
            if (typeof element === 'object' && element !== null && !Array.isArray(element)) {
                temp.push(element);
            }
        }
        if (acc.length > 0) {
            acc = [...temp].concat(acc);
        } else {
            acc = [...temp];
        }
        if (acc.length > 0) {
            return searchByKeyRecursive(key, acc[0], acc);
        } else {
            return 'key_not_found';
        }
    };

    const value = searchByKeyRecursive(searchKey, object);

    return value === 'key_not_found'? defaultValue : value;
}

/**
 * A function that transforms a number into a short human-readable string.
 *
 * @param value The number to transform.
 * @param decimals The number of decimals to show (default is 0).
 * @param fromBytes Whether to use data storage symbols (e.g. "MB", "GB") or metric number symbols (e.g. "M", "G") (default is false).
 * @returns The short human-readable string representation of the number.
 */
export function AlShortNumberTransform(value: number, decimals: number = 0, fromBytes: boolean = false): string {
    const metricNumberSymbols = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const dataStorageSymbols = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (value === 0 || isNaN(value) || value === null) {
        return fromBytes ? "0 B" : '0';
    }
    const k = fromBytes ? 1024 : 1000;
    const i = Math.floor(Math.log(value) / Math.log(k));
    const reduced = parseFloat((value / Math.pow(k, i)).toFixed(decimals))
    return `${reduced}${fromBytes ? ' ' : ''}${fromBytes ? dataStorageSymbols[i] : metricNumberSymbols[i]}`;
}

/**
 * A function that verifies if a string is a IPv4/IPv6 range or CIDR valid
 * 
 * Examples:
 * 
 * 100.64.0.0 - 100.127.255.255 -> A valid IPv4 range
 * 92.0.2.0/24 -> A valid IPv4 cidr
 * 2001:db8::/32 -> A valid IPv6 cidr
 * a20f:110d:1633:0156::/64 -> A valid IPv6 cidr
 * a20f:110d:1633:0156:0000:0000:0000:0000 - a20f:110d:1633:0156:ffff:ffff:ffff:ffff -> A valid IPv6 range
 *
 * @returns ValidationErrors or null.
 */
export function ipv4Ipv6RangeCIDRValidator(control: AbstractControl): ValidationErrors  | null {

        if (!control.value) {
            return { ipv4Ipv6RangeCIDRValidity: { value: 'invalid' } };
        }
        if (typeof control.value === 'string') {

            const valueRange = control.value.split('-');

            if (valueRange.length === 2 && ipaddrJs.isValid(valueRange[0].trim()) && ipaddrJs.isValid(valueRange[1].trim())) {
                return null;
            }

            try {
                ipaddrJs.parseCIDR(control.value);
                return null;
            } catch (error) {
                return { ipv4Ipv6RangeCIDRValidity: { value: 'invalid' } };
            }
        } else {
            return { ipv4Ipv6RangeCIDRValidity: { value: 'invalid' } };
        }
}
