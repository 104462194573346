import {
    Component, 
    EventEmitter, 
    Input, 
    OnChanges, 
    Output, 
    SimpleChanges
} from '@angular/core';
import { BadgeVariant } from '@al/design-patterns/badge';
import { AldObjectValuePipe, AldOptionItem, IconClass } from '@al/design-patterns/common';
import { AldTreeTableColumnDef } from '../types/ald-tree-table.types';


@Component({
    selector: 'ald-tree-table',
    templateUrl: './ald-tree-table.component.html',
    styleUrls: ['./ald-tree-table.component.scss']
})
export class AldTreeTableComponent implements OnChanges {
    @Input() data: AldOptionItem[] = [];
    @Input() columns: AldTreeTableColumnDef[] = [];
    @Input() visibleRows: number = 15; // Indicates number of allowed visible rows, after that number a scrollbar will appear
    @Input() enableToggleAllRows = true;
    @Input() multiselection: boolean = false;

    @Output() didSelectRow: EventEmitter<AldOptionItem[]> = new EventEmitter();
    @Output() didDeselectRow: EventEmitter<AldOptionItem[]> = new EventEmitter();
    @Output() didSelectFilters: EventEmitter<string[]> = new EventEmitter<string[]>();

    @Output() didExpandRow: EventEmitter<any> = new EventEmitter();
    @Output() didContractRow: EventEmitter<any> = new EventEmitter();
    @Output() didToggleAll: EventEmitter<any> = new EventEmitter();

    selectedIndexRow = -1;
    selectedDepth = -1;
    selectedId: string | number = -1;
    filteredData: AldOptionItem[] = [];
    selectedItems: AldOptionItem[] = [];
    expanded: boolean = false;
    startItem: AldOptionItem | null = null;
    endItem: AldOptionItem | null = null;

    constructor(
        private objectValuePipe: AldObjectValuePipe
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.updateEntryData();
        }
    }

    public selectRow(event: PointerEvent, item: AldOptionItem, index: number, depth: number): void {
        if (this.selectedItems.some(selectItem=>selectItem.id===item.id)) {
            this.resetSelectedValues();
            item.selected = false;
            this.selectedItems = this.selectedItems.filter(selectItem=>selectItem.id!==item.id);
            if (!(event.metaKey || event.ctrlKey || event.shiftKey) || (event.shiftKey && this.startItem !== null && this.endItem !== null)) {
                this.cleanRows();
            }
            this.didDeselectRow.emit(this.selectedItems);
        } else if (index === this.selectedIndexRow && depth === this.selectedDepth && item.id === this.selectedId) {
            this.resetSelectedValues();
            item.selected = false;
            this.didDeselectRow.emit();
        } else {
            if (!this.multiselection || ( this.multiselection && !(event.metaKey || event.ctrlKey || event.shiftKey) )) {
                this.cleanRows();
            }
            this.selectedIndexRow = index;
            this.selectedDepth = depth;
            this.selectedId = item.id;
            item.selected = true;
            if (this.startItem === null) {
                this.startItem = item;
            }
            if (this.multiselection && event.shiftKey) {
                if (this.startItem !== null && this.endItem !== null) {
                    this.cleanRows();
                    return this.didDeselectRow.emit([]);
                } else if (this.endItem === null && this.startItem.id !== item.id) {
                    this.endItem = item;
                    if(!this.selectSiblingsRows()) {
                        return this.didDeselectRow.emit([]);
                    }
                }
            }
            if (this.multiselection && (event.metaKey || event.ctrlKey || event.shiftKey)) {
                this.selectedItems = this.getAllSelected();
            }
            this.didSelectRow.emit(this.selectedItems.length <= 1 ? [item] : this.selectedItems);
        }
    }

    public toggleExpand(event: Event, node: AldOptionItem): void {
        if (event) {
            event.stopPropagation();
        }
        if (Array.isArray(node.items) && node.items.length > 0) {
            if (node.expanded) {
                node.expanded = false;
                this.didContractRow.emit(node);
            } else {
                node.expanded = true;
                this.didExpandRow.emit(node);
            }
        }
        const allRootsCollapsed: boolean = this.filteredData.every(node=>(!node.expanded));
        if (allRootsCollapsed) {
            this.expanded = false;
        }
    }

    public toggleAll(event: Event, items: AldOptionItem[], expandedValue?: boolean): void {
        this.expanded = expandedValue ?? !this.expanded;
        const toggleAllFcn = (items: AldOptionItem[]) => {
            items.forEach(item => {
                item.expanded = this.expanded;
                if (Array.isArray(item.items) && item.items.length > 0) {
                    toggleAllFcn(item.items);
                }
            });
        };
        toggleAllFcn(items);
        this.didToggleAll.emit();
    }

    private cleanRows(): void {
        this.selectedItems = [];
        this.startItem = null;
        this.endItem = null;
        this.selectedIndexRow = -1;
        this.selectedDepth = -1;
        this.selectedId = -1;
        this.unselectAllRows(this.filteredData);
    }

    private unselectAllRows(data: AldOptionItem[]): void {
        data.forEach(node => {
            node.selected = false;
            if(Array.isArray(node.items) && node.items.length > 0) {
                this.unselectAllRows(node.items);
            }
        });
    }

    private updateEntryData(): void {
        this.filteredData = JSON.parse(JSON.stringify(this.data));
    }

    private getAllSelected(): AldOptionItem[] {
        let selectedItems: AldOptionItem[] = [];
        const searchSelected = (items: AldOptionItem[]) => {
            items.forEach(item => {
                if (item.selected) {
                    selectedItems.push(item);
                }
                if (Array.isArray(item.items) && item.items.length > 0) {
                    searchSelected(item.items);
                }
            });
        };
        searchSelected(this.filteredData);
        return selectedItems;
    }

    private resetSelectedValues(): void {
        this.selectedIndexRow = -1;
        this.selectedDepth = -1;
        this.selectedId = -1;
    }

    private getParentRow(idToSearch: string | number | undefined): AldOptionItem | null {
        let ancestors: AldOptionItem[] = [];
        let parent: AldOptionItem | null = null;
        const searchForAncestors = (tree: AldOptionItem[]) => {
            for (let index = 0; index < tree.length; index++) {
                const node = tree[index];
                if (node.id === idToSearch) {
                    parent = ancestors.pop() ?? null;
                    break;
                } else {
                    ancestors.push(node);
                    if (Array.isArray(node.items) && node.items.length > 0) {
                        searchForAncestors(node.items);
                    }
                }
                ancestors.pop();
            }
        };
        searchForAncestors(this.filteredData);
        return parent;
    }

    private selectSiblingsRows(): boolean {
        const startParentRow = this.getParentRow(this.startItem?.id);
        const endParentRow = this.getParentRow(this.endItem?.id);
        if (startParentRow?.id === endParentRow?.id && endParentRow?.items) { // same parent
            let startItemPosition = endParentRow.items.findIndex(item=>item.id===this.startItem?.id);
            let endItemPosition = endParentRow.items.findIndex(item=>item.id===this.endItem?.id);
            if (startItemPosition>endItemPosition) {
                startItemPosition = [endItemPosition, endItemPosition = startItemPosition][0]; // swapping the values
            }
            for (let index = startItemPosition; index <= endItemPosition; index++) {
                endParentRow.items[index].selected = true;
            }
            return true;
        } else {
            // different parent is not valid
            this.cleanRows();
            return false;
        }
    }

    /** Get the icon details, defined in the columnDef */
    public getIcon(row, col: AldTreeTableColumnDef): {name: string, iconClass?: IconClass, color?: string, title?: string} {
        const key = this.objectValuePipe.transform(row, col.cellConfig.iconField);
        let icon = {name: '', iconClass: 'material-icons' as IconClass, color: '', title: ''};
        if (col.cellConfig?.icon[key] && col.cellConfig.icon[key].name) {
            icon.name = col.cellConfig.icon[key].name;
        }
        if (col.cellConfig?.icon[key] && col.cellConfig.icon[key].iconClass) {
            icon.iconClass = col.cellConfig.icon[key].iconClass;
        }
        if (col.cellConfig?.icon[key] && col.cellConfig.icon[key].title) {
            icon.title = col.cellConfig.icon[key].title;
        }
        if (col.cellConfig?.icon[key] && col.cellConfig.icon[key].color) {
            icon.color = col.cellConfig.icon[key].color;
        }
        return icon;
    }

    /** Get the icon details, defined in the columnDef */
    public getBadge(row, col: AldTreeTableColumnDef): {label: string, variant?: BadgeVariant, icon?: string, iconClass?: IconClass, lowContrast?: boolean, title?: string} {
        const key = this.objectValuePipe.transform(row, col.cellConfig.badgeField);
        let badge: {label: string, variant?: BadgeVariant, icon?: string, iconClass?: IconClass, lowContrast?: boolean, title?: string} = {label: '', variant: 'default', icon: '', iconClass: 'material-icons', lowContrast: false, title: ''};
        if (col.cellConfig?.badge[key] && col.cellConfig.badge[key].label) {
            badge.label = col.cellConfig.badge[key].label;
        }
        if (col.cellConfig?.badge[key] && col.cellConfig.badge[key].variant) {
            badge.variant = col.cellConfig.badge[key].variant;
        }
        if (col.cellConfig?.badge[key] && col.cellConfig.badge[key].icon) {
            badge.icon = col.cellConfig.badge[key].icon;
        }
        if (col.cellConfig?.badge[key] && col.cellConfig.badge[key].iconClass) {
            badge.iconClass = col.cellConfig.badge[key].iconClass;
        }
        if (col.cellConfig?.badge[key] && col.cellConfig.badge[key].lowContrast) {
            badge.lowContrast = col.cellConfig.badge[key].lowContrast;
        }
        if (col.cellConfig?.badge[key] && col.cellConfig.badge[key].title) {
            badge.title = col.cellConfig.badge[key].title;
        }
        return badge;
    }
}
