import { Component, Input } from '@angular/core';
import { AlIconClass, AlIconSize } from '../types/al-common.types';


@Component({
    selector: 'al-icon',
    templateUrl: './al-icon.component.html'
})
export class AlIconComponent {
    @Input() icon = 'whatshot';
    @Input() iconClass?: AlIconClass = 'material-icons';
    @Input() size?: AlIconSize = 'md';
    @Input() color?: string;
    @Input() opacity?: number;
}
