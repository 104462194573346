<ng-container *ngIf="menu">
  <section class="content-menu tabs-archipeligo19" data-e2e="navigation-content-menu">
    <ng-container *ngFor="let child of menu.children">
    <div *ngIf="child.visible"
          class="content-menu__item {{child.caption}}"
          [ngClass]="{ 'activated': child.activated }"
          (click)="dispatch(child,$event)">
      <a [href]="child.href"
         [ngClass]="[ child.activated ? 'activated': ''  ]"
         class="content-menu__link"
         data-cy="content-menu-item-value">
          {{child.caption}}
        <div class="content-menu__badge" *ngIf="child.properties?.isBeta">
            <ald-badge
            title="Beta feature"
            label="BETA"
            variant="info"
            size="sm"
            [lowContrast]="true"
          >
          </ald-badge>
        </div>
      </a>
    </div>
    </ng-container>
  </section>

</ng-container>
