import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injector, Injectable, TemplateRef, Type } from '@angular/core';
import { AldModalComponent } from './ald-modal/ald-modal.component';
import { ModalRef } from './modal-ref';
import { ModalConfig } from './types';


@Injectable({
    providedIn: 'root'
})
export class AldModalService {

    constructor(
        private overlay: Overlay,
        private injector: Injector
    ) { }

    open<T = any>(content: TemplateRef<any> | Type<any>, config?: ModalConfig): ModalRef {

        const modalConfig: ModalConfig = {
            header: 'Header',
            primaryButtonLabel: 'Save',
            disablePrimaryButton: false,
            primaryActionDoesClose: true,
            secondaryActionDoesClose: true,
            closeButtonLabel: 'Cancel',
            background: true,
            width: 'md',
            icon: '',
            iconClass: 'material-icons',
            footer: true,
            verticalPosition: 'center',
            ...config
        }

        const overlayConfig = new OverlayConfig({
            hasBackdrop: false,
            backdropClass: [],
            panelClass: [],
            width: '100%',
            height: '100%'
        });

        const overlayRef = this.overlay.create(overlayConfig);

        const modalRef = new ModalRef<T>(overlayRef, content, modalConfig);

        const injector = Injector.create({
            parent: this.injector,
            providers: [
                { provide: ModalRef, useValue: modalRef },
            ]
        });

        const portal = new ComponentPortal(AldModalComponent, null, injector);
        overlayRef.attach(portal);

        return modalRef;
    }
}
