import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlDynamicFormControlElement, AlValidationSchemaProvider } from '@al/core';
import { AlExternalContentManagerService } from '@al/ng-generic-components';
import { AlFormElementBase } from '../types/al-form-element-base';
import { AlFormSchematics } from '../types/al-form.schematics';
import { AlDynamicFormElementDescriptor } from '../types/al-form.types';
import { AlFormElementCalendar } from '../types/elements/calendar';
import { AlFormElementCheckbox } from '../types/elements/checkbox';
import { AlFormElementCheckboxGroup } from '../types/elements/checkboxGroup';
import { AlFormElementContent } from '../types/elements/content';
import { AlFormElementDownloadButton } from '../types/elements/downloadButton';
import { AlFormElementDropdown } from '../types/elements/dropdown';
import { AlFormElementInput } from '../types/elements/input';
import { AlFormElementInputResponder } from '../types/elements/inputResponder';
import { AlFormElementInputSwitch } from '../types/elements/inputSwitch';
import { AlFormElementMonacoEditor } from '../types/elements/monacoEditor';
import { AlFormElementMultiSelectList } from '../types/elements/multiSelectList';
import { AlFormElementRadio } from '../types/elements/radio';
import { AlFormElementTextarea } from '../types/elements/textarea';
import { AlFormElementTitle } from '../types/elements/title';
import { AlFormElementTreeSelectList } from '../types/elements/treeSelectList';


/**
 *  base taken from application registry
 */

@Injectable({providedIn: 'root' })
export class AlDynamicFormUtilityService implements AlValidationSchemaProvider {

    private static defaultElementProperties:AlDynamicFormElementDescriptor = {
        type: '',
        property: '',
        label: '',
        description: '',
        options: [],
        placeholder: '',
        aboveDescription: '',
        belowDescription: '',
        patternError: '',
        requiredError: '',
        multiSelectOptions: [],
        treeSelectOptions: [],
        title: '',
        validationPattern: ''
    };

    constructor( public contentManager:AlExternalContentManagerService, public sanitizer:DomSanitizer) {
    }

    /**
     * Normalizes a dynamic form element descriptor.
     *
     * Please note that it is no longer necessary to call this directly -- you can simply assign the raw descriptor to <al-dynamic-form>'s `elements`
     * @Input(), and it will do the necessary normalization automatically.
     */
    public generateBaseProperties(definition: AlDynamicFormControlElement): AlDynamicFormElementDescriptor {
        let element = definition as AlDynamicFormElementDescriptor;     //  we're really just annotating an existing object; no need to clone/copy.
        let [dataType, elementType] = element.type.split('/');
        if ( ! elementType ) {
            throw new Error( `Invalid dynamic form control descriptor has invalid type '${element.type}'` );
        }

        element = Object.assign( {}, AlDynamicFormUtilityService.defaultElementProperties, element );

        // set validation pattern and required
        if (['input','number','password','hidden','textarea','inputResponder', 'dropdown', 'calendar' ].includes(elementType)) {
            element.required = ! element.optional;      /* why we have two bloody properties for the same bloody thing defies explanation */
        }

        if ((['any[]','string[]'].includes(dataType)) && ['textarea','inputResponder'].includes(elementType)) {
            element.joinExpression = element.joinExpression || '\n';
            element.splitExpression = element.splitExpression || new RegExp(/[\r\n]/);
        }

        if ( ! element.value ) {
            // set default value
            if ( 'defaultValue' in element ) {
                element.value = element.defaultValue;
            } else {
                if (dataType === 'boolean') {
                    element.value = false;
                }
                if (dataType === 'string') {
                    element.value = '';
                }
                if (dataType === 'any[]' || dataType === 'string[]') {
                    element.value = [];
                }
                if (dataType === 'object') {
                    element.value = {};
                }
                if (['integer','number'].includes(dataType)) {
                    element.value = 0;
                }
            }
        }
        return element;
    }

    /**
     * Workhorse factory method to generate the correct control subclass based on an element descriptor.
     * Please note that several of these are irregular -- e.g., 'checkbox' corresponds to one of two controls
     * depending on dataType -- but new elements should have a one-to-one ratio between control type and subclass.
     * Let's keep it simple :)
     */
    public generateDynamicElement(  properties: AlDynamicFormElementDescriptor,
                                    baseElementType?: string ):AlFormElementBase|undefined {
        if ( ! baseElementType ) {
            baseElementType = properties.type;
        }
        const [dataType, elementType] = baseElementType.split('/');

        if (elementType) {
            if (['input','number','password','hidden'].includes(elementType)) {
                return new AlFormElementInput(properties);
            } else if (elementType === 'checkbox' && ( dataType === 'any[]' || dataType === 'string[]' || dataType === 'object' ) ) {
                return new AlFormElementCheckboxGroup(properties);
            } else if (elementType === 'checkbox' && dataType === 'boolean') {
                return new AlFormElementCheckbox(properties);
            } else if (elementType === 'textarea') {
                return new AlFormElementTextarea(properties);
            } else if (elementType === 'radio') {
                return new AlFormElementRadio(properties);
            } else if (elementType === 'dropdown') {
                return new AlFormElementDropdown(properties);
            } else if (elementType === 'monaco-editor') {
                return new AlFormElementMonacoEditor(properties);
            } else if (elementType === 'inputSwitch') {
                return new AlFormElementInputSwitch(properties);
            } else if (elementType === 'inputResponder') {
                return new AlFormElementInputResponder(properties);
            } else if (elementType === 'multiSelectList') {
                return new AlFormElementMultiSelectList<any>(properties);
            } else if (elementType === 'treeSelectList') {
                return new AlFormElementTreeSelectList(properties);
            } else if (elementType === 'link' || elementType === 'button' || elementType === 'download-button' ) {
                return new AlFormElementDownloadButton(properties);
            } else if (elementType === 'title') {
                return new AlFormElementTitle(properties);
            } else if (elementType === 'content') {
                return new AlFormElementContent(properties, this.contentManager, this.sanitizer);
            } else if (elementType === 'calendar') {
                    return new AlFormElementCalendar(properties);
            }
        }
        console.warn("Element type not supported in al dynamic form utility ", elementType);
        return undefined;
    }

    public hasSchema( schemaId:string ) {
        return schemaId in AlFormSchematics;
    }

    public getSchema( schemaId:string ):Promise<any> {
        return AlFormSchematics[schemaId];
    }
}

