<div class="date-range-selector-container" [ngClass]="{ 'date-range-selector-container-is-stacked': verticalLayout }" data-cy="date-range-selector">
    
    <div class="calendar-container" *ngIf="showCalendar" [ngClass]="{ 'with-time': showTimePicker }">
        
        <div class="p-calendar-wrapper">
            <p-calendar class="calendar-input" selectionMode="range"
                [disabled]="disableCalendar"
                [dateFormat]="calendarDateFormat"
                [maxDate]="calendarMaxDate"
                [minDate]="calendarMinDate"
                [showIcon]="false"
                [appendTo]="'body'"
                [yearNavigator]="isCalendarYearRange" 
                [yearRange]="calendarYearRange"
                [placeholder]="placeholder"
                [(ngModel)]="selectedDateRange"
                (onSelect)="selectDate(false)"
                (onShow)="updateCalendarOnShow()"
                (onClose)="selectDate(false, true)">
                <p-footer *ngIf="showTimePicker" class="timepicker-container u-grid">
                    <div class="start-time u-col">
                        <div class="timepicker-title">Start time</div>
                        <al-time-selector [time]="startTime"
                                            (onTimeSelected)="startTime = $event; selectDate(true)">
                        </al-time-selector>
                    </div>
                    <div class="divider"></div>
                    <div class="end-time u-col">
                        <div class="timepicker-title">End time</div>
                        <al-time-selector [time]="endTime"
                                          (onTimeSelected)="endTime = $event; selectDate(true)">
                        </al-time-selector>
                    </div>
                    <div *ngIf="timeZoneOffset" class="time-zone-offset u-col-12">GMT{{ timeZoneOffset }}</div>
                </p-footer>
            </p-calendar>

            <button class="p-button-outlined p-button ui-widget p-state-default p-corner-all p-button-text-only" pbutton type="button" (click)="openCalendar($event)">
                <span class="material-icons" style="margin-right: 5px;">
                    date_range
                </span>
                <div *ngIf="selectedDateRange" class="calendar-input-content">
                    {{ selectedDateRange[0] | date:'d MMM y' }} {{ startTime | date:'HH:mm:ss' }} 
                    &ndash;
                    <ng-container *ngIf="selectedDateRange[1]">{{ selectedDateRange[1] | date:'d MMM y' }} {{ endTime | date:'HH:mm:ss' }} 
                          ({{ timeZoneOffset? 'GMT' + timeZoneOffset : endTime | date:'zzzz' }})</ng-container>
                </div>
                <div *ngIf="!selectedDateRange" class="calendar-input-content">
                    All Data Selected
                </div>
            </button>
        </div>
    </div>

    <div *ngIf="!hideSelector" class="fixed-range-selector-container">
        <ng-container *ngIf="sliceDropdownItems && sliceDropdownItems > 0; else inlineDropdownItems">
            <p-selectButton [options]="fixedRanges | slice:0:sliceDropdownItems"
                            [(ngModel)]="selectedFixedDate"
                            (onChange)="updateCalendarFromFixedRange($event.value)">
            </p-selectButton>

            <div class="p-datepicker-dropdown" *ngIf="(fixedRanges | slice:sliceDropdownItems:fixedRanges.length).length > 0">
                <p-dropdown autoWidth="false"
                    placeholder="More"
                    [showClear]="false"
                    [style]="{'minWidth':'0px', 'width': 'fit-content'}"
                    [(ngModel)]="selectedFixedDate"
                    [options]="(fixedRanges | slice:sliceDropdownItems:fixedRanges.length)"
                    (onChange)="updateCalendarFromFixedRange($event.value)">
                </p-dropdown>
            </div>
        </ng-container>
        <ng-template #inlineDropdownItems>
            <p-selectButton [options]="fixedRanges"
                            [(ngModel)]="selectedFixedDate"
                            (onChange)="updateCalendarFromFixedRange($event.value)">
            </p-selectButton>
        </ng-template>
    </div>
</div>
