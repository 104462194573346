import {
    Component, 
    Input, 
    EventEmitter, 
    OnChanges, 
    OnDestroy, 
    SimpleChanges
} from '@angular/core';
import { AlNavigationService } from '../services/al-navigation.service';


@Component({
    selector: 'al-layout-options',
    template: ''
})

export class AlLayoutOptionsComponent implements OnChanges, OnDestroy {

    @Input() disableHeader?:boolean;
    @Input() disablePrimaryMenu?:boolean;
    @Input() disableTertiaryMenu?:boolean;
    @Input() disableContentMenu?:boolean;
    @Input() pageClass?:string;

    protected static optionWhitelist = [
        'disableHeader',
        'disablePrimaryMenu',
        'disableTertiaryMenu',
        'disableContentMenu',
        'pageClass'
    ];
    protected originalValues:{[option:string]:any} = {};

    constructor( public navigation: AlNavigationService ) {
    }

    ngOnChanges( changes:SimpleChanges ) {
        for ( const optionKey in changes ) {
            if ( ! AlLayoutOptionsComponent.optionWhitelist.includes( optionKey ) ) {
                console.error( new Error( `The value '${optionKey}' is not a valid layout option.` ) );
            } else {
                if ( changes[optionKey].firstChange ) {
                    this.originalValues[optionKey] = this.navigation.getLayoutOption( optionKey );
                }
                this.navigation.setLayoutOption( optionKey, changes[optionKey].currentValue );
            }
        }
    }

    ngOnDestroy() {
        Object.entries( this.originalValues ).forEach( ( [ optionKey, optionValue ] ) => {
            this.navigation.setLayoutOption( optionKey, optionValue );
        } );
    }
}
