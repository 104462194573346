import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injector, Injectable } from '@angular/core';
import { AldToastComponent } from './ald-toast/ald-toast.component';
import { ToastRef } from './toast-ref';
import { ToastConfig } from './types';


const CLOSE_TIMEOUT = 4500;
@Injectable({
    providedIn: 'root'
})
export class AldToastService {

    toasts = [];

    constructor(
        private overlay: Overlay,
        private injector: Injector
    ) { }

    open(config?: ToastConfig): ToastRef {

        this.toasts.push(config);

        const toastConfig: ToastConfig = {
            text: 'You have toast.',
            icon: this.defaultIcon(config.variant),
            iconClass: 'material-icons',
            variant: 'info',

            primaryButtonLabel: '',
            primaryButtonIcon: 'close',
            primaryButtonIconClass: 'material-icons',

            secondaryButtonLabel: '',
            secondaryButtonIcon: '',
            secondaryButtonIconClass: 'material-icons',

            hasBackdrop: false,
            closeOnPrimaryButtonClick: true,
            closeOnSecondaryButtonClick: true,
            closeOnBackdropClick: true,
            closeOnEscapeKey: true,
            closeOnTimeout: CLOSE_TIMEOUT,

            ...config
        };

        const positionStrategy = this.overlay.position().global().left().bottom();

        const panelClass = [];

        const overlayConfig = new OverlayConfig({
            positionStrategy,
            hasBackdrop: toastConfig.hasBackdrop,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            panelClass: panelClass
        });

        const overlayRef = this.overlay.create(overlayConfig);

        const toastRef = new ToastRef(overlayRef, toastConfig);

        const injector = Injector.create({
            parent: this.injector,
            providers: [
                { provide: ToastRef, useValue: toastRef },
            ]
        });

        const portal = new ComponentPortal(AldToastComponent, null, injector);
        overlayRef.attach(portal);

        return toastRef;
    }

    /**
     * Notifies the user of a piece of arbitrary information.
     *
     * @param {string} message - The message to display in the toast notification.
     * @param {number} [timeout=CLOSE_TIMEOUT] - The amount of time in milliseconds to display the toast notification before it is automatically closed.
     * @returns {ToastRef} - A reference to the toast notification.
     */
    public notifyInfo( message:string, timeout:number = CLOSE_TIMEOUT ): ToastRef {
        const toastConfig: ToastConfig = {
            variant: 'info',
            text: message,
            closeOnTimeout: timeout
        };
        return this.open( toastConfig );
    }

    /**
     * Warns the user that something is not quite right, but we're still trying to keep our stuff together.
     *
     * @param {string} message - The message to display in the toast notification.
     * @param {number} [timeout=CLOSE_TIMEOUT] - The amount of time in milliseconds to display the toast notification before it is automatically closed.
     * @returns {ToastRef} - A reference to the toast notification.
     */
    public notifyWarning( message:string, timeout:number = CLOSE_TIMEOUT ): ToastRef {
        const toastConfig: ToastConfig = {
            variant: 'warning',
            text: message,
            closeOnTimeout: timeout
        };
        return this.open( toastConfig );
    }

    /**
     * Notifies the user of a successful action with a toast notification.
     *
     * @param {string} message - The message to display in the toast notification.
     * @param {number} [timeout=CLOSE_TIMEOUT] - The amount of time in milliseconds to display the toast notification before it is automatically closed.
     * @returns {ToastRef} - A reference to the toast notification.
     */
    public notifySuccess(message: string, timeout: number = CLOSE_TIMEOUT): ToastRef {
        const toastConfig: ToastConfig = {
            variant: 'success',
            text: message,
            closeOnTimeout: timeout
        };
        return this.open(toastConfig);
    }

    /**
     * Notifies the user of an error with a toast notification.
     *
     * @param {string} message - The error message to display in the toast notification.
     * @param {number} [timeout=CLOSE_TIMEOUT] - The amount of time in milliseconds to display the toast notification before it is automatically closed.
     * @returns {ToastRef} - A reference to the toast notification.
     */
    public notifyError(message: string, timeout: number = CLOSE_TIMEOUT) {
        const toastConfig: ToastConfig = {
            variant: 'danger',
            text: message,
            closeOnTimeout: timeout
        };
        return this.open(toastConfig);
    }

    private defaultIcon(variant:string): string {
        switch (variant) {
            case 'default':
            case 'info' :
                return 'info';
            case 'success':
                return 'check_circle';
            case 'warning':
                return 'warning';
            case 'danger':
                return 'error';
            default:
                return '';
        }
    }
}
