import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng-lts/api';
import { ButtonModule } from 'primeng-lts/button';
import { CardModule } from 'primeng-lts/card';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { ConfirmDialogModule } from 'primeng-lts/confirmdialog';
import { DialogModule } from 'primeng-lts/dialog';
import { DropdownModule } from 'primeng-lts/dropdown';
import { InputSwitchModule } from 'primeng-lts/inputswitch';
import { InputTextModule } from 'primeng-lts/inputtext';
import { MenuModule } from 'primeng-lts/menu';
import { MenubarModule } from 'primeng-lts/menubar';
import { MessageModule } from 'primeng-lts/message';
import { ProgressSpinnerModule } from 'primeng-lts/progressspinner';
import { RadioButtonModule } from 'primeng-lts/radiobutton';
import { SidebarModule } from 'primeng-lts/sidebar';
import { TabMenuModule } from 'primeng-lts/tabmenu';
import { TabViewModule } from 'primeng-lts/tabview';
import { ToastModule } from 'primeng-lts/toast';
import { ToolbarModule } from 'primeng-lts/toolbar';
import { AldBadgeModule } from '@al/design-patterns/badge';
import { NgGenericComponentsModule } from '@al/ng-generic-components';
import * as manifest from './manifest';
import { AldCommonModule } from '@al/design-patterns/common/';


@NgModule({
    declarations: [
        ...manifest.MODULE_COMPONENTS,
        ...manifest.MODULE_DIRECTIVES,
        ...manifest.MODULE_PIPES,
    ],
    imports: [
        AldBadgeModule,
        CommonModule,
        NgSelectModule,
        NgGenericComponentsModule,
        FormsModule,
        ButtonModule,
        CardModule,
        ConfirmDialogModule,
        DialogModule,
        DropdownModule,
        InputTextModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        ProgressSpinnerModule,
        SidebarModule,
        ToastModule,
        ToolbarModule,
        CheckboxModule,
        RadioButtonModule,
        InputSwitchModule,
        AldCommonModule
    ],
    exports: [
        ...manifest.MODULE_COMPONENTS,
        ...manifest.MODULE_DIRECTIVES,
        ...manifest.MODULE_PIPES,
    ],
    providers: [
        ConfirmationService,
        MessageService,
        ...manifest.MODULE_SERVICES,
        {
            provide: ErrorHandler,
            useClass: manifest.AlGlobalErrorService,
        }
    ]
})
export class NgNavigationModule {
}
