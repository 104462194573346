<div class="suggestion-input" #suggestion>
    <div #suggestionPanel [ngClass]="['suggestion-panel', suggestionPanelClass, !openWithSpecialCharacter ? 'full-width' : '']">
        <div *ngIf="suggestionTemp.length > 0" #hasSuggestions>
            <li
                *ngFor="let suggestion of suggestionTemp"
                (click)="putSuggestion(suggestion)"
                class="u-grid u-items-center">
                    <span class="u-col-11">{{suggestion}}</span>
                    <span class="u-col-1 icon"><i class="material-icons">add_circle</i></span>
            </li>
        </div>
        <div *ngIf="suggestionTemp.length === 0" #notSuggestions>
            <li>No suggestions found</li>
        </div>
    </div>
    <div class="tracing-paper" #tracingPaper></div>
    <ng-container *ngIf="type === 'input'; then inputTpl; else textareaTpl"></ng-container>
    <ng-template #inputTpl>
        <span class="p-input-field p-float-label form-row">
            <input
                #input
                pInputText
                type="text"
                [required]="required"
                [ngClass]="{'invalid-input': control?.invalid && (control.dirty || control.touched)}"
                [(ngModel)]="value"
                [disabled]="isDisabled"
                alsuggestioninput="true"
                autocomplete="off"
                (keyup)="onKeyup($event)"
                (click)="onClick($event)"
                (keydown)="onKeydown($event)"
                (input)="onChange()"
                (change)="onChange()"
                (blur)="onTouchFn()"
                (focusin)="hasFocus = true"
                (focusout)="hasFocus = false">
            <ng-content select="[errors]"></ng-content>
            <label>{{placeholder}}</label>
        </span>
        
    </ng-template>
    <ng-template #textareaTpl>
        <span class="form-row">
            <label>{{placeholder}}</label>
            <textarea
                #input
                pInputTextarea
                [rows]="rows"
                [autoResize]="autoResize"
                [required]="required"
                [ngClass]="{'invalid-input': control?.invalid && (control.dirty || control.touched)}"
                [(ngModel)]="value"
                [disabled]="isDisabled"
                alsuggestioninput="true"
                autocomplete="off"
                (keyup)="onKeyup($event)"
                (click)="onClick($event)"
                (keydown)="onKeydown($event)"
                (input)="onChange()"
                (change)="onChange()"
                (blur)="onTouchFn()"
                (focusin)="hasFocus = true"
                (focusout)="hasFocus = false"></textarea>
            <ng-content select="[errors]"></ng-content>
        </span>
    </ng-template>
</div>
