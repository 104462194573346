/**
 * It Allows the construction of calendar type elements, support one calendar
 *
 * @author Miguel Sarmiento <miguel.sarmiento@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2021
 */

import { Validators, FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementCalendar extends AlFormElementBase<boolean> {

    public controlTemplate = "calendar";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
    }

    public createFormControl() {
        const validators = this.buildValidators();

        let formControl = validators.length > 0
            ? new FormControl(this.value, Validators.compose(validators))
            : new FormControl(this.value);

        return formControl;
    }

    getAnswer() {
        return this.getControlValue();
    }
}
