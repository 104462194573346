import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'al-detail-panel',
    templateUrl: './al-detail-panel.component.html',
    styleUrls: ['./al-detail-panel.component.scss']
})
export class AlDetailPanelComponent {
    @Input() icon: string;
    @Input() iconClass?: string = 'material-icons';
    @Input() header: string;
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    constructor() {}

    public emitClose(): void {
        this.onClose.emit();
    }
}
