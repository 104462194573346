import { Pipe, PipeTransform } from '@angular/core';
import { AlShortNumberTransform } from '../services/utility-functions';


@Pipe({
    name: 'alShortNumber'
})
export class AlShortNumberPipe implements PipeTransform {
    transform(value: number, decimals: number = 0, fromBytes: boolean = false): string {
        return AlShortNumberTransform(value, decimals, fromBytes);
    }
}
