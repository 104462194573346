<div
    class="ald-checkbox"
    [ngClass]="[
        disabled ? 'ald-checkbox--disabled' : '',
        size === 'sm' ? 'ald-checkbox--sm' : '',
        bottomPadding ? 'u-pb-2': ''
    ]"
>
    <input
        type="checkbox"
        class="ald-checkbox__input"
        [id]="id"
        [name]="name"
        [value]="value"
        [(ngModel)]="checked"
        [disabled]="disabled"
        [indeterminate]="indeterminate"
        (focus)="hasFocus=true"
        (blur)="hasFocus=false"
        (ngModelChange)="onValueChange($event)"
    />

    <label
        [for]="id"
        class="ald-checkbox__label"
    >
        {{ label }}
    </label>

</div>