<div class="ald-data-field"
    [ngClass]="{ 'ald-data-field--inline': inline }">
    <div class="ald-data-field__label">
        {{ label }}
    </div>
    <div class="ald-data-field__value">
        {{ value ? value : '' }}
        <ng-content></ng-content>
    </div>
</div>



