<ng-container *ngIf="display === 'summary'; else breakdown;">
    <ng-container *ngIf="threatSummary else noThreat">
        <ald-threat-rating
            [inline]="inline"
            [iconOnly]="iconOnly"
            [threatRating]="threatSummary"
        ></ald-threat-rating>
    </ng-container>
</ng-container>

<ng-template #breakdown>
    <ng-container *ngIf="threatBreakdown; else noThreat">
        <div class="u-flex u-items-center u-gap-1">
            <div class="u-flex u-items-center u-gap-1">
                <ald-threat-rating
                    [inline]="false"
                    [iconOnly]="true"
                    threatRating="critical"
                    label="{{ threatBreakdown.critical | aldFormatNumber }}"
                ></ald-threat-rating>
            </div>

            <div class="u-flex u-items-center u-gap-1">
                <ald-threat-rating
                    [inline]="false"
                    [iconOnly]="true"
                    threatRating="high"
                    label="{{ threatBreakdown.high | aldFormatNumber }}"
                ></ald-threat-rating>
            </div>

            <div class="u-flex u-items-center u-gap-1">
                <ald-threat-rating
                    [inline]="false"
                    [iconOnly]="true"
                    threatRating="medium"
                    label="{{ threatBreakdown.medium | aldFormatNumber }}"
                ></ald-threat-rating>
            </div>

            <div class="u-flex u-items-center u-gap-1">
                <ald-threat-rating
                    [inline]="false"
                    [iconOnly]="true"
                    threatRating="low"
                    label="{{ threatBreakdown.low | aldFormatNumber }}"
                ></ald-threat-rating>
            </div>

            <div class="u-flex u-items-center u-gap-1">
                <ald-threat-rating
                    [inline]="false"
                    [iconOnly]="true"
                    threatRating="info"
                    label="{{ threatBreakdown.info | aldFormatNumber }}"
                ></ald-threat-rating>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #noThreat>
    <div class="u-text-sm">{{ noThreatText }}</div>
</ng-template>


