import { BadgeVariant } from '@al/design-patterns/badge';
import { IconClass, AldOptionItem } from '@al/design-patterns/common';


export interface ColumnDef {
    header: string;
    headerTitle?: string; // Column Header title
    field: string; // field value for column per row
    fieldTitle?: string; // Field title
    cellConfig?: CellConfig;
    minColWidth?: number; // px
    maxColWidth?: number; // px
    hidden?: boolean; // Hides the column and filter from view
    filters?: AldOptionItem[];
    searchable?: boolean;
    sortDirection?: 'asc' | 'desc';
    // If a ColumnSortByValue array is passed in then we can sort on the value's replacement value
    // For example Gamma, Delta would sort as Delta, Gamma where we would want to order as Gamma, Delta
    // ColumnSortByValue could be set up as:
    // [ {fieldValue: 'Gamma', sortValue: 0}, {fieldValue: 'Delta', sortValue: 1} ]
    //
    // Sorting then on the sortValue coerces the text values into our intended order
    //
    // This obviously only works on known, finite datasets such as Protection Scope
    //
    sortByValue?: ColumnSortByValue[];
    sortable?: boolean;
    hasSeverities?: boolean;
}

export interface BadgeTable {
    variant?: BadgeVariant,
    field?: string,
    label?: string,
    icon?: string,
    iconClass?: IconClass,
    lowContrast?: boolean,
    title?: string
}

export interface LinkTable {
    key: string
    openInNewTab?: boolean;
    externalUrl?: boolean;
    internalUrl?: string[];
    queryParams?: {[key: string]: string}
}

export interface IconTable {
    name: string,
    color?: string,
    iconClass?: IconClass,
    title?: string,
    position?: 'left' | 'center' | 'right'
}

export interface IconLabelTable {
    name: string,
    label: string,
    color?: string,
    iconClass?: IconClass,
    title?: string
}

export interface TransformTable {
    transformTo?: string
}

/** Use cellConfig to define specific cell type and properties */
export interface CellConfig {
    badge?: Record<string,BadgeTable>;
    date?: {
        format?: string,
    };
    number?: {
        format?: string,
        prefix?: string
    };
    icon?: Record<string, IconTable>;
    icon_with_label?: Record<string, IconLabelTable>;
    link?: LinkTable;
    multiLink?: (value) => LinkTable[];
    format?: {
        static?: boolean, // property to define if the content should be html or static text.
        showRawValue?: boolean,
        ignoreDotNotation?: boolean
    };
    transform?: Record<string, TransformTable>;
    threat?: {
        display?: 'summary' | 'breakdown'
        noThreatText?: string
    };
    dictionary?: {
        property:string
    };
    transformFn?: (value) => string
}

/**
 *
 */
export interface ColumnFilterValue {
    label: string;
    selected: boolean;
    value?: string;
}

export interface ColumnFilter {
    field: string;
    rawField?: string;
    values: ColumnFilterValue[];
}

export interface ColumnSortByValue {
    fieldValue: string;
    sortValue: string | number;
}

export interface TableBulkActionsSelectionEvent {
    option: AldOptionItem; selections: any[];
}

export interface TableColumnSortConfig {
    sortField: string;
    sortDirection: 'asc' | 'desc';
    sortByValue?: ColumnSortByValue[];
    hasSeverities?: boolean;
}

/**
 * This interface allows consumers to inflect the configuration of table columns as they are added, and to respond to
 * new columns when they are added.
 *
 * The `read` method, if provided, will be called when the table (or table header) is populated with columns from the consumer -- the equivalent
 * of a change being recognized on the model.
 *
 * The `write` method, if provided, will be invoked with the table's columns are changed and it is communicating the updated column information
 * to the consumer.
 *
 */
export interface TableColumnDecorator {
    read:( columns:ColumnDef[] ) => ColumnDef[] | null;
    write:( columns:ColumnDef[] ) => void;
}

export interface TableSortDecorator {
    read:( ) => TableColumnSortConfig | null;
    write:( sortConfig:TableColumnSortConfig ) => void;
}

/**
 * An interface for customizing link construction within a table component.
 * 
 * This interface provides a 'build' method which allows for the customization of how links are
 * constructed and represented in the ald-table component.
 */
export interface TableLinkDecorator {
    build:(value, linkConfig: LinkTable) => LinkTable
}

/**
 * Provides utility functions for table operations.
 */
export class TableUtilities {
    /**
     * Sorts two objects based on predefined severities in either ascending or descending order.
     * 
     * This method iterates through a list of severities ('critical', 'high', 'medium', 'low', 'info')
     * and compares the corresponding values in objects `a` and `b`. The first severity level
     * that differs between `a` and `b` determines the order of the two objects.
     * 
     * @template T - The type of the objects being compared. Each object is expected to have keys
     *               corresponding to the severity levels with comparable values (e.g., numbers or strings).
     * @param {T} a - The first object to compare.
     * @param {T} b - The second object to compare.
     * @param {'desc' | 'asc'} sort - Specifies the order of sorting: 'asc' for ascending, 'desc' for descending.
     * @returns {number} - Returns -1 if `a` should come before `b`, 1 if `a` should come after `b`, 
     *                     or 0 if they are considered equal based on the severity levels.
     */
    static sortBreakDown<T>(a: T, b: T, sort: 'desc' | 'asc') {
        const multiplier: number = sort === 'asc' ? 1 : -1;
        const severities = [
            'critical',
            'high',
            'medium',
            'low',
            'info',
        ];
    
        for (const severity of severities) {
            if (a[severity] > b[severity]) {
                return -1 * multiplier;
            } else if (a[severity] < b[severity]) {
                return 1 * multiplier;
            }
        }
    
        return 0;
    }
}