
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'sort'
})
export class AlSortPipe implements PipeTransform {

    transform(data: any[], property: string, direction: string = 'asc'): any[] {
        if( property !== "" ) {
            return data.sort(this.getSortFunction(direction, property));
        } else {
            return data;
        }
    }

    private getSortFunction(direction: string, property: string): (a: any, b: any) => number {
        return (a: any, b: any) => {
            if(property in a && property in b ) {
                return ( direction === 'asc'? a[property] > b[property]: a[property] < b[property]) ? 1 : -1;
            } else {
                return 1;
            }
        };
    }
}
