import { FeatureAuthModule } from '@feature/authentication';
import { Route } from '@angular/router';
import { AlIdentityResolutionGuard } from '@al/ng-navigation-components';
import { DefaultRouteComponent } from './default-route/default-route.component';


export const ApplicationRoutes: Route[] = [
    {
        path: 'dashboards',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/dashboards').then(module => module.DashboardsModule)
    },
    {
        path: 'exposure-management',
        loadChildren: () => import('@feature/exposures').then(module => module.ExposuresModule)
    },
    {
        path: 'exposures',
        loadChildren: () => import('@feature/exposures').then(module => module.ExposuresModule)
    },
    {
        path: 'search',
        loadChildren: () => import('@feature/search').then(module => module.SearchFeatureModule)
    },
    {
        path: 'assets',
        loadChildren: () => import('@feature/assets-console').then(module => module.AssetsConsoleFeatureModule)
    },
    {
        path: 'vulnerabilities',
        loadChildren: () => import('@feature/vulnerabilities').then(module => module.VulnerabilityLibraryModule)
    },
    {
        path: 'pci-scanning',
        loadChildren: () => import('@feature/pci-scanning').then(module => module.PciScanningModule)
    },
    {
        path: 'incidents',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/incidents').then(module => module.IncidentsModule)
    },
    {
        path: 'health',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/health').then(module => module.HealthModule)
    },
    {
        path: 'reports',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/reports').then(module => module.ReportsFeatureModule)
    },
    {
        path: 'tic',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/tic').then(module => module.TicModule)
    },
    {
        path: 'account',
        loadChildren: () => import('@feature/users').then(module => module.UsersModule)
    },
    {
        path: 'diagnostics',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/diagnostics').then(module => module.DiagnosticsFeatureModule)
    },
    {
        path: 'legacy-dashboards',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/legacy-dashboards').then(module => module.LegacyDashboardsModule)
    },
    {
        path: 'waf',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/waf-config').then(module => module.WafConfigModule)
    },
    {
        path: 'manage-notifications',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/notifications').then(module => module.NotificationsModule)
    },
    {
        path: 'automated-response',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/automated-response').then(module => module.AutomatedResponseModule)
    },
    {
        path: 'application-registry',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/applications-registry').then(module => module.ApplicationRegistryV2Module)
    },
    {
        path: 'asset-groups',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/asset-groups').then(module => module.AssetGroupsModule)
    },
    {
        path: 'certificates-adr',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/certificates-keys').then( module => module.CertificatesKeysModule )
    },
    {
        path: 'network-ids-config',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/network-ids-config').then(module => module.NetworkIDSConfigModule)
    },
    {
        path: 'topology',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/topology').then(module => module.TopologyModule)
    },
    {
        path: 'log-management',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/log-management').then(module => module.LogManagementModule)
    },
    {
        path: 'deployments-adr',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/deployments-adr').then(module => module.DeploymentsADRModule)
    },
    {
        path: 'connections',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/connections').then(module => module.ConnectionsModule)
    },
    {
        path: 'standalone-waf',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/waf').then(module => module.WafModule)
    },
    {
        path: 'marketplace',
        loadChildren: () => import('@feature/marketplace').then(module => module.MarketplaceModule)
    },
    {
        path: 'pci',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/pci').then(module => module.PciModule)
    },
    {
        path: 'eep',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/endpoints').then(module => module.EndpointsModule)
    },
    {
        path: 'notifications-config',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/notifications-config').then( module => module.NotificationsConfigModule )
    },
    {
        path: 'legacy-deployments',
        canActivate: [AlIdentityResolutionGuard],
        loadChildren: () => import('@feature/legacy-deployments').then( module => module.LegacyDeploymentsModule )
    },
    {
        path: 'support-information',
        loadChildren: () => import('@feature/support-information').then(module => module.SupportInformationModule)
    },
    /* ^^^ Please add new modules here.  FeatureAuthModule's routes MUST be above the ** catch-all route. ^^^ */
    ...FeatureAuthModule.publicRoutes,
    {
        path: '**',
        component: DefaultRouteComponent
    },

];
