<div class="u-border-l u-border-solid u-border-primary u-flex u-flex-col u-h-100%">

    <section class="ald-detail-pane">

        <div class="ald-detail-pane__header ald-detail-pane__header--has-action" [ngClass]="[ icon ? 'ald-detail-pane__header--has-icon' : '']">
            <div class="ald-detail-pane__icon" *ngIf="icon">
                <i [ngClass]="[iconClass ? iconClass : 'material-icons']">
                    {{ icon }}
                </i>
            </div>
            <div class="ald-detail-pane__title" *ngIf="header">{{ header }}</div>
        </div>

        <div class="ald-detail-pane__content">
            <ng-content></ng-content>
        </div>

    </section>

</div>
