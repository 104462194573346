import { Component, Input } from '@angular/core';
import { AldTooltipPosition } from '@al/design-patterns/tooltip';


@Component({
    selector: 'ald-form-element-header',
    templateUrl: './ald-form-element-header.component.html',
    styleUrls: ['./ald-form-element-header.component.scss']
})
export class AldFormElementHeaderComponent {
    @Input() label = '';
    @Input() id?: string;
    @Input() name?: string;
    @Input() for?: string;
    @Input() hint?: string;
    @Input() tip?: string;
    @Input() required?: boolean = false;
}
