<p-sidebar #pSidebar
    [styleClass]="'al-sidebar ' + cssClass"
    [style]="inlineStyles"
    [(visible)]="visible"
    [appendTo]="appendTo"
    position="right"
    [showCloseIcon]="false"
    [modal]="config.modal"
    [dismissible]="false"
    (onHide)="sidebarHidden()"
    (onShow)="sidebarShown()"
    data-cy="al-sidebar">

    <div class="header" [ngStyle]="{ 'background-color': config.headerColor }" data-cy="al-sidebar-header">
        <ng-container *ngTemplateOutlet="headerTpl || defaultHeaderTpl"></ng-container>
    </div>
    <div class="toolbar" *ngIf="config.enableButtonToolbar || toolbarTpl" [ngStyle]="{ 'background-color': config.toolbarColor }">
        <ng-container *ngTemplateOutlet="toolbarTpl || defaultToolbarTpl"></ng-container>
    </div>
    <div class="content">
        <div class="content-body">
            <al-view-helper #viewHelper [loading]="config.isloading" *ngIf="config.viewHelper" [notifyPanel]="true"></al-view-helper>
            <ng-container *ngTemplateOutlet="contentTpl || defaultContentTpl"></ng-container>
        </div>
    </div>
</p-sidebar>

<ng-template #defaultHeaderTpl>
    <!-- Default Header Template -- only used if no `header` template is provided. -->
    <div class="u-grid u-items-center no-margin">
        <div *ngIf="config.expandable" class="u-ml-5">
            <i class="material-icons expand-icon" fxFlexAlign="baseline"
                (click)="toggleExpand()"
                data-cy="al-sidebar-toggle-expand">
                {{ config.expand ? 'fullscreen_exit' : 'fullscreen' }}</i>
        </div>
        <div class="u-col title">
            <div class="info-icon">
                <i (click)="headerIconClicked()" *ngIf="config.header.icon?.name" class="material-icons">{{ config.header.icon?.name }}</i>
                <span *ngIf="config.header.icon?.title && config.header.icon?.name">{{ config.header.icon?.title }}</span>
            </div>
            <div class="info-icon">
                <i (click)="headerIconClicked()" *ngIf="config.header.icon?.cssClasses" class="{{config.header.icon?.cssClasses}}"></i>
                <span *ngIf="config.header.icon?.title && config.header.icon?.cssClasses">{{ config.header.icon?.title }}</span>
            </div>
            <span *ngIf="config.header.title" class="al-sidebar-title" data-cy="al-sidebar-title">{{config.header.title}}</span>
        </div>
        <div class="u-col-1 no-pad-left">
            <i *ngIf="config.header.showClose"
                class="material-icons close-icon"
                (click)="close()"
                data-cy="al-sidebar-close-button">close</i>
        </div>
    </div>
</ng-template>

<ng-template #defaultToolbarTpl>
    <!-- Default Toolbar Template -- only used if configuration's `enableButtonToolbar` property is `true` and no `toolbar` template is provided. -->
    <button *ngIf="config.secondary"
            (click)="onSecondaryAction()"
            type="button" pButton
            label="{{config.secondary.text}}"
            icon=""
            class="flat"
            [ngClass]="[config.secondary.disabled? 'p-disabled' : '']"
            [disabled]="config.secondary.disabled"
            data-cy="al-sidebar-secondary-action"></button>
    <button *ngIf="config.ternary"
            (click)="onTertiaryAction()"
            type="button" pButton
            label="{{config.ternary.text}}"
            icon=""
            class="outlined light"
            [ngClass]="[config.ternary.disabled? 'p-disabled' : '']"
            [disabled]="config.ternary.disabled"
            data-cy="al-sidebar-ternary-action"></button>
    <button *ngIf="config.primary"
            (click)="onPrimaryAction()"
            type="button" pButton
            label="{{config.primary.text}}"
            icon=""
            class="primaryAction"
            [ngClass]="[config.primary.disabled? 'p-disabled' : '']"
            [disabled]="config.primary.disabled"
            data-cy="al-sidebar-primary-action"></button>
</ng-template>

<ng-template #defaultContentTpl>
    <!-- Default Content Template -- only used if a content template isn't set. -->
    <ng-content></ng-content>
</ng-template>
