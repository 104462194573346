import { Component, OnInit, ViewChild } from '@angular/core';
import {
    AlConduitClient, 
    AlDefaultClient, 
    AlErrorHandler, 
    AlLocatorService, 
    AlLocation, 
    AlRuntimeConfiguration, 
    ConfigOption, 
    AlSession
} from '@al/core';
import { AlViewHelperComponent } from '@al/ng-generic-components';
import { AlNavigationService, AlSessionManagerService } from '@al/ng-navigation-components';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})

export class LogoutComponent implements OnInit {

    @ViewChild(AlViewHelperComponent, { static: true })
    public viewHelper:AlViewHelperComponent;

    protected conduit:AlConduitClient = new AlConduitClient();

    public loading = false;

    constructor( public navigation:AlNavigationService,
                 public sessionManager:AlSessionManagerService ) {
        this.conduit.start();
    }

    async ngOnInit() {
        const returnURL = this.chooseReentryURL();
        try {
            this.loading = true;
            await this.sessionManager.destroySession( returnURL );
        } catch( e ) {
            this.viewHelper.setError( e );
        } finally {
            this.loading = false;
        }
    }

    /**
     * Attempt to choose the best URL to return to after deauthentication is complete.
     */
    chooseReentryURL() {
        const returnURL = this.navigation.queryParam("return");
        if ( returnURL ) {
            return returnURL;
        }
        if ( AlSession.getFortraSession() ) {
            return AlLocatorService.resolveURL( AlLocation.MagmaUI, '/#/' );
        } else {
            let reentryURL = AlLocatorService.resolveURL( AlLocation.MagmaUI, '/#/login' );
            if ( returnURL ) {
                reentryURL += `?return=${encodeURIComponent( returnURL )}`;
            }
            return reentryURL;
        }
    }
}
