<div class="ald-note"
    [attr.role]="variant === 'danger' ? 'alert' : 'status'"
    [ngClass]="noteStyles">
    <div class="u-flex">
        <div class="u-mr-2">
            <ald-icon [icon]="noteIcon" color="inherit" [iconClass]="iconClass" size="sm"></ald-icon>
        </div>
        <div class="ald-note__body">
            <div *ngIf="header" class="ald-note__heading">{{ header }}</div>
            <ng-container *ngIf="hasContent">
                <div #content class="ald-note__content">
                    <ng-content></ng-content>
                </div>
            </ng-container>
        </div>
        <div class="u-ml-2" *ngIf="actionLabel || actionIcon">
            <ng-container *ngIf="!actionOptions.length; else actionDropdown;">
                <ald-button
                    [label]="actionLabel"
                    [icon]="actionIcon"
                    [iconClass]="actionIconClass"
                    [variant]="actionVariant"
                    size="sm"
                    (click)="didAction.emit()">
                </ald-button>
            </ng-container>
            <ng-template #actionDropdown>
                <ald-dropdown
                    #dropdownNote
                    [label]="actionLabel"
                    [icon]="actionIcon"
                    [iconClass]="actionIconClass"
                    [variant]="actionVariant"
                    size="sm">
                    <ald-menu
                        [options]="actionOptions"
                        (didSelect)="didAction.emit($event); dropdownNote.close()">
                    </ald-menu>
                </ald-dropdown>
            </ng-template>
        </div>
    </div>
</div>
