<p-toast styleClass="custom-toast" [position]="position" [key]="key">
    <ng-template let-message pTemplate="message">
        <div class="u-grid u-grid-nogutter u-p-2 u-w-100%">
            <i *ngIf="message.data.iconClass" class="u-col-fixed toast-icon pi {{message.data.iconClass}}"></i>
            <div class="u-col-12 u-grid u-grid-nogutter u-flex u-flex-col">
                <div class="p-toast-content u-col" [style.text-align]="message.data.textAlign">
                    <div *ngIf="message.data.title" class="p-toast-title">{{message.data.title}}</div>
                    <div *ngIf="message.data.message" class="p-toast-message-text">{{message.data.message}}</div>
                    <div *ngIf="message.data.html" class="p-toast-message-html" [innerHTML]="message.data.html"></div>
                </div>
                <div *ngIf="message.data.buttons" class="u-grid toast-buttons u-grid-nogutter">
                    <div class="button-container {{button.class ? button.class : ''}}" *ngFor="let button of message.data.buttons" [style.text-align]="button.textAlign" [ngClass]="{ 'disabled': button.disabled }">
                        <a [id]="button.key" (click)="clickEvent(button)">{{button.label | uppercase}}</a>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
