import { AlCabinet } from '@al/core';
import { ColumnDef, TableColumnDecorator, TableColumnSortConfig, TableSortDecorator } from '@al/design-patterns';


export class TableColumnPersistenceDecorator implements TableColumnDecorator {

    private tableStorage: AlCabinet;
  
    constructor( public localStorageKey:string ) {
      this.tableStorage = AlCabinet.persistent(this.localStorageKey);
    }
  
    public read( currentColumns:ColumnDef[] ) {
        const storedColumns = this.getSavedColumns();
        if ( ! storedColumns ) {
            return null;
        }
        const columnsDict = Object.assign({}, ...currentColumns.map((col) => ({[col.field]: col}))) as ColumnDef[];
        // If condition is true this means that a new col was added and then we need to take it into account
        if (currentColumns.length > storedColumns.length) {
            Object.keys(columnsDict).forEach(indexCol => {
                if (!storedColumns.some(storedCol => storedCol.field === indexCol)) {
                    // We add the new col preserving its position
                    storedColumns.splice(currentColumns.indexOf(columnsDict[indexCol]),0,columnsDict[indexCol]);
                }
            });
        }
        return storedColumns.map(col => {
            if (columnsDict[col.field]) {
                const currentCol = columnsDict[col.field] as ColumnDef;
                // We must to re-assing all properties except for sortDirection, field and hidden props which are the ones we want to preserve
                // Note that implictly we are preserving the order of columns
                const ignoreKeys = ['sortDirection', 'field', 'hidden'];
                for (const [key, value] of Object.entries(currentCol)) {
                    if(!ignoreKeys.includes(key)) {
                        col[key] = value;
                    }
                }
            } else return null; // This means that a col was removed and then we need to take it off
            return col;
        }).filter(col => col !== null);
    }
  
    public write( columns:ColumnDef[] ) {
      this.tableStorage.set('columns', columns);
    }
  
    private getSavedColumns():ColumnDef[]|null {
        try {
            const raw = this.tableStorage.get('columns');
            if ( ! raw ) {
                return null;
            }
            return raw as ColumnDef[];
        } catch( e ) {
            return null;
        }
    }
  }

  export class TableSortPersistenceDecorator implements TableSortDecorator {

    private tableStorage: AlCabinet;
  
    constructor( public localStorageKey:string ) {
      this.tableStorage = AlCabinet.persistent(this.localStorageKey);
    }
  
    public read() {
      try {
        const raw = this.tableStorage.get('sortConfig');
        if ( ! raw ) {
            return null;
        }
        return raw as TableColumnSortConfig;
    } catch( e ) {
        return null;
    }
    }
  
    public write( columns:TableColumnSortConfig ) {
      this.tableStorage.set('sortConfig', columns);
    }
  }
