import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'ald-loading-spinner',
    templateUrl: './ald-loading-spinner.component.html',
    styleUrls: ['./ald-loading-spinner.component.scss'],
})
export class AldLoadingSpinnerComponent implements OnInit {
    @Input() size: 'sm' | 'md' | 'lg' = 'lg';
    @Input() theme: 'default' | 'light' | 'dark' = 'default';

    /**
    * Indicates whether outer spacing is enabled or disabled.
    */
    @Input() outerSpacing: true | false = true;

    strokeWidth: number;

    ngOnInit() {
        switch (this.size) {
            case 'sm':
                this.strokeWidth = 6;
                break;
            case 'md':
                this.strokeWidth = 5;
                break;
            case 'lg':
                this.strokeWidth = 4;
                break;
            default:
                this.strokeWidth = 4;
        }
    }
}
