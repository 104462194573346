<!-- CHORE(*): type hygiene -->
<div class="al-preview" data-e2e="al-preview">
    <h2 class="dont-break-out">{{data.title}}</h2>
    <h3 *ngIf="data.subtitleLabel" class="property-name dont-break-out subtitle-label">{{data.subtitleLabel}}</h3>
    <div class="subtitle dont-break-out">{{data.subtitle}}</div>
    <div *ngFor="let prop of data.properties">
        <h3 class="property-name dont-break-out">{{prop.key}}</h3>
        <div *ngIf="!prop.isTemplate">
            <div class="property-value dont-break-out" *ngFor="let valueDef of $any(prop.value)">
                <span *ngIf="$any(valueDef).icon || prop.icon" class="property-icon">
                    <i [ngClass]="$any(valueDef).icon || prop.icon"></i>
                </span>
                <div *ngIf="$any(valueDef).action;else noAction">
                    <a href="#" (click)="$any(valueDef).action()">{{$any(valueDef).label}}</a>
                </div>
                <ng-template #noAction>{{$any(valueDef).label}}</ng-template>
            </div>
        </div>
        <div *ngIf="prop.isTemplate">
            <ng-container *ngTemplateOutlet="$any(prop.value)[0].label; context:{ $implicit: prop.templateData }"></ng-container>
        </div>
    </div>
</div>
