import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'alObjectValue',
})
export class AlObjectValuePipe implements PipeTransform {
    transform(value: any, name: string): any {
        // If name is prefixed with "direct--" then the
        // it has been directed to use the name itself rather than
        // using the name to target a value property
        if (/^direct--/.test(name)) {
            return name.replace(/^direct--/, '');
        }

        if (Array.isArray(value) || !(value instanceof Object) || !name) {
            return value;
        } else if (name.indexOf('.') > -1) {
            const splitName: string[] = name.split(/\.(.+)/, 2);
            return this.transform(value[splitName[0]], splitName[1]);
        } else {
            return value[name];
        }
    }
}
