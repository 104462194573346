<div
  class="ald-tooltip"
  [id]="id"
  [ngClass]="['ald-tooltip--' + position]"
  [class.ald-tooltip--visible]="visible"
  [style.left]="left + 'px'"
  [style.top]="top + 'px'"
>
  {{ tooltip }}
</div>
