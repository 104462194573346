import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AldCommonModule } from '@al/design-patterns/common';
import * as manifest from './manifest';


@NgModule({
    declarations: [

    ],
    imports: [
        CommonModule,
        RouterModule,
        OverlayModule,
        AldCommonModule,
        ...manifest.MODULE_CHILDREN
    ],
    providers: [

    ],
    exports: [
        AldCommonModule,
        OverlayModule,
        ...manifest.MODULE_CHILDREN
    ]
})

export class DesignPatternComponentsModule { }
