import { Component, OnInit } from '@angular/core';
import { AlSession, AlStopwatch, AlLocation, AlEntitlementCollection } from '@al/core';
import { AlNavigationService, EntitlementGroup } from '@al/ng-navigation-components';


@Component({
    selector: 'al-console-default-route',
    templateUrl: './default-route.component.html',
    styleUrls: [ './default-route.component.scss' ]
})
export class DefaultRouteComponent implements OnInit {

    constructor( public navigation:AlNavigationService ) {
    }

    ngOnInit() {
        AlStopwatch.once( async () => {
            await AlSession.ready();
            if ( AlSession.isActive() ) {
                const effectiveEntitlements: AlEntitlementCollection = await AlSession.getEffectiveEntitlements();
                const isAaidDashboardsEnabled: boolean = effectiveEntitlements.evaluateExpression(EntitlementGroup.Siemless);

                if( (isAaidDashboardsEnabled)){
                    this.navigation.navigate.byNgRoute(['dashboards']);
                } else {
                    this.navigation.navigate.byNgRoute(['legacy-dashboards']);
                }
            } else {
                this.navigation.navigate.byNgRoute(['login']);
            }
        }, 1_500 );
    }
}
