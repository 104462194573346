/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AbstractControl, Validators, FormControl } from '@angular/forms';
import { TreeNode } from 'primeng-lts/api';
import { AlDynamicFormControlElement } from '@al/core';
import { AlChipItem, AlSelectItem } from '@al/ng-generic-components';
import { AlDynamicFormControlOption, AlDynamicFormControlInputResponderOptions, AlDynamicFormElementDescriptor } from './al-form.types';


/**
 * @author Juan Kremer <jkremer@alertlogic.com>
 * @author Kevin <knielsen@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

export type AlFormRefreshCallback = {(values:any,element:AlFormElementBase<any>):Promise<void>};

export interface AlFormColumn {
    elements:AlFormElementBase<any>[];
    cssClass?:string;
}

/**
 * See AlDynamicFormElementDescriptor (the abstract interface for element descriptors) for more information on the
 * specific function of each property.  The base class will automatically populate most of these properties from the
 * origin descriptor and into the element instance.
 */
export abstract class AlFormElementBase<DataType=any> implements AlDynamicFormElementDescriptor {

    public control:AbstractControl|undefined;
    public type: string             =   'any/hidden';
    public dataType: string         =   'any';
    public controlType: string      =   'hidden';
    public controlTemplate: string  =   "none";
    public htmlInputType:string     =   "text";
    public property: string         =   '';

    public visible: boolean         =   true;

    public title?: string;
    public cssClass?: string;
    public dateFormat?: string;
    public showTime?: boolean;
    public value?: DataType;
    public label?: string;
    public required?: boolean;
    public disabled?: boolean;
    public options: AlDynamicFormControlOption[] = [];
    public autocomplete?:"on"|"off"|undefined;
    public validationPattern?: string;
    public description?: string;
    public editorOptions?: any;
    public responderOptions?: AlDynamicFormControlInputResponderOptions;
    public placeholder?: string;
    public belowDescription?: string;
    public aboveDescription?: string;
    public patternError?: string;
    public requiredError?: string;
    public joinExpression?: string;
    public splitExpression?: RegExp | string;
    public multiSelectOptions?: AlSelectItem<DataType>[] | AlChipItem<DataType>[];
    public treeSelectOptions?: TreeNode[];
    public onNodeSelected?: (event: any) => void;
    public onNodeUnselected?: (event: any) => void;
    public minLength?: number;
    public minLengthError?: string;

    public maxLength?: number;
    public maxLengthError?: string;
    public minValue?: number;
    public maxValue?: number;
    public columns?: AlFormColumn[];        //  Used for void/column elements
    public elements?: AlFormElementBase[];  //  Used for void/group elements
    public action?: {   
        icon: string, 
        label: string, 
        callback: {(event:PointerEvent|MouseEvent, value: unknown):void} | undefined
    };
     

    /**
     * Used to make one element react to changes to one or more other elements; see the `refreshOn()` method.
     */
    public dependsOn: string[] = [];
    public tags: string[] = [];
    public refreshCallback?:AlFormRefreshCallback;

    /**
     * For future use
     */
    public busy:boolean = false;

    /**
     * This dictionary allows elements to communicate properties to the element template (al-dynamic-form/element/al-dynamic-form-element.component.html)
     * without typing constraints.
     */
    public view:{[property:string]:any} = {};

    constructor( public descriptor: AlDynamicFormElementDescriptor ) {
        this.type = descriptor.type;
        [ this.dataType, this.controlType ] = this.type.split('/');
        if ( ! this.controlType ) {
            throw new Error( `Invalid control descriptor type '${descriptor.type}' is not even close to being valid` );
        }
        this.title = descriptor.title || '';
        this.cssClass = descriptor.cssClass || '';
        this.dateFormat = descriptor.dateFormat || '';
        this.showTime = !!descriptor.showTime;
        this.value = descriptor.value!;
        if ( ! descriptor.property ) {
            throw new Error( `Invalid control descriptor is missing a 'property' specifier` );
        }
        this.property = descriptor.property || '';
        this.label = descriptor.label || '';
        this.required = !!descriptor.required;
        this.disabled = !!descriptor.disabled;
        this.validationPattern = descriptor.validationPattern ? descriptor.validationPattern : '';
        this.options = descriptor.options ? descriptor.options : [];
        this.autocomplete = descriptor.autocomplete;
        this.placeholder = descriptor.placeholder ? descriptor.placeholder : '';
        this.belowDescription = descriptor.belowDescription ?? '';
        this.aboveDescription = descriptor.aboveDescription ?? '';
        this.description = descriptor.description ?? '';
        this.patternError = descriptor.patternError ? descriptor.patternError : '';
        this.requiredError = descriptor.requiredError ? descriptor.requiredError : '';
        this.joinExpression = descriptor.joinExpression || "";
        this.splitExpression = descriptor.splitExpression || "";
        this.responderOptions = descriptor.responderOptions || {};
        this.minLength = descriptor.minLength;
        this.maxLength = descriptor.maxLength;
        this.minValue = descriptor.minValue ?? descriptor.minimum;
        this.maxValue = descriptor.maxValue ?? descriptor.maximum;
        if ( descriptor.dependsOn ) {
            this.dependsOn = descriptor.dependsOn;
        }
        this.action = descriptor.action;
    }

    /**
     * Type guard: determines whether a candidate object is an AlFormElementBase instance.
     */
    public static isInstance( thing:any ):thing is AlFormElementBase {
        return thing instanceof AlFormElementBase;
    }

    /**
     * Type guard: determines whether a candidate object is a form descriptor.
     */
    public static isDescriptor( thing:any ):thing is AlDynamicFormControlElement {
        return typeof( thing ) === 'object' && 'property' in thing && 'type' in thing;
    }

    /**
     * Type guard: determine whether an array is an array of descriptors
     */
    public static isDescriptorArray( thing:any ):thing is AlDynamicFormControlElement[] {
        if ( Array.isArray( thing ) ) {
            return thing.every( el => AlFormElementBase.isDescriptor( el ) );
        }
        return false;
    }

    /**
     * Creates a new form control based on the associatiated descriptor, or returns the existing one.
     * @returns AbstractControl: Definition of FormControl/FormArray with its validations.
     */
    getFormControl(): AbstractControl {
        if ( ! this.control ) {
            let control = this.createFormControl();
            if ( ! control ) {
                control = new FormControl( null );
            }
            this.control = control;
        }
        return this.control as AbstractControl;
    }

    /**
     * Gets the value of the currently instantiated control.
     */
    getControlValue(): DataType|null {
        return this.control ? this.control.value : null;
    }

    /**
     * Deliver the values assigned by the user in the form
     * @returns Object: The values assigned in the element returned by formcontol defined
     */
    abstract getAnswer(): DataType|null|undefined;

    /**
     * Creates a dependency between a particular control and one or more other form controls, such that
     * the target control is given a chance to react/modify the form state when those other controls change.
     */
    public refreshOn( fields:string|string[], callback: AlFormRefreshCallback ) {
        this.dependsOn = Array.isArray( fields ) ? fields : [ fields ];
        this.refreshCallback = callback;
    }

    /**
     * Creates a form control (subclass logic)
     * @returns AbstractControl:
     */
    abstract createFormControl(): AbstractControl|undefined;

    /**
     * Return an array with all the validators the configuration has
     */
    public buildValidators(){
        const validators = [];
        if (this.required) {
            validators.push(Validators.required);
        }
        if (this.validationPattern) {
            validators.push(Validators.pattern(this.validationPattern));
        }

        if (this.minLength) {
            validators.push(Validators.minLength(this.minLength));
        }

        if (this.maxLength) {
            validators.push(Validators.maxLength(this.maxLength));
        }

        if(!Number.isNaN(this.minValue!)) {
            validators.push(Validators.min(this.minValue!));
        }

        if (!Number.isNaN(this.maxValue!)) {
            validators.push(Validators.max(this.maxValue!));
        }

        return validators;
    }


    /**
     * Return a string
     */
    public transformValueToString(value ?: DataType){
        if (value !== undefined) {
            if( this.dataType === 'object') {
                return typeof value !== 'string'? JSON.stringify(value, null, 2) : value;
            }

            if( value instanceof Array &&
                (this.dataType === "string[]"
                ||  this.dataType === "any[]")
                ) {
                return value.join(this.joinExpression);
            }

            if (this.dataType === "string" && typeof value === "string") {
                return value.toString();
            }

            if (this.dataType && ["integer","number"].includes(this.dataType) && typeof value === "number") {
                return value.toString();
            }
        }

        return  value;
    }

    /**
     * Convert an string to an array or to an object
     */
    public transformStringToDataType( value:string ){
        if (this.dataType === 'object') {
            try{
                return JSON.parse(value);
            } catch(error) {
                console.warn("We are trying to parse as an object the following string", value, error);
            }
        }
        if ((this.dataType === "string[]"
            ||  this.dataType === "any[]")
            && this.splitExpression) {
            return value.split(this.splitExpression);
        }

        if (this.dataType === "integer" && !isNaN(Number(value))) {
            return parseInt(value, 10);
        }

        if (this.dataType === "number" && !isNaN(Number(value)) ) {
            return Number(value);
        }
        return value;
    }

    /**
     * Retrieves the descriptor that this element was derived from.
     */
    public getDescriptor():AlDynamicFormControlElement {
        return this.descriptor;
    }

    /**
     * Allow to set an action
     */
    public setAction(action: { icon: string, label: string, callback: {(event:PointerEvent|MouseEvent, value: unknown):void} | undefined } ) {
        this.action = action;
    }

}
