import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'ald-repeater',
  templateUrl: './ald-repeater.component.html'
})
export class AldRepeaterComponent {

  /**
   * Specify the button label for the Add Item button. Default is "Add Item".
   */
  @Input() addButtonLabel?: string = 'Add Item';

  /**
   * The EventEmitter for when the add item button is clicked.
   * This should typically be used to add items to the array of items.
   */
  @Output() didAddItem: EventEmitter<void> = new EventEmitter();
  
}
