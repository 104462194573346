import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExlusionsDetails } from '@al/exclusions';


export interface EmitDialogClosing {
    doScan: boolean;
    forceScan: boolean;
}

@Component( {
    selector: 'al-exclusion-dialog',
    templateUrl: './al-exclusion-dialog.component.html',
    styleUrls: ['./al-exclusion-dialog.component.scss']
} )
export class AlExclusionDialogComponent {
    @Input() visible = false;
    @Input() fullyExcluded = false;
    @Input() exclusionsList: Array<ExlusionsDetails[]> = [];

    @Output() dialogClosing = new EventEmitter<EmitDialogClosing>();

    forceScan = false;

    constructor() {
        // empty
    }

    cancel() {
        this.dialogClosing.emit({doScan: false, forceScan: false});
    }

    submit() {
        const forceScan = this.fullyExcluded || this.forceScan;
        this.dialogClosing.emit({doScan: true, forceScan});
    }
}
