<div class="time-selector-container u-grid">
    <div class="u-col time-selector-input">
        <p-inputNumber (ngModelChange)="timeChange()" [(ngModel)]="timeParts.hours" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical"
            decrementButtonClass="flat" incrementButtonClass="flat"
            incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down" [min]="0" [max]="23">
        </p-inputNumber>
    </div>
    <span class="time-selector-separator">:</span>
    <div class="u-col time-selector-input">
        <p-inputNumber (ngModelChange)="timeChange()" [(ngModel)]="timeParts.minutes" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical"
            decrementButtonClass="flat" incrementButtonClass="flat"
            incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down" [min]="0" [max]="59" (onChange)="timeChange()">
        </p-inputNumber>
    </div>
    <span class="time-selector-separator">:</span>
    <div class="u-col time-selector-input">
        <p-inputNumber (ngModelChange)="timeChange()" [(ngModel)]="timeParts.seconds" [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical"
            decrementButtonClass="flat" incrementButtonClass="flat"
            incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down" [min]="0" [max]="59">
        </p-inputNumber>
    </div>
</div>