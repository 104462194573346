/**
 *  Component to build and manage a group of form elements dynamically from a JSON definition (see @al/core's AlDynamicFormControlElement interface).
 *
 *  A note on nomenclature:
 *      - An element descriptor (AlDynamicFormControlElement, see definition in @al/core) is the JSON representation of an individual form element;
 *      - An element (AlFormElementBase and its derived classes) is an abstract entity that manages specific types of controls based on an element descriptor;
 *      - A control (AbstractControl) is the angular component corresponding to an element.
 *
 *  These concepts appear interchangeable, but are not.  The only thing linking element descriptors, elements, and controls is their `property` value, which is used to
 *  key the output of a form and to retrieve the element or control corresponding to a specific field.
 *
 *  See the README.md for lots more information.
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 * @author Kevin <knielsen@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import {
    Component, 
    Input, 
    OnInit, 
    Output, 
    EventEmitter, 
    OnDestroy, 
    OnChanges, 
    AfterViewInit, 
    SimpleChanges, 
    ContentChildren, 
    QueryList, 
    TemplateRef
} from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import {
    AlSubscriptionGroup, 
    AlDynamicFormControlElement, 
    AlJsonValidator, 
    AlDataValidationError, 
    AlDefaultClient, 
    AlErrorHandler
} from '@al/core';
import { AlViewHelperComponent, AlExternalContentManagerService } from '@al/ng-generic-components';
import { AlCustomControlDirective } from '../directives/al-custom-control.directive';
import { AlDynamicFormUtilityService } from '../services/al-dynamic-form-utility.service';
import { AlFormElementBase } from '../types/al-form-element-base';
import { AlDynamicFormControlOption, AlFormElementChange } from '../types/al-form.types';


@Component({
    selector: 'al-dynamic-form',
    templateUrl: './al-dynamic-form.component.html',
    styleUrls: ['./al-dynamic-form.component.scss']
})
export class AlDynamicFormComponent<ModelType=any> implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    /**
     * An array of element or element descriptors that compose a form.
     */
    @Input() elements: (AlFormElementBase<any>|AlDynamicFormControlElement<any>)[] = [];

    /**
     * An optional set of values to use as initial values for the form.  If provided, this will override values and
     * default values specified in the form data itself.
     */
    @Input() model?:ModelType;

    /**
     * The resource ID or URL of a JSON file to use as a seed for the form.
     * For example, `presentation:application/forms/search/create-correlation.form.json` or `/assets/schedule-search.form.json`.
     * URLs may be relative or fully qualified, but will not be retrieved with an AIMS Token header (and thus not require CORS).
     */
    @Input() loadFrom?: string;

    /**
     * Whether or not the form should allow empty values
     */
    @Input() allowEmptyValues: boolean = false;

    /**
     * Whether or not errors with the inputs should be handled strictly (thrown exception) or leniently (console warning).
     */
    @Input() strict:boolean     = false;

    /**
     * A view helper instance to use for strict error reporting.  If `strict` is set to true and a view helper instance is provided,
     * form validation/instantiation errors will be sent to the view helper instead of being treated as unhandled exceptions.
     */
    @Input() viewHelper?:AlViewHelperComponent;

    /**
     * Emits a dictionary of form elements after the input element array as been normalized and each form control has been created,
     * but before the final form group has been constructed.  Re-emits every time the `elements` input is modified.
     */
    @Output() onReady           = new EventEmitter<{[property:string]:AlFormElementBase<any>}>();

    /**
     * Emits true if the form's current state is valid, false otherwise
     */
    @Output() isValid           = new EventEmitter<boolean>();

    /**
     * Emits the current aggregate state of the form after each change.
     */
    @Output() onChanges         = new EventEmitter<ModelType>();

    /**
     * Emits a detailed change record for each change.
     */
    @Output() onPropertyChange  = new EventEmitter<AlFormElementChange>();

    /** 
     * Grouping form elements 
     * */
    public normalizedElements: AlFormElementBase<any>[] = [];
    public form: FormGroup;

    /**
     * Custom Form Elements
     */

    @ContentChildren(AlCustomControlDirective)
    public customElements:QueryList<AlCustomControlDirective>;
    public customTemplates:{[property:string]:TemplateRef<any>} = {};
    public keys = '';

    /**
     * Private state data
     */
    private formElements:{[property:string]:AlFormElementBase<any>} = {};        //  map of elements (abstract managers of controls)
    private formControls:{[property:string]:AbstractControl} = {};               //  map of controls (angular components)
    private subscriptions       = new AlSubscriptionGroup();
    private lastValues:any      = {};
    private lastState?:boolean;

    constructor(private formBuilder: FormBuilder,
                public formUtility:AlDynamicFormUtilityService,
                public contentManager:AlExternalContentManagerService ) {
    }

    ngOnInit() {
    }

    ngOnChanges( changes:SimpleChanges ) {
        if ( 'elements' in changes ) {
            this.createForm();
        }
        if ( 'model' in changes && typeof( this.model ) === 'object' && this.model !== null ) {
            this.patchModel( this.model );
        }
        if ( `loadFrom` in changes ) {
            this.loadForm( this.loadFrom );
        }
    }

    ngAfterViewInit() {
        this.customElements.forEach( customElement => {
            this.customTemplates[customElement.controlForProperty] = customElement.template;
        } );
        this.keys = JSON.stringify( Object.keys( this.customTemplates ), null, 4 );
    }

    ngOnDestroy(): void {
        this.subscriptions.cancelAll();
    }

    /**
     * Give the result of the form using a method name that doesn't do what it says or return what it suggests it will
     *
     * @returns Object: key:value with the result of the form
     */
    public onSubmit() {
        return this.getFormValues();
    }

    /**
     * Returns the current value state of the form.  Note that this will exclude values that are empty or values from elements that have been removed from the form;
     * it should be considered the "finished state" of the form.
     */
    public getResult() {
        const formValues:{[property:string]:unknown} = {};
        this.elements.forEach( rawElement => {
            if ( rawElement.property in this.formElements ) {
                const element = this.formElements[rawElement.property];
                const value = element.getAnswer();
                if( element.dataType !== 'void' && (element.type === 'hidden' ||
                    typeof value === 'boolean' ||
                    typeof value === 'number' ||
                    element.dataType === 'object' ||
                    (value && value.length > 0) ||
                    this.allowEmptyValues )) {
                    formValues[element.property] = value;
                }
            }
        });

        return formValues;
    }

    /**
     * Get the current values of the form.
     */
    public getFormValues():ModelType {
        let value = this.getResult();
        return value as unknown as ModelType;
    }

    /**
     * Get the current value of a specific field in the form.
     */
    public getFormValue<Type = any>( fieldPropertyOrIndex:string|number ):Type|undefined {
        let fieldProperty = this.getElementByKeyOrIndex( fieldPropertyOrIndex ).property;
        if ( fieldProperty in this.lastValues ) {
            return this.lastValues[fieldProperty];
        } else {
            return undefined;
        }
    }

    /**
     * Gets the form element (the abstract descriptor/factory) for a given form element.
     */
    public getFormElement<ElementType=AlFormElementBase<any>>( fieldPropertyOrIndex:string|number ):AlFormElementBase<ElementType> {
        return this.getElementByKeyOrIndex( fieldPropertyOrIndex );
    }

    /**
     * Don't we *all* want control?  Sadly, this merely retrieves the form control for a given form property.
     */
    public getControl( fieldPropertyOrIndex:string|number ):AbstractControl|undefined {
        let control = this.getFormElement( fieldPropertyOrIndex );
        if ( control.property in this.formControls ) {
            return this.formControls[control.property];
        }
        return undefined;
    }

    /**
     * Rebuilds the form.  Use this if the content, type, or details of a given control have changed

    /**
     * Replaces a form element with a new (or modified) form element.
     */
    public replaceControl( fieldPropertyOrIndex:string|number,
                           newElement:AlFormElementBase<any>,
                           emitEvent?:boolean ) {
        let fieldProperty = this.getElementByKeyOrIndex( fieldPropertyOrIndex ).property;
        this.form.setControl( fieldProperty, newElement.getFormControl() );
    }

    /**
     * Sets the value of a given control.
     */
    public setControlValue( fieldPropertyOrIndex:string|number, value:any ) {
        const control = this.getFormElement( fieldPropertyOrIndex );
        if ( control && control.property in this.formControls ) {
            this.formControls[control.property].setValue( value );
        }
    }

    public async loadForm( resourceIdOrURL:string ) {
        try {
            if ( this.contentManager.isValidResource( resourceIdOrURL ) ) {
                await this.loadFormFromResource( resourceIdOrURL );
            } else if ( resourceIdOrURL.startsWith("/") || /^http(s?):\/\/.*\.json/m.test( resourceIdOrURL ) ) {
                await this.loadFormFromURL( resourceIdOrURL );
            } else {
                throw new Error( `Cannot load form from '${resourceIdOrURL}': it is neither a URI for a JSON definition nor a valid resource ID.` );
            }
        } catch( e ) {
            AlErrorHandler.log( e, "Couldn't load form!" );
        }
    }

    /**
     * Retrieves the given JSON resource and attempts to use it as the source for a form definition.
     */
    public async loadFormFromResource( resourceId:string ) {
        throw new Error("Not implemented" );
    }

    /**
     * Retrieves the given JSON resource from a relative or fully qualified URI and attempts to use it as the source for a form definition.
     */
    public async loadFormFromURL( url:string ) {
        const content:any = await AlDefaultClient.get( { url } );

        if ( ! ( 'elements' in content ) || ! Array.isArray( content.elements ) ) {
            throw new Error( `Invalid resource: data from '${url}' does not appear to be a valid form descriptor.` );
        }

        this.elements = content.elements as AlDynamicFormControlElement<any>[]
        await this.createForm();
    }

    /**
     * Shows or hides elements based on a tag
     *
     * @returns The number of elements whose visibility state changed because of this call.
     */
    public setVisibilityByTag( tag:string, visible:boolean, elements?:AlFormElementBase[] ):number {
        let changedCount = 0;
        if ( ! elements ) {
            elements = this.normalizedElements;
        }
        elements.forEach( el => {
            if ( el.tags && el.tags.includes( tag ) ) {
                if ( el.visible !== visible ) {
                    el.visible = visible;
                    changedCount++;
                }
            }
            if ( el.columns ) {
                el.columns.forEach( column => {
                    changedCount += this.setVisibilityByTag( tag, visible, column.elements as AlFormElementBase[] );
                } );
            }
            if ( el.elements ) {
                changedCount += this.setVisibilityByTag( tag, visible, el.elements );
            }
        } );
        if ( changedCount && elements === this.normalizedElements ) {
            this.normalizedElements = [ ...this.normalizedElements ];       //  triggers change detection
        }
        return changedCount;
    }

    /**
     * Hides elements based on a tag
     *
     * @returns The number of elements whose visibility state changed because of this call.
     */
    public hideElementsByTag( tag:string, elements?:AlFormElementBase[] ):number {
        return this.setVisibilityByTag( tag, false, elements );
    }

    /**
     * Shows elements based on a tag
     *
     * @returns The number of elements whose visibility state changed because of this call.
     */
    public showElementsByTag( tag:string, elements?:AlFormElementBase[] ):number {
        return this.setVisibilityByTag( tag, true, elements );
    }

    public patchModel( model:ModelType ) {
        if ( this.form ) {
            this.form.patchValue( model );
        }
    }

    public refresh() {
        this.normalizedElements = [ ...this.normalizedElements ];       //  triggers change detection
    }

    private getElementByKeyOrIndex<Type=any>( fieldPropertyOrIndex:string|number ):AlFormElementBase<Type> {
        let property:string;
        if ( typeof( fieldPropertyOrIndex ) === "number" ) {
            if ( fieldPropertyOrIndex < this.elements.length ) {
                property = this.elements[fieldPropertyOrIndex].property;
            } else {
                throw new Error(`Form element lookup error: ${fieldPropertyOrIndex} does not refer to a valid element index` );
            }
        } else {
            property = fieldPropertyOrIndex;
        }
        if ( ! ( property in this.formElements ) ) {
            throw new Error(`Form element lookup error: '${fieldPropertyOrIndex} does not refer to a known element key` );
        }
        return this.formElements[property];
    }

    /**
     * Init and configure the elements and their validation in a FormControl
     *
     * @param elements Element to build
     *
     * @returns FormGroup: Grouping form elements
     */
    private async createForm() {
        this.subscriptions.cancelAll();

        if ( this.elements && Array.isArray( this.elements ) ) {
            this.formElements                               =   {};
            this.lastValues                                 =   {};
            let group:{[property:string]:AbstractControl}   =   {};
            let values:{[property:string]:any}              =   {};

            //  First, perform input validation
            await this.validateElements( this.elements );

            //  Second, coerce all input elements into properly subclassed controls and eliminate any that can't be rendered
            this.normalizedElements = this.elements.map( el => AlFormElementBase.isDescriptor( el )
                                                            ? this.formUtility.generateDynamicElement( el )
                                                            : el )
                                                   .filter( el => el ) as AlFormElementBase<any>[];

            //  Third, generate the angular form controls and calculate initial answers (and emit readiness when done)
            this.formElements = this.mapElements( this.normalizedElements );
            Object.entries( this.formElements ).forEach( ( [ propertyKey, element ] ) => {
                if ( this.model && propertyKey in this.model ) {
                    element.value = this.model[propertyKey];
                }
                group[propertyKey]             = element.getFormControl();
                if ( element.dataType !== 'void' ) {
                    values[propertyKey]            = element.getAnswer();
                }
            } );

            this.formControls = group;
            this.onReady.emit( this.formElements );

            //  Last but not least, create the form group and subscribe to its event emitters
            this.form = this.formBuilder.group(group);
            // emit initial status
            this.onStatusChange(this.form.valid ? 'VALID' : 'INVALID');
            this.subscriptions.manage(
                this.form.valueChanges.subscribe( changes => this.onValueChange( changes ) ),
                this.form.statusChanges.subscribe( status => this.onStatusChange( status ) )
            );

            this.onValueChange( values );           //  emit initial values
        } else {
            this.normalizedElements = [];
            delete this.form;
        }
    }

    private mapElements( elements:AlFormElementBase<any>[] ):{[propertyKey:string]:AlFormElementBase<any>} {
        let results:{[propertyKey:string]:AlFormElementBase} = {};
        if( elements ) {
            elements.forEach( element => {
                if ( element.property !== '#none' ) {
                    results[element.property] = element;
                }
                if ( element.columns ) {
                    element.columns.forEach( column => {
                        results = { ...results, ...this.mapElements( column.elements ) };
                    } );
                }
                if ( element.elements ) {
                    results = { ...results, ...this.mapElements( element.elements ) };
                }
            } );
        }
        return results;
    }

    private async validateElements( elements:(AlFormElementBase|AlDynamicFormControlElement)[] ) {
        let baseElementSchema = `https://alertlogic.com/schematics/ng-forms-components#definitions/baseElementDescriptor`;
        let validator = new AlJsonValidator( this.formUtility );
        for ( let i = 0; i < elements.length; i++ ) {
            let el = elements[i];
            let result = await validator.test( el, baseElementSchema );
            if ( ! result.valid ) {
                let elementRef = 'property' in el ? el.property : `#${i}`;
                if ( this.strict ) {
                    let validationError = new AlDataValidationError( `Form element ${elementRef} is not a valid form element descriptor.`, el, baseElementSchema, [ result.error ] );
                    if ( this.viewHelper ) {
                        console.log("Got a validation error!", validationError );
                        this.viewHelper.setError( validationError );
                    } else {
                        throw validationError;
                    }
                } else {
                    console.warn(`AlDynamicFormComponent warning: element #${elementRef} is not a valid form element descriptor.`, result.error );
                }
            }
        }
    }

    /**
     * Synchronize changes from the form with our own cache of current state values, emit changes to the view parent,
     * and invoke any dynamic option generators or async validation/data loading.
     */
    private onValueChange( changes:any ) {
        let changed:string[] = [];

        //  Step 1: integrate changed fields into known form state
        let formValues = this.getResult();
        Object.entries( formValues ).forEach( ( [ property, newValue ] ) => {
            let previousValue = property in this.lastValues ? this.lastValues[property] : undefined;
            if ( newValue !== previousValue ) {
                let factoryOption = this.getValueFactoryOption( property, newValue );
                if ( factoryOption ) {
                    this.generateValueFromFactory( property, factoryOption );
                } else {
                    this.onPropertyChange.emit( { property, previousValue, value: newValue } );
                    if ( newValue === undefined ) {
                        delete this.lastValues[property];
                    } else {
                        this.lastValues[property] = newValue;
                    }
                    changed.push( property );
                }
            }
        } );

        //  Step 2: remove unreferenced items from known form state
        Object.entries( this.lastValues ).forEach( ( [ property, value ] ) => {
            if ( ! ( property in changes ) ) {
                this.onPropertyChange.emit( { property, previousValue: this.lastValues[property], value: undefined } );
                delete this.lastValues[property];
                changed.push( property );
            }
        } );

        //  Step 3: emit master "onChanges" event.  At this point, `this.lastValues` should be identical to `changes`
        this.onChanges.emit(this.lastValues);

        //  Step 4: invoke any optionFactory methods for elements depending on any of the elements that changed
        let refreshPromises:Promise<void>[] = [];
        Object.entries( this.formElements ).forEach( ( [ property, element ] ) => {
            if ( element.refreshCallback ) {
                element.dependsOn.find( dependentProperty => {
                    if ( changed.includes( dependentProperty ) && element.refreshCallback ) {
                        refreshPromises.push( element.refreshCallback( this.lastValues, element ) );
                        return true;
                    }
                    return false;
                } );
            }
        } );
        if ( refreshPromises.length > 0 ) {
            this.handleRefreshCompletion( refreshPromises );
        }

    }

    private async generateValueFromFactory( property:string, option:AlDynamicFormControlOption ) {
        try {
            if ( option.valueFactory ) {
                let element = this.getFormElement( property );
                let generatedOption = await option.valueFactory( option );
                element.options.push( generatedOption );
                element.value = generatedOption.value;
                element.getFormControl().setValue( generatedOption.value );
            } else {
                throw new Error( `No factory found on provided option '${option.label}'.` );
            }
        } catch( e ) {
            console.log("Failed to generate a new value!", e );
        }
    }

    /**
     * Given a property and a value, determine if the value corresponds to a value factory (a callback used to create new items
     * in a dropdown list).  If such an entry exists, return the corresponding option; otherwise, return null.
     */
    private getValueFactoryOption( property:string, newValue:any ):AlDynamicFormControlOption|undefined {
        let element = this.getFormElement( property );
        if ( element.options ) {
            return element.options.find( option => option.value === newValue && option.valueFactory );
        }
        return undefined;
    }

    private async handleRefreshCompletion( promises:Promise<void>[] ) {
        try {
            await Promise.all( promises );
        } catch( e ) {
            console.warn("Failed to refresh all fields: ", e );
        } finally {
            console.log("Done refreshing!" );
            this.normalizedElements.forEach( el => el.busy = false );
        }
    }

    /**
     * Indicate if the form is valid (if all the elements have been filled in and are valid
     * according to the validations assigned to each element) or if the form has not passed
     * the configured validations.
     *
     * The answers are emitted in each change in any element in the form.
     */
    private onStatusChange( status:string ) {
        let validState = status === 'INVALID' ? false : true;
        if ( validState !== this.lastState ) {
            this.isValid.emit( validState );
            this.lastState = validState;
        }
    }
}
