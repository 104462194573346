<ng-template #missing>
    <i>Missing custom control template</i>
</ng-template>
<ng-container *ngFor="let element of elements">
    <ng-container *ngIf="element.visible" [ngSwitch]="element.controlTemplate">
        <div *ngSwitchCase="'custom'" [ngClass]="element.cssClass ? element.cssClass : 'u-col-12'">
            <ng-container *ngTemplateOutlet="customTemplates[element.property] || missing; context: {form:form}">
            </ng-container>
        </div>
        <div *ngSwitchCase="'columns'" [ngClass]="element.cssClass ? element.cssClass : 'u-col-12'">
            <div class="u-grid u-grid-nogutter">
                <div *ngFor="let column of element.columns" [ngClass]="column.cssClass">
                    <al-dynamic-form-group [form]="form" [elements]="column.elements" [customTemplates]="customTemplates"></al-dynamic-form-group>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'group'" [ngClass]="element.cssClass ? element.cssClass : 'u-col-12'">
            <al-dynamic-form-group [form]="form" [elements]="element.elements" [customTemplates]="customTemplates"></al-dynamic-form-group>
        </div>
        <div *ngSwitchDefault [ngClass]="element.cssClass ? element.cssClass : 'u-col-12'">
            <al-dynamic-form-element [element]="element" [form]="form">
            </al-dynamic-form-element>
        </div>
    </ng-container>
</ng-container>
