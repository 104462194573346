<div
    class="ald-radio"
    [ngClass]="[
        disabled ? 'ald-radio--disabled' : '',
        size === 'sm' ? 'ald-radio--sm' : '',
    ]">
    <input
        type="radio"
        class="ald-radio__input"
        [id]="id"
        [name]="name"
        [value]="value"
        [disabled]="disabled"
        (focus)="hasFocus=true"
        (blur)="hasFocus=false"
        (change)="onValueChange($event)"
        [checked]="checked"/>
    <label
        [for]="id"
        class="ald-radio__label">
        {{ label }}
    </label>
</div>
