<ald-form-element-header
    [id]="id"
    [label]="label"
    [tip]="tip"
    [hint]="hint"
    [required]="required">
</ald-form-element-header>

<textarea
    class="ald-textarea"
    [ngClass]="[
        disabled ? 'ald-textarea--disabled' : '',
        control?.errors && (control?.touched) ? 'ald-textarea--error' : ''
    ]"
    [class.ald-input__element--error]="control?.errors && (control.dirty || control.touched)"
    [id]="id"
    [name]="name"
    [disabled]="disabled"
    [required]="required"
    [autofocus]="autofocus"
    [cols]="cols"
    [rows]="rows"
    [minlength]="minLength"
    [maxlength]="maxLength"
    [placeholder]="placeholder"
    (change)="onValueChange(inputModel)"
    (keyup)="onValueChange(inputModel)"
    [(ngModel)]="inputModel"
>
</textarea>

<ald-form-group-errors
    [control]="control"
    [label]="label"
    [requiredErrorMessage]="requiredErrorMessage">
</ald-form-group-errors>

