/**
 * Allows the construction of a multi select tree-list
 *
 * @author Bryan <bryan.tabarez@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2021
 */

import { FormControl } from '@angular/forms';
import { TreeNode } from 'primeng-lts/api';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementTreeSelectList extends AlFormElementBase<string> {
    public controlTemplate = 'treeSelectList';

    private formControl: FormControl;

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
        this.treeSelectOptions = properties?.treeSelectOptions as TreeNode[] ?? [];
        this.onNodeSelected = properties['onNodeSelected'];
        this.onNodeUnselected = properties['onNodeUnselected'];
        this.columns = properties['columns'] ?? [];

    }

    public createFormControl() {
        this.formControl = new FormControl(this.value || '');

        if(this.disabled) {
            this.formControl.disable();
        }

        return this.formControl;
    }

    getAnswer(): any {
        return this.value;
    }
}
