import {
    AfterViewInit, 
    ChangeDetectorRef, 
    Component, 
    ElementRef, 
    EventEmitter, 
    Input, 
    OnChanges, 
    Output, 
    ViewChild
} from '@angular/core';
import { AldOptionItem, ButtonVariant, IconClass } from '@al/design-patterns/common';
import { NoteVariant } from '../types/ald-notes.types';


/**
 * The note component is useful when information can be provided to a user in the page.
 * For example, some in page guidance and information about a feature. These are often dismissable.
 */
@Component({
    selector: 'ald-note',
    templateUrl: './ald-note.component.html',
    styleUrls: ['./ald-note.component.scss']
})
export class AldNoteComponent implements AfterViewInit, OnChanges {

    /**
     * The content ViewChild checks if there is content projected into the component
     * and sets the hasContent property in afterViewInit.
     */
    @ViewChild('content') content: ElementRef;
    hasContent: boolean = true;

    /** Defines the Note variant, defaults to default (optional). */
    @Input() variant?: NoteVariant = 'default';

    /**
     * Defines the Note\'s icon in the header area.
     * OnChanges will set the icon to the relevant note variant, but can be overriden here (optional).
     */
    @Input() icon?: string;

    /** Icon class for the icon in header. Defaults to the ald-icon default (material-icons). */
    @Input() iconClass?: IconClass;

    /**
     * The header string for the note (required).
     */
    @Input() header: string;

    public noteIcon: string;
    public noteStyles: string;

    /**
     * Passing an action icon will render a ghost ald-button on the top right of the note (optional).
     */
    @Input() actionIcon?: string;

    /** Icon class for the action button icon. Defaults to the ald-icon default (material-icons). */
    @Input() actionIconClass?: IconClass;

    /**
     * Passing an action label will render a default button on the top right of the note (optional).
     */
    @Input() actionLabel?: string = '';

    /**
     * Passing an array of options will render an ald-dropdown with ald-menu (as long as there is an actionIcon or actionLabel) (optional).
     */
    @Input() actionOptions?: AldOptionItem[] = [];

    public actionVariant?: ButtonVariant = 'ghost';

    /**
     * On action button or dropdown item clicked, the EventEmitter will fire.
     * Only with a dropdown selection will it return the selected option and menu index.
     */
    @Output() didAction: EventEmitter<void | {option: AldOptionItem, index: number}> = new EventEmitter<void | {option: AldOptionItem, index: number}>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnChanges(): void {
        this.setVariantStyles();
    }

    ngAfterViewInit(): void {
        this.hasContent = this.content.nativeElement.innerHTML.trim() !== "";
        this.changeDetectorRef.detectChanges();
    }

    setVariantStyles(): void {
        switch (this.variant) {
            case 'info':
                this.noteStyles = 'ald-note--info';
                this.noteIcon = this.icon ? this.icon : 'info';
                break;
            case 'danger':
                this.noteStyles = 'ald-note--danger';
                this.noteIcon = this.icon ? this.icon : 'error';
                break;
            case 'warning':
                this.noteStyles = 'ald-note--warning';
                this.noteIcon = this.icon ? this.icon : 'warning';
                break;
            case 'success':
                this.noteStyles = 'ald-note--success';
                this.noteIcon = this.icon ? this.icon : 'check';
                break;
            case 'default':
            default:
                this.noteStyles = 'ald-note--default';
                this.noteIcon = this.icon ? this.icon : 'info';
                break;
        }

        if (this.actionLabel) {
            this.actionVariant = 'secondary';
        } else {
            this.actionVariant = 'ghost';
        }
    }
}
