import {
    Directive, 
    ElementRef, 
    Input, 
    OnInit, 
    OnChanges, 
    SimpleChanges, 
    AfterViewInit
} from '@angular/core';


@Directive({
    selector: "[alTestId]"
})
export class AlTestIdDirective implements OnChanges
{
    @Input("alTestId") testId:string;

    constructor(private el: ElementRef) {
    }

    ngOnChanges( changes:SimpleChanges  ) {
        if ( this.testId ) {
            this.el.nativeElement.dataset.cy = this.testId.replace( /\s+/g, "_" );
        } else {
            delete this.el.nativeElement.dataset.cy;
        }
    }
}
