import { Component, ViewChild, OnInit } from '@angular/core';
import {
    AlAuthenticationUtility, 
    AlAuthenticationResult, 
    AlCabinet, 
    AlDefaultClient, 
    AlErrorHandler, 
    AlLocation, 
    AlLocatorService, 
    AlSession, 
    AlStopwatch, 
    AlConduitClient
} from '@al/core';
import { AlViewHelperComponent } from '@al/ng-generic-components';
import { AlNavigationService, AlSessionManagerService } from '@al/ng-navigation-components';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mfa-verification',
  templateUrl: './mfa-verification.component.html',
})

export class MFAVerificationComponent implements OnInit {

    public authenticationCode = '';
    public loading = true;
    public conduit:AlConduitClient = new AlConduitClient();
    public returnURL:string;
    public auth0State:string;

    protected failureCount = 0;
    protected maximumFailureCount = 3;
    protected ttl:number = 60 * 5;
    protected abortTimer:AlStopwatch;
    protected authenticator = new AlAuthenticationUtility();
    protected navStorage = AlCabinet.persistent("alnav");

    @ViewChild(AlViewHelperComponent) viewHelper:AlViewHelperComponent;

    constructor( public navigation:AlNavigationService,
                 public detector:AlSessionManagerService ) {
    }

    ngOnInit() {
        this.initialize();
    }

    async initialize() {
        try {
            if ( this.navigation.queryParam("ttl") ) {
                this.ttl = parseInt( this.navigation.queryParam("ttl"), 10 );
            }

            this.returnURL = this.navigation.queryParam( "return", '/#/' );
            this.auth0State = this.navigation.queryParam( 'state', null );     //  Auth0 rule pipeline state token, if federated

            if ( ! this.auth0State ) {
                const sessionFound = await this.detector.detectSession();
                if ( sessionFound ) {
                    this.navigation.navigate.byURL( this.returnURL );
                    return;
                }
            }

            if ( this.ttl > 0 ) {
                //  pad the TTL to 90% of its duration, on the assumption we should fail/redirect BEFORE the token goes away
                this.abortTimer = AlStopwatch.once( () => this.abortVerification, ( this.ttl * 0.9 ) * 1000 );
            }
        } catch( error ) {
            AlErrorHandler.log( error, "Could not initialize MFA verification view" );
        } finally {
            this.loading = false;
        }
    }

    onSubmitAuthenticateCode = async () => {
        this.loading = true;

        this.authenticator.state.sessionToken = this.navigation.queryParam("token");

        try {
            const result = await this.authenticator.validateMfaCode( this.authenticationCode );
            switch( result ) {
                case AlAuthenticationResult.Authenticated :
                    await AlSession.ready();
                    return this.completeVerification( this.returnURL, this.auth0State );

                case AlAuthenticationResult.TOSAcceptanceRequired :
                    this.redirectAfterTOSRequiredMFA("accept");
                    break;
                case AlAuthenticationResult.TOSReacceptanceRequired:
                    this.redirectAfterTOSRequiredMFA("reaccept");
                    break;
                case AlAuthenticationResult.AccountLocked :
                    this.viewHelper.notifyError( "The account you are attempting to log into is inactive.", 2500 );
                    break;
                case AlAuthenticationResult.AccountUnavailable :
                    this.viewHelper.notifyError("The account you are attempting to log into is not available.");
                    break;
                case AlAuthenticationResult.InvalidCredentials :
                default :
                    this.viewHelper.notifyError( "The code you provided was invalid.", 2500);
                    break;

            }
        } catch ( e ) {
            this.viewHelper.notifyError( AlErrorHandler.normalize( e ).message, 2500 );
        } finally {
            this.loading = false;
        }

        this.failureCount++;
        if ( this.failureCount >= this.maximumFailureCount ) {
            this.abortVerification();
        } else {
            this.viewHelper.notifyError("The code you entered did not work. Enter the authentication code and try again.", 2500 );
        }
    }

    completeVerification = async ( returnURL:string, auth0State:any ) => {
        this.viewHelper.notifySuccess("Welcome back!  Please wait a moment while we sign you in.");
        this.abortTimer.cancel();

        await AlStopwatch.promise( 500 );      //  This allows session state to stabilize, preventing a route guard from bouncing us back to this view

        if ( auth0State !== null ) {
            //  Federated/SSO login: we *must* return control to auth0 to complete the rule pipeline execution, or auth0 will force us to log out :)
            this.navigation.navigate.byLocation( AlLocation.Auth0, `/continue?state=${encodeURIComponent( auth0State )}&auth_token=${encodeURIComponent( AlSession.getToken() )}` );
        } else {
            if ( this.detector.useFortraEntry() ) {
                this.navigation.navigate.byURL( `/#/fidp`, { return: this.returnURL, mode: 'splash' } );
            } else if ( returnURL ) {
                //  Explicit return URL: bounce back to wherever the user initially tried to access, being sure not to overwrite query string parameters.
                this.navigation.navigate.byURL( returnURL, {}, { disableQSRewrite: true } );
            } else {
                this.navigation.navigate.byNgRoute( [ '/' ] );
            }
        }
    }

    handleVerificationError = ( error:any ):boolean => {
        if ( AlDefaultClient.isResponse( error ) ) {
            this.loading = false;
            if ( error.status === 401 ) {
                this.viewHelper.notifyError("The code you entered did not work. Enter the authentication code and try again.", 2500 );
                return true;
            } else if ( error.status === 400 ) {
                this.viewHelper.notifyError("An internal error prevented the code from being authenticated properly.  If this problem persists, please contact support.", 2500 );
                return true;
            }
        }
        AlErrorHandler.log( error, `Unable to perform MFA validation` );
        return false;
    }

    abortVerification = ( message?:string ) => {
        this.abortTimer.cancel();
        if ( ! message ) {
            message = "Your login session expired. You will be redirected to the login page. Log in and try again.";
        }
        this.viewHelper.notifyError(message, 2500 );
        AlStopwatch.once( () => {
            this.navigation.navigate.byLocation( '/#/logout' );
        }, 2500 );
    }

    redirectAfterTOSRequiredMFA(context:string) {
        const baseRedirect = {
            return: this.returnURL,
            token: this.authenticator.getSessionToken(),
            url: this.authenticator.getTermsOfServiceURL(),
            ...(context === 'reaccept' && { deferral:this.authenticator.getDeferralTOSPeriodEnd() }),
            tos_context:context
        };
        if (this.auth0State) {
            baseRedirect['state'] = this.auth0State
        }
        this.navigation.navigate.byLocation( AlLocation.MagmaUI, '/#/terms-of-service', baseRedirect);
    }
}
