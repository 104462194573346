<div class="u-pb-1">

    <div
        *ngIf="label"
        [ngClass]="[
            tip || required ? 'u-flex u-items-center' : ''
        ]">
        <ald-label
            *ngIf="label"
            [label]="label"
            [for]="for || id || label"
            [id]="this.id ? this.id : this.label"
            [name]="name"
        ></ald-label>
        <div class="u-ml-1" *ngIf="tip">
            <ald-icon
                [aldTooltip]="tip" tooltipPosition="below" [tooltipShowDelay]="500"
                iconClass="material-icons-outlined"
                size="sm"
                icon="info"
                color="u-text-icon-secondary"
            ></ald-icon>
        </div>
        <span *ngIf="required" class="ald-form-element-header__mark">(Required)</span>
    </div>

    <ald-hint *ngIf="label && hint" [hint]="hint"></ald-hint>

</div>