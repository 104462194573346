/**
 * It allows the construction of an input type element supporting the input, hidden and password types
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { Validators, FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementInput extends AlFormElementBase<string> {

    public controlTemplate = "inputText";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
        if (this.controlType === 'hidden') {
            this.htmlInputType = "hidden";
        } else if (this.controlType === 'password') {
            this.htmlInputType = 'password';
        } else if (this.dataType === 'number' || this.dataType === 'integer') {
            this.htmlInputType = "number";
        } else {
            this.htmlInputType = "text";
        }
    }

    public createFormControl() {
        const validators = this.buildValidators();
        const value = this.transformValueToString(this.value) || '';
        let formControl = validators.length > 0
                            ? new FormControl(value, Validators.compose(validators))
                            : new FormControl(value);

        if(this.disabled) {
            formControl.disable();
        }

        return formControl;
    }

    getAnswer(): any {
        return this.transformStringToDataType(this.getControlValue() || '' );
    }
}
