<button
    [type]="type"
    [ngClass]="[

        variant === 'unstyled' ? 'c-reset-button': 'ald-button',

        (icon && label) ? 'u-flex u-justify-center u-items-center u-gap-1' : '',
        (icon && label && iconPosition === 'right') ? 'u-flex-row-reverse' : '',

        fullwidth ? 'u-w-100%' : '',

        (label && size === 'xs') ? 'ald-button--xs': '',
        (label && size === 'sm') ? 'ald-button--sm': '',
        (label && size === 'md') ? 'ald-button--md': '',
        (label && size === 'lg') ? 'ald-button--lg': '',

        (!label && icon && size === 'xs') ? 'ald-button-icon--xs' : '',
        (!label && icon && size === 'sm') ? 'ald-button-icon--sm' : '',
        (!label && icon && size === 'md') ? 'ald-button-icon--md' : '',
        (!label && icon && size === 'lg') ? 'ald-button-icon--lg' : '',

        variant === 'stateful' && !selected ? 'ald-button--stateful' : '',
        variant === 'stateful' && selected ? 'ald-button--stateful-clicked' : '',

        variant === 'primary' ? 'ald-button--primary' : '',
        variant === 'secondary' ? 'ald-button--secondary' : '',
        variant === 'ghost' ? 'ald-button--ghost' : '',
        variant === 'light' ? 'ald-button--light' : '',
        variant === 'light-ghost' ? 'ald-button--light-ghost' : '',
        variant === 'success' ? 'ald-button--success' : '',
        variant === 'danger' ? 'ald-button--danger' : '',
        variant === 'link' ? 'ald-button--link' : '',

        disabled ? 'u-opacity-50 u-cursor-not-allowed' : 'u-cursor-pointer',
    ]"
    [ngStyle]="{'border-radius': enableCustomBorder ? setCustomInputBorder() : null }"
    [disabled]="disabled">

    <div *ngIf="icon && !loading" class="u-flex u-justify-center u-items-center u-pointer-events-none">
        <ald-icon [icon]="icon" [iconClass]="iconClass" [size]="iconSize" color="ald-icon--child-of-button"></ald-icon>
    </div>

    <ng-container *ngIf="loading">
        <ald-icon icon="rotate_right" class="animate-spin" [size]="iconSize"></ald-icon>
        <!-- TODO: Replace above with spinner - awaiting updates to scall the button for button sizes -->
        <!-- <al-loading-spinner></al-loading-spinner> -->
    </ng-container>

	<div class="u-pointer-events-none" [ngClass]="[
        (label && icon && iconPosition === 'left') ? 'u-pr-1': '',
        (label && icon && iconPosition === 'right') ? 'u-pl-1': '',
    ]">
        {{ label }}
    </div>
</button>
