import {
    Component, 
    Input, 
    OnChanges, 
    OnInit, 
    SimpleChanges
} from '@angular/core';
import { IconClass, IconSize } from '../types/al-common.types';


@Component({
    selector: 'ald-icon',
    templateUrl: './ald-icon.component.html',
    styleUrls: ['./ald-icon.component.scss'],
})
export class AldIconComponent implements OnInit, OnChanges {

    @Input() icon = 'whatshot';
    @Input() iconClass?: IconClass = 'material-icons';
    @Input() size?: IconSize = 'md';
    @Input() color?: string;
    @Input() opacity?: number;
    @Input() inline: boolean = false;

    svgIcon = '';
    pathAl = '/assets/svg-icons/al/symbols/svg-defs.svg';
    pathMdFilled = '/assets/svg-icons/md-filled/symbols/svg-defs.svg';
    pathMdOutlined = '/assets/svg-icons/md-outlined/symbols/svg-defs.svg';

    ngOnInit() {
        this.setIcon();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['icon']) {
            this.setIcon();
        }
    }

    setIcon() {
        if (!this.iconClass) {
            this.iconClass = 'material-icons';
        }

        if (this.iconClass == 'material-icons') {
            this.svgIcon = `${this.pathMdFilled}#${this.icon}`;
        }
        else if (this.iconClass == 'material-icons-outlined') {
            this.svgIcon = `${this.pathMdOutlined}#${this.icon}`;
        }
        else if (this.iconClass == 'al') {
            this.svgIcon = `${this.pathAl}#${this.icon}`;
        }
    }
}
