<p-dialog [visible]="visible"
          [modal]="true"
          header="Are you sure you want to scan this asset at the next available time?"
          [dismissableMask]="false"
          [style]="{width: '50vw'}"
          [closable]="false"
          [resizable]="false"
          [draggable]="false">
    <p *ngIf="fullyExcluded">This host is excluded from internal scanning in the deployment scope of protection.</p>
    <strong *ngIf="!fullyExcluded">Some ports are excluded from internal scanning in the deployment scope of protection
        for this host:</strong>
    <div class="list-of-ports" *ngIf="!fullyExcluded">
        <ng-container *ngFor="let exclusions of exclusionsList">
            <ul *ngFor="let exclusion of exclusions">
                <li *ngIf="exclusion.proto === '*'">* ({{exclusion.ports.join()}})</li>
                <li *ngIf="exclusion.proto === 'icmp'">ICMP ({{exclusion.ports.join()}})</li>
                <li *ngIf="exclusion.proto === 'tcp'">TCP ({{exclusion.ports.join()}})</li>
                <li *ngIf="exclusion.proto === 'udp'">UDP ({{exclusion.ports.join()}})</li>
            </ul>

        </ng-container>
    </div>
    <div class="dialog-block"
         *ngIf="!fullyExcluded">
        <div class="u-col-12">Please select one of following options:</div>
        <div class="u-grid exclusion-options">
            <div class="u-col-12">
                <p-radioButton name="excluded-options-group"
                               [value]="false"
                               label="Honor Exclusions"
                               [(ngModel)]="forceScan"
                               inputId="excluded-force-opt1">
                </p-radioButton>
            </div>
            <div class="u-col-12">
                <p-radioButton name="excluded-options-group"
                               [value]="true"
                               label="Override Exclusions"
                               [(ngModel)]="forceScan"
                               inputId="excluded-force-opt2">
                </p-radioButton>
            </div>
        </div>
    </div>
    <p-footer class="footer">
        <button type="button"
                pButton
                (click)="cancel()"
                label="Cancel"
                class="flat">
        </button>
        <button type="button"
                label="Scan"
                pButton
                (click)="submit()"
                class="primaryAction">
        </button>
    </p-footer>
</p-dialog>
