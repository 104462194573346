import {
    ChangeDetectionStrategy, 
    Component, 
    EventEmitter, 
    Input, 
    OnChanges, 
    Output, 
    SimpleChanges
} from '@angular/core';
import { AldOptionItem, IconClass } from '@al/design-patterns/common';
import { BadgeVariant } from '../types/ald-badge.types';


/**
 * Badges add metadata or indicate status of items.
 */
@Component({
    selector: 'ald-badge',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './ald-badge.component.html',
    styleUrls: ['./ald-badge.component.scss'],
})
export class AldBadgeComponent implements OnChanges {

    /** Text to show inside the badge (optional, but should have a label or an icon).*/
    @Input() label?: string | number;

    /** Displays the badge title when hovered over.*/
    @Input() title?: string = '';

    /**
     * The icon to show inside the badge (optional, but should have a label or an icon).
     * If used with a label, the icon is positioned left of the label.
     */
    @Input() icon?: string;

    /** Icon class for the icon. Defaults to the ald-icon default (material-icons). */
    @Input() iconClass?: IconClass;

    /** The Badge style variant, defaults to "default". */
    @Input() variant?: BadgeVariant = 'default';

    /** The Badge size, defaults to "md" (optional). */
    @Input() size?: 'sm' | 'lg' = 'sm';

    /** Low Contrast reduces the tone of the badge, where less emphasis is required, defaults to false. */
    @Input() lowContrast?: boolean = false;

    /** Adds the full rounded style to the badge, defaults to false, but with minor rounded corners. */
    @Input() rounded?: boolean = false;

    badgeStyles: string;
    sizeStyles: string;
    roundedStyles: string;
    iconStyles: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['variant'] || changes['lowContrast']) {
            this.badgeStyles = this.setBadgeStyles();
        }
        if (changes['size'] || changes['icon'] || changes['label'] || changes['rounded']) {
            this.sizeStyles = this.setSizeStyles();
            this.iconStyles = this.setIconStyles();
            this.roundedStyles = this.setRoundedStyles();
        }
    }

    private setBadgeStyles(): string {

        let badgeStyle = '';

        switch (this.variant) {
            case 'info':
                badgeStyle = this.lowContrast ? 'ald-badge--info-low' : 'ald-badge--info';
                break;
            case 'success':
                badgeStyle = this.lowContrast ? 'ald-badge--success-low' : 'ald-badge--success';
                break;
            case 'warning':
                badgeStyle = this.lowContrast ? 'ald-badge--warning-low' : 'ald-badge--warning';
                break;
            case 'danger':
                badgeStyle = this.lowContrast ? 'ald-badge--danger-low' : 'ald-badge--danger';
                break;
            case 'essentials':
                badgeStyle = 'ald-badge--essentials';
                break;
            case 'professional':
                badgeStyle = 'ald-badge--professional';
                break;
            case 'enterprise':
                badgeStyle = 'ald-badge--enterprise';
                break;
            case 'aws':
                badgeStyle = 'ald-badge--aws';
                break;
            case 'azure':
                badgeStyle = 'ald-badge--azure';
                break;
            case 'datacenter':
                badgeStyle = 'ald-badge--datacenter';
                break;
            case 'tmpro':
            case 'lmpro':
                badgeStyle = 'ald-badge--tmpro';
                break;
            case 'default':
            default:
                badgeStyle = this.lowContrast ? 'ald-badge--default-low' : 'ald-badge--default';
            }
            return badgeStyle;
    }

    private setSizeStyles(): string {
        switch (this.size) {
            case 'sm':
                return (this.icon && !this.label) ? 'ald-badge-icon--sm' : 'ald-badge--sm';
            case 'lg':
                return (this.icon && !this.label) ? 'ald-badge-icon--lg' : 'ald-badge--lg';
            default:
                return (this.icon && !this.label) ? 'ald-badge-icon--lg' : 'ald-badge--lg';
        }
    }

    private setRoundedStyles(): string {
        return this.rounded ? 'u-rounded-full' : 'u-rounded-sm';
    }

    private setIconStyles(): string {
        return this.icon ? 'ald-badge--with-icon' : '';
    }

}
