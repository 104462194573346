/**
 * Allows the construction of textarea type element.
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { Validators, FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementTextarea extends AlFormElementBase<string> {

    public controlTemplate = "textarea";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
    }

    public createFormControl() {
        const validators = this.buildValidators();
        const value = this.transformValueToString(this.value);

        let formControl = validators.length > 0
                            ? new FormControl(value || '', Validators.compose(validators))
                            : new FormControl(value || '');
        if(this.disabled) {
            formControl.disable();
        }
        return formControl;
    }

    getAnswer() {
        return this.transformStringToDataType(this.getControlValue() || "");
    }
}
