import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'aldLineBreak'
})
export class AldLineBreakPipe implements PipeTransform {

  transform(text: string): string {
    if (!text || !text.length) { return text; }
    
    return text.replace(/(\\r\\n)|([\r\n])/gmi, '<br/>');
  }

}
