<div class="ald-table__pagination">
    <ng-container>
        <ald-loading-spinner *ngIf="isLoading; else footer" size="md"></ald-loading-spinner>
    </ng-container>
    <ng-template #footer>
        <div class="ald-table__results">
            <div class="u-text-secondary" data-cy="pagination-total-results">
                {{ totalResults }} Results
                <span *ngIf="summary" class="summary">{{ summary }}</span>
            </div>
        </div>
        <div class="u-flex u-items-center u-gap-1">
            <ald-dropdown
                *ngIf="showResultsPerPageSelector"
                #resultsPerPage
                [disabled]="totalResults <= paginationOptions[0].value"
                variant="ghost"
                size="sm"
                [label]="rowsPerPage + ' per page'"
                data-cy="pagination-rows-per-page">
                <ald-menu
                    [options]="paginationOptions"
                    (didSelect)="selectNumberOfResultsPerPage($event)"
                >
                </ald-menu>
            </ald-dropdown>
            <div class="u-flex u-items-center u-gap-1">
                <ald-button
                    variant="secondary"
                    size="sm"
                    icon="first_page"
                    (click)="goToFirstPage()"
                    [disabled]="totalResults <= rowsPerPage || currentPage === 1"
                    data-cy="pagination-first-page"
                ></ald-button>
                <ald-button
                    variant="secondary"
                    size="sm"
                    icon="navigate_before"
                    (click)="goToPreviousPage()"
                    [disabled]="totalResults <= rowsPerPage || currentPage === 1"
                    data-cy="pagination-previous-page"
                ></ald-button>
                <ald-input *ngIf="paginationType === 'offset'"
                           [disabled]="totalResults <= rowsPerPage"
                           type="number"
                           [minValue]="1"
                           [maxValue]="totalPages"
                           size="sm"
                           class="u-w-20"
                           [(ngModel)]="currentPage"
                           (ngModelChange)="currentPageInputChange($event)"
                           data-cy="pagination-current-page"></ald-input>
                <div class="u-px-2" *ngIf="paginationType === 'offset'"
                    [ngClass]="{'u-text-secondary': totalResults <= rowsPerPage}">
                    of {{ totalPages }}
                </div>
                <ald-button
                    variant="secondary"
                    size="sm"
                    icon="navigate_next"
                    (click)="goToNextPage()"
                    [disabled]="totalResults <= rowsPerPage || currentPage === totalPages"
                    data-cy="pagination-next-page"
                ></ald-button>
                <ald-button *ngIf="paginationType === 'offset'"
                    variant="secondary"
                    size="sm"
                    icon="last_page"
                    (click)="goToLastPage()"
                    [disabled]="totalResults <= rowsPerPage || currentPage === totalPages"
                    data-cy="pagination-last-page"
                ></ald-button>
            </div>
        </div>
    </ng-template>
</div>
