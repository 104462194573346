import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';


@Component({
    selector: 'al-collapsible-layout',
    templateUrl: './al-collapsible-layout.component.html',
    styleUrls: ['./al-collapsible-layout.component.scss']
})
export class AlCollapsibleLayoutComponent implements AfterViewInit {

  public leftTooltip: string = '';
  public rightTooltip: string = '';
  public showLeft: boolean = true;
  public showRight: boolean = true;

  @Input() leftPanel: boolean = true;
  @Input() rightPanel: boolean = true;
  @Input() panelWidth: string = '280px';
  @Input() leftTooltipLabel: string = '';
  @Input() rightTooltipLabel: string = '';

  @ViewChild('sidenavPanelLeft') sidenavPanelLeft;
  @ViewChild('sidenavPanelRight') sidenavPanelRight;
  @ViewChild('mainContent') mainContent;

  ngAfterViewInit(): void {
    if (this.showLeft && this.leftPanel) {
      this.togglePanel('left', true);
    }
    if (this.showRight && this.rightPanel) {
      this.togglePanel('right', true);
    }
    this.updateTooltipLabel();
  }

  toggle(side: string) {
    let open: boolean;
    if (side === 'left') {
      this.showLeft = !this.showLeft;
      open = this.showLeft;
    } else {
      this.showRight = !this.showRight;
      open = this.showRight;
    }
    this.togglePanel(side, open);
    this.updateTooltipLabel();
  }

  private togglePanel(side: string, open: boolean) {
    const panel = side === 'left' ? this.sidenavPanelLeft : this.sidenavPanelRight;
    panel.nativeElement.style.width = open ? this.panelWidth : '0';
    this.mainContent.nativeElement.style.width = open ? `calc(100% - ${this.panelWidth}` : '100%';
    this.mainContent.nativeElement.style.margin = `0px ${this.rightPanel ? '10' : '0'}px 0px ${this.leftPanel ? '10' : '0'}px`;
    this.mainContent.nativeElement.style.transition = side === 'right' ? 'margin-right 0.5s' : 'margin-left 0.5s';
  }

  private updateTooltipLabel() {
    this.leftTooltip = this.showLeft ? `Hide ${this.leftTooltipLabel}` : `Show ${this.leftTooltipLabel}`;
    this.rightTooltip = this.showRight ? `Hide ${this.rightTooltipLabel}` : `Show ${this.rightTooltipLabel}`;
  }
}
