/**
 * The AlNavigationFrameComponent is meant to provide an abstract, top level frame for a specific navigation implementation to be inserted into.
 *
 * In addition, the <al-navigation-frame> directive allows applications to assign an initial schema and experience for the navigation layer.
 * This can also be assigned programmatically via AlNavigationService.
 */

import {
    ChangeDetectorRef, 
    Component, 
    EventEmitter, 
    Input, 
    OnInit, 
    OnDestroy, 
    TemplateRef, 
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    AIMSClient, 
    AlRoute, 
    AlRuntimeConfiguration, 
    ConfigOption, 
    AlSession, 
    AlSubscriptionGroup, 
    AlSessionEndedEvent, 
    AlSessionStartedEvent
} from '@al/core';
import { AlTemplateIndexService } from '@al/ng-generic-components';
import { AlExperiencePreferencesService } from '../services/al-experience-preferences.service';
import { AlNavigationService } from '../services/al-navigation.service';
import {
    AlAddendumToNavTitleEvent, 
    ALNAV_PRIVATE, 
    ALNAV_PUBLIC, 
    AlNavigationContextChanged, 
    AlNavigationFrameChanged, 
    AlNavigationApplicationError, 
    AlNavigationRouteDispatched, 
    AlNavigationTrigger
} from '../types/navigation.types';


@Component({
    selector: 'al-navigation-frame',
    templateUrl: './al-navigation-frame.component.html',
    styleUrls: ['./al-navigation-frame.component.scss']
})
export class AlNavigationFrameComponent implements OnInit, OnDestroy
{
    /**
     * Instance properties
     */
    @Input() public experience:string = null;                       //  this is only used to set the *initial* state.
    @Input() public schema:string = null;                           //  this is only used to set the *initial* state.
    @Input() public allowUnauthenticatedMenus = false;      //  allows an application to show menus even when there is no session
    public accessAllowed?:boolean = undefined;
    public displayNav = false;
    public headingText: string = null;
    public showContentMenu = false;

    public primaryMenu:AlRoute;
    public userMenu:AlRoute;
    public contentMenu:AlRoute;
    public headerActionsMenus:AlRoute[] = [];
    public sidenavMenu:AlRoute;
    public sidenavContentRef:TemplateRef<any>;
    public sidenavContentBelowRef:TemplateRef<any>;
    public showTertiaryMenu = false;
    public breadcrumbs:AlRoute[] = [];
    public userLoggedIn     =   AlSession.isActive();
    public appLayoutClasses:string[] = [ 'default' ];
    public applicationError:AlNavigationApplicationError;

    addendumToNavTitle      =   "";
    disableNavigationHeader =   false;
    disablePrimaryMenu      =   false;
    disableContentMenu      =   false;
    disableTertiaryMenu     =   false;
    pageClass               =   "default";

    routePaddedContent      =   false;

    protected subscriptions = new AlSubscriptionGroup();

    constructor( public alNavigation:AlNavigationService,
                 public templateIndex:AlTemplateIndexService,
                 public activatedRoute:ActivatedRoute,
                 public changeDetector:ChangeDetectorRef,
                 protected alExperiencePreferences: AlExperiencePreferencesService
                 ) {
    }

    ngOnInit() {
        this.subscriptions.manage(
            this.alNavigation.events.attach( AlNavigationFrameChanged, this.onNavigationChanged ),
            this.alNavigation.events.attach( AlNavigationContextChanged, this.onNavigationContextChanged ),
            this.alNavigation.events.attach( AlAddendumToNavTitleEvent, this.setAddendumToNavTitle ),
            AlSession.notifyStream.attach( AlSessionStartedEvent, () => this.onSessionStarted()),
            AlSession.notifyStream.attach( AlSessionEndedEvent, () => this.onSessionEnded()),
            this.alNavigation.events.attach<AlNavigationApplicationError>( AlNavigationApplicationError, ( error ) => this.onApplicationError( error ) ),
            this.alNavigation.events.attach<AlNavigationRouteDispatched>( AlNavigationRouteDispatched, () => this.clearApplicationError( false ) ),
            this.templateIndex.listen( "navigation", this.onNavigationTemplatesChanged ),
            this.alNavigation.layoutOptionsChanged$.subscribe( options => this.onLayoutOptionsChanged( options ) )
        );
        this.activatedRoute.queryParams.subscribe((params) => this.onQueryParamsChanged(params) );
        if ( this.schema ) {
            this.alNavigation.setSchema( this.schema );
            this.alNavigation.setForceSchema( true );
        } else {
            this.alNavigation.setForceSchema( false );
            this.alNavigation.setSchema( null );
        }
        if ( this.experience ) {
            this.alNavigation.setExperience( this.experience );
            this.alNavigation.setForceExperience( true );
        } else {
            this.alNavigation.setForceExperience( false );
            this.alNavigation.setExperience( null );
        }
    }

    ngOnDestroy() {
        this.subscriptions.cancelAll();
    }

    onNavigationTemplatesChanged = ( templates:{[templateId:string]:TemplateRef<any>} ) => {
        this.sidenavContentRef = 'sidenav' in templates ? templates.sidenav : null;
        this.sidenavContentBelowRef = 'sidenavBelow' in templates ? templates.sidenavBelow : null;
        this.evaluateMenuState();
    }

    // onFiltersTemplatesChanged = ( templates:{[templateId:string]:TemplateRef<any>} ) => {
    //     this.sidenavContentBelowRef = 'sidenavBelow' in templates ? templates.sidenavBelow : null;
    //     this.evaluateMenuState();
    // }

    onQueryParamsChanged = async ( params: {[key:string]: string} ) => {
        if ( AlSession.isActive() && 'aaid' in params && params.aaid !== AlSession.getActingAccountId() ) {
            try {
                const accountInfo = await AIMSClient.getAccountDetails( params.aaid );
                await this.alNavigation.setActingAccount( accountInfo.id );
            } catch( e ) {
                console.warn("Warning: failed to change acting account to reflect aaid failed to change acting account", e );
            }
        }
    }

    onNavigationChanged = ( event:AlNavigationFrameChanged ) => {
        if ( ! event.schema ) {
            console.warn("Cannot assign menus for the current experience in the absence of a navigation scheme!  Ignoring." );
            return;
        }
        this.experience = event.experience;
        this.schema = event.schemaId;
        try {
            this.primaryMenu = this.alNavigation.getLoadedMenu( event.schemaId, 'primary' );
            this.userMenu = this.alNavigation.getLoadedMenu( event.schemaId, 'user' );
        } catch( e ) {
            console.warn("Warning: failed to assign primary and user menus because schema does not have them", e );
        }
        this.changeDetector.detectChanges();
        this.evaluateMenuState();
    }

    onNavigationContextChanged = ( event:AlNavigationContextChanged ) => {
        this.evaluateMenuState();
        let routeDirectives:string[] = [];
        if ( "alNavigation" in event.routeData ) {
            routeDirectives = event.routeData.alNavigation as string[];
        } else if ( "navigation" in event.routeData ) {
            routeDirectives = event.routeData.navigation as string[];
        }

        /* tslint:disable:no-boolean-literal-compare */
        const defaultAuthState = AlRuntimeConfiguration.getOption<boolean|null>( ConfigOption.NavigationDefaultAuthState );
        if ( defaultAuthState === true ) {
            //  This application requires authentication by default -- only allow routes explicitly tagged as public to be displayed without authentication.
            this.accessAllowed = AlSession.isActive() || routeDirectives.includes( ALNAV_PUBLIC );
        } else if ( defaultAuthState === false ) {
            //  This application does NOT require authentication by default -- only enforce authentication for routes explicitly tagged as private
            this.accessAllowed = ( routeDirectives.includes( ALNAV_PRIVATE ) && ! AlSession.isActive() ) ? false : true;
        } else {
            //  This application doesn't care about authentication or handles it separately, using a route guard
            this.accessAllowed = true;
        }
        this.changeDetector.detectChanges();
    }

    setAddendumToNavTitle = ( event:AlAddendumToNavTitleEvent ) => {
        this.addendumToNavTitle = event.addendumToTitle;
    }

    onLayoutOptionsChanged = ( options:{[option:string]:string|boolean} ) => {
        this.disableNavigationHeader = 'disableHeader' in options ? !!options.disableHeader : false;
        this.disablePrimaryMenu = 'disablePrimaryMenu' in options ? !!options.disablePrimaryMenu : false;
        this.disableTertiaryMenu = 'disableTertiaryMenu' in options ? !!options.disableTertiaryMenu : false;
        this.disableContentMenu = 'disableContentMenu' in options ? !!options.disableContentMenu : false;

        this.appLayoutClasses = [];

        if ( options.pageClass ) {
            this.appLayoutClasses.push( options.pageClass.toString() );
        } else {
            this.appLayoutClasses.push( "default" );
        }

        if (!this.disableNavigationHeader) {
            this.appLayoutClasses.push('app-layout--has-min-width');
        }
    }

    onSessionStarted() {
        this.userLoggedIn = true;
    }

    onSessionEnded() {
        this.userLoggedIn = false;
        this.experience = null;
    }

    onApplicationError( error:AlNavigationApplicationError ) {
        this.applicationError = error;
    }

    clearApplicationError( redirectToErrorRoute = true ) {
        if ( redirectToErrorRoute ) {
            const targetRoute = this.applicationError?.returnRoute ?? [ '/' ];
            this.alNavigation.navigate.byNgRoute( targetRoute );
        }
        this.applicationError = null;
    }

    evaluateMenuState() {
        this.breadcrumbs        = [];
        this.headerActionsMenus = [];
        this.contentMenu        = undefined;

        const activatedRoutes   = this.alNavigation.activatedRoutes;
        let contentMenu:AlRoute = undefined;
        let sidenavMenu:AlRoute = undefined;
        let hideTabs = false;

        activatedRoutes.forEach( ( route:AlRoute ) => {
            const outlet = route.getProperty("childOutlet", "none" );
            if ( outlet === "content-menu" ) {
                contentMenu = route;
            } else if ( outlet === "sidenav" ) {
                sidenavMenu = route;
            }
            hideTabs = hideTabs || route.getProperty("noTabs", false);
        } );

        if ( ! contentMenu && ! sidenavMenu ) {
            // it should only get here to display regular tertiary menu
            if(activatedRoutes.length > 3 && activatedRoutes[2].getProperty("childOutlet", null) !== "none") {
                sidenavMenu = activatedRoutes[2];
            }
        }

        if ( contentMenu ) {
            this.contentMenu          = new AlRoute(contentMenu.host, contentMenu.definition, contentMenu.parent);
            this.contentMenu.children = [];
            this.contentMenu.children = contentMenu.children.filter( c => !c.getProperty("isHeaderAction") );
            this.headerActionsMenus   = contentMenu.children.filter( c => c.getProperty("isHeaderAction") );
        }
        if ( this.sidenavMenu !== sidenavMenu ) {
            this.sidenavMenu = sidenavMenu;
        }

        //  Store a reference to the activation path, with duplicate items/breadcrumb-suppressed items removed
        this.breadcrumbs = activatedRoutes.filter( ( item, index ) => {
            if ( index > 0 && item.caption !== activatedRoutes[index-1].caption ) {
                return item.getProperty("breadcrumb", true ) === true;
            }
            return false;
        } );

        //  Determine if tertiary (sidebar) menu should be visible
        if ( this.sidenavContentRef || this.sidenavContentBelowRef ) {
            this.showTertiaryMenu = true;
        } else if ( this.sidenavMenu ) {
            //  If a menu is present, only show the sidenav if it has at least one visible child and isn't assigned to the content tab
            this.showTertiaryMenu = ! this.sidenavMenu.getProperty( `childOutletOnly`)
                                        &&
                                    this.sidenavMenu.children.find( route => route.visible ) ? true : false;
        } else {
            this.showTertiaryMenu = false;
        }

        //  Determine if content menu should be visible
        this.showContentMenu = false;
        if (this.contentMenu && this.contentMenu.children.length && !hideTabs && this.contentMenu.children.find(e => e.visible)) {
            this.showContentMenu = true;
        }
    }

    toggleNav() {
        this.displayNav = ! this.displayNav;
    }

    toggleChildren(menuItem: AlRoute) {
        const isExpanded = menuItem.getProperty('expanded', false);
        menuItem.setProperty('expanded', !isExpanded);
    }

}
