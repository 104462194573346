<al-layout-options [disableHeader]="true" [disablePrimaryMenu]="true" pageClass="white"></al-layout-options>
<div class="authentication-view">
    <div class="authentication-view__contents">
        <div>
            <div class="header">
                <div class="circle-logo"><i class="icon-logo al al-logo"></i></div>
                <h2 class="title">Log In</h2>
            </div>

            <al-view-helper [loading]="! formReady" [blur]="true"></al-view-helper>

            <ng-container *ngIf="variant==='ramped'">
                <div alExternalHtml="navigation:notices/regulated-system-notice.html" class="regulated-system-notice"></div>
            </ng-container>

            <form #loginForm="ngForm">
                <div class="u-grid">
                    <div class="u-col-12">
                        <span class="p-input-field p-float-label">
                            <input [(ngModel)]="userName"
                                    [alForceFocus]="formReady"
                                    autocomplete="username"
                                    id="login_email"
                                    name="email"
                                    pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                    required
                                    pInputText />
                            <label for="login_email" class="p-inputwrapper-filled">Email address</label>
                        </span>
                    </div>
                    <div class="u-col-12">
                        <span class="p-input-field p-float-label">
                            <div class="u-flex u-relative">
                                <input (keydown)="onKeyDown($event)"
                                    [(ngModel)]="passPhrase"
                                    [type]="showPassword ? 'text' : 'password'"
                                    autocomplete="current-password"
                                    name="password"
                                    id="login_password"
                                    required
                                    pInputText />
                                <label for="login_password" class="p-inputwrapper-filled">Password</label>
                                <div class="show-toggle" (click)="toggleShowPassword()">SHOW</div>
                            </div>
                        </span>
                    </div>
                    <div class="u-col-12">
                        <button type="button" pButton color="primary" label="Log In" class="p-button-secondary primaryAction u-min-w-100%" [disabled]="!loginForm.form.valid" (click)="onLogin()"></button>
                    </div>
                    <div class="u-col-12 u-flex u-justify-center">
                        <p>
                            <a href="#"
                                class="soft-link"
                               (click)="goToForgotPassword($event)">&nbsp;CAN'T LOG IN?&nbsp;</a>
                            <ng-container *ngIf="variant==='default'">
                            |
                            <a href="#"
                                class="soft-link"
                               (click)="goToFortraLogin($event)">&nbsp;LOG IN WITH FORTRA&nbsp;</a>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="bottom-links">
        <a href="https://www.alertlogic.com/privacy-statement/" target="_blank">Privacy Statement</a>
        <a href="#" (click)="onClickIntegrations($event)">Integrations</a>
        <a href="https://status.alertlogic.com" class="api-doc-link">Service Status</a>
    </div>
</div>
