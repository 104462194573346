<div class="nav-header-bar" *ngIf="authenticated||allowUnauthenticatedMenus">
  <div class="nav-header-content">
    <i *ngIf="displayIconName.length > 0" class="material-icons">{{displayIconName}}</i>
    <span *ngIf="breadcrumbs && breadcrumbs.length" class="breadcrumbs">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
      <ng-container *ngIf="i < 2">
        <div *ngIf="i > 0" class="breadcrumb-separator"><i class="material-icons">chevron_right</i></div>
        <span class="breadcrumb" [ngClass]="breadcrumb.caption">
            <a *ngIf="i > 0 && breadcrumb.href" [href]="breadcrumb.href" (click)="dispatch(breadcrumb,$event)">
              {{breadcrumb.properties?.breadcrumbTitle ? breadcrumb.properties?.breadcrumbTitle : breadcrumb.caption}}
            </a>
            <ng-container *ngIf="i===0 || !breadcrumb.href" (click)="dispatch(breadcrumb, $event)">
              {{breadcrumb.properties?.breadcrumbTitle ? breadcrumb.properties?.breadcrumbTitle : breadcrumb.caption}}
            </ng-container>
        </span>
      </ng-container>
    </ng-container>
    <ng-container>
      <span *ngIf="addendumToNavTitle" class="breadcrumb-separator">|</span> {{addendumToNavTitle}}
    </ng-container>
    <ng-container *ngIf="isBeta">
        <ald-badge
          class="u-ml-3"
          label="BETA"
          variant="info"
          size="sm"
          icon="science"
          iconClass="material-icons-outlined"
          [lowContrast]="true"
        >
        </ald-badge>
    </ng-container>
    </span>
  </div>
  <div class="nav-header-actions">
      <ng-container *ngTemplateOutlet="contextualActionsTpl"></ng-container>
      <span *ngIf="experienceToggle !== undefined"
            [pTooltip]="experienceToggle.tooltip" tooltipPosition="bottom"
            [ngClass]="{ 'nav-header-actions-switch': true, 'items-margin': true }"
            (click)="experienceToggle.callback()">
        <p-inputSwitch [ngModel]="experienceToggle.checked"></p-inputSwitch>
        {{experienceToggle.label}}
      </span>
      <ng-container *ngFor="let headerActionMenu of headerActionsMenus; let i = index; let last = last;">
          <p-menu styleClass="custom-p-menu"
                  *ngIf="headerActionMenu.visible && headerActionMenu.children?.length > 0"
                  #headerActionsItemMenus
                  [popup]="true"
                  [model]="headerActionMenu.children | transformToMenu">
          </p-menu>
          <button *ngIf="headerActionMenu.visible"
                class="{{headerActionMenu.caption}}"
                pButton
                [ngClass]="{ 'flat p-button-sm': true, 'items-margin': !last }"
                (click)="dispatch(headerActionMenu, $event, headerActionMenu.children?.length > 0 )">
            <i *ngIf="headerActionMenu.properties?.iconClass"
               [ngClass]="[headerActionMenu.properties.iconClass ? 'material-icons header-actions-icons' : '']">{{headerActionMenu.properties.iconClass}}</i>
              {{headerActionMenu.caption}}
          </button>
      </ng-container>
  </div>
</div>
