import {
    Component, 
    Input, 
    EventEmitter, 
    Output, 
    OnInit
} from '@angular/core';
import { AlErrorHandler } from '@al/core';
import { AlScanSchedulerClientV2, AlTimeZone } from '@al/scan-scheduler';


export interface AlTimezoneItem {
    label: string;
    value?: string;
    utcOffset: string;
}

export const defaultTimeZoneItem: AlTimezoneItem =  {label: 'Etc/UTC [+00:00]', value: 'Etc/UTC', utcOffset: '+00:00'};
export const browserTimeZone: AlTimezoneItem = {
    label: Intl.DateTimeFormat().resolvedOptions().timeZone,
    utcOffset: getBrowserTimezoneOffset()
};


function getBrowserTimezoneOffset(): string {
    let offset = new Date().getTimezoneOffset();
    const sign = offset < 0 ? '+' : '-';
    offset = Math.abs(offset);
    const hour = Math.floor(offset / 60).toString().padStart(2, '0');
    const remainder = offset % 60;
    const minutes = remainder === 0 ? '00' : remainder.toString().padStart(2, '0');
    return `${sign}${hour}:${minutes}`;
}

@Component({
    selector: 'al-time-zone-selector',
    templateUrl: './al-time-zone-selector.component.html',
    styleUrls: ['./al-time-zone-selector.component.scss']
})

export class AlTimeZoneSelectorComponent implements OnInit {
    /**
     * Set the initial time zone if provided (e.g. 'Etc/UTC')
     */
    @Input() initialTimeZone?: string;
    @Input() disabled: boolean = false;

    /**
     * Outputs
     */
    @Output() onTimeZoneSelected: EventEmitter<AlTimezoneItem> = new EventEmitter();

    /**
     * Public variables
     */
    public selectedTimeZone?: AlTimezoneItem;
    public timeZoneOptions: AlTimezoneItem[] = [];
    public filteredTimeZoneOptions: AlTimezoneItem[] = [];

    ngOnInit() {
        this.getAllTimeZoneOptions();
    }

    onSelectTimezone(timezone: AlTimezoneItem): void {
        this.onTimeZoneSelected.emit(timezone);
    }

    searchTimezone(event: any): void {
        const query: string = event.query;
        this.filteredTimeZoneOptions = this.timeZoneOptions.filter(tzOption => tzOption.label.toLowerCase().includes(query.toLowerCase()));
    }

    /**
     * Get all timezone options
     */
    private async getAllTimeZoneOptions() {
        this.filteredTimeZoneOptions = [];
        try {
            const timezones:AlTimeZone[] = await AlScanSchedulerClientV2.getTimeZonesList();
            this.timeZoneOptions = timezones.filter( (tz:AlTimeZone) => tz.tz_name && tz.utc_offset && tz.dst_offset )
                                            .map( (tz:AlTimeZone) => this.setTimezoneItem( tz.tz_name, tz.dst ? tz.dst_offset : tz.utc_offset ) );
            this.selectedTimeZone = this.getTimezoneItem(this.initialTimeZone);
        } catch ( e ) {
            // If scheduler fails to provide time zones then let's fallback to the client browser time zone (default time zone)
            AlErrorHandler.log( e, "Failed to retrieve global timezone list");
            this.timeZoneOptions = [browserTimeZone];
            this.selectedTimeZone = browserTimeZone;
        }
        this.filteredTimeZoneOptions = [ ...this.timeZoneOptions ];
        this.onTimeZoneSelected.emit(this.selectedTimeZone);
    }

    /**
     * Search for a timezoneItem from timezone value in the
     * timeZoneOptions array (contains all available timezone options from backend),
     * if no param is passed will search one using timezone local browser
     * @param timezoneValue
     */
    private getTimezoneItem(timezoneValue?: string): AlTimezoneItem {
        // let's check the browser timezone name
        const localTimeZoneName: string = timezoneValue ? timezoneValue : Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneItem = this.timeZoneOptions.find(timezone => localTimeZoneName === timezone.value);

        return timeZoneItem? timeZoneItem : defaultTimeZoneItem;
    }

    /**
     * Creates a timezone item based in the data coming from scan-scheduler endpoint
     * @param timezoneName Time zone name representation (e.g. Etc/UTC)
     * @param tzOffset The time zone offset thia can be utc or dst if applies
     */
    private setTimezoneItem(timezoneName: string, tzOffset: string): AlTimezoneItem {
        return {
            label: `${timezoneName} ${tzOffset}`,
            value: timezoneName,
            utcOffset: tzOffset
        };
    }
}
