<div class="dynamic-form"
     [formGroup]="form">
    <div *ngIf="element.title" class="form-content-title">
        {{element.title}}
    </div>
    <div [ngClass]="{'form-row': element.htmlInputType !== 'hidden'}"
         [hidden]="element.htmlInputType === 'hidden'"
         [ngSwitch]="element.controlTemplate">

        <div *ngSwitchCase="'inputText'">
            <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>
            <span class="p-input-field p-float-label">
                <input pInputText
                       [required]="element.required"
                       [id]="element.property"
                       [type]="element.htmlInputType"
                       [placeholder]="element.placeholder ? element.placeholder : ''"
                       [formControlName]="element.property"
                       [autocomplete]="element.autocomplete||'off'"
                       [attr.data-e2e]="element.property"
                       [ngClass]="{
                        'p-error': (form?.controls[element.property]?.dirty
                                  || form?.controls[element.property]?.touched)
                                  && form?.controls[element.property]?.errors
                      }">
                <label *ngIf="element.controlType !== 'hidden'"
                       [for]="element.property">{{element.label}} <span *ngIf="element.required"> *</span></label>
                <ng-container *ngTemplateOutlet="errorForm"></ng-container>
                <ng-container *ngTemplateOutlet="infoIconLeft"></ng-container>
            </span>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'inputResponder'">
            <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>

            <div class="p-input-field inputfield-responder">
                <al-input-responder [element]="element"
                    [formControlName]="element.property"
                    [options]="element.responderOptions"
                    [attr.data-e2e]="element.property"></al-input-responder>
                <ng-container *ngTemplateOutlet="errorForm"></ng-container>
                <div *ngIf="element?.responderOptions?.type !=='dropdown'">
                    <ng-container *ngTemplateOutlet="infoIconLeft"></ng-container>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'inputSwitch'">
            <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>

            <div class="p-inputgroup">
                <p-inputSwitch [formControlName]="element.property" [attr.data-e2e]="element.property"></p-inputSwitch>
                <label class="u-ml-2">{{element.label}}</label>
                <ng-container *ngTemplateOutlet="infoIconRight"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'checkbox'">
            <ng-container *ngTemplateOutlet="aboveDescriptionPlain"></ng-container>

            <div class="p-inputgroup">
                <p-checkbox [formControl]="$any(element).control"
                            [label]="element.label || ''"
                            [binary]="true"
                            [attr.data-e2e]="element.property">
                </p-checkbox>
                <ng-container *ngTemplateOutlet="infoIconRight"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'checkboxGroup'">
            <ng-container *ngTemplateOutlet="aboveDescriptionPlain"></ng-container>
            <ng-container *ngTemplateOutlet="labelRow"></ng-container>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
            <ng-container *ngIf="element.options && element.options.length > 0 && element.view.controls">
                <div *ngFor="let option of element.options" class="form-row">
                    <p-checkbox [binary]="true"
                                [formControl]="element.view.controls[option.value]"
                                [label]="option.label"
                                [attr.data-e2e]="element.property">
                    </p-checkbox>
                </div>
            </ng-container>
            <div class="belowDescriptionPlain" *ngIf="element.belowDescription" [innerHTML]="element.belowDescription | markdown"></div>
        </div>

        <div *ngSwitchCase="'textarea'"
             class="textarea-field form-row">
             <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>

            <span class="p-input-field">
                <label>{{element.label}} <span *ngIf="element.required"> *</span></label>
                <textarea pInputTextarea
                          [autoResize]="true"
                          [formControlName]="element.property"
                          [placeholder]="element.placeholder ? element.placeholder : ''"
                          [id]="element.property"
                          [name]="element.property"
                          [attr.data-e2e]="element.property"
                          rows="5">
                </textarea>
                <ng-container *ngTemplateOutlet="infoIconLeft"></ng-container>
                <ng-container *ngTemplateOutlet="errorForm"></ng-container>
            </span>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'radio'">
            <ng-container *ngTemplateOutlet="aboveDescriptionPlain"></ng-container>

            <ng-container *ngTemplateOutlet="labelRow"></ng-container>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
            <div *ngFor="let option of element.options" class="form-row flex">
                <p-radioButton [formControlName]="element.property"
                               [name]="element.property"
                               [label]="option.label"
                               [value]="option.value"
                               [attr.data-e2e]="element.property">
                </p-radioButton>
                <i *ngIf="option.description" class="material-icons tooltip-icon info-icon-form"
                    [pTooltip]="option.description" tooltipPosition="right">info</i>
            </div>
            <div class="belowDescriptionPlain" *ngIf="element.belowDescription" [innerHTML]="element.belowDescription | markdown"></div>
        </div>

        <div *ngSwitchCase="'calendar'">
            <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>
            <span class="p-float-label p-input-field">
                <p-calendar appendTo="body"
                            [showTime]="element.showTime"
                            [formControlName]="element.property"
                            [placeholder]="element.placeholder != null ? element.placeholder : ''"
                            [dateFormat]="element.dateFormat != null ? element.dateFormat : ''"
                            [style]="{'width':'100%'}"
                            [inputStyle]="{'width':'100%'}"
                            [attr.data-e2e]="element.property">
                </p-calendar>
                <label>{{element.label}}<span *ngIf="element.required && element.label"> *</span></label>
                <ng-container *ngTemplateOutlet="infoIconLeft"></ng-container>
                <ng-container *ngTemplateOutlet="errorForm"></ng-container>
            </span>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'dropdown'">
            <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>
            <div class="u-flex u-justify-end">
                <button *ngIf="element.action"
                    pButton
                    class="flat p-button-sm p-button dropdown-action-button"
                    [icon]="element.action.icon"
                    [label]="element.action.label"
                    [attr.data-e2e]="element.property"
                    (click)="element.action.callback($event, form?.controls[element.property].value)"
                    (contextmenu)="element.action.callback($event, form?.controls[element.property].value)">
            </button>
            </div>
            <div>
                <span class="p-float-label">
                    <p-dropdown [options]="element.options || []"
                                [placeholder]="element.placeholder != null ? element.placeholder : ''"
                                [formControlName]="element.property"
                                [filter]="true"
                                [style]="{'width':'100%'}"
                                [attr.data-e2e]="element.property">
                    </p-dropdown>
                    <label>{{element.label}} <ng-container *ngIf="element.required && element.label"> *
                    </ng-container><ng-container *ngTemplateOutlet="infoIconLeft"></ng-container></label>
                    <ng-container *ngTemplateOutlet="errorForm"></ng-container>
                </span>
            </div>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'multiSelectList'">
            <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>

            <div class="p-input-field inputfield-responder multi-select-list">
                <span class="dynamic-label">{{element.label}}<ng-container *ngIf="element.required"> * </ng-container><ng-container *ngTemplateOutlet="infoIconRight"></ng-container></span>
                <al-multiselect-list [placeholder]="element.placeholder || ''"
                    [formControlName]="element.property" [required]="element.required"
                    [options]="element.multiSelectOptions" [attr.data-e2e]="element.property"></al-multiselect-list>
                <ng-container *ngTemplateOutlet="errorForm"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
        </div>

        <div *ngSwitchCase="'treeSelectList'">
            <ng-container *ngTemplateOutlet="aboveDescription"></ng-container>
            <div class="inputfield-responder" #actualTarget>
                <ng-container *ngTemplateOutlet="labelRow"></ng-container>
                <div class="input-dropdown" >
                    <input pInputText [id]="element.property" [type]="element.controlType"
                        [placeholder]="element.placeholder ? element.placeholder : ''" [readonly]="true" [attr.data-e2e]="element.property"
                        (click)="op.toggle($event)" value="{{element?.value | alMitreLabelPipe }}">
                </div>
                <ng-container *ngTemplateOutlet="errorForm"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
            <p-overlayPanel #op [appendTo]="actualTarget">
                <ng-template pTemplate>
                    <p-tree appendTo="body" [value]="element.treeSelectOptions" selectionMode="checkbox" [(selection)]="element.value"
                        (onNodeSelect)="element.onNodeSelected ? element?.onNodeSelected($event) : false"
                        (onNodeUnselect)="element.onNodeUnselected ? element.onNodeUnselected($event) : false"></p-tree>
                </ng-template>
            </p-overlayPanel>
        </div>

        <div *ngSwitchCase="'monacoEditor'">
            <ng-container *ngTemplateOutlet="aboveDescriptionPlain"></ng-container>
            <ng-container *ngTemplateOutlet="labelRow"></ng-container>
            <ng-container *ngTemplateOutlet="belowDescription"></ng-container>
            <div class="monaco-editor-container">
                <ngx-monaco-editor [formControlName]="element.property"
                                [options]="element.editorOptions"></ngx-monaco-editor>
            </div>
            <div class="belowDescriptionPlain" *ngIf="element.belowDescription" [innerHTML]="element.belowDescription | markdown"></div>
        </div>
        <div *ngSwitchCase="'downloadButton'">
            <div class="aboveDescriptionPlain" *ngIf="element.aboveDescription" [innerHTML]="element.aboveDescription | markdown"></div>
            <al-download-button-element [url]="element.value" [label]="element.label"></al-download-button-element>
            <div class="belowDescriptionPlain" *ngIf="element.belowDescription" [innerHTML]="element.belowDescription | markdown"></div>
        </div>
        <div *ngSwitchCase="'title'">
            <label class="title-border">
                <h3 class="title title-dynamic">{{element.label}}</h3>
                <i class="material-icons tooltip-icon info-icon-form title-icon"
                   [pTooltip]="element.description"
                   tooltipPosition="right">info</i>
            </label>
        </div>
        <div *ngSwitchCase="'content'">
            <div *ngIf="element.view.htmlContent" [innerHTML]="element.view.htmlContent">
            </div>
            <div *ngIf="element.view.externalResourceId" [alExternalHtml]="element.view.externalResourceId">
            </div>
        </div>
    </div>
    <ng-template #errorForm>
        <div *ngIf="element && form !== undefined && form.controls != undefined &&
                    (form.controls[element.property]?.dirty || form.controls[element.property]?.touched)
                    && form.controls[element.property].errors"
            class="p-message p-messages-error p-corner-all">

            <ng-container *ngIf="element.required && form && form.controls && form.controls[element.property]?.errors?.required">
                <div *ngIf="!element.requiredError">{{element.label}} is required.</div>
                <div *ngIf="element.requiredError">{{element.requiredError}}</div>
            </ng-container>
            <ng-container *ngIf="element.validationPattern
                        && form.controls[element.property].errors?.pattern">
                <div *ngIf="!element.patternError">{{element.label}} does not meet the defined pattern.</div>
                <div *ngIf="element.patternError">{{element.patternError}}</div>
            </ng-container>
            <ng-container *ngIf="element.minLength
                        && form.controls[element.property].errors?.minlength">
                <div *ngIf="!element.minLengthError">{{element.label}} should contain minimum {{element.minLength}} characters.</div>
                <div *ngIf="element.minLengthError">{{element.minLengthError}}</div>
            </ng-container>
            <ng-container *ngIf="element.maxLength
                        && form.controls[element.property].errors?.maxlength">
                <div *ngIf="!element.maxLengthError">{{element.label}} should contain maximum {{element.maxLength}} characters.</div>
                <div *ngIf="element.maxLengthError">{{element.maxLengthError}}</div>
            </ng-container>
            <ng-container *ngIf="form.controls[element.property].errors?.max">
                <div>{{element.label}} should be maximun {{element.maxValue}}.</div>
            </ng-container>
            <ng-container *ngIf="form.controls[element.property].errors?.min">
                <div>{{element.label}} should be at least {{element.minValue}}.</div>
            </ng-container>
        </div>
    </ng-template>
    <ng-template #labelRow>
        <div *ngIf="element.label" class="label-row u-flex u-mb-2">
            <span class="dynamic-label u-flex u-align-center">{{element.label}}<span *ngIf="element.required"> * </span></span>
            <ng-container *ngTemplateOutlet="infoIconRight"></ng-container>
            <ng-container *ngTemplateOutlet="errorForm"></ng-container>
        </div>
    </ng-template>

    <ng-template #infoIconRight>
        <i *ngIf="element.type !== 'hidden' && element.description"
        class="material-icons tooltip-icon info-icon-form"
        [pTooltip]="element.description"
        tooltipPosition="right">info</i>
    </ng-template>

    <ng-template #infoIconLeft>
        <i *ngIf="element.type !== 'hidden' && element.description"
           class="material-icons tooltip-icon info-icon-form"
           [ngClass]="{ 'info-icon-input-position': element.controlType !== 'inputResponder' && element.controlType !== 'dropdown',
                        'info-icon-input-position-responder': element.controlType === 'inputResponder',
                        'info-icon-dropdown-position': element.controlType === 'dropdown',
                        'info-icon-input-number-position': element.htmlInputType === 'number',
                        'info-icon-textarea-position': element.controlTemplate === 'textarea'
                        }"
           [pTooltip]="element.description"
           tooltipPosition="left">info</i>
    </ng-template>

    <ng-template #aboveDescriptionPlain>
        <div class="aboveDescriptionPlain"
            *ngIf="element.aboveDescription"
            [innerHTML]="element.aboveDescription | markdown"></div>
    </ng-template>

    <ng-template #aboveDescription>
        <div class="aboveDescription"
            *ngIf="element.aboveDescription"
            [innerHTML]="element.aboveDescription"></div>
    </ng-template>

    <ng-template #belowDescription>
        <div class="belowDescriptionPlain"
            *ngIf="element.belowDescription"
            [innerHTML]="element.belowDescription"></div>
    </ng-template>
</div>
