/**
 * Use this directive as a reusable mechanism to capture optional templates for use inside of a component.  This allows component
 * developers to support complex variations of basic functionality without needing to support all variations inside the component 
 * logic itself, and helps avoid or minimize the need for complicated "configuration" interfaces to control component behaviors.  See
 * [README.md](README.md) for more information.
 *
 * @copyright Alert Logic 2022
 * @author McNielsen <knielsen@alertlogic.com>
 */

import {
    Directive, 
    Input, 
    TemplateRef, 
    OnInit, 
    OnDestroy
} from '@angular/core';
import { AlTemplateIndexService } from '../services/al-template-index.service';


@Directive({
    selector: "[alTemplate]"
})
export class AlTemplateDirective 
             implements OnInit, OnDestroy 
{
    public templateId:string    =   '';

    /**
     * Attribute value is treated as the property for which this custom control should be used.
     */
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input( 'alTemplate' )
    public set alTemplate( value:string ) {
        this.templateId = value;
    }

    @Input() category?:string;

    constructor( public template:TemplateRef<any>,
                 public index:AlTemplateIndexService ) {
    }

    ngOnInit() {
        if ( this.category !== undefined ) {
            this.index.publish( this.category, this.templateId, this.template );
        }
    }

    ngOnDestroy() {
        if ( this.category !== undefined ) {
            this.index.retract( this.category, this.templateId );
        }
    }
}
