<ald-form-element-header
    *ngIf="label"
    [id]="id"
    [name]="ariaLabelledBy"
    [label]="label"
    [tip]="tip"
    [hint]="hint"
    [required]="required"
    [ngClass]="type === 'search' ? 'u-sr-only' : ''"
    >
</ald-form-element-header>

<div class="u-relative">
    <span *ngIf="type === 'search' && !hideSearchIcon" class="ald-input__search-icon">
        <ald-icon icon="search" iconClass="material-icons" size="sm"></ald-icon>
    </span>
    <input
        #input
        class="ald-input"
        [ngStyle]="{'border-radius': enableCustomBorder ? setCustomInputBorder() : null }"
        [ngClass]="[
            type === 'search' && !hideSearchIcon ? 'ald-input--search' : '',
            size === 'sm' ? 'ald-input--sm' : '',
            disabled ? 'ald-input--disabled' : '',
            control?.errors && (control?.touched) && !disableError ? 'ald-input--error u-outline u-outline-2 u-outline-offset-2 u-outline-danger' : '',
            htmlSize ? 'ald-input--auto-size' : ''
        ]"
        [type]="type"
        [name]="name"
        [id]="id"
        [attr.aria-labelledby]="ariaLabelledBy"
        [(ngModel)]="value"
        [disabled]="disabled"
        [required]="required"
        [placeholder]="placeholder"
        [pattern]="validationPattern"
        [min]="minValue"
        [max]="maxValue"
        [attr.size]="htmlSize"
        [minlength]="minLength"
        [maxlength]="maxLength"
        (input)="onChange($any($event.target).value)"
        (focus)="onFocus()"
    />
    <div *ngIf="type === 'search' && value !== ''" class="ald-input__search-button">
        <ald-button variant="ghost" icon="close" size="xs" class="u-flex u-flex-col" (click)="clearSearch()"></ald-button>
    </div>

</div>

<ald-form-group-errors
    *ngIf="!disableErrorMessage"
    [control]="control"
    [label]="label"
    [requiredErrorMessage]="requiredErrorMessage"
    [patternErrorMessage]="patternErrorMessage"
    [minLength]="minLength"
    [maxLength]="maxLength"
    [minValue]="minValue"
    [maxValue]="maxValue"
    >
</ald-form-group-errors>

