<fieldset
    class="u-border-0 u-p-0 u-mx-0">

    <div
        [ngClass]="[ legendLarge ? 'u-pb-4' : 'u-pb-2']"
    >
        <div
            [ngClass]="[tip || required ? 'u-flex u-items-center' : '']">
            <legend
                class="ald-legend"
                [ngClass]="{'ald-legend--lg':legendLarge }">
                {{ legend }}
            </legend>
            <div class="u-ml-1" *ngIf="tip">
                <ald-icon
                    [aldTooltip]="tip" tooltipPosition="above" [tooltipShowDelay]="500"
                    iconClass="material-icons-outlined"
                    size="sm"
                    icon="info"
                    color="u-text-icon-secondary"
                ></ald-icon>
            </div>
            <span *ngIf="required" class="ald-legend__mark">(Required)</span>
        </div>

        <ald-hint *ngIf="hint" [hint]="hint"></ald-hint>

    </div>

    <div
        [ngClass]="[fieldsetContent, inline ? 'u-flex u-gap-4' : '' ]"
    >
        <ng-content></ng-content>
    </div>

</fieldset>
