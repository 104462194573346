/**
 * It allows the construction of an input type element supporting the input, hidden and password types
 *
 * @author Maryit <msanchez@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { Validators, FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementInputResponder extends AlFormElementBase<string> {

    public controlTemplate = "inputResponder";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
    }

    public createFormControl() {
        const validators = this.buildValidators();
        const value = this.transformValueToString(this.value);
        let formControl = validators.length > 0
                            ? new FormControl(value || '', Validators.compose(validators))
                            : new FormControl(value || '');

        if ( this.disabled ) {
            formControl.disable();
        }

        return formControl;
    }

    getAnswer(): any {
        return this.transformStringToDataType(this.getControlValue() || '' );
    }
}
