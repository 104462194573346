/**
 *  AlCatchallRouteComponent provides a standardized way of catching "fallthrough" routes at the feature module level.
 *
 *  @author McNielsen <knielsen@alertlogic.com>
 *
 *  @copyright Alert Logic, Inc 2009-2024 and beyond
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlSession, AlErrorHandler } from '@al/core';
import { AlViewHelperComponent } from '@al/ng-generic-components';
import { AlNavigationService } from '../services/al-navigation.service';
import { AlCatchallRouteBehavior } from '../types/navigation.types';


@Component({
  selector: 'al-catchall',
  templateUrl: './al-catchall-route.component.html',
})
export class AlCatchallRouteComponent implements OnInit
{
    @ViewChild(AlViewHelperComponent, { static: true } )
    public helper:AlViewHelperComponent;
    public behavior?:AlCatchallRouteBehavior;

    constructor( public navigation:AlNavigationService, public router:Router ) {
    }

    ngOnInit() {
        if ( ! ( 'catchall' in this.navigation.routeData ) ) {
            AlErrorHandler.log(`Catchall route misconfiguration at '${this.navigation.currentUrl}'` );
            return;
        }
        try {
            this.behavior = this.navigation.routeData.catchall as AlCatchallRouteBehavior;
            let routePath = this.router.url;
            if ( routePath.startsWith("/") ) {
                routePath = routePath.substring( 1 );
            }
            if ( routePath.indexOf( "?" ) !== -1 ) {
                routePath = routePath.substring( 0, routePath.indexOf("?") );
            }
            const routeParts = routePath.split("/");
            if ( routeParts.length < 2 ) {
                this.reenterFeature();
            }
        } catch( e ) {
            AlErrorHandler.log( `Catchall route internal error`, e );
        }
    }

    reenterFeature() {
        if ( this.behavior ) {
            let entryRoute = Array.isArray( this.behavior.entryPath ) ? this.behavior.entryPath : [ this.behavior.entryPath ];
            entryRoute = entryRoute.map( element => this.substituteParams( element ) );
            if ( entryRoute.length === 1 && entryRoute[0].startsWith("http") || entryRoute[0].startsWith("/#/") ) {
                //  This is a URL or URL fragment -- redirect to it.  This will automatically substitute route parameters.
                this.navigation.navigate.byURL( entryRoute[0] );
            } else {
                //  This is a route -- explicitly apply route parameters and then use the angular router to navigate to it.
                this.navigation.navigate.byNgRoute( entryRoute );
            }
        }
    }

    substituteParams( text:string ):string {
        return text.replace( /\:[a-zA-Z_]+/g, match => {
            const variableId = match.substring( 1 );
            if ( variableId in this.navigation.routeParams ) {
                return this.navigation.routeParams[variableId];
            } else if ( variableId === 'accountId' ) {
                return AlSession.getActingAccountId();
            } else {
                return "(null)";
            }
        } );
    }
}

