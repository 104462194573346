import { Component, Input, OnInit } from '@angular/core';
import { ThreatRating } from '../types';


@Component({
    selector: 'ald-threat-rating',
    templateUrl: './ald-threat-rating.component.html',
    styleUrls: ['./ald-threat-rating.component.scss']
})
export class AldThreatRatingComponent implements OnInit {
    @Input() threatRating: ThreatRating;
    @Input() iconOnly = false;
    @Input() inline = false;
    @Input() label?:string;

    threat: { icon: string; background: string; label: string } | null;

    ngOnInit(): void {
        this.setThreat();
    }

    setThreat() {
        switch (this.threatRating) {
            case 'critical':
                this.threat = {
                    icon: 'al-risk-1',
                    background: 'critical',
                    label: 'Critical',
                };
                break;
            case 'high':
                this.threat = {
                    icon: 'al-risk-2',
                    background: 'high',
                    label: 'High',
                };
                break;
            case 'medium':
                this.threat = {
                    icon: 'al-risk-3',
                    background: 'medium',
                    label: 'Medium',
                };
                break;
            case 'low':
                this.threat = {
                    icon: 'al-risk-1',
                    background: 'low',
                    label: 'Low',
                };
                break;
            case 'info':
                this.threat = {
                    icon: 'al-risk-4',
                    background: 'info',
                    label: 'Info',
                };
                break;
            default:
                this.threat = null;
        }
    }
}
