import { Pipe, PipeTransform } from '@angular/core';


/**
 * A pipe that evaluates a function and returns its value.
 */
@Pipe({
  name: 'evaluate'
})
export class AldEvaluatePipe implements PipeTransform {
    /**
     * Transforms a function by evaluating it and returning its value.
     * @param func The function to evaluate.
     * @param args Optional arguments to pass to the function.
     * @returns The value returned by the function.
     */
    transform(func: (...args: any[]) => any, ...args: any[]): any {
        return func(...args);
    }
}
