import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit, 
    Component, 
    ContentChild, 
    ElementRef, 
    EventEmitter, 
    HostBinding, 
    Input, 
    NgZone, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    Output, 
    QueryList, 
    SimpleChanges, 
    ViewChild, 
    ViewChildren
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AldObjectValuePipe, AldOptionItem, IconClass, PaginationType } from '@al/design-patterns/common';
import { AldDropdownComponent } from '@al/design-patterns/forms';
import { AldPaginationComponent, AldPaginationParams } from '../../pagination';
import { AldTableHeaderComponent } from '../table-header/table-header.component';
import {
    BadgeTable, 
    ColumnDef, 
    IconLabelTable, 
    IconTable, 
    LinkTable, 
    TableBulkActionsSelectionEvent, 
    TableColumnDecorator, 
    TableColumnSortConfig, 
    TableLinkDecorator, 
    TableSortDecorator, 
    TransformTable
} from '../types';


/**
 * Represents a selection model that allows comparing and selecting/deselecting objects based on a custom comparison function.
 * It is particularly useful for comparing objects based on specific properties and enables easy deselection when the object reference changes.
 *
 * This can be removed when upgrading to Angular 14
 *
 * This implementation is inspired by the selection model in Angular 14, more information here
 * https://stackoverflow.com/questions/62686577/is-it-possible-to-set-the-comparewith-function-of-angular-selectionmodel-object
 */
export class ComparableSelectionModel<T> extends SelectionModel<T> {
    private compareWith: (o1: T, o2: T) => boolean;

    constructor(
        _multiple?: boolean,
        initial?: T[],
        _emitChanges?: boolean,
        compareWith?: (o1: T, o2: T) => boolean) {
        super(_multiple, initial, _emitChanges);

        this.compareWith = compareWith ? compareWith : (o1, o2) => o1 === o2;
    }

    override isSelected(value: T): boolean {
        return this.selected.some((x) => this.compareWith(value, x));
    }

    /**
     * We also need to override deselect since you may have objects that
     * meet the comparison criteria but are not the same instance.
     */
    override deselect(...values: T[]): void {
        // using bracket notation here to work around private methods
        this['_verifyValueAssignment'](values);

        values.forEach((value) => {
            // need to find the exact object in the selection set so it
            // actually gets deleted
            const found = this.selected.find((x) => this.compareWith(value, x));
            if (found) {
                this['_unmarkSelected'](found);
            }
        });

        this['_emitChangeEvent']();
    }
}

@Component({
    selector: 'ald-table',
    templateUrl: './ald-table.component.html',
    styleUrls: ['./ald-table.component.scss']
})
export class AldTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @HostBinding('class') class = 'has-ald-components u-font-system u-flex u-flex-col';

    @ViewChildren('actionMenu') actionMenu: QueryList<AldDropdownComponent>;
    @ViewChild('multiSelectActionMenu') multiSelectActionMenu: AldDropdownComponent;
    @ViewChild('pagination') pagination: AldPaginationComponent;

    // Table Data
    @Input() columns: ColumnDef[]; // column definitions
    @Input() data: any[]; // data to display as rows
    @Input() dictionary?:any; // dictionary to display extra information
    @Input() uniqueIdentifierFieldName: string; //required to ensure checkbox selections behave
    @Input() isLoading = false; //used to toggle loading states on table
    @Input() totalRecords: number;
    /** Provide filters to enable the filters feature */
    @Input() summary?: string;
    @Input() columnDecorator?: TableColumnDecorator;
    @Input() sortDecorator?: TableSortDecorator;
    @Input() linkDecorator?: TableLinkDecorator;

    // Sorting
    @Input() enableSorting?: boolean;
    @Input() sortField?: string; // default field to sort by
    @Input() sortDirection?: 'asc' | 'desc' = 'asc';
    @Output() didSort: EventEmitter<TableColumnSortConfig> = new EventEmitter();

    // Table Presentation
    @Input() outerBorder?: boolean = true; // enables the table outer border.
    @Input() stickyColumn?: boolean; // enable first data column to be sticky.
    @Input() multilineRows?: boolean; // enables the rows to display as multlines - uglify!
    @Input() set fullHeight(fullHeight: boolean) {
        const [classPart, ] = this.class.split(' u-h-100%');
        if (!fullHeight) {
            this.class = classPart;
        }
    };
    @Input() paginator = false;
    @Input() paginationType?: PaginationType = 'offset';
    @Input() enableResetSort?: boolean = false; // enables a "reset sort" when the user change the column.

    // Table Shadows on overflow content
    @ViewChild('tableContainer') tableContainer: ElementRef;
    @ViewChild('tableHeaderRow', { read: ElementRef }) tableHeaderRow: ElementRef;
    @ViewChild('table') table: any;
    @ContentChild(AldTableHeaderComponent, {static: true}) tableHeader: AldTableHeaderComponent;

    tableContainerResizeObserver: ResizeObserver;
    contentResizeObserver: ResizeObserver;
    shadowLeft = false;
    shadowRight = false;
    indexSortDirection:number = 0;
    sortDirections:Array<'asc'|'desc'> = ['asc', 'desc'];

    public scrollbarWidth: number;

     // Table Actions
    @Input() linkedPath?: string; // path for prepending to the link
    @Input() selectableRows?: boolean; // rows are clickable - typically used to pass the row value to a sidebar
    @Input() preselectDataRow?: boolean; // select first row of data after data loads
    @Input() actions?: AldOptionItem[]; // array of actions
    @Input() currentRowActions: AldOptionItem[] = []; // array of actions for a particular selected row
    @Input() actionPropagation?: boolean = false; // Propagate Events on actions button click on higher order elements
    @Input() enableSelectAllResults = false;
    @Output() didActionItem: EventEmitter<{action: AldOptionItem; item: any; rowIndex: number}> = new EventEmitter();
    @Output() didSelectRow: EventEmitter<{ item: any, index: number }> = new EventEmitter();
    @Output() actionButtonClick: EventEmitter<number> = new EventEmitter();


    /** An array of actions (AldOptionItem[]) for applying to the multiple selected rows. */
    @Input() multiSelectActions?: AldOptionItem[]; // array of actions
    activeActionMenuRow = -1;
    selectedRow: number;

    // Check Rows
    @Input() checkboxRows?: boolean; // enables the checkboxes on each row
    @Input() checkboxPropagation?: boolean = false; // Propagate Events on checkbox click on higher order elements
    @Output() didCheckRow: EventEmitter<number> = new EventEmitter();
    @Output() didUncheckRow: EventEmitter<number> = new EventEmitter();

    // Check All
    @Output() didCheckAllRows: EventEmitter<boolean> = new EventEmitter();
    isCheckAllIndeterminate: boolean = undefined;

    @Output() toggleSelectAllRows: EventEmitter<boolean> = new EventEmitter();


    @Output() tableSelectionChanged: EventEmitter<any[]> = new EventEmitter();
    @Output() didActionMultiItems: EventEmitter<TableBulkActionsSelectionEvent> = new EventEmitter();
    @Output() onPaginationAction: EventEmitter<AldPaginationParams> = new EventEmitter();
    @Output() toggleBulkActionButton: EventEmitter<void> = new EventEmitter();
    @Output() didChangeResultsPerPage: EventEmitter<number> = new EventEmitter();

    // Table Pagination
    @Input() rowsPerPage: number = 25;
    @Input() currentPage: number = 1;
    @Input() lazyLoadOnInit = true;
    @Input() showFooterLoading: boolean = false;

    // Subjects
    dataSource$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]); // the data source. When this changes, it updates the displayed data.
    displayedData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]); // the data supplied to the table cdk for display.
    displayedColumns$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    // Use SelectionModel instead of ComparableSelectionModel when upgrading to Angular 14
    public selection = new ComparableSelectionModel<any>(true, [], true, this.compareWith.bind(this));
    // Making sure that the columns are loaded once from local storage or from parent component
    private colsLoaded = false;

    private selectionModelChangeSubscription: Subscription;

    private readonly loadingRowId: string = 'ald-table-loading-row';

    // Using NgZone to manually ensure the resize observer runs in the zone
    constructor(
        private zone: NgZone,
        private objectValuePipe: AldObjectValuePipe
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // listener for column changes
        if ( 'columns' in changes && this.columns?.length > 0 ) {
            if ( ! this.colsLoaded ) {
                if ( this.columnDecorator ) {
                    let rewritten = this.columnDecorator.read( this.columns );
                    if ( rewritten ) {
                        //this.colsLoaded = true;
                        this.columns = rewritten;
                        this.showColumns(this.columns);
                    } else {
                        this.showColumns(this.columns);
                    }
                } else {
                    this.showColumns(this.columns);
                }
                //this.colsLoaded = true;
            } else {
                if ( this.columnDecorator ) {
                    this.columnDecorator.write( this.columns );
                }
            }
        }

        if ('isLoading' in changes && changes.isLoading.currentValue === true) {
            this.dataSource$.next([]);
            setTimeout(() => this.tableHeaderRow?.nativeElement.scrollIntoView({ block: "center", inline: "nearest" }));
        }

        // listener for table data change
        if (changes.data) {
            this.dataSource$.next(changes.data.currentValue);
        }
    }

    ngOnInit(): void {
        if(this.checkboxRows && (!this.uniqueIdentifierFieldName || this.uniqueIdentifierFieldName.length === 0)) {
            throw new Error('No uniqueIdentifierFieldName value supplied - this will break checkbox selection behaviour')
        }
        if ((!this.data || !this.data.length) && this.isLoading) {
            this.data = [];
        }

        this.dataSource$.next(this.data);

        this.dataSource$.subscribe((data) => {
            this.normalizeData(data);
            this.displayedData$.next(data);
            if(this.preselectDataRow && data.length > 0) {
                this.rowSelected(0, data[0]);
            }
            this.setIsCheckAllIndeterminate();
        });

        this.selectionModelChangeSubscription = this.selection.changed.subscribe((change: SelectionChange<any>) => {
            this.tableSelectionChanged.emit(change.source.selected);
            this.setIsCheckAllIndeterminate();
         })

         if(this.tableHeader) {
            this.tableHeader.columnDecorator = this.columnDecorator;
            this.tableHeader.didApplyColumnConfig.subscribe(cols => {
                this.showColumns(cols);
             })
         }

         if(this.sortDecorator) {
            let colSortConfig: TableColumnSortConfig = this.sortDecorator.read();
            if(colSortConfig) {
                this.sortField = colSortConfig.sortField;
                this.sortDirection = colSortConfig.sortDirection;
            }
         }

    }

    ngAfterViewInit(): void {
        // Container and content widths
        let container: number;
        let content: number;

        // Container Resize Observer
        this.tableContainerResizeObserver = new ResizeObserver(entries => {
            this.zone.run(() => {
                container = entries[0].contentRect.width;
                this.shadowLeft = content > container;
                this.shadowRight = content > container;
                this.scrollbarWidth = this.getScrollbarWidth();
            });
        });

        // Content Resize Observer
        this.contentResizeObserver = new ResizeObserver(entries => {
            this.zone.run(() => {
                content = entries[0].contentRect.width;
                this.shadowLeft = content > container;
                this.shadowRight = content > container;
                this.scrollbarWidth = this.getScrollbarWidth();
            });
        });

        // Observe the container and content seperately
        this.tableContainerResizeObserver.observe(this.tableContainer.nativeElement);
        this.contentResizeObserver.observe(this.table._elementRef.nativeElement);
        this.scrollbarWidth = this.getScrollbarWidth();
    }

    ngOnDestroy(): void {
        this.dataSource$.complete();
        this.displayedData$.complete();
        this.displayedColumns$.complete();

        // Destroy Resize Observers
        this.tableContainerResizeObserver?.unobserve(this.tableContainer.nativeElement);
        this.contentResizeObserver?.unobserve(this.table._elementRef.nativeElement);

        this.selectionModelChangeSubscription?.unsubscribe();
    }

    /**
     * Defines the visible columns for the table and the order in which to show them.
     */
    public showColumns(columns: ColumnDef[]) {
        const visibleHeaders = columns
            .filter((col: ColumnDef) => {
                return !col.hidden;
            })
            .map((col: ColumnDef) => col.header);

        this.displayedColumns$.next([
            ...(this.actions ? ['ald-table-actions'] : []),
            ...(this.checkboxRows ? ['checkboxRows'] : []),
            ...visibleHeaders,
        ]);
        if(!this.colsLoaded) {
            if(this.lazyLoadOnInit) {
                this.onPaginate({
                    limit: this.rowsPerPage,
                    offset: 0,
                    page: 1
                });
            }
        }
        this.colsLoaded = true;
    }

    /**
     * Emits the sort key when a column is selected for sorting.
     * @param key The value of the column field to sort by
     */
    public adjustSort(key: string) {


        if (this.enableSorting) {

            /**
             * Enables the reset sort when the user changes the colum.
             *
             * [enableResetSort=false]: the asc/desc order is changing every time the user sort doesn't matter if is changing the column.
             *                          i.e. user sorts column A in 'asc'->'desc'->'asc', sorts another column and the sort order will be 'desc'
             *
             * [enableResetSort=true]: the asc/desc order is changing every time the user sort the same column **BUT** is reset when the column change.
             *                          i.e. user sorts column A in 'asc'->'desc'->'asc', sorts another column and the sort order will be 'asc' again
             *                          due to the "enableResetSort" variable is true.
             */
            if (this.enableResetSort) {
                if (this.indexSortDirection > 1 || this.sortField !== key) {
                    // Reset the index when is out of the sortDirections array **OR** the user changes the column.
                    this.indexSortDirection = 0;
                }
                this.sortDirection = this.sortDirections[this.indexSortDirection];
            } else {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            }

            this.sortField = key;
            const sortConfig: TableColumnSortConfig = {
                sortField: this.sortField,
                sortDirection: this.enableResetSort ? this.sortDirections[this.indexSortDirection] : this.sortDirection
            };
            const origColDef = this.columns.find(col => col.field === key);
            if(origColDef?.sortByValue) {
                sortConfig.sortByValue = origColDef.sortByValue;
            }
            if(origColDef?.hasSeverities) {
                sortConfig.hasSeverities = origColDef.hasSeverities;
            }
            if(this.sortDecorator) {
                this.sortDecorator.write(sortConfig);
            }
            this.didSort.emit(sortConfig);

            /**
             * Increases the index.
             *
             * ONLY WHEN enableResetSort=true.
             */
            if(this.enableResetSort) {
                this.indexSortDirection ++;
            }
        }
    }

    /**
     * Emits the selected action when a user selects an action item.
     * @param action The $event emitted
     * @param item The action item selected
     */
    public actionSelected(action: { index: number, option: AldOptionItem }, item: any) {
        this.didActionItem.emit({ action: action.option, item, rowIndex: this.activeActionMenuRow });
        this.actionMenu.get(this.activeActionMenuRow).close();
    }

    /**
     * When a user selects a row
     * @param index The table row index
     * @param item The item selected
     */
    public rowSelected(index, item) {
        if (this.selectableRows) {
            this.selectedRow = index;
            this.didSelectRow.emit({ item, index });
        }
    }

    /**
     * When a row checkbox is checked/unchecked
     * @param checked The checkbox value of the checked/unchecked checkbox
     * @param index The row index of the checked/unchecked item
     */
    public checkRow(row: any) {
        this.selection.toggle(row);
        this.setIsCheckAllIndeterminate();
    }

    /**
     * When an action button/checkbox is clicked, this function checks the propagation input
     * to prevent propagation or not - by default propagation is prevented.
     * @param e $event
     */
    public propagationCheck(event: Event): void {
        if (!this.checkboxPropagation) {
            event.stopPropagation();
        }
    }

    /**
     * When the action button is clicked, this function checks the propagation input
     * to prevent propagation or not - by default propagation is prevented.
     * @param e $event
     * @param index selected row index
     */
    public actionButtonClicked(event: Event, index: number): void {
        this.propagationCheck(event);
        this.activeActionMenuRow = index;
        this.actionButtonClick.emit(index);
    }

    public getInternalRoute(key): string[] {
        const path = typeof key === 'object' ? key.path : key;
        if (!path) {
            return [];
        }
        return [this.linkedPath, ...path.split('/')];
    }

    public getQueryParams(value): { [k: string]: string } | {} {
        return typeof value === 'object' ? value.queryParams ?? {} : {};
    }

    expandJson(event: any) {
        event.stopPropagation()
    }

    public toggleSelectAll(check: boolean) {
        check ? this.selection.select(...this.data) : this.selection.clear();
    }

    public toggleCheckAllResults(check): void {
        this.toggleSelectAllRows.emit(check);
    }

    public onCheckAllChange(check: boolean){
        this.toggleSelectAll(check);
        this.setIsCheckAllIndeterminate();
        this.didCheckAllRows.emit(check);
    }

    private setIsCheckAllIndeterminate() {
        this.isCheckAllIndeterminate = this.selection.selected.length > 0 && !this.data.every(row => this.selection.isSelected(row));
    }

    onPaginate(pagingParams: AldPaginationParams) {
        this.onPaginationAction.emit({...pagingParams, sortField: this.sortField, sortOrder: this.sortDirection });
    }

    onChangeResultsPerPage(event) {
        this.didChangeResultsPerPage.emit(event);
    }

    resetPagination() {
        this.pagination.goToFirstPage();
    }

    private compareWith(row1, row2) {
        const rootLevelFieldName = this.uniqueIdentifierFieldName.split('.')[0]; //handle dot notation field references
        if (!row1.hasOwnProperty(rootLevelFieldName) || !row2.hasOwnProperty(rootLevelFieldName)) {
            throw new Error(`You have configured the uniqueIdentifierFieldName to be "${this.uniqueIdentifierFieldName}" however this field is not present on the data row object`);
        }
        return this.objectValuePipe.transform(row1, this.uniqueIdentifierFieldName) === this.objectValuePipe.transform(row2, this.uniqueIdentifierFieldName);
    }

    /**
     * Normalizes the input data by applying transformations based on column definitions.
     * @param {Array<Object>} data - The array of data rows to be normalized.
     * @private
     */
    private normalizeData(data) {
        if(!data) {
            console.warn('ald-table: data must be defined');
            return;
        }

        if(!this.columns){
            console.warn('ald-table: columns definitions must be defined');
            return;
        }
        const mapCellConfig = this.getMapCellConfig();
        data.forEach(row => {
            const normalizedRow = {};
            Object.keys(mapCellConfig).forEach((property) => {
                const value = this.objectValuePipe.transform(row, property);
                const config = mapCellConfig[property];

                if (config.badge) {
                    const badge = {... config.badge} as Record<string, BadgeTable>;
                    normalizedRow[property] = this.getBadge(row, badge, value);
                }

                if (config.link) {
                    const link = this.getLink(row, {...config.link});
                    normalizedRow[property] = link;
                }

                if (config.icon) {
                    const badge = {... config.icon} as Record<string, IconTable>;
                    normalizedRow[property] = this.getIcon(badge, value);
                }

                if (config.icon_with_label) {
                    const badge = {... config.icon_with_label} as Record<string, IconLabelTable>;
                    normalizedRow[property] = this.getIconWithLabel(badge, value);
                }

                if (config.transform) {
                    const badge = {... config.transform} as Record<string, TransformTable>;
                    normalizedRow[property] = this.getTransformation(badge, value);
                }
            });
            row['aldTableNormalize'] = normalizedRow;
        });
    }

    private getMapCellConfig() {
       const colsWithExtraConfig = this.columns.filter((col) => col.cellConfig);
       return colsWithExtraConfig.reduce((acc, col) => {
            acc[col.field] = col.cellConfig;
            return acc;
       }, {});
    }

    private getLink(row, link: LinkTable): LinkTable {
        const externalUrl = link.externalUrl;
        const value = this.objectValuePipe.transform(row, link.key);
        if (this.linkDecorator) {
            return this.linkDecorator.build(value, link);
        }
        if (!externalUrl) {
            link.internalUrl = this.getInternalRoute(value);
            link.queryParams = this.getQueryParams(value);
        } else {
            link.externalUrl = value;
        }
        return link;
    }

    /** Get the icon details, defined in the columnDef */
    private getIcon(icons: Record<string, IconTable>, value: string): IconTable {
        const icon = icons[value];

        if (!icon) {
            return { name: '', iconClass: 'material-icons' as IconClass, color: '', title: '', position: 'center' };
        }

        icon.position = icon.position ?? 'center';

        return icon;
    }

    /** Get the icon with label details, defined in the columnDef */
    private getIconWithLabel(icons: Record<string, IconLabelTable>, value: string): IconLabelTable {
        const iconLabel = icons[value];

        if (!iconLabel) {
            return { name: '', iconClass: 'material-icons' as IconClass, color: '', title: '', label: '' };
        }

        return iconLabel;
    }

    /** Get the text transform details, defined in the columnDef */
    private getTransformation(transform: Record<string, TransformTable>, value: string): string {
        return transform[value]?.transformTo ?? '';
    }

    /** Get the icon details, defined in the columnDef */
    private getBadge(row, badges: Record<string,BadgeTable>, value: string): BadgeTable {
        const badge = badges[value];
        if (!badge) {
            return { label: '', variant: 'default', icon: '', iconClass: 'material-icons', lowContrast: false, title: '' };
        }

        if(!badge.field && !badge.label){
            badge.label = value;
            return badge;
        }

        if (badge.field) {
            badge.label = this.objectValuePipe.transform(row, badge.field);
        }

        return badge;
    }

    private getScrollbarWidth(): number {
        const outer: HTMLDivElement = document.createElement( 'div' );
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        document.body.appendChild( outer );

        const widthNoScroll: number = outer.offsetWidth;
        outer.style.overflow = 'scroll';

        const inner: HTMLDivElement = document.createElement( 'div' );
        inner.style.width = '100%';
        outer.appendChild( inner );

        const widthWithScroll: number = inner.offsetWidth;
        outer.parentNode.removeChild( outer );

        return widthNoScroll - widthWithScroll;
    }

}
