import { Component, Input } from '@angular/core';


@Component({
    selector: 'ald-hint',
    templateUrl: './ald-hint.component.html',
    styleUrls: ['./ald-hint.component.scss']
})
export class AldHintComponent {
    @Input() hint = 'This is a hint';
}
