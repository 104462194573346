import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'ald-repeater-item',
  templateUrl: './ald-repeater-item.component.html',
  styleUrls: ['./ald-repeater-item.component.scss']
})
export class AldRepeaterItemComponent {

  @Input() header: string;
  @Input() removeBtnPos: 'header' | 'inline' = 'header';
  @Input() hasRemoveBtn: boolean = true;
  @Output() didRemoveItem: EventEmitter<void> = new EventEmitter();

}
