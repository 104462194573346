import { Pipe, PipeTransform } from '@angular/core';
import { AldOptionItem } from '../types/al-common.types';


@Pipe({
  name: 'aldFilter'
})
export class AldFilterPipe implements PipeTransform {

  transform(items: AldOptionItem[], searchTerm: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchTerm) {
      return items;
    }

    return items.filter(item => {
      return item.label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    });
  }

}
