<div class="ald-modal has-ald-components u-font-system"
  [ngClass]="modal.verticalPosition === 'center' ? 'ald-modal--center' : ''">

  <div class="ald-modal__box"
    [ngClass]="[
      modal.width === 'xs' ? 'ald-modal__box--xs' :
      modal.width === 'sm' ? 'ald-modal__box--sm' :
      modal.width === 'md' ? 'ald-modal__box--md' :
      modal.width === 'lg' ? 'ald-modal__box--lg' :
      'ald-modal__box--md'
    ]">

    <!-- Modal Wrapper -->
    <div class="ald-modal__wrapper"
      *ngIf="modalBodyOpen">

      <!-- Head -->
      <div class="ald-modal__head">
        <div class="ald-modal-header">
          <div class="ald-modal-header__heading">
            <ald-icon *ngIf="modal.icon" [icon]="modal.icon" [iconClass]="modal.iconClass" size="sm"></ald-icon>
            <h1>{{modal.header}}</h1>
          </div>
          <ald-button icon="close" variant="ghost" size="sm" (click)="close($event)"></ald-button>
        </div>
      </div>

      <!-- Body -->
      <div class="ald-modal__body" [ngClass]="[modal.background ? 'u-background' : 'u-foreground']">
        <ng-container [ngSwitch]="contentType">
          <ng-container *ngSwitchCase="'template'">
            <ng-container *ngTemplateOutlet="contentIsTemplateRef"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'component'">
            <ng-container *ngComponentOutlet="contentIsComponent"></ng-container>
          </ng-container>
        </ng-container>
      </div>

      <!-- Footer -->
      <div *ngIf="modal.footer" class="ald-modal__footer">
        <ald-button *ngIf="modal.secondaryAction" [label]="modal.closeButtonLabel" variant="secondary" (click)="secondaryAction()"></ald-button>
        <ald-button *ngIf="modal.primaryAction" [label]="modal.primaryButtonLabel" [disabled]="modal.disablePrimaryButton" variant="primary" (click)="primaryAction()"></ald-button>
      </div>

    </div>

  </div>

  <!-- Backdrop -->
  <div
    class="ald-modal__overlay"
    [ngClass]="modalBackgroundOpen ? 'ald-modal__overlay--open' : 'ald-modal__overlay--close'"
    (click)="close($event)">
  </div>

</div>
