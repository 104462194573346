import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AldBadgeModule } from '@al/design-patterns/badge';
import { AldCommonModule } from '@al/design-patterns/common';
import { AldFormsModule } from '@al/design-patterns/forms';
import { AldNoteModule } from '../note';
import { AldPaginationModule } from '../pagination';
import { AldThreatModule } from '../threat';
import * as manifest from './manifest';


@NgModule({
    imports: [
        CommonModule,
        AldCommonModule,
        AldBadgeModule,
        AldFormsModule,
        AldThreatModule,
        AldPaginationModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        DragDropModule,
        RouterModule,
        AldNoteModule
    ],
    declarations: [
        ...manifest.MODULE_COMPONENTS
    ],
    exports: [
        ...manifest.MODULE_COMPONENTS
    ],
})
export class AldTableModule {}
