<div class="ald-table-bulk-actions" *ngIf="multiSelectActions && selectedRowCount > 0">
    <ald-dropdown
        #multiSelectActionMenu
        variant="primary"
        icon="more_vert"
        size="sm"
        [label]="'Bulk Action (' + selectedRowCount + ')'"
        iconPos="left"
        (didOpen)="toggleBulkActions()">
        <ald-menu
            [options]="multiSelectActions"
            (didSelect)="actionMultiItems($event)"
        ></ald-menu>
    </ald-dropdown>
    <ng-container *ngIf="enableSelectAllResults">
        <ald-button
            *ngIf="selectedRowCount < totalResults"
            variant="ghost"
            size="sm"
            [label]="'Select all ' + totalResults + ' results'"
            (click)="toggleSelectAllResults(true)">
        </ald-button>
        <ald-button
            *ngIf="selectedRowCount >= totalResults"
            variant="ghost"
            size="sm"
            label="Unselect all results"
            (click)="toggleSelectAllResults(false)">
        </ald-button>
    </ng-container>
</div>