<div class="u-p-5 u-font-system"
    [ngClass]="toastOpen ?  'u-translate-y-0 u-transition-in' : 'u-translate-y-100% u-transition-out'">

    <div class="u-flex u-justify-between u-gap-2 u-px-4 u-py-3 u-h-100% u-overflow-hidden u-rounded-md u-shadow-md u-w-80" [ngClass]="toastStyle">

        <div class="u-flex u-gap-1">
            <ng-container *ngIf="toast.icon">
                <ald-icon [icon]="toast.icon" [iconClass]="toast.iconClass" color="inherit" size="sm"></ald-icon>
            </ng-container>
            <div class="u-text-sm">{{ toast.text }}</div>
        </div>

        <div class="u-flex u-justify-end u-gap-5">
            <ald-button 
                *ngIf="toast.secondaryButtonLabel || toast.secondaryButtonIcon"
                [label]="toast.secondaryButtonLabel" 
                [icon]="toast.secondaryButtonIcon" 
                [iconClass]="toast.secondaryButtonIconClass"
                variant="light"
                size="sm"
                (click)="secondaryAction()">
            </ald-button>


            <div class="u-flex u-gap-2">

                <div class="u-border-l u-border-solid" [ngClass]="toast.variant === 'warning' ? 'u-border-gray-900 u-opacity-25' : 'u-border-white u-opacity-25'"></div>

                <ald-button 
                    *ngIf="toast.primaryButtonLabel || toast.primaryButtonIcon"
                    [label]="toast.primaryButtonLabel" 
                    [icon]="toast.primaryButtonIcon" 
                    [iconClass]="toast.primaryButtonIconClass"
                    variant="unstyled"
                    size="xs"
                    (click)="primaryAction()">
                </ald-button>
            </div>

        </div>
    </div>
</div>
