<div class="user-info-summary">

  <al-menu-trigger (toggleButtonClick)="toggleClick()"></al-menu-trigger>

  <div class="user-menus-container" *ngIf="authenticated && ready">

    <div class="u-relative">

      <p-menu
          #usermenu
          [popup]="true"
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          styleClass="p-menu-align-left"
          [model]="userMenuItems">
      </p-menu>

      <button (click)="usermenu.toggle($event)" class="user-menus-button">
        {{ username }}
        <i class="material-icons">keyboard_arrow_down</i>
      </button>

    </div>

    <div class="u-relative u-mx-4">

      <ng-select #accountSelector
            [items]="managedAccounts"
            [virtualScroll]="true"
            bindLabel="name"
            bindValue="id"
            [dropdownPosition]="'bottom'"
            [searchable]="false"
            [(ngModel)]="actingAccountId"
            [searchFn]="accountSearchFn"
            (change)="onAccountChanged()"
            class="al-account-selector material-design-select u-mx-1"
            (open)="onOpenAccountSelector()"
            [clearable]="false"
            data-cy="account-selector">
          <ng-template ng-header-tmp>
            <div style="display:flex;">
                <input #filterInput
                  pInputText
                  class="filter-input"
                  type="text"
                  (input)="filterAccounts( $event )"
                  placeholder="Search by account id or name..."
                  data-cy="account-selector-input"
                  [alForceFocus]="true" />
            </div>
            <div pTooltip="Loading" *ngIf="loadingManagedAccounts" id="accounts-loading-indicator"><p-progressSpinner></p-progressSpinner> <i>Loading...</i></div>
          </ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled" [style.display]="loadingManagedAccounts ? 'none' : 'block'">
              No matches found for "{{searchTerm}}"
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-account="item" let-index="index">
            <div class="account-selector-option" data-cy="account-selector-option"><div class="account-selector-option__name">{{account.name}}</div><div class="account-selector-option__id">({{account.id}})</div></div>
          </ng-template>
      </ng-select>

    </div>

    <ng-container *ngIf="datacenter && datacenter.locationsAvailable > 0">

      <div class="u-relative">

        <p-menu
            #menux
            class="region-menu"
            [popup]="true"
            [model]="datacenter.selectableRegions"
            [style]="{'width':'180px'}"
            styleClass="p-menu-align-right"
            [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'">
        </p-menu>

        <button (click)="onOpenRegionSelector($event)" class="user-menus-button user-menus-button--upercase">
          <div *ngIf="datacenter.currentResidency==='US'">
              {{datacenter.currentRegion}} (US)
          </div>
          <div *ngIf="datacenter.currentResidency==='EMEA'">
              {{datacenter.currentRegion}} (UK)
          </div>
          <i class="material-icons">keyboard_arrow_down</i>
        </button>

      </div>

    </ng-container>

  </div>

</div>
