import {
    Component, 
    EventEmitter, 
    Input, 
    OnInit, 
    Optional, 
    Output, 
    Self
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';


@Component({
    selector: 'ald-checkbox',
    templateUrl: './ald-checkbox.component.html',
    styleUrls: ['./ald-checkbox.component.scss'],
})
export class AldCheckboxComponent implements OnInit, ControlValueAccessor {

    hasFocus = false;

    @Input() label? = '';
    @Input() size?: 'sm' | 'md' = 'md';
    @Input() id?: string | number = '';
    @Input() name?: string;
    @Input() value?: string | number | boolean;
    @Input() checked? = false;
    @Input() disabled? = false;
    @Input() indeterminate? = false;
    @Input() bottomPadding? = true;

    @Output() didCheckedChange: EventEmitter<boolean> = new EventEmitter();

    
    onChange = (value: boolean) => { return value; };
    onTouch = () => { return; };

    get control() {
        return this.ngControl?.control;
    }

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.id = this.id || this.label;
        this.name = this.name || this.label;
    }

    onValueChange(newValue: boolean) {

        if (this.indeterminate) {
            this.indeterminate = false;
        }

        if (newValue === undefined) {
            this.onChange(this.checked);
        } else {
            this.onChange(newValue);
            this.checked = newValue;
        }

        this.didCheckedChange.emit(this.checked);
    }

    writeValue(value: boolean) {
        this.checked = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    setIndeterminateState(val: boolean): void {
        this.indeterminate = val;
    }
}
