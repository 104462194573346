import {
    Component, 
    Input, 
    Optional, 
    Self, 
    ViewChild, 
    OnInit, 
    EventEmitter, 
    Output, 
    OnChanges, 
    SimpleChanges
} from '@angular/core';
import {
    ControlValueAccessor, 
    NgControl, 
    FormBuilder, 
    FormArray, 
    FormGroup, 
    FormControl
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    AldOptionItem, 
    ButtonVariant, 
    IconClass, 
    IconPosition, 
    ButtonSize
} from '@al/design-patterns/common';
import { AldDropdownComponent } from '../ald-dropdown/ald-dropdown.component';
import { AldInputComponent } from '../ald-input/ald-input.component';


@Component({
    selector: 'ald-multiselect',
    templateUrl: './ald-multiselect.component.html',
    styleUrls: ['./ald-multiselect.component.scss']
})
export class AldMultiselectComponent<ItemType=any> implements OnInit, OnChanges, ControlValueAccessor {

    @ViewChild('multiSelectDropdown') multiSelectDropdown: AldDropdownComponent;
    @ViewChild('searchInputComponent') searchInputComponent: AldInputComponent;

    // Form Element Header Inputs - Optional (pass through)
    @Input() id?: string;
    @Input() label?: string;
    @Input() tip?: string;
    @Input() hint?: string;
    @Input() required?: boolean = false;

    // Form Validation
    @Input() requiredErrorMessage?: string;

    // Dropdown Button Inputs
    @Input() buttonLabel:string|{(id:string,selection:AldOptionItem<ItemType>[]):string};
    @Input() variant?: ButtonVariant = 'secondary';
    @Input() size?: ButtonSize = 'md';
    @Input() disabled?: boolean = false;
    @Input() icon?: string = 'expand_more';
    @Input() iconPosition?: IconPosition = 'right';
    @Input() iconClass?: IconClass = 'material-icons';
    @Input() selected?: boolean;

    // Dropdown elements
    @Input() search?: boolean = true;
    @Input() autofocusSearch?: boolean = true;
    @Input() options: AldOptionItem<ItemType>[] = [];

    // Action Button Labels
    @Input() primaryButtonLabel = 'Apply';
    @Input() secondaryButtonLabel = 'Clear';

    @Output() didSearch = new EventEmitter<string>();                       // Output search event
    @Output() didSelect = new EventEmitter<AldOptionItem<ItemType>[]>();    // Emits selected options when they change
    @Output() didSelectValues = new EventEmitter<ItemType[]>();             // Emits selected values when they change

    buttonLabelText = "Select options";
    searchControl = new FormControl('');
    multiselectForm: FormGroup;

    selectedOptions: AldOptionItem<ItemType>[] = [];

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private formBuilder: FormBuilder
    ) {

        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }

        this.multiselectForm = this.formBuilder.group({
            checkboxes: this.formBuilder.array([])
        });
    }

    onChange = (value: AldOptionItem<ItemType>[]) => {};
    onTouch = () => {};

    get control() {
        return this.ngControl?.control;
    }

    get checkboxes() {
        return this.multiselectForm.get('checkboxes') as FormArray;
    }

    ngOnInit(): void {
        if ( this.label && ! this.id ) {
            this.id = this.label.replace(/\s+/g, '_').toLowerCase();
        }
        this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe((searchTerm: string) => {
            this.didSearch.emit(searchTerm);
        });
        this.updateButtonLabel( this.options?.filter( x => x.selected ) ?? [] );
    }

    ngOnChanges( changes:SimpleChanges ) {
        if ( 'options' in changes ) {
            this.updateButtonLabel( this.options?.filter( x => x.selected ) ?? [] );
            this.buildControlsArray(this.options);
        } else if ( 'buttonLabel' in changes ) {
            this.updateButtonLabel( this.options?.filter( x => x.selected ) ?? [] );
        }
    }

    buildControlsArray(options: AldOptionItem<ItemType>[]) {
        this.checkboxes.clear();

        options.forEach((option) => {
            if (this.selectedOptions && this.selectedOptions.find((selected) => selected.id === option.id)) {
                option.selected = true;
            }
            this.checkboxes.push(this.formBuilder.control(option.selected));
        });
    }

    writeValue(options: AldOptionItem<ItemType>[]): void {
        if (options) {
            this.selectedOptions = options;
        } else {
            this.selectedOptions = [];
        }
        this.buildControlsArray(this.options);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Updates the selected options, triggers on onChange and closes the dropdown
     */
    submitSelectedOptions() {
        this.checkboxes.value.forEach((checked, index) => {
            this.options[index].selected = checked;
        });

        this.selectedOptions = this.options.filter( o => o.selected );
        this.onChange(this.selectedOptions);
        this.didSelect.emit( this.selectedOptions );
        this.didSelectValues.emit( this.selectedOptions.map( o => o.value ) );

        if (this.searchControl.value) {
            this.searchControl.setValue('');
        }

        this.multiSelectDropdown.close();

        this.updateButtonLabel( this.selectedOptions );
    }

    /**
     * Reset (clear selected options) and submit the form.
     */
    clearSelectedOptions(shouldSubmit = true) {
        this.searchControl.setValue('');
        this.options.forEach((e, index) => {
            this.checkboxes.controls[index].setValue(e.selected);
        });
        shouldSubmit && this.checkboxes.reset();
        shouldSubmit && this.submitSelectedOptions();
    }

    /**
     * Catch the event when the dropdown opens
     */
    didDropdown() {
        if (this.search && this.autofocusSearch) {
            this.searchInputComponent.setFocus();
        }
    }

    /**
     * Update the button label
     */
    updateButtonLabel( selection:AldOptionItem<ItemType>[] ) {
        if ( typeof( this.buttonLabel ) === 'function' ) {
            this.buttonLabelText = this.buttonLabel( this.id || 'none', selection );
        } else if ( typeof( this.buttonLabel ) === 'string' ) {
            this.buttonLabelText = this.buttonLabel;
        }
    }
}
