import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';
import { ToastConfig } from './types';


export class ToastRef {

    private afterClosingSubject$ = new Subject<any>();
    private primaryActionSubject$ = new Subject<any>();
    private secondaryActionSubject$ = new Subject<any>();

    constructor(
        private overlayRef: OverlayRef,
        public toastConfig: ToastConfig
    ) {

        if (this.toastConfig.closeOnEscapeKey) {
            overlayRef.keydownEvents().subscribe(event => {
                if (event.key === "Escape") this.close()
            });
        }

        if (this.toastConfig.closeOnBackdropClick) {
            overlayRef.backdropClick().subscribe(() => {
                this.close();
            });
        }
    }

    public close(result?: any) {
        this.overlayRef.dispose();
        this.afterClosingSubject$.next(result);
        this.afterClosingSubject$.complete();
        this.primaryActionSubject$.complete();
        this.secondaryActionSubject$.complete();
    }

    public afterClosed(): Observable<any> {
        return this.afterClosingSubject$.asObservable();
    }

    public didPrimaryAction() {
        this.primaryActionSubject$.next();
    }

    public primaryAction(): Observable<any> {
        return this.primaryActionSubject$.asObservable();
    }

    public didSecondaryAction() {
        this.secondaryActionSubject$.next();
    }

    public secondaryAction(): Observable<any> {
        return this.secondaryActionSubject$.asObservable();
    }
}
