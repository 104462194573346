/*
 *  AIMS/Auth0 Route Guard.
 *
 *  @author McNielsen <knielsen@alertlogic.com>
 *  @author Sir Robert <robert.parker@alertlogic.com>
 *  @copyright 2019 Alert Logic, Inc.
 *
 */

import  Keycloak from 'keycloak-js';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import {
    AlSession, 
    AlCabinet, 
    AlConduitClient, 
    AlErrorHandler, 
    AlLocatorService, 
    AlLocation, 
    AlAuthenticationUtility, 
    AlDefaultClient, 
    AlStopwatch, 
    APIRequestParams, 
    FortraSession
} from '@al/core';
import { AlTrackingMetricEventCategory, AlTrackingMetricEventName } from '@al/ng-generic-components';
import { AlNavigationService } from './al-navigation.service';
import { AlSessionManagerService } from './al-session-manager.service';
import { AlNavigationApplicationError } from '../types/navigation.types';

@Injectable({
    providedIn: 'root'
})
export class AlIdentityResolutionGuard implements CanActivate {
    protected resolver$: Observable<boolean>;
    protected conduit = new AlConduitClient();
    protected storage = AlCabinet.persistent("alnav");

    constructor( public navigation: AlNavigationService, public detector:AlSessionManagerService ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

        if (this.resolver$) {
            return this.resolver$;
        }

        this.resolver$ = new Observable<boolean>( (observer: Subscriber<boolean>) => {
            this.findSession( observer, route );
        } );

        return this.resolver$;
    }

    async findSession( observer:Subscriber<boolean>, route:ActivatedRouteSnapshot ) {
        const returnURL = 'return' in route.queryParams ? route.queryParams.return : undefined;
        const activeDatacenterId = route.queryParams.hasOwnProperty("locid") ? route.queryParams["locid"] : undefined;
        const actingAccountId = route.queryParams.hasOwnProperty("aaid") ? route.queryParams["aaid"] : undefined;
        if ( `ignore_session` in route.queryParams ) {
            await this.detector.destroySession( false );
        }
        if ( `aims_token` in route.queryParams ) {
            const options = { actingAccount: actingAccountId, locationId: activeDatacenterId };
            try {
                await AlSession.authenticateWithAccessToken( route.queryParams['aims_token'], options );
                await this.conduit.setSession( AlSession.getSession() );
                return this.onResolved( observer );
            } catch( error ) {
                console.error( `Failed to switch identity to provided aims_token; ignoring `, error );
            }
        }
        try {
            const sessionFound = await this.detector.detectSession();
            if (sessionFound) {
                if ( AlSession.getFortraSession() ) {
                    //  Remember that the user has authenticated via fortra: we should prefer that login entry point in the future
                    this.storage.set("fortra_authenticated", true ).synchronize();
                }
                if ( activeDatacenterId && activeDatacenterId !== AlSession.getActiveDatacenter() ) {
                    AlSession.setActiveDatacenter(route.queryParams["locid"]);
                }
                if ( actingAccountId && ( actingAccountId !== AlSession.getActingAccountId() ) ) {
                    let result = await this.navigation.setActingAccount(actingAccountId );
                    if ( ! result && actingAccountId !== AlSession.getPrimaryAccountId() ) {
                        result = await this.navigation.setActingAccount( AlSession.getPrimaryAccountId() );
                    }
                    if ( ! result ) {
                        return this.onUnauthenticatedAccess(observer, `Could not set acting account to ${actingAccountId}`, returnURL );
                    }
                }
            } else {
                return this.onUnauthenticatedAccess(observer, 'The user does not appear to be authenticated; no session detected.', returnURL );
            }
        } catch (e) {
            return this.onUnauthenticatedAccess(observer, "Received unexpected error while detecting session state." + e.toString(), returnURL );
        }
        this.onResolved( observer );

        return this.resolver$;
    }

    onResolved(observer: Subscriber<boolean>) {
        observer.next(true);
        observer.complete();
        this.resolver$ = undefined;
    }

    onUnauthenticatedAccess(observer: Subscriber<boolean>, reason: string, returnURL?:string ) {
        let authenticationAttempts = this.storage.get("auth_attempts", 0 );
        if ( authenticationAttempts >= 2 ) {
            const errorText = `It looks like we're having a hard time logging you in. `
                                + `This could mean that your browser's security settings are forbidding the use of 3rd party cookies on this domain (${window.location.origin}), `
                                + `or that the URL you are using to access the console is malformed or incomplete, `
                                + `or that there is a service or network outage. `
                                + `If it persists, please create a support ticket and include your username or email and the URL you are trying to access.`;
            this.navigation.events.trigger( new AlNavigationApplicationError( "Session Persistence Error", errorText, "report_problem" ) );
            this.storage.delete("auth_attempts").synchronize();
            return;
        }
        this.storage.set( "auth_attempts", authenticationAttempts + 1, 20 ).synchronize();
        returnURL = returnURL || window.location.href;
        AlErrorHandler.log("AlIdentityResolutionGuard: refusing access to route: " + (reason ? reason : "No reason specified"));
        observer.next(false);
        observer.complete();
        this.resolver$ = undefined;
        this.navigation.forceAuthentication( returnURL );
    }
}

