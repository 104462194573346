<al-layout-options [disableHeader]="true" [disablePrimaryMenu]="true" pageClass="white"></al-layout-options>
<div class="authentication-view">
    <div class="authentication-view__contents">
        <div *ngIf="ready">
            <div class="header">
                <div class="circle-logo"><i class="icon-logo al al-logo"></i></div>
                <div class="title"><h2>Terms of Service</h2></div>
            </div>

            <al-view-helper [loading]="!ready" [blur]="true"></al-view-helper>

            <div class="u-grid">
                <ng-container *ngIf="contentType === 'text'">
                    <div *ngIf="context === 'accept'" class="u-col-12 legalese u-py-10">
                        By clicking CONTINUE, you agree to the applicable end user customer terms governing use of the
                        services, which are available at <a href="{{authenticator.state.termsOfServiceURL}}" target="_blank">https://www.alertlogic.com/company/legal/terms</a>.
                        If you are using the services on behalf of a company, you are agreeing on behalf of that company.
                        If you or your company have entered into a separate written agreement with Alert Logic or an Alert Logic reseller governing use of the services,
                        then those terms control to the extent they conflict with the linked terms above.
                    </div>
                    <div *ngIf="context === 'reaccept'" class="u-col-12 legalese u-py-10">
                        Alert Logic has updated its Master Terms and Conditions for End User Customers available at,
                        and effective as of the date listed at,
                        <a href="{{authenticator.state.termsOfServiceURL}}" target="_blank">Terms of Use for Products and Services - Alert Logic.</a>
                        By clicking CONTINUE, you agree to the modified terms as of the effective date.
                        If you are using the services on behalf of a company, you are agreeing on behalf of that company.
                        If you or your company have entered into a separate written agreement with Alert Logic or
                        an Alert Logic reseller governing use of the services, then those terms control to the extent they conflict with the linked terms above.
                    </div>
                </ng-container>
                <ng-container *ngIf="contentType === 'html'">
                    <div [innerHtml]="contentAsHtml"></div>
                </ng-container>
                <div class="u-col-12 u-grid u-px-5">
                    <div class="u-col u-pl-10">
                        <p-checkbox [(ngModel)]="doAccept" [binary]="true" class="u-pl-4"></p-checkbox>
                        <label>I accept</label>
                    </div>
                    <div class="u-col">
                        <button pButton type="button" class="primaryAction" label="CONTINUE" [disabled]="!doAccept" (click)="doAcceptOrSkipTerms()"></button>
                    </div>
                    <div class="u-col u-pl-10" *ngIf="context === 'reaccept' && !hideSkipButton">
                        <button pButton pTooltip="{{skipTOSdeferralMessage}}" type="button" label="SKIP" (click)="doAcceptOrSkipTerms(false)"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
