/**
 * An empty (control-less) form element that can inject HTML, text, or markdown into
 * the DOM.
 *
 * @author Kevin <knielsen@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2021
 */

import { DomSanitizer } from '@angular/platform-browser';
import { AlExternalContentManagerService } from '@al/ng-generic-components';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementContent extends AlFormElementBase<any> {
    public controlTemplate = "content";

    constructor(public properties: AlDynamicFormElementDescriptor, public contentManager:AlExternalContentManagerService, public sanitizer:DomSanitizer) {
        super(properties);
        if ( typeof( this.value ) === 'string' ) {
            if ( this.contentManager.isValidResource( this.value ) ) {
                this.view.externalResourceId = this.value;
            } else {
                this.view.htmlContent = this.sanitizer.bypassSecurityTrustHtml( this.value );
            }
        } else {
            this.usage();
        }
    }

    public setContent( htmlString:string ) {
        this.view.htmlContent = this.sanitizer.bypassSecurityTrustHtml( htmlString );
    }

    public setResource( resourceId:string ) {
        this.view.externalResourceId = resourceId;
    }

    public createFormControl():undefined {
        return undefined;
    }

    public getAnswer():any {
        return undefined;
    }

    usage() {
        console.warn( `Content elements must have a value of type string that either refers to an external content resource or is an HTML-izable string.` );
    }
}
