import {
    Component, 
    Input, 
    Output, 
    OnChanges, 
    SimpleChanges, 
    TemplateRef, 
    EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { AlRoute } from '@al/core';
import { AlNavigationService } from '../../services/al-navigation.service';


@Component({
    selector: 'al-tertiary-menu',
    templateUrl: './al-tertiary-menu.component.html',
    styleUrls: [ './al-tertiary-menu.component.scss' ]
})

export class AlTertiaryMenuComponent implements OnChanges
{
    @Input()
    public visible              =   true;

    @Input()
    menu:AlRoute                =   null;

    @Input()
    customSidenavRef:TemplateRef<any>   =   null;

    @Input()
    customSidenavBelowRef:TemplateRef<any>   =   null;

    @Input()
    showQuaternaryMenu          =   true;

    @Output()
    changed                     =   new EventEmitter<boolean>();

    public activeTabs:AlRoute   =   null;

    constructor(public router:Router,
                public alNavigation:AlNavigationService) {

    }

    ngOnChanges( changes:SimpleChanges ) {
        this.activeTabs = null;
    }

    onClick( menuItem:AlRoute, $event:any ) {
        if ( $event ) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        menuItem.dispatch();
    }

    onHoverStart( menuItem:AlRoute ) {
        menuItem.refresh( true );
    }

    setMenuItemClasses( route:AlRoute, hasCuartoTab = false ) {
        const classes = [ route.getProperty("css_class", "default" ) ];
        if ( route.activated ) {
            classes.push( 'active' );
        }
        if ( ! route.activated ) {
            classes.push( "disabled" );
        }
        if ( hasCuartoTab ) {
            classes.push( "cuarto-tab" );
        }

        classes.push( route.definition.id ? route.definition.id.replace( /:/g, "_" ) : route.caption.replace( /\s/g, "_" ) );
        route.setProperty( "consolidated_css_classes", classes.join(" " ) );
    }

    onLocationChange = () => {
        this.activeTabs = null;
        if ( ! this.menu ) {
            return;
        }
        this.menu.children.forEach( child => {
            this.setMenuItemClasses( child );
            if ( child.activated ) {
                this.activeTabs = child;
                child.children.forEach( grandchild => this.setMenuItemClasses( grandchild, true ) );
            }
        } );
    }
}
