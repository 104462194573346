import {
    AfterViewInit, 
    Component, 
    ElementRef, 
    Input, 
    OnInit, 
    Optional, 
    Self, 
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';


export type InputType = 'text' | 'number' | 'email' | 'password' | 'range' | 'search' | 'time' | 'date' | 'datetime-local';

@Component({
    selector: 'ald-input',
    templateUrl: './ald-input.component.html',
    styleUrls: ['./ald-input.component.scss'],
})
export class AldInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement>;

    @Input() label = '';
    @Input() size?: 'sm' | 'md' = 'md';
    @Input() placeholder = '';
    @Input() type: InputType = 'text';
    @Input() name: string = this.label;
    @Input() id?: string;
    @Input() ariaLabelledBy?: string;
    @Input() disabled = false;
    @Input() autofocus = false; // no worky?
    @Input() value: any;
    @Input() required?: boolean = false;
    @Input() requiredErrorMessage?: string; // custom required error message
    @Input() validationPattern?: string;
    @Input() patternErrorMessage?: string; // custom pattern error message
    @Input() disableError = false; // disable the red outline on error
    @Input() disableErrorMessage = false; // disable the error message
    @Input() hint?: string;
    @Input() tip?: string;
    @Input() minValue?: number | string;
    @Input() maxValue?: number | string;
    @Input() minLength?: number;
    @Input() maxLength?: number;
    @Input() htmlSize?: number;
    @Input() hideSearchIcon?: boolean = false; // Special use case when the search by column feature is enabled.
    @Input() enableCustomBorder?: boolean = false; // custom border
    @Input() customTopLeftBorder?: number = 0; // custom border
    @Input() customBottomLeftBorder?: number = 0; // custom border
    @Input() customTopRightBorder?: number = 0; // custom border
    @Input() customBottomRightBorder?: number = 0; // custom border


    onChange = (val) => { return val; };
    onTouch = () => { return; };

    get control() {
        return this.ngControl?.control;
    }

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        if (this.type === 'search') {
            this.label = 'Search';
            this.id = 'search';
        } else {
            this.id = this.id || this.label.replace(/\s/g, '').toLowerCase();
        }

        if (this.autofocus) {
            this.onFocus();
        }
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            this.setFocus();
        }
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    clearSearch() {
        this.ngControl.control.setValue('');
        this.setFocus();
    }

    setFocus() {
        setTimeout(() => {
            this.input.nativeElement.focus();
            this.onFocus();
        });
    }

    onFocus() {
        this.onTouch();
    }

    /**
     * Creates a border style based on the custom border inputs.
     * @returns {String}- Border configuration i.e. 0px 3px 3px 0px.
     */
    public setCustomInputBorder():string {
        const borders: string[] = ['0px','0px','0px','0px'];
        if(this.customTopLeftBorder) {
            borders[0] = this.customTopLeftBorder+"px";
        }
        if(this.customTopRightBorder) {
            borders[1] = this.customTopRightBorder+"px";
        }
        if(this.customBottomRightBorder) {
            borders[2] = this.customBottomRightBorder+"px";
        }
        if(this.customBottomLeftBorder) {
            borders[3] = this.customBottomLeftBorder+"px";
        }
        return borders.join(" ");
    }
}
