<div class="ald-tree-table">
    <div class="ald-tree-table__body">
        <table class="ald-tree-table__el">
            <thead class="ald-tree-table__thead">
                <tr>
                    <th
                        class="ald-tree-table__th"
                        *ngFor="let th of columns; let isFirst = first"
                        [ngClass]="isFirst ? 'u-w-100%' : ''">
                        <div *ngIf="isFirst; else thCell" class="ald-tree-table__th-left">
                            <ald-button
                                *ngIf="isFirst && enableToggleAllRows"
                                (click)="toggleAll($event, filteredData)"
                                variant="unstyled"
                                size="xs"
                                [icon]="expanded ? 'unfold_less' : 'unfold_more'">
                            </ald-button>
                            <span class="u-ml-1 u-my-1">{{ th.header }}</span>
                        </div>
                        <ng-template #thCell>
                            <div class="ald-tree-table__th-right" [title]="th.headerTitle ? th.headerTitle : ''">
                                <ald-icon
                                    *ngIf="th.headerIcon"
                                    size="sm"
                                    [iconClass]="th.headerIconClass"
                                    [icon]="th.headerIcon">
                                </ald-icon>
                                <div *ngIf="th.header" [ngClass]="th.headerIcon ? 'u-ml-05' : ''">
                                    {{ th.header }}
                                </div>
                            </div>
                        </ng-template>
                    </th>
                </tr>
            </thead>
            <tbody class="ald-tree-table__tbody">
                <ng-container *ngFor="let item of filteredData; let index = index;">
                    <ng-container *ngTemplateOutlet="aldTableRow; context: {index: index, item: item, depth: 0}"></ng-container>
                </ng-container>
                <!-- NO DATA -->
                <tr *ngIf="filteredData.length === 0">
                    <td colspan="100%">
                        <div class="ald-tree-table__empty">
                            No data to show or it is not available.
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <ng-template #aldTableRow let-index="index" let-node="item" let-depth="depth">
            <tr class="ald-tree-table__row" [attr.id]="node.id"
                [ngClass]="[node?.selected ? 'ald-tree-table__row--selected': '']"
                tabindex="0"
                (click)="selectRow($any($event), node, index, depth)"
                (keyup.enter)="selectRow($any($event), node, index, depth)">
                <ng-container *ngFor="let col of columns; let isFirst = first">
                    <!-- FIRST COLUMN ONLY -->
                    <td *ngIf="isFirst" class="ald-tree-table__td">
                        <div class="" [style.padding-left.px]="depth * 8">
                            <div class="ald-tree-table__toggle-wrapper">
                                <div class="ald-tree-table__btn">
                                    <ald-button
                                        *ngIf="node.items?.length > 0"
                                        (click)="toggleExpand($event, node)"
                                        variant="unstyled"
                                        size="xs"
                                        [icon]="node.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'">
                                    </ald-button>
                                </div>
                                <div class="ald-tree-table__name">
                                    <div *ngIf="col.cellConfig?.icon" class="ald-tree-table__name-icon">
                                        <ald-icon
                                            size="sm"
                                            [icon]="getIcon(node, col).name"
                                            [title]="getIcon(node, col).title || null"
                                            [iconClass]="getIcon(node, col).iconClass || null"
                                        ></ald-icon>
                                    </div>
                                    <div *ngIf="col.field" class="ald-tree-table__name-value">
                                        {{ node | aldObjectValue: col.field }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>

                    <!-- OTHER COLUMNS ONLY -->
                    <td *ngIf="!isFirst" class="ald-tree-table__td">
                        <div class="ald-tree-table__col-wrap" [ngClass]="(col.cellConfig?.format === 'number') ? 'u-justify-end' : ''">
                            <ald-icon
                                *ngIf="col.cellConfig?.icon"
                                size="sm"
                                [icon]="getIcon(node, col).name"
                                [title]="getIcon(node, col).title || ''"
                                [color]="getIcon(node, col).color || ''"
                                [iconClass]="getIcon(node, col).iconClass || 'material-icons'"
                            ></ald-icon>
                            <ng-container *ngIf="col.field">
                                {{ node | aldObjectValue: col.field }}
                            </ng-container>
                            <ald-badge
                                *ngIf="col.cellConfig?.badge && getBadge(node, col).label"
                                size="sm"
                                [label]="getBadge(node, col).label"
                                [variant]="getBadge(node, col).variant"
                                [icon]="getBadge(node, col).icon"
                                [iconClass]="getBadge(node, col).iconClass"
                                [lowContrast]="getBadge(node, col).lowContrast"
                                [title]="getBadge(node, col).title">
                            </ald-badge>
                        </div>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="node.expanded">
                <ng-container *ngFor="let item of node.items; let index = index">
                    <ng-container
                        *ngTemplateOutlet="aldTableRow; context: {index: index, item: item, depth: depth + 1}">
                    </ng-container>
                </ng-container>
            </ng-container>

        </ng-template>
    </div>
</div>
