import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlNavigationService } from '../../services/al-navigation.service';


@Component({
    selector: 'al-menu-trigger',
    templateUrl: './al-menu-trigger.component.html',
    styleUrls: ['./al-menu-trigger.component.scss']
})

export class AlArchipeligo19MenuTriggerComponent {

    @Input() hideLogo: boolean = false;
    @Output() toggleButtonClick: EventEmitter<any> = new EventEmitter();

    constructor(public alNavigation: AlNavigationService) {
    }

    redirectToDashboards() {
        this.alNavigation.navigate.byNamedRoute("cd19:dashboards");
    }

    toggleClick() {
        this.toggleButtonClick.emit();
    }

}
