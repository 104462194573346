/**
 * Allows for groups of checkbox controls, eaching corresponding to separate values.
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { FormGroup, FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementCheckboxGroup extends AlFormElementBase<any[]>
{
    public controlTemplate = "checkboxGroup";
    public controlSet:FormGroup;
    public lastValue:any;
    public lastHash:string = '';

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
    }

    public createFormControl() {
        this.controlSet = new FormGroup({});
        if ( ! this.options ) {
            console.warn("Warning: checkboxGroup form element requires a list of options, and none were provided." );
            return null;
        } else {
            this.options.forEach( ( option ) => {
                let childValue = Array.isArray( this.value )
                                    ? this.value.some( v => v === option.value )      //  coerce to boolean
                                    : false;
                let childControl = new FormControl( childValue );
                if ( option.disabled ) {
                    childControl.disable();
                }
                this.controlSet.addControl( option.value, childControl );
            } );

            if(this.disabled) {
                this.controlSet.disable();
            }
            this.view.controls = this.controlSet.controls;      //  expose individual controls to template
            return this.controlSet;
        }
    }

    /**
     * The group's values can be extracted in one of several ways depending on the resulting data type.
     */
    getAnswer() {
        let formValues = this.controlSet.getRawValue();
        let valueHash = this.getStateHash( formValues );
        if ( valueHash === this.lastHash ) {
            return this.lastValue;
        }
        if ( this.dataType === 'object' ) {
            //  Return a dictionary of properties and their true/false state
            return this.saveValue( formValues, valueHash );      /* dictionary of true/false values */
        } else if ( this.dataType === 'string[]' || this.dataType === 'any[]' ) {
            //  Return an array of the keys that are enabled
            let value = Object.entries( formValues )
                        .filter( ( [ property, enabled ] ) => enabled )
                        .map( ( [ property, enabled ] ) => property );
            return this.saveValue( value, valueHash );
        } else if ( this.dataType === 'string' ) {
            let value = Object.entries( formValues )
                        .filter( ( [ property, enabled ] ) => enabled )
                        .map( ( [ property, enabled ] ) => property )
                        .join( this.joinExpression || "," );
            return this.saveValue( value, valueHash );
        } else {
            console.warn(`Warning: checkboxGroup form element cannot return data as '${this.dataType}'` );
            return null;
        }
    }

    /**
     * Converts the current state to a string form, so that we can return a reference to a known value if it doesn't change
     * (prevents unnecessary change-detection shenanigans)
     */
    protected getStateHash( rawValue:any ):string {
        return Object.entries( rawValue )
                    .map( ( [ property, value ]:[ string, any ] ) => `${property}=${value.toString()}` )
                    .join("&");
    }

    /**
     * Save a value object with its hash
     */
    protected saveValue( value:any, valueHash:string ) {
        this.lastValue = value;
        this.lastHash = valueHash;
        return value;
    }
}
