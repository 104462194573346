import { AxiosResponse } from 'axios';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AlLocatorService, AIMSClient } from '@al/core';
import { AlDynamicFormComponent, AlDynamicFormUtilityService, AlFormElementBase } from '@al/ng-forms-components';
import { AlViewHelperComponent } from '@al/ng-generic-components';


type State = 'sent' | 'forgot' | 'reset' | 'reset_with_token';

// forgot -> initiate-password-reset (email)
// reset -> reset-confirmation (old pass, new pass, confirm pass)
// reset_with_token -> password-reset (new pass, cofirm pass)

@Component({
    selector: 'al-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class AlPasswordResetComponent implements  AfterViewInit {

    @ViewChild("dynamicForm") dynamicForm: AlDynamicFormComponent;
    @ViewChild("viewHelper") viewHelper: AlViewHelperComponent;

    emailAddress: string;
    invalidParameters: boolean = false;
    newPasswordMatchedError: boolean = false;
    state: State = "forgot";
    returnUrl: string;
    dynamicFormElements: AlFormElementBase<any>[] = [];
    isDynamicFormValid: boolean = false;
    loading: boolean = true;
    backToLoginMessage: string = '';
    backToLoginHeaderTitle: string = '';
    resetText = '';
    private token: string;
    private  dynamicFormConfig: any[];
    private readonly forgotFormConfig = [
        {
            type: 'string/input',
            property: 'email',
            label: 'Enter your email address',
            validationPattern: "^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
            required: true,
            requiredError: 'Email is required',
            patternError: 'The entered email is not valid'
        }
    ];
    private readonly resetFormConfig = [
        {
            type: 'string/password',
            property: 'old_password',
            label: 'Old Password',
            required: true,
            requiredError: 'The old password is required'
        },
        {
            type: 'string/password',
            property: 'new_password',
            label: 'New Password',
            required: true,
            requiredError: 'The new password is required',
            minLength: 12
        },
        {
            type: 'string/password',
            property: 'confirm_new_password',
            label: 'Confirm New Password',
            required: true,
            requiredError: 'The confirmation password is required',
            minLength: 12
        }
    ];
    private readonly resetWithTokenFormConfig = [
        {
            type: 'string/password',
            property: 'new_password',
            label: 'New Password',
            required: true,
            requiredError: 'The new password is required',
            minLength: 12
        },
        {
            type: 'string/password',
            property: 'confirm_new_password',
            label: 'Confirm New Password',
            required: true,
            requiredError: 'The confirmation password is required',
            minLength: 12
        }
    ];


    constructor(
        public alDynamicFormUtilityService: AlDynamicFormUtilityService,
        public activatedRoute: ActivatedRoute) { }


    ngAfterViewInit(): void {
        this.initialize();
    }

    initialize(): void {
        combineLatest(
            [this.activatedRoute.params,
            this.activatedRoute.queryParams,
            this.activatedRoute.data]
        ).subscribe(([params, qParams, data]: [Params, Params, any]) => {
            this.loading = true;
            this.invalidParameters = false;
            this.viewHelper.cleanNotifications();
            if (data?.confirmChange) {
                this.token = params?.token ?? '';
                if (this.token) {
                    this.state = 'reset_with_token';
                } else {
                    this.state = 'reset';
                }
            } else {
                this.state = 'forgot';
            }
            this.returnUrl = qParams?.return_to ?? '';
            this.emailAddress = qParams?.email ?? '';
            if (this.state === 'reset' && !this.emailAddress ) {
                this.viewHelper.notifyError("Invalid parameters");
                this.invalidParameters = true;
            } else {
                this.setupForm();
            }
            this.loading = false;
        })
    }

    async onKeyDown(event: KeyboardEvent): Promise<void> {
        if (event.key === 'Enter' && this.isDynamicFormValid) {
            this.submit();
        }
    }

    async submit(): Promise<void> {
        switch (this.state) {
            case 'forgot':
                await this.submitEmail()
                break;
            case 'reset_with_token':
                this.submitPassword();
                break
            case 'reset':
                this.submitPassword();
                break;
        }
    }

    onDynamicFormChanges(): void {
        const { new_password, confirm_new_password } = this.getFormValues();
        const truthyPasswords: boolean = !!new_password && !!confirm_new_password;
        const passwordsMatched: boolean = new_password === confirm_new_password;
        this.newPasswordMatchedError = truthyPasswords && !passwordsMatched;
    }

    getFormValues(): { [i: string]: string } {
        return this.dynamicForm.getResult() as { [i: string]: string };
    }

    validate(isValid: boolean): void {
         this.isDynamicFormValid = isValid;
    }

    returnToLogin(): void {
        window.location.href = this.sanitizeRedirectUrl(this.returnUrl);
    }


    private sanitizeRedirectUrl(redirectUrl: string): string {
        let regexValidator = new RegExp('.+\.alertlogic\.com$');
        let belongsToAlertlogic = regexValidator.test(this.returnUrl);
        // whitelist redirect url before redirects
        // this validation needs to apply only in production because in dev/local
        // we use localhost:<port> domain
        if (!belongsToAlertlogic && this.isProductionEnv()) {
            return AlLocatorService.resolveURL("cd21:magma");
        }
        return redirectUrl;
    }

    private isProductionEnv(): boolean {
        return AlLocatorService.getContext().environment === 'production';
    }

    private manageErrors(error: string): void {
        let message = "An internal error occurred while saving your information. Please try again.";
        if (error === 'weak_password') {
            message = "You must choose a strong password. Please review our password policy and try again.";
        } else if (error === 'password_reuse_prevented') {
            message = "Cannot update user information, because the password was already used.";
        } else if (error === 'unauthorized') {
            message = "Alert Logic could not authenticate your credentials.";
        } else if (error === 'fortra_required') {
            message = "The requested user must log in with Fortra instead.";
        }

        this.viewHelper.notifyError(message);
    }

    private setupForm(): void {
        this.dynamicFormElements = [];
        this.resetText = ` Password must have at least 12 characters and contain uppercase and lowercase letters, numbers, and special characters.
                           The password cannot be identical to your current or previous four passwords.`;
        if (this.state === 'forgot') {
            this.resetText = ` Alert Logic provides a single log in for Cloud Defender and Cloud Insight.
                                Change your password to use the same credentials for both products.`;
            this.dynamicFormConfig = this.forgotFormConfig;
        } else if (this.state === 'reset') {
            this.dynamicFormConfig = this.resetFormConfig;
        } else if (this.state === 'reset_with_token') {
            this.dynamicFormConfig = this.resetWithTokenFormConfig;
        }
        this.dynamicFormConfig.forEach(configItem => {
            const base = this.alDynamicFormUtilityService.generateBaseProperties(configItem);
            const element = this.alDynamicFormUtilityService.generateDynamicElement(base, configItem.type);
            // Hack. The library drops the 'required' field so I need to set it manually again
            element.required = configItem.required;
            if (this.emailAddress && this.state === 'forgot') {
                element.value = this.emailAddress;
            }
            this.dynamicFormElements.push(element);
        });
    }

    private async submitEmail(): Promise<void> {
        this.loading = true;
        const { email } = this.getFormValues();
        this.emailAddress = email;
        let returnTo = this.returnUrl ? this.returnUrl : AlLocatorService.resolveURL("cd21:magma", "");
        if (!this.emailAddress) {
            this.manageErrors("Invalid username.");
            return;
        }
        if (!returnTo) {
            this.manageErrors("Invalid return to parameter.");
            return;
        }
        try {
            await AIMSClient.initiateReset(this.emailAddress, returnTo);
            this.state = "sent";
            this.viewHelper.cleanNotifications();
            this.backToLoginHeaderTitle = `Password email sent`;
            this.backToLoginMessage = `Alert Logic just sent an email to you.
                                        Open the email, and follow the instructions to reset your password.`;
        } catch (error: unknown) {
            this.state = "forgot";
            this.manageErrors((error as AxiosResponse).data.error);
        } finally {
            this.loading = false;
        }
    }

    private async submitPassword(): Promise<void> {
        this.loading = true;
        const { old_password, new_password } = this.getFormValues();
        try {
            if (this.state === 'reset_with_token') {
                await AIMSClient.resetWithToken(this.token, new_password);
            } else if (this.state === 'reset') {
                await AIMSClient.changePassword(this.emailAddress, old_password, new_password)
            }
            this.state = 'sent';
            this.backToLoginMessage = `Your password was reset.`;
            this.backToLoginHeaderTitle = "Success";
            this.viewHelper.cleanNotifications();
        } catch (error) {
            this.manageErrors((error as AxiosResponse).data.error);
        } finally {
            this.loading = false;
        }
    }

}
