/**
 * AlTemplateIndexService provides a central storage facility for templates which are projected across/outside
 * of component hierarchies.  While intended to be used directly in markup using the `alTemplate` directive, it can
 * also be interacted with programmatically.  See `al-template`'s [README.md](../directives/README.md) for more information.
 *
 * @copyright Alert Logic, Inc 2022
 * @author McNielsen <knielsen@alertlogic.com>
 */

import { Injectable, TemplateRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';


type TemplateDictionary = {[templateId:string]:TemplateRef<any>};

interface TemplateCategory {
    templates:TemplateDictionary;
    emitter:EventEmitter<TemplateDictionary>;
};

@Injectable( {
    providedIn: 'root',
} )
export class AlTemplateIndexService {

    protected categories:{[categoryId:string]:TemplateCategory} = {};

    constructor() {
    }

    /**
     * Publishes a template within a specific category and fires its change emitter.
     *
     * @categoryId The template's category, which should be a reference to its feature (e.g., "navigation", "header", etc.)
     * @templateId The identifier for the template (e.g., "aboveLogo" or "afterFooter")
     * @template The TemplateRef.
     */
    public publish( categoryId:string, templateId:string, template:TemplateRef<any> ) {
        const category = this.getCategory( categoryId );
        if ( ! ( templateId in category.templates ) || category.templates[templateId] !== template ) {
            category.templates[templateId] = template;
            category.emitter.emit( category.templates );
        }
    }

    /**
     * Retracts a template from a given category and fires its change emitter.
     *
     * @categoryId The template's category, which should be a reference to its feature (e.g., "navigation", "header", etc.)
     * @templateId The identifier for the template (e.g., "aboveLogo" or "afterFooter")
     */
    public retract( categoryId:string, templateId:string ) {
        const category = this.getCategory( categoryId );
        if ( templateId in category.templates ) {
            delete category.templates[templateId];
            category.emitter.emit( category.templates );
        }
    }

    /**
     * Retrieves a template associated with a given category and template identifier.
     */
    public getTemplate( categoryId:string, templateId:string ):TemplateRef<any>|undefined {
        const category = this.getCategory( categoryId );
        return templateId in category.templates ? category.templates[templateId] : undefined;
    }

    /**
     * Retrieves the EventEmitter for a given category.
     */
    public getEmitter( categoryId:string ):EventEmitter<TemplateDictionary> {
        const category = this.getCategory( categoryId );
        return category.emitter;
    }

    /**
     * Listens for changes to a given category.
     */
    public listen( categoryId:string, handler:{(templates:TemplateDictionary):void} ):Subscription {
        const category = this.getCategory( categoryId );
        return category.emitter.subscribe( handler );
    }


    protected getCategory( categoryId:string ):TemplateCategory {
        if ( ! ( categoryId in this.categories ) ) {
            this.categories[categoryId] = {
                templates: {},
                emitter: new EventEmitter<TemplateDictionary>()
            };
        }
        return this.categories[categoryId];
    }
}
