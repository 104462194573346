import { Input, Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng-lts/api';
import { AlToastService } from '../services/al-toast.service';
import { AlToastButtonDescriptor, AlToastMessage } from './types/al-toast.types';


@Component({
    selector: 'al-toast',
    templateUrl: './al-toast.component.html',
    styleUrls: ['./al-toast.component.scss']
})
export class AlToastComponent implements OnInit, OnDestroy {

    @Input() key: string;
    @Input() position?: string;

    private showEmiter: Subscription;
    private closeEmiter: Subscription;

    constructor(private alToastService: AlToastService,
        private messageService: MessageService) {
        if (!this.position) {
            this.position = 'bottom-center';
        }
    }

    ngOnInit() {
        this.showEmiter = this.alToastService.getShowEmitter(this.key).subscribe((message) => this.onShow(message));
        this.closeEmiter = this.alToastService.getCloseEmitter(this.key).subscribe(() => this.onClose());
    }

    ngOnDestroy() {
        if ( this.showEmiter ) {
            this.showEmiter.unsubscribe();
        }
        if ( this.closeEmiter ) {
            this.closeEmiter.unsubscribe();
        }
        this.alToastService.cleanEmitters(this.key);
    }

    public clickEvent(button:AlToastButtonDescriptor) {
        this.alToastService.emitButtonClicked(this.key, button);
    }

    private onShow = (alToastMessage: AlToastMessage) => {
        this.messageService.add(alToastMessage);
    }

    private onClose = () => {
        this.messageService.clear(this.key);
    }
}
