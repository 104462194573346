import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    Component, 
    TemplateRef, 
    HostBinding, 
    Input, 
    Output, 
    OnInit, 
    OnChanges, 
    AfterContentInit, 
    SimpleChanges, 
    QueryList, 
    ContentChildren, 
    EventEmitter, 
    ViewChild, 
    ViewEncapsulation
} from '@angular/core';
import { AlStopwatch } from '@al/core';
import { AlTemplateDirective } from '../directives/al-template.directive';


export interface DialogButtonDescriptor {
    label:string;
    icon?:string;
    iconClass?:string;
    variant?:string;
}

export interface DialogAction<DataType = any> {
    actionType:string;
    modal:AlDialogComponent<DataType>;
    data?:DataType;
}

@Component({
    selector: 'al-dialog',
    templateUrl: './al-dialog.component.html',
    styleUrls: ['./al-dialog.component.scss'],
})
export class AlDialogComponent<DataType = any> implements OnInit, AfterContentInit {

    @HostBinding('class') class = 'u-w-100%';
    @ViewChild('dialogTemplate') dialogPortal:TemplatePortal<any>;

    @ContentChildren(AlTemplateDirective) childTemplates!:QueryList<AlTemplateDirective>;

    @Input() public verticalPosition:'center'|'top' = 'center';
    @Input() public width:"xs"|"sm"|"md"|"lg" = "md";
    @Input() data?:DataType;

    /**
     * Inputs to control the default header of the dialog.  Ignored if a custom template is used.
     */
    @Input() headerIconClass:string = "material-icons";
    @Input() headerIcon?:string;
    @Input() headerTitle?:string;

    @Input() background:boolean = true;

    /**
     * Inputs to control the default footer and actions of the dialog.  Ignored if a custom template is used.
     */
    @Input() primaryAction?:string|DialogButtonDescriptor;
    @Input() primaryActionDisabled?:boolean;
    @Input() secondaryAction?:string|DialogButtonDescriptor;
    @Input() secondaryActionDisabled?:boolean;
    @Input() autoClose:boolean = true;

    @Output() onClose = new EventEmitter<DialogAction<DataType>>();
    @Output() onActionClick = new EventEmitter<DialogAction<DataType>>();

    public contentTpl?: TemplateRef<any>;  
    public footerTpl?: TemplateRef<any>;
    public headerTpl?: TemplateRef<any>;

    public modalBackgroundOpen: boolean;
    public primaryButton?:DialogButtonDescriptor;
    public secondaryButton?:DialogButtonDescriptor;

    protected overlayRef?:OverlayRef;

    constructor( public overlay:Overlay ) {
    }

    ngOnInit() {
    }

    ngOnChanges( changes:SimpleChanges ) {
        if ( 'primaryAction' in changes ) {
            this.primaryButton = this.normalizeAction( this.primaryAction );
        }
        if ( 'secondaryAction' in changes ) {
            this.secondaryButton = this.normalizeAction( this.secondaryAction );
        }
    }

    normalizeAction( action:DialogButtonDescriptor|string|undefined ):DialogButtonDescriptor|undefined {
        if ( typeof( action ) === 'string' ) {
            return { label: action };
        } else if ( typeof( action ) === 'object' && action !== null ) {
            return action;
        }
    }

    ngAfterContentInit() {
        this.childTemplates.forEach( child => {
            if ( child.templateId === "content" ) {
                this.contentTpl = child.template;
            } else if ( child.templateId === "footer" ) {
                this.footerTpl = child.template;
            } else if ( child.templateId === "header" ) {
                this.headerTpl = child.template;
            }
        } );
        this.modalBackgroundOpen = true;
    }

    /**
     * Open the dialog
     */
    public open( data?:DataType ) {
        this.modalBackgroundOpen = true;
        const overlayConfig = new OverlayConfig( {
            hasBackdrop: false,
            backdropClass: [],
            panelClass: [],
            width: '100%',
            height: '100%'
        } );
        this.overlayRef = this.overlay.create( overlayConfig );
        this.overlayRef.backdropClick().subscribe( () => {
            this.onClose.emit( { actionType: 'close', modal: this, data: this.data } );
        } );
        this.overlayRef.keydownEvents().subscribe( event => {
            if ( event.key === 'Escape' ) {
                this.close();
            }
        } );
        this.overlayRef.attach( this.dialogPortal );
    }

    /**
     * Closes the modal
     */
    public close(event?: Event) {
        if ( event ) {
            event.stopPropagation();
        }

        // Starts the transition
        this.modalBackgroundOpen = false;
        AlStopwatch.once( () => {
            this.onClose.emit( { actionType: 'close', modal: this, data: this.data } );
            this.overlayRef.dispose();
        }, 150 );
    }

    /**
     * Emits an action to the host, passing a reference to itself for convenience.
     */
    public emitAction( actionType:string ) {
        const modal = this;
        this.onActionClick.emit( { actionType, modal, data: this.data } );
        if ( this.autoClose ) {
            this.close();
        }
    }

}
