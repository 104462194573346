<div class="al-menu-trigger">

  <button (click)="toggleClick()" class="al-menu-trigger__button" data-cy="menu-trigger">
    <i class="material-icons">menu</i>
  </button>

  <a href="" class="al-menu-trigger__image" (click)="redirectToDashboards()" *ngIf="!hideLogo">

    <img width="125" src="/assets/images/logos/al-mdr-logo.svg" alt="Alert Logic logo" class="al-logo">
    <div class="u-flex">
      <img width="125" src="/assets/images/logos/fta-tm.svg" alt="Alert Logic logo" class="al-fta-logo">
      <h6 class="al-fta-name">Alert Logic</h6>
    </div>

    
  </a>
</div>
