<!-- We change the "modal" property to true so that the dismissible property works correctly.
    Please check the SCSS _overlay.scss in ng-generic-components -->
<p-sidebar #navSideBar [(visible)]="menu && menu.visible && displayNav"
    (onHide)="hideSideBar()"
    styleClass="al-sidenav"
    [modal]="true"
    [showCloseIcon]="false"
    [baseZIndex]="1000"
    [autoZIndex]="false"
    [style]="{width:'280px'}" 
    [dismissible]="true">
    <div class="sidenav-container">
        <div class="al-sidenav-sticky-header">
            <al-menu-trigger (toggleButtonClick)="toggleClick()" [hideLogo]="hideSidenavLogo"></al-menu-trigger>
        </div>    
        <div class="sidenav-items" *ngIf="menu && menu.visible">
            <div class="top-items">
                <ng-template ngFor let-menuItem [ngForOf]="menu.children">
                    <div class="menu-item-container" *ngIf="menuItem.visible && !menuItem.properties.atEndSideNav && !menuItem.properties.isHeaderAction">
                        <div class="menu-item-parent"
                            *ngIf="(menuItem.properties.hasOwnProperty('visible') && menuItem.properties.visible) || !menuItem.properties.hasOwnProperty('visible')"
                            [class.highlight]="menuItem.caption === 'Dashboards'" (click)="dispatchParent(menuItem,$event);">
                            <i class="material-icons">{{menuItem.properties.iconClass}}</i>
                            <span [class.active]="menuItem.activated">
                                <div *ngIf="!menuItem.href">{{menuItem.caption}}</div>
                                <a *ngIf="menuItem.href" [href]="menuItem.href">{{menuItem.caption}}</a>
                            </span>
                        </div>
                        <div class="menu-item-children-container" [hidden]="!menuItem.properties.expanded" *ngIf="menuItem.children">
                            <ng-template ngFor let-childItem [ngForOf]="menuItem.children">
                                <div class="menu-item-child" *ngIf="childItem.visible && !childItem.properties.isHeaderAction" [class.active]="childItem.activated">
                                    <span (click)="dispatch(childItem,$event)">
                                        <a [href]="childItem.href" [target]="childItem.properties.target ? childItem.properties.target : ''"
                                            >{{childItem.caption}}
                                            <ald-badge
                                              *ngIf="childItem.properties.isBeta"
                                              class="u-font-normal u-ml-3"
                                              label="BETA"
                                              variant="info"
                                              size="sm"
                                              icon="science"
                                              iconClass="material-icons-outlined"
                                              [lowContrast]="true"
                                          >
                                          </ald-badge>
                                  </a>
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="bottom-elements">
                <ng-template ngFor let-menuItem [ngForOf]="menu.children">
                    <div class="menu-item-parent" *ngIf="menuItem.visible && menuItem.properties.atEndSideNav">
                        <i class="material-icons">{{menuItem.properties.iconClass}}</i>
                        <span [class.active]="menuItem.activated" (click)="dispatch(menuItem,$event,true)">
                            <ng-template  *ngIf="!menuItem.href">{{menuItem.caption}}</ng-template>
                            <a *ngIf="menuItem.href" [href]="menuItem.href">{{menuItem.caption}}</a>
                        </span>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</p-sidebar>
