/**
 * It allows the construction of an multi select list
 *
 * @author Maryit <msanchez@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2021
 */

import { FormControl } from '@angular/forms';
import { AlSelectItem } from '@al/ng-generic-components';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementMultiSelectList<DataType=string> extends AlFormElementBase<DataType> {

    public controlTemplate = "multiSelectList";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
        if (!AlFormElementBase.isInstance(properties)) {
            this.setMultiSelectOptions(properties);
        } else {
            this.multiSelectOptions = properties.multiSelectOptions;
            this.value = properties.value;
        }
    }

    public createFormControl() {
        let formControl = new FormControl(this.value || '');

        if(this.disabled) {
            formControl.disable();
        }

        return formControl;
    }

    getAnswer(): any {
        let value = this.getControlValue();
        if (Array.isArray(value)){
            return value.map(val => val?.value);
        }
    }

    private setMultiSelectOptions(properties: AlDynamicFormElementDescriptor) {
        const multiSelectOptions = properties.multiSelectOptions as {label: string, value: any, description?: string}[];
        this.multiSelectOptions = this.getMultiSelectOptions(multiSelectOptions);

        // get and set initial values
        let defaultValue: any = [];
        if (Array.isArray(properties.defaultValue)) {
            properties.defaultValue.forEach((value) => {
                let foundValue = multiSelectOptions.find((option) => JSON.stringify(option.value) === JSON.stringify(value));
                if (foundValue) {
                    defaultValue.push(foundValue);
                } else {
                    console.error(`${properties.label}: ${value} not found in options`);
                }
            });
        }
        defaultValue = this.getMultiSelectOptions(defaultValue);
        this.value = defaultValue.map((x:any) => x?.value);
    }

    private getMultiSelectOptions(options: { label: string, value: any, description?: string }[]): AlSelectItem[] {
        let multiSelectOptions: AlSelectItem[] = [];
        if (options) {
            options.forEach((item) => {
                let option: AlSelectItem = {
                    title: item.label,
                    subtitle: item?.description ?? "",
                    value: {
                        title: item.label,
                        subtitle: item?.description ?? "",
                        value: item.value,
                        checked: true
                    }
                };
                multiSelectOptions.push(option);
            });
        }
        return multiSelectOptions;
    }

}
