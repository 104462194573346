import { Component, Input } from '@angular/core';


@Component({
    selector: 'al-data-field',
    templateUrl: './al-data-field.component.html',
    styleUrls: ['./al-data-field.component.scss']
})
export class AlDataFieldComponent {
    @Input() label: string = 'Label';
    @Input() value: string | number = 'Value';
    @Input() inline: boolean = false;
    constructor() { }
}
