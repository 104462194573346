import { SelectionModel } from '@angular/cdk/collections';
import {
    Component, 
    EventEmitter, 
    Input, 
    OnDestroy, 
    OnInit, 
    Output, 
    ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AldOptionItem } from '@al/design-patterns/common';
import { AldDropdownComponent } from '@al/design-patterns/forms';
import { TableBulkActionsSelectionEvent } from '../types';


@Component({
    selector: 'ald-table-bulk-actions',
    templateUrl: './table-bulk-actions.component.html',
    styleUrls: ['./table-bulk-actions.component.scss']
})
export class AldTableBulkActionsComponent implements OnInit, OnDestroy {

    tableSelectionChangedSubscription: Subscription;

    @ViewChild('multiSelectActionMenu') multiSelectActionMenu: AldDropdownComponent;

    /** An array of actions (AldOptionItem[]) for applying to the multiple selected rows. */
    @Input() multiSelectActions?: AldOptionItem[] = []; // array of actions

    /** This is the count of selected (checked rows) - this determines whether to show (if > 0) the bulk actions component,
     * uses the count on the Bulk Action button,
     * and (if enabled) determines if the select all results button should show.
     * */
    @Input() selectedRowCount: number;

    /** Total number of results is used to determine whether all the results are selected, it is compared to the selectedRowCount input. */
    @Input() totalResults?: number;

    /** Enables the option to allow a user to select all results beyond just the current page of results */
    @Input() enableSelectAllResults?: boolean = true;

    @Input() tableSelectionModel?: SelectionModel<any>;

    /**
     * When a user selects a bulk action from the menu, this returns the selected action (AldOptionItem, and the index of that action).
     */
    @Output() didActionMultiItems: EventEmitter<TableBulkActionsSelectionEvent> = new EventEmitter();

    @Output() toggleCheckAllResults: EventEmitter<boolean> = new EventEmitter();

    @Output() toggleBulkActionButton: EventEmitter<void> = new EventEmitter();

    ngOnInit(): void {
        this.tableSelectionChangedSubscription = this.tableSelectionModel?.changed.subscribe(s => {
            this.selectedRowCount = s.source.selected.length;
        });
    }

    ngOnDestroy(): void {
        this.tableSelectionChangedSubscription?.unsubscribe();
    }

    /** selected bulk action */
    public actionMultiItems(actionMultiItemsEvent: { option: AldOptionItem, index: number}) {
        this.didActionMultiItems.emit({option: actionMultiItemsEvent.option, selections: this.tableSelectionModel.selected});
        this.multiSelectActionMenu.close();
    }

    public toggleSelectAllResults(check: boolean) {
        this.selectedRowCount = check ? this.totalResults : this.selectedRowCount;
        this.toggleCheckAllResults.emit(check);
    }

    public toggleBulkActions() {
        this.toggleBulkActionButton.emit();
    }
}
