/**
 * Know the elements to build (input, hidden, password and textarea)
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 * @author Maryit
 *
 * @copyright Alert Logic, Inc 2020
 */

import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlFormElementBase } from '../../types/al-form-element-base';


@Component({
    selector: 'al-dynamic-form-element',
    templateUrl: './al-dynamic-form-element.component.html',
    styleUrls: ['./al-dynamic-form-element.component.scss']
})
export class AlDynamicFormElementComponent implements OnInit {
    @Input() element: AlFormElementBase<string>;
    @Input() form: FormGroup;
    @Input() customTemplate?:TemplateRef<any>;

    ngOnInit() {
    }

    get isValid() {
        return this.form.controls[this.element.property].valid;
    }

    showMinLengthError(){
        return this.element.minLength
        && this.form.controls[this.element.property].hasError('minlength')
        && (this.form.controls[this.element.property].dirty
            || this.form.controls[this.element.property].touched);
    }

    showMaxLengthError(){
        return this.element.maxLength
        && this.form.controls[this.element.property].hasError('maxlength')
        && (this.form.controls[this.element.property].dirty
            || this.form.controls[this.element.property].touched);
    }

    showIsRequireError(){
        return this.element.required
        && this.form.controls[this.element.property].hasError('required')
        && (this.form.controls[this.element.property].dirty
            || this.form.controls[this.element.property].touched);
    }

    showPatternError(){
        return this.element.validationPattern
        && this.form.controls[this.element.property].hasError('pattern')
        && (this.form.controls[this.element.property].dirty
             || this.form.controls[this.element.property].touched);
    }
}
