import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'alMitreLabelPipe'
})
export class AlMitreLabelPipe implements PipeTransform {
    transform(data?: any|any[]): string {
        if ( ! Array.isArray( data ) ) {
            data = [ data ];
        }
        if (data && data?.length === 1) {
            return data.map(x => x?.key).join(", ");
        } else if (data && data.length > 1) {
            // Count number of leaf nodes
            let count = data.reduce((acum, item) => {
                return !item?.children ? acum + 1 : acum;
            }, 0);
            return `${count} items selected`;
        }
        return data?.map(x => x?.label).join(', ') ?? '';
    }
}
