import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'alARStatus'
})
export class AlARStatusPipe implements PipeTransform {
    private statusMap:{ [key:string]:string } = {
        blocked: 'Succeeded',
        blocking: 'Running',
        start_blocking: 'Running',
        unblocking: 'Reverting',
        timeout_blocking: 'Response Timed Out',
        block_fail: 'Failed',
        block_failed: 'Failed',
        unblocked: 'Reverted',
        timeout_unblocking: 'Reversion Timed Out',
        unblock_fail: 'Reversion Failed',
        unblock_failed: 'Reversion Failed',
        wait: 'Action Pending'
    };
    transform( status:string ):string {
        return status && this.statusMap.hasOwnProperty(status) ? this.statusMap[status] : status;
    }
}

@Pipe({
    name: 'alARStatusClass'
})
export class AlARStatusClassPipe implements PipeTransform {
    transform( status:string ):string {
        let badgeClass = "risk-low";
        badgeClass = status.toLocaleLowerCase() == 'succeeded' ? "success" : badgeClass;
        badgeClass = ["Failed", "Reversion Timed Out", "Reversion Failed", "Response Timed Out"].includes(status) ? "error" : badgeClass;
        return badgeClass;
    }
}
