/**
 * @author Fair Tarapues <fair.tarapues@alertlogic.com>
 * @copyright Alert Logic, Inc 2022
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import {
    AlAuthenticationResult, 
    AlAuthenticationUtility, 
    AlCabinet, 
    AlConduitClient, 
    AlErrorHandler, 
    AlLocation, 
    AlLocatorService, 
    AlSession, 
    AlStopwatch
} from '@al/core';
import { AlExternalContentManagerService, AlViewHelperComponent } from '@al/ng-generic-components';
import { AlNavigationService, AlSessionManagerService } from '@al/ng-navigation-components';


@Component({
    selector: 'fortra-authentication',
    templateUrl: './fortra-authentication.component.html',
    styleUrls: [ './fortra-authentication.component.scss' ]
})

export class FortraAuthenticationComponent implements OnInit {

    public ready = false;
    public fortraIdP = false;
    public fortraEligible = false;
    public unauthenticated = false;
    public splash = false;

    public authenticator = new AlAuthenticationUtility();
    protected abortTimer:AlStopwatch;
    protected storage = AlCabinet.persistent("alnav");

    @ViewChild(AlViewHelperComponent) public viewHelper:AlViewHelperComponent;

    constructor( public navigation:AlNavigationService, public sessionManager:AlSessionManagerService ) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    public retry() {
        this.initialize();
    }

    async initialize() {

        try {
            this.fortraIdP = false;
            this.fortraEligible = false;
            let active = await this.sessionManager.detectSession();
            if ( ! active ) {
                //  You must login before you can see this splash screen :)
                this.unauthenticated = true;
                return;
            }
            this.unauthenticated = false;

            const session = AlSession.getSession();
            if ( AlSession.getFortraSession() ) {
                this.storage.set("fortra_authenticated", true ).synchronize();
                this.fortraIdP = true;
            } 
            if ( session.authentication.account.fortra_authenticated ) {
                this.fortraEligible = true;
            }
            if ( this.navigation.queryParam("mode", "default" ) === "splash" ) {
                this.splash = true;
                this.fortraIdP = false;
                this.fortraEligible = true;
            }
        } catch( e ) {
            this.viewHelper.setError( e );
        } finally {
            this.ready = true;
        }
    }

    async useFortraAuthentication() {
        const userEmail = AlSession.isActive() ? AlSession.getUserEmail() : undefined;
        if ( AlSession.isActive() && ! AlSession.getFortraSession() ) {
            await this.sessionManager.destroySession( false );
        }
        this.sessionManager.forceFortraLogin( undefined, userEmail );
    }

    async useLegacyAuthentication() {
        this.storage.delete("fortra_authenticated" ).synchronize();
        this.storage.destroy();
        localStorage.clear();
        sessionStorage.clear();
        await AlStopwatch.promise( 2500 );
        if ( AlSession.isActive() && AlSession.getFortraSession() ) {
            await this.sessionManager.destroySession( false );
        }
        this.navigation.navigate.byNgRoute( [ '/login' ] );
    }

    async skipForNow() {
        let returnURL = this.navigation.queryParam("return", `/#/` );
        this.navigation.navigate.byURL( returnURL );
    }
}
