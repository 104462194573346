import { Overlay, OverlayRef, ViewportRuler } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import {
    AfterViewInit, 
    ChangeDetectorRef, 
    Component, 
    ElementRef, 
    EventEmitter, 
    HostListener, 
    Input, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    Optional, 
    Output, 
    QueryList, 
    Renderer2, 
    Self, 
    SimpleChanges, 
    ViewChild, 
    ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AldOptionItem } from '@al/design-patterns/common';
import { AldInputComponent } from '../ald-input/ald-input.component';


@Component({
    selector: 'ald-select',
    templateUrl: './ald-select.component.html',
    styleUrls: ['./ald-select.component.scss'],
})
export class AldSelectComponent<ValueType=string> implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() id?: string;
    @Input() label = '';
    @Input() name: string = this.label;
    @Input() disabled: boolean = false;
    @Input() required: boolean = false;
    @Input() size?: number = 0;
    @Input() hint?: string;
    @Input() tip?: string;
    @Input() requiredErrorMessage?: string;
    @Input() defaultOptions?: AldOptionItem<ValueType>[];
    @Input() options: AldOptionItem<ValueType>[] = [];
    @Input() value?:string|ValueType;                               //  This can be either the ID of the selected object, or the selected object itself
    @Input() enableCustomBorder?: boolean = false; // custom border
    @Input() customTopLeftBorder?: number = 0; // custom border
    @Input() customBottomLeftBorder?: number = 0; // custom border
    @Input() customTopRightBorder?: number = 0; // custom border
    @Input() customBottomRightBorder?: number = 0; // custom border

    @Output() didChange: EventEmitter<string>                       =   new EventEmitter<string>();
    @Output() didChangeValue: EventEmitter<ValueType>               =   new EventEmitter<ValueType>();

    @ViewChild('aldInput') aldInput: AldInputComponent;
    @ViewChild(CdkPortal) overlayContent: CdkPortal;
    @ViewChildren('options') optionElements: QueryList<ElementRef>;

    currentOptionIndex: number = 0;
    valueId:string;
    selectedItem: AldOptionItem<ValueType> = { label: '', value: undefined };

    filteredOptions: AldOptionItem<ValueType>[] = [];

    overlayRef: OverlayRef;
    closedOption = false;

    protected readonly destroy = new Subject<void>();

    /**
     * Handles opening the dropdown with an ArrowDown or Enter
     * @param {KeyboardEvent} event
     */
    @HostListener( 'keydown', [ '$event' ] )
    handleKeyDown(event: KeyboardEvent) {
        if (!this.overlayRef.hasAttached() && (event.key === "Enter" || event.key === "ArrowDown")) {
            this.openOptions();
            this.currentOptionIndex = 0;
            event.stopPropagation();
            event.preventDefault();
        }
    }

    onChange = (val) => {};
    onTouch = () => {};


    get control() {
        return this.ngControl?.control;
    }

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        private viewportRuler: ViewportRuler,
        private cd: ChangeDetectorRef,
        private overlay: Overlay,
        private renderer: Renderer2,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.id = this.id || this.label.replace(/\s/g, '').toLowerCase();
        this.viewportRuler
            .change()
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                if (this.overlayRef.hasAttached()) {
                    this.overlayRef.updateSize({ width: this.getOverlayWidth() });
                    this.cd.detectChanges();
                }
            });
    }

    ngAfterViewInit(): void {
        this.setUpOverlay();
    }

    ngOnDestroy(): void {
        this.overlayRef.detach();
        this.destroy.next();
        this.destroy.complete();
    }

    ngOnChanges( changes:SimpleChanges ) {
        //  Guarantee that every option has a valid string 'id' proeprty
        if ( 'options' in changes ) {
            this.options?.forEach( option => this.normalizeOption( option ) );
            this.filteredOptions = [... this.options];
            // selecting the default option item
            if (this.valueId) {
                const option = this.getOptionFromValue(this.valueId, { value: undefined, id: '' });
                this.selectedItem = { ...option };
            }
        }
        if ( 'defaultOptions' in changes ) {
            this.defaultOptions.forEach( option => this.normalizeOption( option ) );
            this.selectedItem = this.defaultOptions[0];
        }
        if ('value' in changes) {
            const defaultOption = { value: changes.value.currentValue };
            // the option must always be normalized, since the valudId must always be a string
            this.normalizeOption(defaultOption);
            const option = this.getOptionFromValue(changes.value.currentValue, defaultOption);
            this.valueId = option.id as string;
            this.selectedItem = { ...option };
        }
    }

    writeValue(value: any) {
        const defaultOption = { value };
        // the option must always be normalized, since the valudId must always be a string
        this.normalizeOption(defaultOption);
        const match = this.getOptionFromValue( value, defaultOption );
        this.valueId = match.id as string;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    onFocus() {
        this.onTouch();
    }

    onValueChanged(value: string) {
        const option = this.getOptionFromValue(value, { value: undefined, id: '' });
        this.valueId = option.id as string;
        this.onChange(option.value);
    }

    /**
     * Handles the selection of an item.
     *
     * This function is called when an item is selected. It updates the `selectedItem` property
     * with the selected item, triggers the value change event with the item's value, and closes the options.
     *
     * @param {AldOptionItem<ValueType>} item - The selected item.
     * @returns {void}
     */
    onSelectItem(item: AldOptionItem<ValueType>) {
        this.selectedItem = {...item};
        this.onValueChanged(item.id as string);
        this.didChange.emit(item.id as string);
        this.closeOptions();
    }

    /**
     * Filters the items based on the provided query.
     *
     * This function filters the items based on the provided query string. If the query is empty,
     * it resets the filteredOptions to contain all the options and triggers the value change event.
     * If the query is not empty, it filters the options based on case-insensitive matching against the label property.
     *
     * @param {string} query - The query string used for filtering.
     * @returns {void}
     */
    filterItems(query: string) {
        if (query === '') {
            this.filteredOptions = [...this.options];
            this.onValueChanged('');
            return;
        }
        this.filteredOptions = this.options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()));
    }

    /**
     * Finds first option that matches either a value ID (string), the value, or the option referencing the value
     */
    getOptionFromValue( value?:string|ValueType|AldOptionItem<ValueType>, defaultValue?:AldOptionItem<ValueType> ):AldOptionItem<ValueType> {
        let match:AldOptionItem<ValueType>;
        if ( typeof( value ) === 'string' ) {
            match = this.options?.find( opt => opt.id === value )
                        || this.defaultOptions?.find( opt => opt.id === value );

        } else if ( typeof( value ) === 'object' && value !== null ) {
            if ( 'label' in value && 'value' in value ) {
                match = value;
            } else {
                match = this.options?.find( opt => opt.value === value )
                            || this.defaultOptions?.find( opt => opt.value === value );
            }
        }
        if ( ! match ) {
            if ( defaultValue ) {
                return defaultValue;
            }
            throw new Error(`Internal error: could not find matching value` );
        } else {
            this.normalizeOption( match );
        }
        return match;       //  this may be redundant in most cases, but occasionally this method may be invoked before normalization of items
    }

    normalizeOption( option:AldOptionItem<ValueType> ) {
        if ( ! option.id ) {
            if ( option.value === null ) {
                option.id = "null";
            } else if ( option.value === undefined ) {
                option.id = "undefined";
            } else if ( typeof( option.value ) === 'number') {
                option.id = option.value.toString();
            } else if ( typeof( option.value ) === 'string') { //  preserve existing behavior, where all values are strings
                option.id = option.value;
            } else {
                option.id = option.label?.toLowerCase().replace( /\s+/g, '_' );  //  support cases where the value is an arbitrary structure
            }
        }
    }

    /**
     * This function allows attaching the content of the options to the overlay
     * @returns {void}
     */
    openOptions(): void {
        if (!this.overlayRef.hasAttached()) {
            this.overlayRef.attach(this.overlayContent);
            this.overlayRef.updateSize({ width: this.getOverlayWidth() });
            this.cd.detectChanges();
            this.changeOption();
        }
    }

    /**
     * This function allows closing the overlay
     * @returns {void}
     */
    closeOptions(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            this.currentOptionIndex = 0;
            this.closedOption = true;
        }
    }

    /**
     * Handles the click event on the document to close the overlay and perform validations.
     *
     * This function allows you to close the overlay by clicking outside the overlayElement or ald-input.
     * If the click happens outside, it will validate the current selection. If it is not valid, the input will be cleared,
     * the selected element will be deleted, and the value change will be triggered.
     *
     * @private
     * @param {MouseEvent} event - The click event object.
     * @returns {void}
     */
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        const clickedElement = event.target as HTMLElement;
        const overlayElement = this.overlayRef.overlayElement;
        if (!this.aldInput.input.nativeElement.contains(clickedElement) && !overlayElement.contains(clickedElement) && this.overlayRef.hasAttached()) {
            this.closeOptions();
            if (!this.valueId || !this.isSelectedOptionValid()) {
                this.aldInput.input.nativeElement.value = "";
                this.filteredOptions = [...this.options];
                this.selectedItem = { label: '', value: undefined };
                this.onValueChanged('');
            }
        }
    }

    private setUpOverlay(): void {
        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.aldInput.input)
                .withPositions([{
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                }, {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'bottom',
                }]),
            panelClass: 'overlay-panel',
            width: this.getOverlayWidth(),
            disposeOnNavigation: true,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.block()
        });
        this.overlayRef.keydownEvents().subscribe((event) => {
            if (event.key === "Escape") {
                this.closeOptions();
            }
            if (event.key === "ArrowDown") {
                this.nextOption();
            }
            if (event.key === "ArrowUp") {
                this.prevOption();
            }
            if (event.key === "Enter") {
                this.onSelectItem(this.filteredOptions[this.currentOptionIndex]);
            }
        });
    }

    private changeOption() {
        this.optionElements.forEach( (el) => {
            this.renderer.removeClass( el.nativeElement, 'focused' );
        } );
        this.renderer.addClass( this.optionElements.get( this.currentOptionIndex ).nativeElement, 'focused' );
    }

    private nextOption(): void {
        this.currentOptionIndex += 1;
        if (this.currentOptionIndex > this.optionElements.length-1) {
            this.currentOptionIndex = this.optionElements.length-1;
            return;
        }
        this.changeOption();
    }

    private prevOption(): void {
        this.currentOptionIndex -= 1;
        if (this.currentOptionIndex < 0) {
            this.currentOptionIndex = 0;
            return;
        }
        this.changeOption();
    }

    /**
     * returns the width of the ald-input
     * @returns number
     */
    private getOverlayWidth(): number {
        return this.aldInput.input.nativeElement.getBoundingClientRect().width;
    }

    /**
     * Checks if the selected option is valid.
     *
     * @private
     * @returns {boolean} True if the selected option is valid, false otherwise.
     */
    private isSelectedOptionValid() {
        const stringOption = this.selectedItem.label;
        return this.options.some(option => option.label === stringOption);
    }
}
