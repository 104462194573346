import { Component, HostBinding, Input, OnChanges } from '@angular/core';


@Component({
   selector: 'ald-data-field',
   templateUrl: './ald-data-field.component.html',
   styleUrls: ['./ald-data-field.component.scss']
 })
 export class AldDataFieldComponent implements OnChanges {

   @Input() label: string;
   @Input() value?: string | number;
   @Input() inline: boolean = false;
   @Input() hideEmptyValue: boolean = true;
   @HostBinding('attr.hiddenfield')  hidden = false;

   ngOnChanges() {
    this.hidden = !(!this.hideEmptyValue || this.value);
   }
 }
