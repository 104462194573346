import {
    Directive,
    EventEmitter,
    HostListener,
    Output
} from '@angular/core';

@Directive({
    selector: '[alFileDropper]'
})

export class AlFileDropperDirective {

    @Output() hasFilesHovered = new EventEmitter<boolean>();
    @Output() filesDropped = new EventEmitter<FileList>();
    @Output() clicked = new EventEmitter<boolean>();

    protected hasLeftDropArea: boolean = true;


    @HostListener('drop', ['$event'])
    onDrop(event) {
        event.preventDefault();
        let transfer = event.dataTransfer;
        this.filesDropped.emit(transfer.files);
        this.hasFilesHovered.emit(false);
    }

    @HostListener('click', ['$event'])
    onClick(_) {
        this.clicked.emit(true);
    }

    @HostListener('dragover', ['$event'])
    onDragOver(event) {
        event.preventDefault();
        if (this.hasLeftDropArea) {
            this.hasLeftDropArea = false;
            this.hasFilesHovered.emit(true);
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(_) {
        this.hasLeftDropArea = true;
        this.hasFilesHovered.emit(false);
    }

}
