<div class="al-wizzard-stepper">
    <div class="al-wizard-stepper-header">
        <div *ngIf="headerOptions" class="u-grid u-justify-center title-header">
            <i class="material-icons" *ngIf="headerOptions?.icon" [ngStyle]="headerOptions?.iconStyle ? headerOptions?.iconStyle : {}">{{headerOptions.icon}}</i>
            <i class="class-icon" *ngIf="headerOptions?.classIcon" [ngClass]="headerOptions.classIcon" [ngStyle]="headerOptions?.iconStyle ? headerOptions?.iconStyle : {}"></i>
            <p *ngIf="headerOptions?.title !== ''" data-cy="al-wizard-stepper-title-header">
                {{headerOptions.title}}
            </p>
            <span class="u-col"></span>
            <button *ngIf="headerOptions?.secondaryAction"
                data-cy="al-wizzard-stepper-secondary-action"
                pButton
                class="flat"
                type="button"
                [label]="headerOptions?.secondaryAction?.text"
                [disabled]="headerOptions?.secondaryAction?.disabled"
                (click)="onCancelButton.emit()">
            </button>
        </div>
        <div class="u-flex-col u-w-100%">
            <div class="margin-top-12 steps-group">
                <div *ngFor="let step of steps; index as i; last as isLast" class="step"
                    [ngClass]="{'complete': step.complete, 'active': step.active}"
                    [class.disabled]="!step.complete && !step.active"
                    (click)="onJumpStep(step)">
                    <div *ngIf="!step.complete || (step.complete && step.active)" class="step-no">{{ i+1 }}</div>
                    <div *ngIf="step.complete && !step.active" class="step-no check-icon"><i class="material-icons">check</i></div>
                    <ng-container *ngIf="step.stepTitle">
                        <div class="step-title">{{ step.stepTitle }}</div>
                    </ng-container>
                    <div *ngIf="!isLast" class="step-separator"></div>
                </div>
            </div>
        </div>
    </div>
    <al-view-helper [loading]="loading"></al-view-helper>
    <div class="stepper-content" [ngClass]="{'stepper-content--one-col': oneColumn}">
        <div class="step-content" [hidden]="loading">
            <ng-content></ng-content>
        </div>
    </div>
</div>
