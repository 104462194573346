/**
 *  @moduleName: NgNavigationModule
 *  @moduleImport: @al/ng-navigation-components
 *
 *  Please DO NOT modify this file directly.  It is regenerated automatically by running 'npm run restructurate'.  Ask Kevin if you have questions!
 */

import { AlCatchallRouteComponent } from './al-catchall-route/al-catchall-route.component';
import { AlDefenderEmbeddedViewComponent } from './al-defender-embedded-view/al-defender-embedded-view.component';
import { AlDefenderSessionLinkComponent } from './al-defender-session-link/al-defender-session-link.component';
import { AlLayoutOptionsComponent } from './al-layout-options/al-layout-options.component';
import { AlLoginComponent } from './al-login/al-login.component';
import { AlNavigationFrameComponent } from './al-navigation-frame/al-navigation-frame.component';
import { AlNavigationOverlaysComponent } from './al-navigation-overlays/al-navigation-overlays.component';
import { AlProtectedContentExComponent } from './al-protected-content-ex/al-protected-content-ex.component';
import { AlProtectedContentComponent } from './al-protected-content/al-protected-content.component';
import { AlArchipeligo17AccountSelectorComponent } from './archipeligo17/account-selector/al-archipeligo17-account-selector.component';
import { AlArchipeligo17HeaderComponent } from './archipeligo17/header/al-archipeligo17-header.component';
import { AlArchipeligo17PrimaryMenuComponent } from './archipeligo17/primary-menu/al-archipeligo17-primary-menu.component';
import { AlArchipeligo17UserMenuComponent } from './archipeligo17/user-menu/al-archipeligo17-user-menu.component';
import { AlArchipeligo19AppHeaderComponent } from './archipeligo19/header/al-archipeligo19-app-header.component';
import { AlArchipeligo19MenuTriggerComponent } from './archipeligo19/menu-trigger/al-menu-trigger.component';
import { AlArchipeligo19NavHeaderComponent } from './archipeligo19/nav-header/al-archipeligo19-nav-header.component';
import { AlArchipeligo19SidenavComponent } from './archipeligo19/sidenav/al-archipeligo19-sidenav.component';
import { AlContentMenuComponent } from './common/al-content-menu/al-content-menu.component';
import { AlTertiaryMenuComponent } from './common/al-tertiary-menu/al-tertiary-menu.component';
import { AlAddendumToNavTitleDirective } from './directives/al-addendum-navtitle.directive';
import { AlCustomTitleDirective } from './directives/al-custom-title.directive';
import { AlNavigationCustomSidenavDirective } from './directives/al-navigation-custom-sidenav.directive';
import { TransformToMenuPipe } from './pipes/transformToMenu.pipe';
import { AlDataMigrationService } from './services/al-data-migration.service';
import { AlEmbeddedUrlsService } from './services/al-embedded-urls.service';
import { AlExperiencePreferencesService } from './services/al-experience-preferences.service';
import { AlGlobalErrorService } from './services/al-global-error.service';
import { AlIdentityResolutionGuard } from './services/al-identity-resolution.guard';
import { AlManageExperienceService } from './services/al-manage-experience.service';
import { AlNavigationService } from './services/al-navigation.service';
import { AlSessionManagerService } from './services/al-session-manager.service';
import { AlUrlFilterService } from './services/al-url-filter.service';
import { AlMiniConduit } from './types/al-mini-conduit';
import { EntitlementGroup } from './types/entitlement-group.class';
import {
    AlAddendumToNavTitleEvent, 
    AlCatchallRouteBehavior, 
    AlContentUnavailable, 
    AlDatacenterOptionsSummary, 
    AlExperience, 
    ALNAV_PRIVATE, 
    ALNAV_PUBLIC, 
    AlNavigateOptions, 
    AlNavigationApplicationError, 
    AlNavigationContextChanged, 
    AlNavigationExtras, 
    AlNavigationFrameChanged, 
    AlNavigationIdlePrompt, 
    AlNavigationInitializeToggle, 
    AlNavigationNavHeaderMounted, 
    AlNavigationPromptDescriptor, 
    AlNavigationReauthenticatePrompt, 
    AlNavigationRemoveToggle, 
    AlNavigationRouteDispatched, 
    AlNavigationSecondarySelected, 
    AlNavigationSidenavMounted, 
    AlNavigationTertiarySelected, 
    AlNavigationTrigger, 
    ExperiencePreference, 
    ExperienceToggleDefinition, 
    ExternalResourceMap, 
    IEWarningState, 
    TrackEventCategory
} from './types/navigation.types';
import { AccountChangeNotification } from './types/protected-content.types';


const MODULE_COMPONENTS = [
    AlArchipeligo17AccountSelectorComponent,
    AlArchipeligo17HeaderComponent,
    AlArchipeligo17PrimaryMenuComponent,
    AlArchipeligo17UserMenuComponent,
    AlArchipeligo19AppHeaderComponent,
    AlArchipeligo19MenuTriggerComponent,
    AlArchipeligo19NavHeaderComponent,
    AlArchipeligo19SidenavComponent,
    AlCatchallRouteComponent,
    AlContentMenuComponent,
    AlDefenderEmbeddedViewComponent,
    AlDefenderSessionLinkComponent,
    AlLayoutOptionsComponent,
    AlLoginComponent,
    AlNavigationFrameComponent,
    AlNavigationOverlaysComponent,
    AlProtectedContentComponent,
    AlProtectedContentExComponent,
    AlTertiaryMenuComponent,
];

const MODULE_DIRECTIVES = [
    AlAddendumToNavTitleDirective,
    AlCustomTitleDirective,
    AlNavigationCustomSidenavDirective,
];

const MODULE_PIPES = [
    TransformToMenuPipe,
];

const MODULE_SERVICES = [
    AlEmbeddedUrlsService,
    AlGlobalErrorService,
    AlUrlFilterService,
];


export {
    MODULE_COMPONENTS,
    MODULE_DIRECTIVES,
    MODULE_PIPES,
    MODULE_SERVICES,
        AccountChangeNotification,
    AlAddendumToNavTitleDirective,
    AlAddendumToNavTitleEvent,
    AlArchipeligo17AccountSelectorComponent,
    AlArchipeligo17HeaderComponent,
    AlArchipeligo17PrimaryMenuComponent,
    AlArchipeligo17UserMenuComponent,
    AlArchipeligo19AppHeaderComponent,
    AlArchipeligo19MenuTriggerComponent,
    AlArchipeligo19NavHeaderComponent,
    AlArchipeligo19SidenavComponent,
    AlCatchallRouteBehavior,
    AlCatchallRouteComponent,
    AlContentMenuComponent,
    AlContentUnavailable,
    AlCustomTitleDirective,
    AlDatacenterOptionsSummary,
    AlDataMigrationService,
    AlDefenderEmbeddedViewComponent,
    AlDefenderSessionLinkComponent,
    AlEmbeddedUrlsService,
    AlExperience,
    AlExperiencePreferencesService,
    AlGlobalErrorService,
    AlIdentityResolutionGuard,
    AlLayoutOptionsComponent,
    AlLoginComponent,
    AlManageExperienceService,
    AlMiniConduit,
    ALNAV_PRIVATE,
    ALNAV_PUBLIC,
    AlNavigateOptions,
    AlNavigationApplicationError,
    AlNavigationContextChanged,
    AlNavigationCustomSidenavDirective,
    AlNavigationExtras,
    AlNavigationFrameChanged,
    AlNavigationFrameComponent,
    AlNavigationIdlePrompt,
    AlNavigationInitializeToggle,
    AlNavigationNavHeaderMounted,
    AlNavigationOverlaysComponent,
    AlNavigationPromptDescriptor,
    AlNavigationReauthenticatePrompt,
    AlNavigationRemoveToggle,
    AlNavigationRouteDispatched,
    AlNavigationSecondarySelected,
    AlNavigationService,
    AlNavigationSidenavMounted,
    AlNavigationTertiarySelected,
    AlNavigationTrigger,
    AlProtectedContentComponent,
    AlProtectedContentExComponent,
    AlSessionManagerService,
    AlTertiaryMenuComponent,
    AlUrlFilterService,
    EntitlementGroup,
    ExperiencePreference,
    ExperienceToggleDefinition,
    ExternalResourceMap,
    IEWarningState,
    TrackEventCategory,
    TransformToMenuPipe,
};

export * from './types/navigation.types';
export * from './types/entitlement-group.class';
export * from './types/al-mini-conduit';
export * from './types/protected-content.types';
export * from './services/al-identity-resolution.guard';