import { Injectable } from '@angular/core';
import {
    AIMSClient, 
    AlCabinet, 
    AlLocatorService, 
    AlLocation, 
    AlSession, 
    AlStopwatch, 
    AlErrorHandler, 
    AlBaseError
} from '@al/core';
import { AlDismissableNoticeConfig, AlExternalContentManagerService } from '@al/ng-generic-components';
import { AlNavigationService } from './al-navigation.service';


type PciPage = 'scan-groups' | 'pci-disputes';

@Injectable({
    providedIn: 'root'
})
export class AlDataMigrationService {

    public storage: AlCabinet = AlCabinet.persistent("migration");
    public pciMigrationStorageKey = "scanningDeprecation";
    public pciMigrationSuppressionTTL = 60 * 60 * 24;       //  1 day in seconds

    constructor( protected navigation: AlNavigationService, 
                 protected externalContent: AlExternalContentManagerService ) {
    }

    public async getPciMigrationNotice(accountId?: string): Promise<AlDismissableNoticeConfig | null> {
        if (!accountId) {
            accountId = AlSession.getActingAccountId();
        }
        try {
            const eligableForMigration = AlSession.getActingAccount()?.fortra_authenticated;
            const hasFortraSession = !!AlSession.getFortraSession();
            const migrationStatus = await this.getScanMigrationStatus( accountId );
            const scanMigrationComplete = migrationStatus.import_status === 'COMPLETED' ? true : false;
            const isMDR = this.navigation.evaluateEntitlementExpression("assess|detect|respond|tmpro");

            if (!eligableForMigration) {
                return null;
            }

            const link = this.getPciMigrationWizardUrl(accountId, isMDR, AlSession.getActiveDatacenter());
            const contentResourceId = this.getBannerContentResourceId(hasFortraSession, scanMigrationComplete);
            const noticeConfig: AlDismissableNoticeConfig = {
                link,
                contentResourceId,
                redirectable: false,
                icon: 'block'
            };

            this.externalContent.setSubstitutionValue("wizardLink", link);
            return noticeConfig;
        } catch (e) {
            AlErrorHandler.log(e, `An error prevented pci migration state from being calculated`);
            return null;
        }
    }

    public getPciMigrationWizardUrl(accountId: string, isMdr: boolean, locId: string): string {
        let path = "/#/";
        if (isMdr) {
            path += `pci/scanning/${accountId}`;
        } else {
            path += `legacy-dashboards/scans/${accountId}`;
        }

        return AlLocatorService.resolveURL(AlLocation.MagmaUI)
            + `${path}?locid=${locId}&aaid=${accountId}&migrate=true`;
    }

    private async getScanMigrationStatus( accountId:string ):Promise<{ import_status: "PENDING"|"RUNNING"|"COMPLETED"|"ERRORED", import_details:any }> {
        try {
            return await AIMSClient.getFrontlineMigrationStatus( accountId );
        } catch( e ) {
            return {
                import_status: "ERRORED",
                import_details: null
            };
        }
    }

    private getBannerContentResourceId(
        hasFortraSession: boolean,
        scanMigrationComplete: boolean
    ): string {
        let version: string;
        if (!hasFortraSession && !scanMigrationComplete) {
            version = 'v1';
        } else if (hasFortraSession && scanMigrationComplete) {
            version = 'v2';
        } else if (!hasFortraSession && scanMigrationComplete) {
            version = 'v3';
        } else {
            version = 'v1';
        }
        return `navigation:help/pci-scan-migration#${version}`
    }
}
