<div class="al-initials" (mouseenter)="onHoverStart()" (mouseleave)="onHoverEnd()"
    [ngClass]="{'selected': selected, 'hoverClass': hovering}" (click)="toggleSelection()">
    <div class="icon-square u-flex u-justify-around u-items-center">
        <span class="colored-square" [ngClass]="classes" *ngIf="!hovering&&!selected">
            <font class="colored-initials" *ngIf="!selected">
                <span>
                    {{letters}}
                </span>
            </font>
        </span>
        <span *ngIf="hovering||selected" class="colored-square">
            <i class="material-icons" *ngIf="selected">check_box</i>
            <i class="material-icons" *ngIf="!selected">check_box_outline_blank</i>
        </span>
    </div>
    <div class="account-info u-flex u-flex-col u-justify-around">
        <span *ngIf="labelled" class="label">{{name}}</span>
        <span *ngIf="labelled && subname" class="subname">{{subname}}</span>
    </div>
</div>