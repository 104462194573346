<ng-container *ngIf="internalRoute; then internal; else external"></ng-container>

<ng-template #internal>
    <a
        class="ald-link"
        [routerLink]="internalRoute"
        queryParamsHandling="merge"
        [queryParams]="queryParams"
        [target]="openInNewTab ? '_blank' : '_self'"
        [ngClass]="{
            'ald-link--grayscale': type === 'grayscale',
            'u-items-center u-gap-1': icon,
            'u-flex-row-reverse': iconPos === 'left',
            'ald-link--underline': underline
        }"
    >
        <ng-container *ngIf="truncate; else noTruncate">
            <div class="u-overflow-hidden u-overflow-ellipsis">{{ label }}</div>
        </ng-container>
        <ng-template #noTruncate>
            {{ label }}
        </ng-template>

        <ald-icon
            *ngIf="icon"
            [icon]="icon"
            [iconClass]="iconClass"
            color="inherit"
            size="sm"
        ></ald-icon>
    </a>
</ng-template>

<ng-template #external>
    <a
        class="ald-link"
        [attr.href]="externalUrl ? externalUrl : null"
        [target]="openInNewTab ? '_blank' : '_self'"
        [ngClass]="{
            'ald-link--grayscale': type === 'grayscale',
            'u-items-center u-gap-1': icon,
            'u-flex-row-reverse': iconPos === 'left',
            'ald-link--underline': underline
        }"
    >
        <ng-container *ngIf="truncate; else noTruncate">
            <div class="u-overflow-hidden u-overflow-ellipsis">{{ label }}</div>
        </ng-container>
        <ng-template #noTruncate>
            {{ label }}
        </ng-template>

    <ald-icon
            *ngIf="icon"
            [icon]="icon"
            [iconClass]="iconClass"
            color="inherit"
            size="sm"
        ></ald-icon>
    </a>
</ng-template>
