<div class="u-pb-3">

    <div *ngIf="content?.templateRef" class="u-flex u-my-3 u-justify-between u-items-center u-gap-5">
        <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
    </div>

    <div class="u-flex u-justify-between u-items-center u-gap-5">

        <div class="u-flex u-items-center" [ngClass]="[!searchByColumn ? 'u-gap-2' :  '']">

            <ng-container *ngIf="searchByColumn">
                <ald-select
                    [(ngModel)]="searchColumnSelected"
                    [defaultOptions]="defaultColumn"
                    [options]="searchColumnOptions"
                    [enableCustomBorder]="true"
                    [customTopLeftBorder]="4"
                    [customBottomLeftBorder]="4">
                </ald-select>
            </ng-container>

            <ald-input
                *ngIf="showSearch"
                [type]="'search'"
                [hideSearchIcon]="searchByColumn"
                [formControl]="tableSearchControl"
                [size]="searchByColumn ? 'md': 'sm'"
                [enableCustomBorder]="searchByColumn"
                [ngClass]="searchByColumn ? 'u-w-100': null"
                [placeholder]="searchByColumn ? getPlaceHolders() : searchPlaceholderInput"
                class="u-w-lg"
                data-cy="table-header-input-search"
            ></ald-input>

            <ald-button
                *ngIf="searchByColumn || enableManualSearch"
                [label]="searchByColumn ? '' : 'Search'"
                icon="search"
                iconClass="material-icons-outlined"
                [size]="searchByColumn ? 'md' :'sm' "
                [enableCustomBorder]="searchByColumn"
                [customTopRightBorder]="4"
                [customBottomRightBorder]="4"
                (click)="manualSearch()"
            ></ald-button>

            <ng-container>
                <ald-loading-spinner *ngIf="isLoading; else filterButton" class="u-relavite filter-spinner-loading" size="md"></ald-loading-spinner>
            </ng-container>
            <ng-template #filterButton>
                <ald-button
                    *ngIf="filters"
                    [ngClass]="[searchByColumn ? 'u-ml-3' :  '']"
                    [label]="filtersToggleButtonLabel"
                    [selected]="filtersSelected"
                    icon="filter_alt"
                    iconClass="material-icons-outlined"
                    [size]="searchByColumn ? 'md' :'sm' "
                    (click)="displayFilters = !displayFilters"
                    data-cy="table-header-filters-button"
                ></ald-button>
                <ald-button
                    *ngIf="filtersSelected"
                    [ngClass]="[searchByColumn ? 'u-ml-3' :  '']"
                    variant="link"
                    label="Clear All"
                    size="xs"
                    icon="close"
                    (click)="clearAll()"
                    data-cy="table-header-filters-clear"
                ></ald-button>
            </ng-template>
        </div>

        <div class="u-flex u-items-center u-gap-1 u-ml-5">

            <ald-dropdown
                *ngIf="columns"
                #columnDropdown
                variant="secondary"
                iconPos="left"
                size="sm"
                width="lg"
                icon="view_week"
                iconClass="material-icons-outlined"
                data-cy="table-header-column-selector"
                title="Column settings"
            >

                <div class="u-flex u-items-center u-justify-between u-p-2">
                    <ald-input
                        class="u-w-100%"
                        type="search"
                        [formControl]="columnSearchControl"
                        data-cy="table-header-column-selector-search"
                    ></ald-input>
                </div>

                <div
                    cdkDropList
                    (cdkDropListDropped)="dropColumn($event)"
                    class="table-column-list u-p-2"
                    style="max-height: 200px; overflow-y: scroll"
                >
                    <ng-container
                        *ngFor="
                            let columnOption of filteredColumnList;
                            let index = index
                        ">
                        <div
                            cdkDrag
                            class="table-column-list--item u-py-1"
                            cdkDragLockAxis="y"
                            [cdkDragDisabled]="index === 0"
                            [ngStyle]="{
                                cursor: index === 0 ? 'not-allowed' : 'move'
                            }"
                        >

                            <div class="u-flex u-items-start u-gap-2" cdkDragHandle>
                                <ald-icon
                                    *ngIf="index === 0"
                                    style="opacity: 0.5"
                                    icon="drag_indicator"
                                    size="sm"
                                    color="u-text-secondary"
                                ></ald-icon>
                                <ald-icon
                                    *ngIf="index !== 0"
                                    icon="drag_indicator"
                                    size="sm"
                                    color="u-text-secondary"
                                ></ald-icon>
                                <ald-checkbox
                                    [label]="columnOption.label"
                                    [id]="columnOption.value"
                                    [checked]="columnOption.selected"
                                    [bottomPadding]="false"
                                    (didCheckedChange)="didSelectColumn($event, columnOption.value)"
                                    [disabled]="index === 0 && !columnSearchControl.value"
                                    data-cy="table-header-column-selector-option"
                                ></ald-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="u-flex u-gap-2 u-flex-wrap u-justify-between u-border-t u-border-solid u-border-primary u-p-2">
                    <ald-button
                        variant="ghost"
                        label="Cancel"
                        (click)="cancelColumnConfig()"
                        data-cy="table-header-column-selector-cancel"
                    ></ald-button>
                    <ng-container *ngIf="defaultColumns && defaultColumns.length > 0">
                        <ald-button
                            variant="ghost"
                            label="Reset"
                            (click)="resetColumnConfig()"
                            data-cy="table-header-column-selector-reset"
                        ></ald-button>
                        <!--Spacer to align the primary button to the end.-->
                        <div class="u-px-10 u-py-5"></div>
                    </ng-container>
                    <ald-button
                        variant="primary"
                        label="Apply"
                        (click)="applyColumnConfig()"
                        data-cy="table-header-column-selector-apply"
                    ></ald-button>
                </div>
            </ald-dropdown>

            <!-- CUSTOM BUTTONS or DROPDOWNS PROJECTION -->
            <ng-content select="ald-button, ald-dropdown"></ng-content>

        </div>

    </div>

    <div *ngIf="displayFilters" class="u-mt-3 u-pt-3 u-border-t u-border-solid u-border-primary">
        <div class="u-flex u-flex-wrap u-items-start u-gap-2">
            <ng-container *ngFor="let filter of filterList">
                <ald-multiselect
                    [attr.data-e2e]="'table-header-filter-'+filter.label"
                    type="default"
                    size="sm"
                    [buttonLabel]="filter.label"
                    [options]="filter.items"
                    [selected]="filter.selected"
                    (didSearch)="didSearchFilter.emit({searchTerm: $event, filter: filter})"
                    (didSelect)="filterUpdated(filter)"
                >
                </ald-multiselect>
            </ng-container>
        </div>
    </div>
</div>
