<ald-button
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [variant]="variant"
    [size]="size"
    [icon]="icon"
    [label]="label"
    [iconPosition]="iconPosition"
    [iconClass]="iconClass"
    [disabled]="disabled"
    [selected]="selected"
    (click)="open()"
    data-testid="ald-dropdown-trigger"
>
</ald-button>

<ng-template
    #overlay
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    (backdropClick)="close()"
>
    <ald-dropdown-panel [width]="width" data-testid="ald-dropdown-overlay">
        <ng-content></ng-content>
    </ald-dropdown-panel>
</ng-template>
