/**
*
* AlGlobalErrorService
*
* This Service catch all the Javascript Errors in the UI and send metrics
* to Google Analytics using the "track" method.
*
*  @author Fair Tarapues <fair.tarapues@alertlogic.com>
*  @copyright Alert Logic Inc, 2020
*/

import { datadogRum } from '@datadog/browser-rum';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AlLocatorService, AlErrorHandler } from '@al/core';
import { AlTrackingMetricEventName, AlTrackingMetricEventCategory } from '@al/ng-generic-components';
import { AlNavigationService } from './al-navigation.service';


@Injectable()
export class AlGlobalErrorService extends ErrorHandler {
    constructor( private injector:Injector ) {
        super();
        AlErrorHandler.upstream = ( error:unknown ) => this.handleError( error );
    }

    handleError(error: any) {
        const navigation = this.injector.get(AlNavigationService);
        if ( ['integration', 'development'].includes(AlLocatorService.getCurrentEnvironment()) ) {
            console.error(error);
        }
        const descr = AlErrorHandler.describe( error );

        if ( navigation ) {
            navigation.track(AlTrackingMetricEventName.UsageTrackingEvent, {
                category: AlTrackingMetricEventCategory.GenericConsoleException,
                action: "Javascript Error",
                label: `${descr.title}: ${descr.description}`
            });
        }
        if ( AlLocatorService.getCurrentEnvironment() === 'integration' ) {
            datadogRum.addError( AlErrorHandler.normalize( error ) );
        }
    }
}
