<div class="ald-icon"
    [ngClass]="[
        color ? color : 'ald-icon--no-color-set',
        size === 'sm' ? 'ald-icon--sm c-icon-sm': '',
        size === 'md' ? 'ald-icon--md c-icon-md': '',
        size === 'lg' ? 'ald-icon--lg c-icon-lg': '',
        size === 'xl' ? 'ald-icon--xl c-icon-xl': '',
        inline ? 'ald-icon--inline' : ''
    ]"
    [style.opacity]="opacity"
>

    <svg [ngClass]="{'ald-icon--inline': inline}">
        <use [attr.xlink:href]="svgIcon"></use>
    </svg>

</div>
