/**
 * Generic box alert
 * @author Poem Pitzer
 * @author msanchez <msanchez@alertlogic.com>
 * @author fmiranda <fmiranda@alertlogic.com>
*/
import { Component, Input } from '@angular/core';


@Component({
    selector: 'al-alert-box',
    templateUrl: './al-alert-box.component.html',
    styleUrls: [ './al-alert-box.component.scss' ],
})
export class AlAlertBoxComponent {
    @Input() boxColor = '';
    @Input() iconColor = '';
}
