<ald-form-element-header
    [id]="id"
    [label]="label"
    [tip]="tip"
    [hint]="hint"
    [required]="required">
</ald-form-element-header>

<ald-dropdown
    #multiSelectDropdown
    [variant]="variant"
    [size]="size"
    [label]="buttonLabelText"
    [icon]="icon"
    [iconPosition]="iconPosition"
    [iconClass]="iconClass"
    [disabled]="disabled"
    [selected]="selected"
    width="lg"
    (didOpen)="didDropdown()"
    (didClose)="clearSelectedOptions(false)"
>
    <div *ngIf="search" class="ald-multiselect__header">
        <ald-input
            #searchInputComponent
            class="u-w-100%"
            type="search"
            size="sm"
            [formControl]="searchControl"
        ></ald-input>
    </div>
 
    <form [formGroup]="multiselectForm">
        <div formArrayName="checkboxes" class="u-py-2 u-px-3 u-overflow-auto" style="max-height: 200px">
            <ald-checkbox
                *ngFor="let control of checkboxes.controls; let i = index"
                [label]="options[i].label"
                [value]="$any(options[i].value)"
                [checked]="options[i].selected"
                [formControlName]="i"
                [attr.data-e2e]="'multiselect-option-'+options[i].value"
            ></ald-checkbox>
        </div>

        <div class="ald-multiselect__footer">
            <ald-button
                tabIndex="1"
                variant="ghost"
                size="sm"
                [label]="secondaryButtonLabel"
                (click)="clearSelectedOptions()"
            ></ald-button>
            <ald-button
                data-e2e="multiselect-apply-button"
                tabIndex="0"
                variant="primary"
                size="sm"
                [label]="primaryButtonLabel"
                (click)="submitSelectedOptions()"
            ></ald-button>
        </div>
    </form>
</ald-dropdown>

<ald-form-group-errors
    [control]="control"
    [label]="label"
    [requiredErrorMessage]="requiredErrorMessage">
</ald-form-group-errors>
