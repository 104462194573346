<nav class="sideNav">
    <ng-container *ngIf="customSidenavRef; else standardMenu">
        <ng-content select=".custom-tertiary" *ngTemplateOutlet="customSidenavRef"></ng-content>
    </ng-container>
    <ng-container *ngIf="customSidenavBelowRef">
        <ng-content select=".custom-tertiary" *ngTemplateOutlet="customSidenavBelowRef"></ng-content>
    </ng-container>
    <ng-template #standardMenu>
        <ul *ngIf="menu">
            <ng-template ngFor let-menuItem [ngForOf]="menu.children">
                <li *ngIf="menuItem.visible"
                    [ngClass]="{'active': menuItem.activated}"
                    (click)="onClick(menuItem,$event)"
                    (mouseover)="onHoverStart(menuItem)">
                    <a [ngClass]="{'active':menuItem.activated}"
                        [href]="menuItem.href || ''"
                        (click)="onClick(menuItem,$event)">{{menuItem.caption}}</a>
                </li>
            </ng-template>
        </ul>
    </ng-template>
</nav>

<main class="" [ngClass]="alNavigation.tertiaryContentClass$ | async">
    <section class="navigation-tabs" *ngIf="showQuaternaryMenu && activeTabs && activeTabs.children.length > 1">
        <div class="tab-container">
            <ng-template ngFor let-tab [ngForOf]="activeTabs.children">
                <div    *ngIf="tab.visible"
                        [ngClass]="tab.properties.consolidated_css_classes"
                        (click)="onClick(tab,$event)"
                        (mouseover)="onHoverStart(tab)">
                    <a [ngClass]="{'active':tab.activated,'disabled': ! tab.enabled}"
                        [href]="tab.href || ''"
                        (click)="onClick(tab,$event)">{{tab.caption}}</a>
                </div>
            </ng-template>
        </div>
    </section>
</main>
