/**
 * It allows the construction of an input type element supporting the input, hidden and password types
 *
 * @author Maryit <msanchez@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { FormControl, Validators } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementDropdown extends AlFormElementBase<string> {

    public controlTemplate = "dropdown";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
        if (!this.placeholder && !properties.defaultValue) {
            this.placeholder = 'Select an option';
        }
        if (!this.required) {
            this.options = [{label:"-- Select --", value: ''}, ...this.options];
        }
    }

    public createFormControl() {
        let formControl = new FormControl(this.value || '');
        const validators = this.buildValidators();
        formControl = validators.length > 0 ? new FormControl(this.value || '', Validators.compose(validators))
            : new FormControl(this.value || '');


        if ( this.disabled) {
            formControl.disable();
        }

        return formControl;
    }

    getAnswer(): any {
        return this.getControlValue();
    }

}
