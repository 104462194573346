/**
 *  @moduleName: AldNoteModule
 *  @moduleImport: @al/design-patterns
 *
 *  Please DO NOT modify this file directly.  It is regenerated automatically by running 'npm run restructurate'.  Ask Kevin if you have questions!
 */

import { AldTableConstants } from './constants';
import { AldModalModule } from './modal';
import { AldNoteModule } from './note';
import { AldPaginationModule } from './pagination';
import { AldRepeaterModule } from './repeater';
import { AldStepperModule } from './stepper';
import { AldTableModule } from './table';
import { AldThreatModule } from './threat';
import { AldToastModule } from './toast';
import { AldTreeTableModule } from './tree-table';


const MODULE_COMPONENTS = [

];

const MODULE_DIRECTIVES = [

];

const MODULE_PIPES = [

];

const MODULE_SERVICES = [

];

const MODULE_CHILDREN = [
    AldNoteModule,
    AldPaginationModule,
    AldToastModule,
    AldStepperModule,
    AldTreeTableModule,
    AldThreatModule,
    AldTableModule,
    AldModalModule,
    AldRepeaterModule,
];


export {
    MODULE_COMPONENTS,
    MODULE_DIRECTIVES,
    MODULE_PIPES,
    MODULE_SERVICES,
    MODULE_CHILDREN,
        AldTableConstants,
};

export * from './constants';
export * from './note/manifest';
export * from './pagination/manifest';
export * from './toast/manifest';
export * from './stepper/manifest';
export * from './tree-table/manifest';
export * from './threat/manifest';
export * from './table/manifest';
export * from './modal/manifest';
export * from './repeater/manifest';
export { AldNoteModule } from './note';
export { AldPaginationModule } from './pagination';
export { AldToastModule } from './toast';
export { AldStepperModule } from './stepper';
export { AldTreeTableModule } from './tree-table';
export { AldThreatModule } from './threat';
export { AldTableModule } from './table';
export { AldModalModule } from './modal';
export { AldRepeaterModule } from './repeater';