import { Component, Input, OnInit } from '@angular/core';
import { IconClass } from '../types/al-common.types';


/**
 * Provide either an internalRoute or externalUrl to link to.
 */
@Component({
    selector: 'ald-link',
    templateUrl: './ald-link.component.html',
    styleUrls: ['./ald-link.component.scss'],
})
export class AldLinkComponent implements OnInit {
    /**
     * The link text.
     */
    @Input() label: string;

    /**
     * Internal link - uses angular routerLink.
     */
    @Input() internalRoute: string[];

    /**
     * External link - uses href and opens in new tab.
     */
    @Input() externalUrl: string;

    /**
     * Optionally open the link in a new tab. Applicable only to internalRoutes.
     */
    @Input() openInNewTab?: boolean = false;

    /**
     * The link style.
     */
    @Input() type?: 'default' | 'grayscale' = 'default';

    /**
     * Optionally add an underline to help highlight the link.
     */
    @Input() underline?: boolean;

    /**
     * Optionally give it an icon - use the icon name/class.
     */
    @Input() icon?: string;

    /**
     * Optionally truncate text link.
     */
    @Input() truncate?: boolean = false;

    /**
     * Specify the icon class to use. Default is "material-icons.
     */
    @Input() iconClass?: IconClass = 'material-icons';

    /**
     * Optionally position the icon left or right; default is 'right'.
     */
    @Input() iconPos?: 'left' | 'right' = 'right';

    /**
     * Optionally pass in any query params
     */
    @Input() queryParams?: {[k:string]:string};

    ngOnInit(): void {
        if (this.externalUrl && !this.icon) {
            this.icon = 'open_in_new';
        }
    }
}
