import { Component, OnInit } from '@angular/core';
import { AldTooltipPosition } from '../ald-tooltip.types';


@Component({
  selector: 'ald-tooltip-container',
  templateUrl: './ald-tooltip-container.component.html',
  styleUrls: ['./ald-tooltip-container.component.scss']
})
export class AldTooltipComponent implements OnInit {

  id = '';
  position: AldTooltipPosition = 'above';
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}