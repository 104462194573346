<div class="u-pb-5">

    <ng-content select="ald-repeater-item"></ng-content>
    <ald-button
        variant="secondary"
        icon="add"
        [label]="addButtonLabel"
        [fullwidth]="true"
        (click)="didAddItem.emit()">
    </ald-button>

</div>