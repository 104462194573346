<div class="view-helper" [ngClass]="{'ready': ! loading, 'inline-view': inline, 'blurred-when-busy': blur }">

    <!-- Loading Spinner for views that are in the process of loading -->
    <ald-loading-spinner *ngIf="loading" class="loading-spinner u-flex u-justify-center u-z-max"></ald-loading-spinner>

    <!-- Legacy notification panel -->
    <al-notification-panel [alertSource]="notifications" [displayMaximum]="3"></al-notification-panel>

    <al-zero-state [flavor]="zeroStateFlavor" *ngIf="empty">
        <span zero-state-title>
            <ng-content select="[zero-state-title]"></ng-content>
        </span>
        <span zero-state-description>
            <ng-content select="[zero-state-description]"></ng-content>
        </span>
        <div zero-state-icon>
            <ng-content select="[zero-state-icon]"></ng-content>
        </div>
    </al-zero-state>
    <al-zero-state [flavor]="zeroStateFlavor" *ngIf="showError">
        <span zero-state-icon><i class="material-icons">report_problem</i></span>
        <span zero-state-title>{{errorTitle}}</span>
        <div zero-state-description>
            <p>{{errorDescription}}</p>
            <code class="error-details" *ngIf="showErrorDetails">
                {{errorDetails}}
            </code>
            <p>
                <button pButton type="button" class="primaryAction" (click)="attemptReload($event)" label="Try Again" *ngIf="retryHandler"></button>
                &nbsp;
                <button pButton type="button" class="secondaryAction" (click)="showErrorDetails = true;" label="Details" icon="ui-icon-keyboard-arrow-down" iconPos="right" *ngIf="errorDetails && ! showErrorDetails"></button>
                <button pButton type="button" class="secondaryAction" (click)="showErrorDetails = false;" label="Details" icon="ui-icon-keyboard-arrow-up" iconPos="right" *ngIf="errorDetails && showErrorDetails"></button>
            </p>
        </div>
    </al-zero-state>

    <ng-container *ngIf="blur">
        <div class="blur-overlay" [ngClass]="{ active: applyBlur }"></div>
    </ng-container>

    <ng-content></ng-content>
</div>
