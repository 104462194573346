import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'aldObjectValue',
})
export class AldObjectValuePipe implements PipeTransform {
    transform(value: any, name: string, ignoreDotNotation: boolean = false, showRawValue: boolean = false): any {
        if (!value) {
            return '';
        }
        let content = '';
        if (ignoreDotNotation || name.indexOf('.') === -1) {
            content = value[name];
        } else {
            content = getValueFromPath(value, name);
        }

        // This will make the value to be taken as the structure if comes (raw/stringify)
        // otherwise it will be shown as the angular template presents it
        if (showRawValue && typeof (content) === 'object') {
            return JSON.stringify(content);
        }
        return content;
    }
}

export function getValueFromPath(value: {[key:string]: any}, path: string) {
    if (Array.isArray(value) || !(value instanceof Object) || !path) {
        return value;
    } else {
        // Let's get recursively from a dotted notation the value from the object
        // by splitting and goind deep into the object levels
        const splitName: string[] = path.split(/\.(.+)/, 2);
        let propertyName = splitName[0];
        let newValue = value[propertyName] ?? '';
        // if the property name is an array lets get the array value
        const reArray = /^([a-z0-9_]+)\[(\d+)\]/gi;
        const match = reArray.exec(propertyName);
        if (match !== null) {
            propertyName = match[1];
            const arrayIndex = match[2];
            newValue = value[propertyName]?.[arrayIndex] ?? '';
        }
        return getValueFromPath(newValue, splitName[1]);
    }
}
