import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AldCommonModule } from '@al/design-patterns/common';
import { AldFormsModule } from '@al/design-patterns/forms';
import * as manifest from './manifest';


@NgModule({
    imports: [
        CommonModule,
        AldCommonModule,
        AldFormsModule,
    ],
    declarations: [
        ...manifest.MODULE_COMPONENTS,
    ],
    exports: [
        ...manifest.MODULE_COMPONENTS,
    ]
})
export class AldNoteModule {}
