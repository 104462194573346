import {
    Component, 
    Input, 
    Self, 
    Optional, 
    OnInit
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';


@Component({
    selector: 'ald-radio',
    templateUrl: './ald-radio.component.html',
    styleUrls: ['./ald-radio.component.scss'],
})
export class AldRadioComponent implements OnInit, ControlValueAccessor {

    hasFocus = false;

    @Input() label = '';
    @Input() size?: 'sm' | 'md' = 'md';
    @Input() value: string | number | boolean;
    @Input() id?: string | number = '';
    @Input() name?: string;
    @Input() disabled? = false;

    public checked?: boolean = false;
    private selectedValue: string | number | boolean = '';

    onChange = (value: string | number | boolean) => { return value; };
    onTouch = () => { return; };

    get control() {
        return this.ngControl?.control;
    }

    constructor(@Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        this.updateChecked();
    }

    ngOnInit(): void {
        this.id = this.id || this.label;
        this.ngControl?.control.valueChanges.subscribe(value => {
            if ( this.selectedValue !== value ) {
                this.writeValue(value);
            }
        });
    }

    onValueChange(value: Event) {
        this.selectedValue = this.value;
        this.onChange(this.value);
        this.updateChecked();
    }

    writeValue(value: string | number | boolean): void {
        this.selectedValue = value;
        this.updateChecked();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    updateChecked() {
        this.checked = this.selectedValue === this.value;
    }
}
