<div #scalableGraphContent>
    <div *ngIf="graphReady">
        <ng-template ngFor
                     let-region
                     [ngForOf]="tree.regions"
                     *ngIf="tree">
            <div class="slideContainer"
                 *ngIf="region">

                <!--=======VERTICAL BLOCK=====-->
                <div class="slide"
                     *ngIf="type === 'threat' || type === 'scan'">
                    <div class="initial-block"
                         [ngClass]="getColorClass(region.properties.threat_level)"></div>
                </div>

                <!-- ============REGION============== -->
                <div class="slide">

                    <!-- ============REGION============== -->
                    <div class="region"
                         [ngClass]="getColorClass(region.properties.threat_level, region, 'region-box')"
                         (click)="handleAssetClicked(region, $event)"
                         (mouseover)="handleAssetHovered(region, $event)"
                         (mouseleave)="handleMouseLeave()"
                         [ngStyle]="{'width.px':regionWidth}">
                        <i class="al al-region"></i>
                        <div class="region-text">{{ region.name }}</div>
                    </div>

                </div>

                <!-- ======VPCS AND SUBNETS========= -->
                <ng-template ngFor
                             let-vpc
                             [ngForOf]="region.children">
                    <div class="slide"
                         *ngIf="verifyAsset(vpc, 'vpc')">

                        <!-- ============VPCS============== -->
                        <div class="vpc"
                             [ngClass]="getColorClass(vpc.properties.threat_level, vpc, 'vpc-box')"
                             (click)="handleAssetClicked(vpc, $event)"
                             (mouseover)="handleAssetHovered(vpc, $event)"
                             (mouseleave)="handleMouseLeave()"
                             [ngStyle]="{'width.px':vpcWidth}">
                            <i class="al al-vpc"
                               [ngStyle]="{'font-size.px':vpcIconSize}"></i>
                        </div>

                        <!-- ==================SUBNETS================== -->
                        <div class="subnets">
                            <ng-template ngFor
                                         let-subnet
                                         let-last="last"
                                         [ngForOf]="vpc.children">
                                <div class="subnet"
                                     *ngIf="verifyAsset(subnet, 'subnet')"
                                     [ngClass]="getColorClass(subnet.properties.threat_level, subnet, 'subnet-box')"
                                     [ngStyle]="{'width.px':vpcWidth}">
                                </div>

                                <!--===========ALERT LOGIC APPLIANCE========-->
                                <div class="subnet risk-1"
                                     *ngIf="type === 'scope_preview' && last"
                                     [ngStyle]="{'width.px':vpcWidth}">
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </div>
</div>