<div #sidenavPanelLeft
     *ngIf="leftPanel"
     class="sidenav-panel left">
    <ng-content select="[left]"></ng-content>
</div>
<div #mainContent
     class="main-content">
    <div *ngIf="leftPanel"
         class="hide-left">
        <button class="left"
                tooltipPosition="right"
                type="button"
                [pTooltip]="leftTooltip"
                (click)="toggle('left')">
            <div class="hide-left-button">
                <i class="material-icons">{{showLeft ? 'skip_previous' : 'skip_next'}}</i>
            </div>
        </button>
    </div>
    <ng-content select="[center]"></ng-content>
    <div *ngIf="rightPanel"
         class="hide-right">
        <button class="right u-mt-05"
                tooltipPosition="right"
                type="button"
                [pTooltip]="rightTooltip"
                (click)="toggle('right')">
            <div class="hide-left-button">
                <i class="material-icons">{{showRight ? 'skip_next' : 'skip_previous'}}</i>
            </div>
        </button>
    </div>
    
</div>
<div #sidenavPanelRight
     *ngIf="rightPanel"
     class="sidenav-panel u-right-0 u-border-0 u-border-solid u-border-secondary">
    <ng-content select="[right]"></ng-content>
</div>
