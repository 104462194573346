<div
    *ngIf="control && control.errors && (control.dirty || control.touched)"
    class="ald-error-message">
    <div class="ald-error-message__icon">
        <ald-icon icon="error" iconClass="material-icons" size="sm" color="inherit"></ald-icon>
    </div>

    <div class="ald-error-message__text">
        <ng-container *ngIf="control.errors.required">
            <div *ngIf="!requiredErrorMessage">{{ label }} is required.</div>
            <div *ngIf="requiredErrorMessage">{{ requiredErrorMessage }}</div>
        </ng-container>

        <ng-container *ngIf="control.errors.email">
            <div>
                {{ label }} should contain a valid email address.
            </div>
        </ng-container>

        <ng-container *ngIf="control.errors.pattern">
            <div *ngIf="!patternErrorMessage">
                {{ label }} does not meet the defined pattern.
            </div>
            <div *ngIf="patternErrorMessage">{{ patternErrorMessage }}</div>
        </ng-container>

        <ng-container *ngIf="control.errors.minlength">
            <div>
                {{ label }} should contain minimum
                {{ control.errors.minlength.requiredLength }} characters.
            </div>
        </ng-container>

        <ng-container *ngIf="control.errors.maxlength">
            <div>
                {{ label }} should contain maximum
                {{ control.errors.maxlength.requiredLength }} characters.
            </div>
        </ng-container>

        <ng-container *ngIf="control.errors.max">
            <div>
                {{ label }} should be no more than {{ control.errors.max.max }}.
            </div>
        </ng-container>

        <ng-container *ngIf="control.errors.min">
            <div>
                {{ label }} should be at least {{ control.errors.min.min }}.
            </div>
        </ng-container>
    </div>
</div>
