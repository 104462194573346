import {
    Component, 
    EventEmitter, 
    Input, 
    Output, 
    OnChanges, 
    SimpleChanges
} from '@angular/core';
import { AlDismissableNoticeConfig } from '../types/al-dismissable-notice.types';


@Component({
    selector: 'al-dismissable-notice',
    templateUrl: './al-dismissable-notice.component.html',
    styleUrls: ['./al-dismissable-notice.component.scss']
})
export class AlDismissableNoticeComponent implements OnChanges {

    @Input() config: AlDismissableNoticeConfig;

    @Output() dismiss: EventEmitter<void> = new EventEmitter();
    @Output() linkClicked: EventEmitter<void> = new EventEmitter();

    visible: boolean = true;

    ngOnChanges( changes:SimpleChanges ) {
        if ( 'config' in changes && this.config ) {
            this.normalizeConfiguration();
        }
    }

    normalizeConfiguration() {
        if ( ! this.config.icon ) {
            this.config.icon = "info";
        }
        if ( ! this.config.description && ! this.config.contentResourceId ) {
            throw new Error(`Usage error: notice configuration must have either a description or a resource identifier.` );
        }
    }

    dissmissClicked(): void {
        this.dismiss.emit();
        this.visible = false;
    }

}
