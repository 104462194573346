import {
    Directive, 
    Input, 
    ElementRef, 
    HostListener, 
    OnDestroy, 
    OnInit
} from '@angular/core';


@Directive({
    selector: '[aldResize]'
})
export class AldResizeDirective implements OnInit, OnDestroy {

    private htmlNode: HTMLElement;
    private data: {x: number, y: number, rect: {width: number}};

    @Input() enableResize: boolean;
    @Input() resizeDirection: 'left' | 'right';

    constructor(
        private element: ElementRef,
    ) {
        this.mousemove = this.mousemove.bind(this);
        this.mouseup = this.mouseup.bind(this);
    }

    mousemove(e) {
        if (this.data) {
            const { width } = this.data.rect;
            const offset_x = this.data.x - e.clientX;
            let elementWidth: number;

            switch (this.resizeDirection) {
                case 'left':
                    elementWidth = width + offset_x;
                    break;
                case 'right':
                    elementWidth = width - offset_x;
                    break;
            }

            this.element.nativeElement.style.width = elementWidth + 'px';
        }
    }

    createNode() {
        const node = document.createElement('div');
        node.classList.add('js-resizable-border', 'ald-resize');
        switch (this.resizeDirection) {
            case 'left':
                node.classList.add('u-left-0');
                break;
            case 'right':
                node.classList.add('u-right-0');
                break;
        }

        node.innerHTML = `
            <div class="js-resizable-border ald-resize__wrapper">
                <div class="ald-resize__icon">

                    <svg width="16" height="40" viewBox="0 0 16 40">
                        <path d="M5 6H6.5L6.5 34H5L5 6ZM9.5 6H11L11 34H9.5L9.5 6Z" fill="currentColor"/>
                    </svg>

                </div>
            </div>
        `;
        this.element.nativeElement.appendChild(node);
        this.htmlNode = node;
    }

    ngOnInit() {
        if (!this.enableResize) { return; }
        this.createNode();
        window.addEventListener('mousemove', this.mousemove);
        this.element.nativeElement.classList.add('resize');
        window.addEventListener('mouseup', this.mouseup);
    }

    @HostListener('mousedown', ['$event']) mousedown(e) {
        if (e.target.classList.contains('js-resizable-border')) {
            const rect = this.element.nativeElement.getBoundingClientRect();
            this.data = {
                x: e.clientX,
                y: e.clientY,
                rect,
            };
            e.preventDefault();
        } else {
            delete this.data;
        }
    }

    mouseup(e) {
         delete this.data;
    }

    ngOnDestroy() {
        this.htmlNode.remove();
        window.removeEventListener('mousemove', this.mousemove);
        window.removeEventListener('mouseup', this.mouseup);
    }

}