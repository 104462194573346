import { AlBehaviorPromise, AIMSSessionDescriptor } from '@al/core';


export class AlMiniConduit
{
    verbose = false;
    conduitWindow:Window;
    conduitOrigin:string;
    refCount:number = 0;

    ready = new AlBehaviorPromise<boolean>();

    protected requests: { [requestId: string]: { resolve: any, reject: any, canceled: boolean } } = {};
    protected requestIndex = 0;

    constructor( public conduitUri:string ) {}

    public start() {
        if ( typeof( document ) === 'undefined' || typeof( window ) === 'undefined' ) {
            console.error( "No document or window available; cannot start." );
            return;
        }
        if ( this.refCount < 1 ) {
            window.addEventListener( "message", this.onReceiveMessage, false );
            if ( document.body && typeof( document.body.appendChild ) === 'function' ) {
                const fragment = document.createDocumentFragment();
                const container = document.createElement( "div" );
                container.setAttribute("id", "conduitMiniClient" );
                container.setAttribute("class", "conduit-container" );
                container.innerHTML = `<iframe frameborder="0" src="${this.conduitUri}" style="width:1px;height:1px;position:absolute;left:-1px;top:-1px;"></iframe>`;
                fragment.appendChild( container );
                document.body.appendChild( fragment );
            } else {
                console.error(`Unable to create conduit frame.`, document );
            }
        }
        this.refCount++;
    }

    public stop() {
        if ( this.refCount > 0 ) {
            this.refCount--;
        }
        if ( this.refCount === 0 ) {
            let container = document.getElementById( "conduitMiniClient" );
            if ( container ) {
                document.body.removeChild( container );
            }
        }
    }

    public destroy() {
        let container = document.getElementById( "conduitMiniClient" );
        if ( container ) {
            document.body.removeChild( container );
        }
    }

    /**
     * Retrieves session information from the conduit.  Resolves with valid session information if a session exists, or null if no session is established;
     * an error indicates a problem with conduit operation rather than the absence of a session.
     */
    public getSession(): Promise<AIMSSessionDescriptor> {
        return this.request('conduit.getSession')
                    .then( rawResponse => rawResponse.session );
    }

    /**
     * Sets session information to the conduit.  
     */
    public setSession( session:AIMSSessionDescriptor ): Promise<AIMSSessionDescriptor> {
        return this.request('conduit.setSession', { session } )
                    .then( rawResponse => rawResponse.session );
    }

    /**
     * Deletes existing session information.
     */
    public deleteSession(): Promise<boolean> {
        return this.request('conduit.deleteSession')
                    .then( () => true );
    }

    /**
     * Receives a message from conduit, and dispatches it to the correct handler.
     */
    public onReceiveMessage = (event: any):void => {
        if ( ! event.data
                || typeof (event.data.type) !== 'string'
                || typeof (event.data.requestId) !== 'string'
                || ! event.origin
                || ! event.source) {
            //  Disqualify events that aren't of the correct type/structure
            return;
        }

        if ( ! event.origin.endsWith( ".alertlogic.com" ) ) {
            //  Ignore any events that don't originate from an alertlogic domain
            return;
        }

        switch (event.data.type) {
            case 'conduit.ready':
                return this.onConduitReady(event);
            case 'conduit.getSession':
            case 'conduit.setSession':
            case 'conduit.deleteSession':
                return this.onDispatchReply(event);
            default:
                break;
        }
    }

    public onConduitReady(event: any ): void {
        if ( this.conduitUri.startsWith( event.origin ) ) {
            this.conduitWindow = event.source;
            this.conduitOrigin = event.origin;
            this.ready.resolve( true );
        }
    }

    public onDispatchReply(event: any): void {
        const requestId: string = event.data.requestId;
        if (!this.requests.hasOwnProperty(requestId)) {
            if ( this.verbose ) {
                console.warn(`Warning: received a conduit response to an unknown request with ID '${requestId}'; multiple clients running?` );
            }
            return;
        } else if ( this.requests[requestId].canceled ) {
            if ( this.verbose ) {
                console.warn(`Warning: received a conduit response after its timeout expired; discarding.` );
            }
            return;
        }

        this.requests[requestId].resolve( event.data );
        delete this.requests[requestId];
    }

    protected request( methodName: string, data: any = {} ): Promise<any> {
        const requestId = `conduit-request-${++this.requestIndex}-${Math.floor(Math.random() * 1000)}`;
        return new Promise<any>( ( resolve, reject ) => {
            this.requests[requestId] = { resolve, reject, canceled: false };
            this.ready.then( () => {
                /**
                 * Requests can be queued at any time in the application's lifespan, even before the conduit iframe has been created or communications
                 * have been established.  However, no actually message will be broadcast until the initial handshake has occurred.
                 */
                const payload = Object.assign({ type: methodName, requestId: requestId }, data);
                this.conduitWindow.postMessage(payload, this.conduitOrigin );
            } );
        } );
    }
}
