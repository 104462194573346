/**
 * Allows the construction of checkbox type elements, support one checkbox
 *
 * @author Juan Kremer <jkremer@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementCheckbox extends AlFormElementBase<boolean> {

    public controlTemplate = "checkbox";

    constructor(properties: AlDynamicFormElementDescriptor ) {
        super(properties);
    }

    public createFormControl() {
        let formControl = new FormControl(this.value||false);

        if(this.disabled) {
            formControl.disable();
        }

        return formControl;
    }

    getAnswer() {
        return this.getControlValue();
    }
}
