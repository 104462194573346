/**
 * @author Mohasin Nadaf <mohasin.nadaf@alertlogic.com>
 * @author Kevin Nielsen <knielsen@alertlogic.com>
 * @copyright 2020 Alert Logic, Inc.
 */

import {
    trigger, 
    state, 
    style, 
    transition, 
    animate
} from '@angular/animations';
import {
    AfterViewInit, 
    OnChanges, 
    SimpleChanges, 
    Component, 
    Input, 
    EventEmitter, 
    Output, 
    ViewChild, 
    ContentChildren, 
    QueryList, 
    TemplateRef
} from '@angular/core';
import { Sidebar } from 'primeng-lts/sidebar';
import { AlViewHelperComponent } from '../al-view-helper/al-view-helper.component';
import { AlTemplateDirective } from '../directives/al-template.directive';
import { AlNotificationType } from '../types/al-notification-panel.types';
import { AlSidebarConfig } from '../types/al-sidebar.types';


@Component({
    selector: 'al-sidebar',
    templateUrl: './al-sidebar.component.html',
    styleUrls: ['./al-sidebar.component.scss'],
    animations: [
        trigger('panelState', [
            state('hidden', style({
                opacity: 0
            })),
            state('visible', style({
                opacity: 1
            })),
            transition('visible => hidden', animate('300ms ease-in')),
            transition('hidden => visible', animate('300ms ease-out'))
        ])
    ]
})
export class AlSidebarComponent implements AfterViewInit, OnChanges {

    private static defaultConfig:AlSidebarConfig = {
        header: {
            title: '',
            showClose: true,
            icon: {}
        },
        cssClass: "default",
        modal: true,
        isloading: false,
        viewHelper: true,
    };

    @Input() visible: boolean           =   false;
    @Input() cssClass: string           =   "default";
    @Input() config: AlSidebarConfig    =   AlSidebarComponent.defaultConfig;
    @Input() hideOnClose: boolean       =   true;
    @Input() loading?: boolean;
    @Input() appendTo?: any;

    @Output() onSaved: EventEmitter<any> = new EventEmitter();
    @Output() onClosed: EventEmitter<any> = new EventEmitter();
    @Output() onVisibilityChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() onToggleExpand: EventEmitter<boolean> = new EventEmitter();
    @Output() onHeaderIconClicked: EventEmitter<boolean> = new EventEmitter();

    /**
     * -------------------------------------------------------------
     * Private State - do not reference these directly
     * -------------------------------------------------------------
     */

    @ViewChild("viewHelper", { static: false })     helper!: AlViewHelperComponent;
    @ViewChild("pSidebar", { static: false })       pSideBar!: Sidebar;
    @ContentChildren(AlTemplateDirective) 

    templateList:QueryList<AlTemplateDirective>;
    headerTpl?:TemplateRef<any>;
    toolbarTpl?:TemplateRef<any>;
    contentTpl?:TemplateRef<any>;

    inlineStyles:{[klass:string]:string} = {};

    /**
     * -------------------------------------------------------------
     * Public API
     * -------------------------------------------------------------
     */

    /**
     * Makes the sidebar visible.
     */
    public open() {
        this.visible = true;
    }

    /**
     * Closes the sidebar if it is currently visible.
     */
    close( emit:boolean = true ) {
        if ( this.visible ) {
            if (this.hideOnClose) {
                this.hide();
            }
            if ( emit || ! this.hideOnClose ) {
                this.onClosed.emit();
            }
        }
    }

    /**
     * Toggles the sidebar's visibility state.
     */
    public toggle() {
        if ( this.visible ) {
            this.open();
        } else {
            this.close();
        }
    }

    /**
     * Sets the title (and optionally, icon) of the sidebar.
     */
    public setTitle( title:string,
                     iconClasses?:string,
                     iconLigature?:string ) {
        this.config.header.title = title;
        if ( iconClasses || iconLigature ) {
            this.config.header.icon.name = iconLigature;
            this.config.header.icon.cssClasses = iconClasses;
        }
    }

    /**
     * Sets the content template.
     */
    public setContentTemplate( template?:TemplateRef<any> ) {
        this.contentTpl = template;
    }

    /**
     *  Emits a notification through the panel's viewhelper instance.
     */
    public notify(text: string, type: AlNotificationType, autoDismiss: number = 0) {
        this.helper.notify(text, type, autoDismiss);
    }

    /**
     *  Emits an info-style notification through the panel's viewhelper instance.
     */
    public notifyInfo(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.helper.notifyInfo(text, autoDismiss, flush);
    }

    /**
     *  Emits a warning notification through the panel's viewhelper instance.
     */
    public notifyWarning(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.helper.notifyWarning(text, autoDismiss, flush);
    }

    /**
     *  Emits an error notification through the panel's viewhelper instance.
     */
    public notifyError(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.helper.notifyError(text, autoDismiss, flush);
    }

    /**
     *  Emits an success notification through the panel's viewhelper instance.
     */
    public notifySuccess(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.helper.notifySuccess(text, autoDismiss, flush);
    }

    /**
     * Clean the notifications
     */
    public cleanNotifications() {
        this.helper.cleanNotifications();
    }

    /**
     * -------------------------------------------------------------
     * Lifecycle Handlers
     * -------------------------------------------------------------
     */

    ngAfterViewInit() {
        for ( const item of this.templateList ) {
            switch( item.templateId ) {
                case "header":  
                    this.headerTpl = item.template; break;
                case "toolbar": 
                    this.toolbarTpl = item.template; break;
                case "content":
                    this.contentTpl = item.template; break;
                default :       
                    throw new Error(`Usage Error: <al-sidebar> does not accept a custom template with id '${item.templateId}'` );
            }
        }
    }

    ngOnChanges( changes:SimpleChanges ) {
        if ( 'loading' in changes ) {
            this.config.isloading = this.loading || false;
        }
        if ( 'config' in changes ) {
            if ( ! this.config ) {
                this.config = Object.assign( {}, AlSidebarComponent.defaultConfig );
            }
            if ( ! this.config.header ) {
                this.config.header = AlSidebarComponent.defaultConfig.header;
            }
            if ( this.config.cssClass ) {
                this.cssClass = this.config.cssClass;
            }
            this.cssClass = this.config.expand ? 'expand' : 'default';
        }
        if ( 'appendTo' in changes ) {
            this.inlineStyles.position = this.appendTo ? 'absolute' : undefined;
        }
    }


    /**
     * -------------------------------------------------------------
     * Event Handlers - do not call these directly.
     * -------------------------------------------------------------
     */
    toggleExpand() {
        this.config.expand = !this.config.expand;
        this.cssClass = this.config.expand ? 'expand' : 'default'
        this.onToggleExpand.emit(this.config.expand);
    }

    /**
     * Fires on click of close button or pressing the ESC key.
     * DO NOT CALL THIS METHOD DIRECTLY.
     */

    hide() {
        this.visible = false;
        if (this.pSideBar.mask) {
            this.pSideBar.destroyModal();
        }
    }

    sidebarShown() {
        this.onVisibilityChanged.emit( true );
    }

    sidebarHidden() {
        this.onVisibilityChanged.emit( false );
    }

    headerIconClicked() {
        this.onHeaderIconClicked.emit();
    }

    /**
     * Fires on click of primary action button if the default toolbar template is used
     */
    onPrimaryAction() {
        if ( this.config.primary && this.config.primary.callback ) {
            this.config.primary.callback();
        } else {
            this.onSaved.emit();
        }
    }

    /**
     * Fires on click of secondary action button if the default toolbar template is used
     */
    onSecondaryAction() {
        if (this.config.secondary && this.config.secondary.callback) {
            this.config.secondary.callback();
        } else {
            this.close();
        }
    }

    /**
     * Fires on click of tertiary action button if the default toolbar template is used
     */
    onTertiaryAction() {
        if (this.config.ternary && this.config.ternary.callback) {
            this.config.ternary.callback();
        } else {
            this.close();
        }
    }

}
