import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';


export enum AldDatetimeEnum {
    Default     = 'yyyy-MM-dd hh:mm:ss a UTCZZZZZ',
    NoSeconds   = 'yyyy-MM-dd hh:mm a UTCZZZZZ',
    NoTime      = 'yyyy-MM-dd',
    militaryTimeNoTimezone = 'yyyy-MM-dd HH:mm'
}

@Pipe( {
    name: 'aldDateTime'
} )
export class AldDatetimePipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe,
    ) {
    }

    transform(date?: Date | number | string, format?: string | AldDatetimeEnum): string {
        if (!date) {
            return '';
        }
        if (!format) {
            format = AldDatetimeEnum.Default;
        }
        return this.datePipe.transform( date, format );
    }
}
