import { NgSelectComponent } from '@ng-select/ng-select';
import {
    Component, 
    ElementRef, 
    EventEmitter, 
    Input, 
    NgZone, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    Output, 
    QueryList, 
    ViewChild, 
    ViewChildren
} from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng-lts/api';
import { Menu } from 'primeng-lts/menu';
import {
    AIMSAccount, 
    AIMSClient, 
    AlActingAccountChangedEvent, 
    AlActingAccountResolvedEvent, 
    AlInsightLocations, 
    AlLocatorService, 
    AlRoute, 
    AlSession, 
    AlSessionEndedEvent, 
    AlSessionStartedEvent, 
    AlSubscriptionGroup
} from '@al/core';
import { AlTrackingMetricEventName, AlTrackingMetricEventCategory } from '@al/ng-generic-components';
import { AlNavigationService } from '../../services/al-navigation.service';
import { AlDatacenterOptionsSummary, AlNavigationContextChanged } from '../../types/navigation.types';


@Component({
    selector: 'al-archipeligo19-app-header',
    templateUrl: './al-archipeligo19-app-header.component.html',
    styleUrls: ['./al-archipeligo19-app-header.component.scss']
})
export class AlArchipeligo19AppHeaderComponent implements OnInit, OnChanges, OnDestroy
{
    @Input() userMenu:AlRoute;

    authenticated = false;
    ready = false;

    actingAccountName = '';
    actingAccountId: string;
    actingAccount: AIMSAccount = null;

    username = '';

    managedAccounts: AIMSAccount[] = [];
    loadedManagedAccounts                   =   false;
    loadingManagedAccounts                  =   false;

    userMenuItems: MenuItem[] = [];

    datacenter:AlDatacenterOptionsSummary;

    subscriptions = new AlSubscriptionGroup( null );

    @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
    @ViewChild('accountSelector') accountSelector: NgSelectComponent
    @ViewChild('menux') regionSelector: Menu;


    // TODO - Thinking instead we should have navigation component service, where we can emit toggle state and
    // listen to that in the al-sidenav,
    // Otherwise every app will need to handle this output and set the toggle boolean @input to the al-sidenav!!
    @Output() toggleButtonClick: EventEmitter<any> = new EventEmitter();

    constructor( public navigation:AlNavigationService,
                 public ngZone:NgZone,
                 private confirmationService: ConfirmationService ) {
        if ( AlSession.isActive() ) {
            this.loadActiveSession();
        }
    }

    async loadActiveSession() {
        await AlSession.ready();
        await this.navigation.ready();
        this.actingAccount = AlSession.getActingAccount();
        this.actingAccountId = this.actingAccount.id;
        this.actingAccountName = this.actingAccount.name;
        this.managedAccounts = [ this.actingAccount ];
        this.username = AlSession.getUserName();
        this.ready = true;
    }

    ngOnInit() {
        this.subscriptions.manage( [
            AlSession.notifyStream.attach( AlSessionStartedEvent, this.onSessionStart),
            AlSession.notifyStream.attach( AlActingAccountChangedEvent, this.onActingAccountChanged),
            AlSession.notifyStream.attach( AlActingAccountResolvedEvent, this.onActingAccountResolved),
            AlSession.notifyStream.attach( AlSessionEndedEvent, this.onSessionEnded ),
            this.navigation.events.attach( AlNavigationContextChanged, this.onNavigationContextChanged )
        ] );
        this.actingAccount = AlSession.getActingAccount();
        if ( this.actingAccount ) {
            this.actingAccountId = this.actingAccount.id;
        }
        this.onNavigationContextChanged();
    }

    ngOnChanges( changes ) {
        if ( 'userMenu' in changes ) {
            this.refreshUserMenu();
        }
    }

    ngOnDestroy() {
        this.subscriptions.cancelAll();
    }

    onSessionEnded = () => {
        this.authenticated = false;
    }

    onSessionStart = () => {
        this.authenticated = true;
        this.loadActiveSession();
    }

    onActingAccountChanged = ( ) => {
        this.ready = false;
    }

    onActingAccountResolved = (event: AlActingAccountResolvedEvent) => {
        this.actingAccount = event.actingAccount;
        this.actingAccountId = event.actingAccount.id;
        if ( this.managedAccounts.length < 2 ) {
            this.managedAccounts = [ event.actingAccount ];
        }
        this.ready = true;
    }

    onNavigationContextChanged = ( ) => {
        if ( AlSession.isActive() ) {
            this.authenticated = true;
            this.datacenter = this.navigation.generateDatacenterMenu( AlSession.getActiveDatacenter(),
                                                                        AlSession.getActingAccountAccessibleLocations(),
                                                                        this.onClickDatacenter );
        } else {
            this.datacenter = undefined;
        }
        this.refreshUserMenu();
    }

    refreshUserMenu() {
        if ( ! this.userMenu ) {
            return;
        }
        this.userMenuItems = this.userMenu.children
                                .filter( route => route.visible )
                                .map( route => {
                                    return {
                                        label: route.caption,
                                        icon: route.properties?.icon || null,
                                        command: () => this.navigation.dispatch( route )
                                    };
                                } );
    }

    onAccountChanged() {
        this.navigation.setActingAccount(this.actingAccountId);
    }

    accountSearchFn(term: string, account: AIMSAccount) {
        term = term.toLocaleLowerCase();
        return account.name.toLocaleLowerCase().indexOf(term) > -1 || account.id.startsWith(term);
    }

    async onOpenAccountSelector() {
        const canNavigate = await this.navigation.canNavigateAway();
        if (canNavigate) {
            if ( ! this.loadedManagedAccounts ) {
                this.loadingManagedAccounts = true;
                this.managedAccounts = [];
                AIMSClient.getAccountsByRelationship( AlSession.getPrimaryAccountId(), "managed", { active: true } ).then( ( managedAccounts:AIMSAccount[] ) => {
                    this.managedAccounts = [ AlSession.getPrimaryAccount(), ...managedAccounts ]
                                           .sort( ( a, b ) => a.name.toUpperCase().localeCompare( b.name.toUpperCase() ) );
                    this.loadedManagedAccounts = true;
                    this.loadingManagedAccounts = false;
                } );
                this.filterInput.changes.subscribe(() => {     //  we don't have to unsubscribe?  Okay...
                    if (this.filterInput.first) {
                        this.filterInput.first.nativeElement.focus();
                    }
                });
            }
        }
    }

    onClickDatacenter = ( insightLocationId:string ) => {
        this.navigation.setActiveDatacenter( insightLocationId );
    }

    redirectToDashboards() {
        this.navigation.navigate.byNamedRoute("cd19:dashboards");
    }

    filterAccounts( $event:Event ) {
      this.accountSelector.filter((<HTMLInputElement>$event.target).value);
    }

    toggleClick() {
        this.toggleButtonClick.emit();
    }

    async onOpenRegionSelector($event){
        const canNavigate = await this.navigation.canNavigateAway();
        if (canNavigate) {
            this.regionSelector.toggle($event);
        }
    }

}
