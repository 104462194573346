import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { AlActingAccountResolvedEvent, AlSession, AlSessionEndedEvent, AlSubscriptionGroup } from '@al/core';
import { AlNavigationService } from '../../services/al-navigation.service';


@Component({
  selector: 'al-archipeligo17-header',
  templateUrl: './al-archipeligo17-header.component.html',
  styleUrls: [ './al-archipeligo17-header.component.scss' ]
})
export class AlArchipeligo17HeaderComponent implements OnInit, OnDestroy {

    @Input()
    public transparentMode = false;

    public authenticated = false;

    protected subscriptions = new AlSubscriptionGroup();

    constructor( public alNavigation:AlNavigationService ) {
    }

    ngOnInit() {
        this.authenticated = AlSession.isActive();
        this.subscriptions.manage( this.alNavigation.events.attach( AlActingAccountResolvedEvent, this.onActingAccountResolved ) );
        this.subscriptions.manage( this.alNavigation.events.attach( AlSessionEndedEvent, this.onSessionEnded ) );
    }

    ngOnDestroy() {
        this.subscriptions.cancelAll();
    }

    onSessionEnded = () => {
        this.authenticated = false;
    }

    onActingAccountResolved = () => {
        this.authenticated = true;
    }
}
