/**
 *
 * Sample about how to use it
 *   <al-loading-spinner *ngIf="condition for hide/show"></al-loading-spinner>
 */
import { Component } from '@angular/core';


@Component({
    selector: 'al-loading-spinner',
    templateUrl: './al-loading-spinner.component.html',
    styleUrls: ['./al-loading-spinner.component.scss']
})
export class AlLoadingSpinnerComponent {
}
