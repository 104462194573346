<al-layout-options [disableHeader]="true" [disablePrimaryMenu]="true" pageClass="white"></al-layout-options>
<div class="authentication-view">
    <div class="authentication-view__contents">

        <div>
            <div class="header">
                <div class="circle-logo"><i class="icon-logo al al-logo"></i></div>
                <h2 class="title">Multi-Factor Authentication</h2>
            </div>

            <al-view-helper [loading]="loading" [blur]="true"></al-view-helper>

            <form #authenticationCodeForm="ngForm" (ngSubmit)="onSubmitAuthenticateCode()">
                <div class="u-grid">
                    <div class="u-col-12">
                        <div class="u-text-sm u-pb-5">Please provide your authentication code below:</div>
                        <span class="p-input-field p-float-label">
                            <!-- Code input -->
                            <input autocomplete="one-time-code" 
                                    alForceFocus
                                    type="text" 
                                    name="authenticationCode" 
                                    id="authentication_code"
                                    [(ngModel)]="authenticationCode" 
                                    #authenticationCodeField="ngModel" 
                                    required 
                                    pInputText />
                            <label for="authentication_code" class="p-inputwrapper-filled">Authentication code</label>
                            <!-- End of Code input -->
                        </span>
                    </div>
                    <div class="u-col-12">
                        <button type="submit" 
                                pButton 
                                color="primaryAction" 
                                label="Authenticate" 
                                class=" primaryAction u-min-w-100%" 
                                [disabled]="!authenticationCodeForm.form.valid">
                        </button>
                    </div>
                    <div class="u-col-12 u-text-xs">
                        Problem setting up MFA? Contact your account Administrator for help.
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
