<div class="selectable-list"
     [ngStyle]="{'height': config.height}">
    <ng-container *ngIf="data.length !== 0">

        <p-virtualScroller #scroll
                           [scrollHeight]="config.scrollHeight ? config.scrollHeight : '1000px'"
                           [value]="data | sort: config.sortBy: config.sortDirection"
                           [itemSize]="config.scrollItemSize ? config.scrollItemSize : null">

            <ng-template pTemplate="item"
                         let-item>
                <div class="row-selectable-list">
                    <div class="action-selectable-list"
                         *ngIf="!config.action.isRight"
                         (click)="actionItem(item)">
                        <i [class]="config.action.icon"> </i>
                        <span>{{ config.action.label | uppercase }}</span>
                    </div>
                    <div class="selectable-list-item ">

                        <div  *ngIf="item.title"
                             class="item-title">
                             <div >
                                <i *ngIf="item.icon"
                                class="icon-selectable-list {{item.icon}}"
                                [pTooltip]="item.type"
                                [tooltipPosition]="item.type ? 'left' : ''"></i>
                             </div>
                           
                            <div [pTooltip]="item.title"
                                 [tooltipPosition]="item.title ? 'right' : ''"
                                 class="label-selectable-list">
                                <span>{{ (item.title | slice:0:99) + (item.title.length > 100 ? '...' : '')
                                    }}</span>
                            </div>
                        </div>

                        <div class="list-of-sub-items"
                             *ngIf="item.subItems?.length > 0">
                            <div *ngFor="let subItem of item.subItems">
                                <div class="sub-item">
                                    <div class="subitem-title">
                                        {{ (subItem.title | slice:0:99) + (subItem.title.length > 100 ? '...' : '') }}
                                    </div>
                                    <div class="subitem-detail"
                                         [ngStyle]="{width: item.subItems.length > 1 ? '35%': '40%'}">
                                        {{ (subItem.details | slice:0:99) + (subItem.details.length > 100 ? '...' : '')
                                        }}
                                    </div>
                                    <div class="subitem-action"
                                         *ngIf="item.subItems.length > 1">
                                        <i [class]="subItem.icon"
                                           (click)="onSubItemAction(subItem)">
                                            {{ subItem.icon }}
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.labels && item.labels.length"
                             class="item-label">
                            <span *ngFor="let label of item.labels">{{ label }}</span>
                            <span *ngIf="item.modified as modified"
                                class="item-modified">
                                <ng-container *ngIf="modified.at === 0">
                                    {{modified.label}} by {{modified.by}}
                                </ng-container>
                                <ng-container *ngIf="modified.at !== 0">
                                    {{modified.label}} at {{modified.at | date:'HH:mm'}} on {{modified.at | date:'yyyy-MM-dd'}} by {{modified.by}}
                                </ng-container>
                                
                            </span>
                        </div>
                        <div *ngIf="item.description as description" class="item-label">
                            <span><b>{{description.label}}:</b> {{description.value}}</span>
                        </div>
                    </div>
                    <div class="action-selectable-list"
                         *ngIf="config.action.isRight"
                         (click)="actionItem(item)">
                        <i class="{{ config.action.icon }}" > </i>
                        <span >{{ config.action.label | uppercase }}</span>
                    </div>

                </div>
            </ng-template>

        </p-virtualScroller>

    </ng-container>



    <al-view-helper [empty]="data.length === 0">
        <div zero-state-icon>
            <i *ngIf="config.zeroStateIcon"
               class="{{config.zeroStateIcon}}"></i>
        </div>
        <span zero-state-description>
            <p>{{ config.zeroStateMessage }}</p>
        </span>
    </al-view-helper>

</div>