import { Component, Input, OnChanges } from '@angular/core';


export enum StackDirection {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

export enum StackDistribution {
    start = 'start',
    end = 'end',
    center = 'center',
    between = 'between',
    around = 'around',
    evenly = 'evenly',
}

export enum StackAlignment {
    start = 'start',
    end = 'end',
    center = 'center',
    stretch = 'stretch',
}

@Component({
    selector: 'ald-stack',
    templateUrl: './ald-stack.component.html',
    styleUrls: ['./ald-stack.component.scss']

})
export class AldStackComponent implements OnChanges {
    @Input() direction: StackDirection|string = StackDirection.horizontal;
    @Input() alignment: StackAlignment|string = StackAlignment.start;
    @Input() distribution: StackDistribution|string = StackDistribution.between;
    @Input() wrap = true;
    @Input() gap = 0;

    ngOnChanges(): void {
        this.updateLayout();
    }

    updateLayout(): string {
        const flexDirection = `u-flex-${this.direction === StackDirection.vertical ? 'col' : 'row'}`;
        const flexAlignment = `u-items-${this.alignment || 'start'}`;
        const flexDistribution = `u-justify-${this.distribution || 'between'}`;

        return `${flexDirection} ${flexDistribution} ${flexAlignment}`;
    }
}
