import { FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementInputSwitch extends AlFormElementBase<string> {

    public controlTemplate = "inputSwitch";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
    }

    public createFormControl() {

        let formControl = new FormControl(this.value || false);

        if(this.disabled) {
            formControl.disable();
        }

        return formControl;
    }

    getAnswer(): any {
        return this.getControlValue();
    }
}
