import {
    Component, 
    Input, 
    OnChanges, 
    OnDestroy, 
    OnInit, 
    QueryList, 
    SimpleChanges, 
    TemplateRef, 
    ViewChildren
} from '@angular/core';
import { Menu } from 'primeng-lts/menu';
import {
    AlRoute, 
    AlSession, 
    AlSessionEndedEvent, 
    AlSessionStartedEvent, 
    AlSubscriptionGroup
} from '@al/core';
import { AlTemplateIndexService } from '@al/ng-generic-components';
import { AlNavigationService } from '../../services/al-navigation.service';
import { AlNavigationInitializeToggle, AlNavigationNavHeaderMounted, AlNavigationRemoveToggle, ExperienceToggleDefinition } from '../../types/navigation.types';


@Component({
    selector: 'al-archipeligo19-nav-header',
    templateUrl: './al-archipeligo19-nav-header.component.html',
    styleUrls: ['./al-archipeligo19-nav-header.component.scss']
})
export class AlArchipeligo19NavHeaderComponent implements OnInit, OnChanges, OnDestroy {

    @Input() breadcrumbs: AlRoute[] = [];
    @Input() headerActionsMenus: AlRoute[] = [];
    @ViewChildren('headerActionsItemMenus') headerActionsItemMenus:QueryList<Menu>;
    @Input() addendumToNavTitle: string;
    @Input() allowUnauthenticatedMenus = false;

    displayIconName = '';
    subscriptions: AlSubscriptionGroup = new AlSubscriptionGroup(null);
    authenticated = false;
    experienceToggle: ExperienceToggleDefinition | undefined = undefined;
    contextualActionsTpl?:TemplateRef<any>;
    isBeta = false;

    constructor( public alNavigation: AlNavigationService,
                 public templateIndex:AlTemplateIndexService ) {
        this.subscriptions.manage([
            this.alNavigation.events.attach(AlNavigationInitializeToggle, (event: AlNavigationInitializeToggle) => {
                this.experienceToggle = <ExperienceToggleDefinition> {
                    label: event.label,
                    tooltip: event.tooltip,
                    checked: event.checked,
                    callback: event.callback,
                };
            }),
            this.alNavigation.events.attach(AlNavigationRemoveToggle, () => {
                this.experienceToggle = undefined;
            }),
        ]);
    }

    ngOnInit() {
        this.authenticated = AlSession.isActive();
        this.subscriptions.manage([
            AlSession.notifyStream.attach(AlSessionStartedEvent, this.onSessionStart),
            AlSession.notifyStream.attach(AlSessionEndedEvent, this.onSessionEnd),
            this.templateIndex.listen( 'navigation', () => this.onRefreshTemplates() )
        ]);
        this.alNavigation.events.trigger(new AlNavigationNavHeaderMounted());
        this.onRefreshTemplates();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("breadcrumbs")) {
            this.inferIconFromBreadcrumbs(this.breadcrumbs || []);
            this.isBeta = this.breadcrumbs.some((breadcrumb: AlRoute) => {
                const props: {[property: string]: any} = breadcrumb.properties;

                if (props && props.isBeta === true) {
                    return true;
                } else {
                    return false;
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.cancelAll();
    }

    onSessionStart = () => {
        this.authenticated = true;
    }

    onSessionEnd = () => {
        this.authenticated = false;
    }

    onRefreshTemplates() {
        this.contextualActionsTpl = this.templateIndex.getTemplate( 'navigation', 'contextualActions' );
    }

    inferIconFromBreadcrumbs(breadcrumbs: AlRoute[]) {
        let breadcrumbIcon = '';
        breadcrumbs.forEach(breadcrumb => {
            breadcrumbIcon = breadcrumb.getProperty("iconClass", breadcrumbIcon);
        });
        this.displayIconName = breadcrumbIcon;
    }

    dispatch(route: AlRoute, $event: MouseEvent, hasSubmenu: boolean = false): void {
        const menuId: string = route.properties.menuId;
        // If the item does not has submenu: dispatch route directly
        // otherwise open the dropdown menu
        if (!hasSubmenu) {
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }
            route.dispatch();
        } else {
            const findChilds = (item: Menu): boolean => {
                const childId: string = item.model[0].id.split('--')[0];// why model[0]? because all the childs will have the same parent, then the same id part [menuId--index]
                return menuId && menuId === childId;
            };
            const menu: Menu = this.headerActionsItemMenus.find(findChilds);
            if (menu) {
                // Open dropdown
                menu.toggle($event);
            }
        }
    }
}
