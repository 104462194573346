/**
 *  @moduleName: AldCommonModule
 *  @moduleImport: @al/design-patterns/common
 *
 *  Please DO NOT modify this file directly.  It is regenerated automatically by running 'npm run restructurate'.  Ask Kevin if you have questions!
 */

import { AldButtonComponent } from './ald-button/ald-button.component';
import { AldCardComponent } from './ald-card/ald-card.component';
import { AldDataFieldComponent } from './ald-data-field/ald-data-field.component';
import { AldIconComponent } from './ald-icon/ald-icon.component';
import { AldInlineSvgComponent } from './ald-inline-svg/ald-inline-svg.component';
import { AldLinkComponent } from './ald-link/ald-link.component';
import { AldLoadingSpinnerComponent } from './ald-loading-spinner/ald-loading-spinner.component';
import { AldStackComponent, StackAlignment, StackDirection, StackDistribution } from './ald-stack/ald-stack.component';
import { AldResizeDirective } from './directives/resize.directive';
import { AldDatetimeEnum, AldDatetimePipe } from './pipes/ald-datetime.pipe';
import { AldEvaluatePipe } from './pipes/ald-evaluate.pipe';
import { AldFilterPipe } from './pipes/ald-filter.pipe';
import { AldFormatNumberPipe } from './pipes/ald-format-number.pipe';
import { AldLineBreakPipe } from './pipes/ald-line-break.pipe';
import { AldObjectValuePipe, getValueFromPath } from './pipes/ald-object-value.pipe';
import { AldRemoveWhitespacePipe } from './pipes/ald-remove-whitespace.pipe';
import {
    AldOptionItem, 
    ButtonSize, 
    ButtonVariant, 
    IconClass, 
    IconPosition, 
    IconSize, 
    PaginationType
} from './types/al-common.types';


const MODULE_COMPONENTS = [
    AldButtonComponent,
    AldCardComponent,
    AldDataFieldComponent,
    AldIconComponent,
    AldInlineSvgComponent,
    AldLinkComponent,
    AldLoadingSpinnerComponent,
    AldStackComponent,
];

const MODULE_DIRECTIVES = [
    AldResizeDirective,
];

const MODULE_PIPES = [
    AldDatetimePipe,
    AldEvaluatePipe,
    AldFilterPipe,
    AldFormatNumberPipe,
    AldLineBreakPipe,
    AldObjectValuePipe,
    AldRemoveWhitespacePipe,
];

const MODULE_SERVICES = [

];


export {
    MODULE_COMPONENTS,
    MODULE_DIRECTIVES,
    MODULE_PIPES,
    MODULE_SERVICES,
        AldButtonComponent,
    AldCardComponent,
    AldDataFieldComponent,
    AldDatetimeEnum,
    AldDatetimePipe,
    AldEvaluatePipe,
    AldFilterPipe,
    AldFormatNumberPipe,
    AldIconComponent,
    AldInlineSvgComponent,
    AldLineBreakPipe,
    AldLinkComponent,
    AldLoadingSpinnerComponent,
    AldObjectValuePipe,
    AldOptionItem,
    AldRemoveWhitespacePipe,
    AldResizeDirective,
    AldStackComponent,
    ButtonSize,
    ButtonVariant,
    getValueFromPath,
    IconClass,
    IconPosition,
    IconSize,
    PaginationType,
    StackAlignment,
    StackDirection,
    StackDistribution,
};

export * from './types/al-common.types';
export * from './pipes/ald-datetime.pipe';
export * from './pipes/ald-object-value.pipe';
export * from './ald-stack/ald-stack.component';