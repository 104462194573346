import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'existsInArray'
})
export class AlExistsInArrayPipe implements PipeTransform {

    transform(value: any, array: any[]): boolean {
        return array.includes(value);
    }

}
