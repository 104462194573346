import {
    AfterContentInit, 
    Component, 
    Input, 
    ContentChildren, 
    QueryList
} from '@angular/core';
import { AldCheckboxComponent } from '../ald-checkbox/ald-checkbox.component';
import { AldRadioComponent } from '../ald-radio/ald-radio.component';


@Component({
    selector: 'ald-fieldset',
    templateUrl: './ald-fieldset.component.html',
    styleUrls: ['./ald-fieldset.component.scss']
})
export class AldFieldsetComponent implements AfterContentInit{
    @Input() legend?: string;
    @Input() legendLarge?: boolean;
    @Input() inline?: boolean  = false;
    @Input() hint?: string;
    @Input() tip?: string;
    @Input() required?: boolean;

    fieldsetContent = 'c-form-group';

    @ContentChildren(AldRadioComponent) radios: QueryList<AldRadioComponent>;
    @ContentChildren(AldCheckboxComponent) checkboxes: QueryList<AldCheckboxComponent>;

    ngAfterContentInit(): void {
        if (this.radios.length > 0 || this.checkboxes.length > 0) {
            this.fieldsetContent = '';
        }
    }

}
