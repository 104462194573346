import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import * as manifest from './manifest';


@NgModule({
    declarations: [
        ...manifest.MODULE_PIPES,
        ...manifest.MODULE_DIRECTIVES,
        ...manifest.MODULE_COMPONENTS
    ],
    imports: [
        CommonModule,
        RouterModule,
        OverlayModule
    ],
    providers: [
        ...manifest.MODULE_PIPES,
        ...manifest.MODULE_DIRECTIVES
    ],
    exports: [
        ...manifest.MODULE_PIPES,
        ...manifest.MODULE_DIRECTIVES,
        ...manifest.MODULE_COMPONENTS,
    ]
})

export class AldCommonModule { }
