<div class="u-flex u-items-center u-justify-center u-flex-shrink-0 u-overflow-hidden" 
    [ngClass]="[
        size === 'sm' ? 'u-w-5 u-h-5': '',
        size === 'md' ? 'u-w-6 u-h-6': '',
        size === 'lg' ? 'u-w-9 u-h-9': '',
        size === 'xl' ? 'u-w-12 u-h-12': '',
    ]"
>
    <i
        [ngClass]="[
            iconClass ? iconClass : 'material-icons',
            color ? color : '',
            size === 'sm' ? 'c-icon-sm': '',
            size === 'md' ? 'c-icon-md': '',
            size === 'lg' ? 'c-icon-lg': '',
            size === 'xl' ? 'c-icon-xl': '',
        ]"
        [style.opacity]="opacity"
    >
        {{ icon }}
    </i>
</div>
