import {
    Component, 
    EventEmitter, 
    Input, 
    OnChanges, 
    OnInit, 
    Output, 
    SimpleChanges
} from '@angular/core';
import { AlToolbarContentConfig } from '@al/ng-generic-components';
import { AlTreeOptionItem, AlTreeTableColumnDef, AlTreeTableColumnType } from './types/al-tree-table.types';


@Component( {
    selector: 'al-tree-table',
    templateUrl: './al-tree-table.component.html',
    styleUrls: ['./al-tree-table.component.scss']
} )
export class AlTreeTableComponent implements OnInit, OnChanges {

    @Input() data: AlTreeOptionItem[] = [];
    @Input() columns: AlTreeTableColumnDef[] = [];
    @Input() visibleRows: number = 15; // Indicates number of allowed visible rows, after that number a scrollbar will appear
    @Input() showContentToolbar: boolean = true;
    @Input() loading: boolean = false;
    @Input() toolbarConfig!: AlToolbarContentConfig;
    @Input() zeroStateIcon: string = "search";
    @Input() zeroStateClassIcon: string = "material-icons";
    @Input() zeroStateText: string = "No items matched your search";
    @Input() multiselection: boolean = false;
    @Input() fieldsToSearch: string[] = ["label", "id"];
    @Output() onDidSelectRow: EventEmitter<AlTreeOptionItem[]> = new EventEmitter<AlTreeOptionItem[]>();
    @Output() onDidDeselectRow: EventEmitter<AlTreeOptionItem[] | void> = new EventEmitter<AlTreeOptionItem[] | void>();
    @Output() onDidSelectFilters: EventEmitter<string[]> = new EventEmitter<string[]>();

    public treeTableColumnType = AlTreeTableColumnType;

    selectedIndexRow = -1;
    selectedDepth = -1;
    selectedId: string | number = -1;
    filteredData: AlTreeOptionItem[] = [];
    selectedItems: AlTreeOptionItem[] = [];
    expanded: boolean = false;
    startItem: AlTreeOptionItem | null = null;
    endItem: AlTreeOptionItem | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        this.updateEntryData();
    }

    ngOnInit(): void {
        this.updateEntryData();
    }

    public selectRow(event: PointerEvent, item: AlTreeOptionItem, index: number, depth: number): void {
        if (this.selectedItems.some( selectItem => selectItem.id === item.id )) {
            this.resetSelectedValues();
            item.selected = false;
            this.selectedItems = this.selectedItems.filter( selectItem => selectItem.id !== item.id );
            if (!(event.metaKey || event.ctrlKey || event.shiftKey) || (event.shiftKey && this.startItem !== null && this.endItem !== null)) {
                this.cleanRows();
            }
            this.onDidDeselectRow.emit( this.selectedItems );
        } else if (index === this.selectedIndexRow && depth === this.selectedDepth && item.id === this.selectedId) {
            this.resetSelectedValues();
            item.selected = false;
            this.onDidDeselectRow.emit();
        } else {
            if (!this.multiselection || (this.multiselection && !(event.metaKey || event.ctrlKey || event.shiftKey))) {
                this.cleanRows();
            }
            this.selectedIndexRow = index;
            this.selectedDepth = depth;
            this.selectedId = item.id;
            item.selected = true;
            if (this.startItem === null) {
                this.startItem = item;
            }
            if (this.multiselection && event.shiftKey) {
                if (this.startItem !== null && this.endItem !== null) {
                    this.cleanRows();
                    return this.onDidDeselectRow.emit( [] );
                } else if (this.endItem === null && this.startItem.id !== item.id) {
                    this.endItem = item;
                    if (!this.selectSiblingsRows()) {
                        return this.onDidDeselectRow.emit( [] );
                    }
                }
            }
            if (this.multiselection && (event.metaKey || event.ctrlKey || event.shiftKey)) {
                this.selectedItems = this.getAllSelected();
            }
            this.onDidSelectRow.emit( this.selectedItems.length <= 1 ? [item] : this.selectedItems );
        }
    }

    public toggleExpand(event: Event, node: AlTreeOptionItem): void {
        if (event) {
            event.stopPropagation();
        }
        if (Array.isArray( node.items ) && node.items.length > 0) {
            node.expanded = !node.expanded;
        }
        const allRootsCollapsed: boolean = this.filteredData.every( node => (!node.expanded) );
        if (allRootsCollapsed) {
            this.expanded = false;
        }
    }

    public toggleAll(event: Event, items: AlTreeOptionItem[], expandedValue?: boolean): void {
        this.expanded = expandedValue ?? !this.expanded;
        const toggleAllFcn = (items: AlTreeOptionItem[]) => {
            items.forEach( item => {
                item.expanded = this.expanded;
                if (Array.isArray( item.items ) && item.items.length > 0) {
                    toggleAllFcn( item.items );
                }
            } );
        };
        toggleAllFcn( items );
    }

    // Searching based on fieldsToSearch
    onSearch(termToSearch: string): void {
        if (termToSearch.trim() !== "") {
            termToSearch = termToSearch.trim().toLowerCase();
            this.filteredData = JSON.parse( JSON.stringify( this.data ) );
            const checkData = (data: AlTreeOptionItem[], termToSearch: string) => {
                let found = false;
                for (let i = 0; i < data.length; i++) {
                    let item = data[i];
                    for (let field of this.fieldsToSearch) {
                        let prop = field.split( '.' ).reduce( (o, i) => o?.[i], item );
                        if (prop && (prop + "").toLowerCase().includes( termToSearch )) {
                            found = true;
                        }
                    }
                }
                return found;
            };
            const filterData = (data: AlTreeOptionItem[], termToSearch: string) => {
                for (let index = 0; index < data.length; index++) {
                    const row: AlTreeOptionItem = data[index];
                    if (Array.isArray( row.items ) && row.items.length > 0) {
                        filterData( row.items, termToSearch );
                        const found = checkData( row.items, termToSearch );
                        row.items = row.items.filter( (item: AlTreeOptionItem) => !(JSON.stringify( item ) === JSON.stringify( {id: ""} )));
                        if (row.items.length === 0 && !found) {
                            data[index] = {id: ""};
                        }
                    } else {
                        const found = checkData( [row], termToSearch );
                        if (!found) {
                            data[index] = {id: ""};
                        }
                    }
                }
            };
            filterData( this.filteredData, termToSearch );
            this.filteredData = this.filteredData.filter( (item: AlTreeOptionItem) => !(JSON.stringify( item ) === JSON.stringify( {id: ""} )) );
            this.toggleAll( new Event( 'click' ), this.filteredData, true );
        } else {
            this.filteredData = JSON.parse( JSON.stringify( this.data ) );
            this.expanded = false;
        }
        this.onDidDeselectRow.emit();
    }

    onSelectedFilters(filters: string[]): void {
        this.onDidSelectFilters.emit( filters );
        this.expanded = false;
    }

    private cleanRows(): void {
        this.selectedItems = [];
        this.startItem = null;
        this.endItem = null;
        this.selectedIndexRow = -1;
        this.selectedDepth = -1;
        this.selectedId = -1;
        this.unselectAllRows( this.filteredData );
    }

    private unselectAllRows(data: AlTreeOptionItem[]): void {
        data.forEach( node => {
            node.selected = false;
            if (Array.isArray( node.items ) && node.items.length > 0) {
                this.unselectAllRows( node.items );
            }
        } );
    }

    private updateEntryData(): void {
        this.filteredData = JSON.parse( JSON.stringify( this.data ) );
    }

    private getAllSelected(): AlTreeOptionItem[] {
        // eslint-disable-next-line prefer-const
        let selectedItems: AlTreeOptionItem[] = [];
        const searchSelected = (items: AlTreeOptionItem[]) => {
            items.forEach( item => {
                if (item.selected) {
                    selectedItems.push( item );
                }
                if (Array.isArray( item.items ) && item.items.length > 0) {
                    searchSelected( item.items );
                }
            } );
        };
        searchSelected( this.filteredData );
        return selectedItems;
    }

    private resetSelectedValues(): void {
        this.selectedIndexRow = -1;
        this.selectedDepth = -1;
        this.selectedId = -1;
    }

    private getParentRow(idToSearch: string | number | undefined): AlTreeOptionItem | null {
        const ancestors: AlTreeOptionItem[] = [];
        let parent: AlTreeOptionItem | null = null;
        const searchForAncestors = (tree: AlTreeOptionItem[]) => {
            for (let index = 0; index < tree.length; index++) {
                const node = tree[index];
                if (node.id === idToSearch) {
                    parent = ancestors.pop() ?? null;
                    break;
                } else {
                    ancestors.push( node );
                    if (Array.isArray( node.items ) && node.items.length > 0) {
                        searchForAncestors( node.items );
                    }
                }
                ancestors.pop();
            }
        };
        searchForAncestors( this.filteredData );
        return parent;
    }

    private selectSiblingsRows(): boolean {
        const startParentRow = this.getParentRow( this.startItem?.id );
        const endParentRow = this.getParentRow( this.endItem?.id );
        if (startParentRow?.id === endParentRow?.id && endParentRow?.items) { // same parent
            let startItemPosition = endParentRow.items.findIndex( item => item.id === this.startItem?.id );
            let endItemPosition = endParentRow.items.findIndex( item => item.id === this.endItem?.id );
            if (startItemPosition > endItemPosition) {
                startItemPosition = [endItemPosition, endItemPosition = startItemPosition][0]; // swapping the values
            }
            for (let index = startItemPosition; index <= endItemPosition; index++) {
                endParentRow.items[index].selected = true;
            }
            return true;
        } else {
            // different parent is not valid
            this.cleanRows();
            return false;
        }
    }

}
