import {
    Component,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    ElementRef,
    OnInit,
    AfterViewInit
} from '@angular/core';
import { SelectItem } from 'primeng-lts/api';
import { Calendar } from 'primeng-lts/calendar';


export interface DateRangeSelection {
    timeFrame?: number|null;
    timeRange?: {
        start: number,
        end?: number
    };
}

export interface SelectFixedRange extends SelectItem {
    selected?: boolean;
}

/**
 * This constant object represents the chain to calculate
 * the amount of seconds from a particular time unit, so this
 * can be used as follows:
 * if we want to transform 2-d to seconds we will loop over the
 * keys until we find the needed entry and perform sequencial
 * multiplications the: 2-d would be: we find the time unit key
 * which is d -> 24*60(h)*60(m) = 86400 * 2 = 172800
 */
const timeUnitsMap: {[key: string]: number} = {
    min: 60,
    h: 60,
    d: 24,
    m: 30,
    y: 12
};

@Component({
    selector: 'al-date-range-selector',
    templateUrl: './al-date-range-selector.component.html',
    styleUrls: ['./al-date-range-selector.component.scss']
})

export class AlDateRangeSelectorComponent implements OnInit, AfterViewInit {

    @ViewChild(Calendar) calendar?: Calendar;
    @ViewChild('menu') menu: any;

    /**
     * Inputs, properties to handle visual behaviour
     */
    @Input() selectorTitle: string = 'Data for the last:';
    @Input() fixedRanges: SelectFixedRange[] = [];
    @Input() customRangeTitle: string ='Custom range:';
    @Input() hideSelector: boolean = false;
    @Input() calendarDateFormat: string = 'dd M yy';
    @Input() calendarMaxDaysHistory: number|null = 90; // By default we will be allowing the user to select 90 days in the past tops null to set i
    @Input() calendarMinYearHistory: string = '1970'; // Min year to be shown for selection in calendar year dropdown
    @Input() calendarMaxDate: Date = new Date();
    @Input() calendarMinDate?: Date|null = this.calendarMaxDaysHistory?
                                            new Date(new Date().setDate(this.calendarMaxDate.getDate() - this.calendarMaxDaysHistory)) :
                                            null;
    @Input() disableCalendar = false;
    @Input() showCalendar: boolean = true;
    @Input() showTimePicker: boolean = false;
    @Input() returnTimeFrame: boolean = false; // This will tell the component to return timeFrame instead of timeRange for fixed ranges
    @Input() timeZoneOffset?: string; // Time zone offset that will let the calendar to correctly represent the user tz configured
    @Input() placeholder?: string; // Placeholder for if there shouldn't be a default date.
    @Input() initialDateRangeSelection?: Date[]|undefined;
    @Input() sliceDropdownItems: number = 4;
    @Input() verticalLayout: boolean = false;
    @Input() emitOnInit: boolean = true; //

    /**
     * Outputs
     */
    @Output() onDateSelected: EventEmitter<DateRangeSelection> = new EventEmitter();
    @Output() onClosedCalendar: EventEmitter<DateRangeSelection> = new EventEmitter();
    @Output() onSelectFixedRange: EventEmitter<DateRangeSelection> = new EventEmitter();
    @Output() onShowCalendar: EventEmitter<DateRangeSelection> = new EventEmitter();

    /**
     * Public variables
     */
    public selectedFixedDate?: string;
    public selectedDateRange?: Date[];
    public startTime: Date = new Date();
    public endTime: Date = new Date();
    public isCalendarYearRange: boolean = false;
    public calendarYearRange: string = '';

    public hideCalendar: boolean = false;
    public isDropdownActive: boolean = false;
    public isDropdownSelected: boolean = false;

    constructor(private eref: ElementRef){
        // Constructor
    }

    onClick(event: MouseEvent) {
        if (!this.eref.nativeElement.contains(event.target)) {
            this.isDropdownActive = false;
        }
    }

    ngOnInit() {
        //  Always called at initialization time
        this.setupCalendarProperties();
    }

    ngAfterViewInit() {
        if (!this.placeholder) {
            if(!this.initialDateRangeSelection) {
                this.selectedDateRange = undefined;
                this.hideCalendar = true;
                this.setDefaultDateRange(undefined, this.emitOnInit);
            } else {
                this.setInitialDateSelection();
            }
        } else {
            this.setInitialDateSelection();
        }
    }

    /**
     * Sets the default fixed date range or the one passed as param
     * @param fixedRangeToSelect SelectFixedRange, the fixedRange we want to set
     * @param emit boolean, if we want to emit the events or only setup the calendar
     */
    setDefaultDateRange(fixedRangeToSelect?: SelectFixedRange, emit: boolean = true): void {
        let selectedFixedRange: SelectFixedRange;
        if (fixedRangeToSelect) {
            selectedFixedRange = fixedRangeToSelect;
        } else {
            selectedFixedRange = this.fixedRanges.find((fixedRange: SelectFixedRange) => fixedRange.selected) || this.fixedRanges[0];
        }
        // Helps to wait the lifecycle to end and
        // assign the new value at the right time
        setTimeout(() => {
            this.selectedFixedDate = selectedFixedRange.value;
            this.updateCalendarFromFixedRange(this.selectedFixedDate, undefined, emit);
        });
    }

    /**
     * Handle date selection on fixed selector or calendar
     * @param date string
     * @param event string, possible event where the function may be called from (fixed or show)
     * @param emit boolean, if we want to emit the events or only setup the calendar
     */
    updateCalendarFromFixedRange(fixedRange: string|undefined = this.selectedFixedDate, event: 'fixed'|'show' = "fixed", emit: boolean = true): void {

        this.hideCalendar = false;

        // Let's set the emitter besed in the event we are emitting (fixed or closed)
        let emitter = (event === 'fixed')? this.onSelectFixedRange : this.onShowCalendar;

        if (fixedRange) {

            this.selectedFixedDate = fixedRange;
            this.isDropdownSelected = !!((this.fixedRanges.slice(this.sliceDropdownItems,this.fixedRanges.length)).filter(item => item.value).find(item => item.value.includes(this.selectedFixedDate)));
            if(this.isDropdownSelected) {
                this.isDropdownActive = false;
            }

            const milliseconds: number = this.getMillisecondsFromFixedRange(fixedRange);
            this.setCalendarDateRange(milliseconds);
            if (this.returnTimeFrame) {
                if (emit) {
                    emitter.emit({ timeFrame: Math.round(milliseconds / 1000) });
                }
            } else {
                this.handleDateRangeSelection(event, false, emit);
            }
        } else if (fixedRange === null) {
            this.hideCalendar = true;
            this.selectedDateRange = undefined;
            if (emit) {
                emitter.emit(undefined);
            }
        }
        // Let's check if we are hiding the calendar
        // in order to set its placeholder
        if (this.hideCalendar) {
            this.placeholder = 'None';
        }
    }

    /**
     * Handle the event on show calendar
     */
    updateCalendarOnShow() {
        this.updateCalendarFromFixedRange(this.selectedFixedDate, 'show');
    }

    handleDateRangeSelection(event?: string, isFromTimeSelector: boolean = false, emit: boolean = true) {
        // Let's ensure we have the selectedDateRange set before continue
        if (this.selectedDateRange) {
            // Let's update on date range selection the start time to (00:00:00)
            // and the end time if we are on current day to reflect the actual
            // time seconds for more precision otherwise to 23:29:29
            if (event === "selected" && !isFromTimeSelector) {
                const currentDate: Date = new Date();
                this.startTime = new Date(currentDate.setHours(0, 0, 0));
                this.endTime = currentDate.toLocaleDateString() === this.selectedDateRange[1].toLocaleDateString()?
                                    new Date() :
                                    new Date(currentDate.setHours(23, 59, 59));
            }

            let startTime: Date = this.startTime;
            let endTime: Date = this.endTime;

            let dateSelection: DateRangeSelection = {
                timeRange: {
                    start: Math.round(this.getMillisecondsFromDate(this.selectedDateRange[0], startTime) / 1000),
                    end: Math.round(this.getMillisecondsFromDate(this.selectedDateRange[1], endTime) / 1000)
                }
            };
            if (emit) {
                if (event === "fixed") {
                    this.onSelectFixedRange.emit(dateSelection);
                } else if (event === "closed") {
                    this.onClosedCalendar.emit(dateSelection);
                } else if (event === "selected") {
                    this.onDateSelected.emit(dateSelection);
                } else if (event === "show") {
                    this.onShowCalendar.emit(dateSelection);
                }
            }
        }
    }

    /**
     * Handle date selection from calendar
     * @param date string, date string representation
     */
    selectDate(isFromTimeSelector: boolean = false, isClosing: boolean = false): void {

        if (!this.selectedDateRange) {
            return;
        }
        // Let's unselect all fixed options
        // if we are selecting a calendar option
        this.selectedFixedDate = undefined;
        let selectedDateRange: Date[] = this.selectedDateRange;
        // Let's check if we are closing the calendar only selecting the start date
        // then we force the end date to be equals to that one to make it work
        if (isClosing && selectedDateRange[1] === null) {
            selectedDateRange[1] = selectedDateRange[0];
        }
        // If we get the whole range, then we handle it
        if (selectedDateRange[1]) {
            const eventType = isClosing ? "closed" : "selected";
            this.handleDateRangeSelection(eventType, isFromTimeSelector);
        }
        // Let's unhide the calendar
        this.hideCalendar = false;
    }

    getMillisecondsFromDate(date: Date, timeOnlyDate?: Date): number {
        let milliseconds = timeOnlyDate? date.setHours(timeOnlyDate.getHours(), timeOnlyDate.getMinutes(), timeOnlyDate.getSeconds()):
                                         date.getTime();
        return this.timeZoneOffset? this.getMillisecondsWithTZApplied(milliseconds) : milliseconds;
    }
    getMillisecondsWithTZApplied(time: number): number {
        //  this.timeZoneOffset = +05:30
        const tzTimeZoneOffsetStr = this.timeZoneOffset.split(':'); // [+5, 30]
        const tzOffsetHr = parseInt(tzTimeZoneOffsetStr[0]); //Hours
        const tzOffsetMin = parseInt(tzTimeZoneOffsetStr[1]); //Minutes

        const tzCurrentOffsetInMinutes = (new Date).getTimezoneOffset(); // in minutes
        const txSelectedOffsetInMinutes = (tzOffsetHr * 60) + tzOffsetMin

        const tzDifference = txSelectedOffsetInMinutes + tzCurrentOffsetInMinutes;
        const tzOffsetInMilliseconds = tzDifference * 60 * 1000;

        return time + tzOffsetInMilliseconds;
    }

    getMillisecondsFromFixedRange(fixedRange: string): number {
        const fixedRangeParts: string[] = fixedRange.split('-');
        const quantity: number = parseInt(fixedRangeParts[0], 10);
        const unit: string = fixedRangeParts[1];
        let numberOfSeconds: number = 0;
        Object.keys(timeUnitsMap).find(timeUnit => {
            if (numberOfSeconds === 0) {
                numberOfSeconds = timeUnitsMap[timeUnit];
            } else {
                numberOfSeconds *= timeUnitsMap[timeUnit];
            }
            return timeUnit === unit;
        });
        numberOfSeconds = numberOfSeconds * quantity;
        if (numberOfSeconds === 0) {
            console.log(`Not valid fixed range value! -> ${fixedRange}`);
            return -1;
        }
        return numberOfSeconds * 1000;
    }

    /**
     * Allows the user to set the calendar date-range based on a timeframe
     * (milliseconds in the past) or using start and end date in milliseconds
     *
     * @param millisecondsInthePast number, this can represent the milliseconds in the past or the start date in milliseconds
     * @param endMilliseconds number, if set this means we are setting start and end date in milliseconds
     */
    setCalendarDateRange(millisecondsInthePast: number, endMilliseconds?: number): void {
        const endDate = (endMilliseconds)? new Date(endMilliseconds) : new Date();
        const startDate = (endMilliseconds)? new Date(millisecondsInthePast) : new Date(endDate.getTime() - millisecondsInthePast);

        // Now let's set the calendar values in order
        // to provide visual changes on each selection
        this.selectedDateRange = [startDate, endDate];
        this.startTime = startDate;
        this.endTime = endDate;
    }

    /**
     * Public function allowing parent to setup the whole date-range-selector
     * visualization from a defined dateRange programmatically
     * @param dateRange DateRangeSelection, date range structure to be used as base for the setup
     * @param emit boolean, if we want to emit the events or only setup the calendar
     */
    setupFromDateRange(dateRange: DateRangeSelection, emit: boolean = true): void {
        if (dateRange.timeFrame !== undefined) {
            // Let's look for the fixedRange that represents the timeFrame
            // we are trying to set programmatically and return the item
            const selectedFixedRange: SelectFixedRange|undefined = this.fixedRanges.find((fixedRange: SelectFixedRange) => {
                let secondsFromFixedRange: number = (fixedRange.value)? this.getMillisecondsFromFixedRange(fixedRange.value) / 1000 : fixedRange.value;
                return secondsFromFixedRange === dateRange.timeFrame;
            });
            // if item found lets set it as the default date range
            if (selectedFixedRange) {
                this.setDefaultDateRange(selectedFixedRange, emit);
            } else {
                console.error('The dateRange.timeframe is not a valid value entry from the list of fixed ranges you passed to the component');
            }
        } else {
            setTimeout(() => {
                if (dateRange.timeRange && dateRange.timeRange.end) {
                    this.selectedFixedDate = undefined;
                    this.setCalendarDateRange(dateRange.timeRange.start * 1000, dateRange.timeRange.end * 1000);
                    if (emit) {
                        this.handleDateRangeSelection("selected", true);
                    }
                }
            });
        }
    }

    openCalendar(event: Event):void {
        this.calendar?.toggle();
        event.stopPropagation();
    }

    private setupCalendarProperties(): void {
        this.isCalendarYearRange = this.calendarMinDate? this.calendarMinDate.getFullYear() !== this.calendarMaxDate?.getFullYear() : true;
        if (this.isCalendarYearRange) {
            this.calendarYearRange = (this.calendarMinDate? this.calendarMinDate.getFullYear() : this.calendarMinYearHistory) + ':' + this.calendarMaxDate.getFullYear();
        }
    }

    private setInitialDateSelection() {
        setTimeout(() => {
            this.selectedDateRange = this.initialDateRangeSelection;
        });
    }
}
