<div #container
     data-cy="sheet-container"
     [ngClass]="{
                    'p-sidebar':true,
                    'p-sidebar-active': visible,
                    'p-sidebar-bottom p-sidebar-bottom-center': true,
                    'al-sidebar-hidden': ! visible,
                    'al-sidebar-full': fullScreen
                }"
     [@panelState]="state === 'opened' || state === 'collapsed' ? 'visible' : 'hidden'"
     (@panelState.start)="onAnimationStart($event)"
     (@panelState.done)="onAnimationEnd($event)"
     [ngStyle]="style"
     [class]="styleClass"
     role="complementary"
     [attr.aria-modal]="modal">
    <a  [ngClass]="{'ui-sidebar-close ui-corner-all':true}"
        *ngIf="showCloseIcon"
        tabindex="0"
        role="button"
        (click)="close($event)"
        (keydown.enter)="close($event)">
            <span class="pi pi-times"></span>
    </a>
    <ng-container *ngIf="headerOptions || headerTemplate?.templateRef">
        <ng-container *ngTemplateOutlet="headerTemplate?.templateRef || defaultHeaderTemplate; context: { $implicit: target }"></ng-container>
    </ng-container>
    <ng-template #defaultHeaderTemplate>
        <div #header class="u-grid u-items-center header">
            <i class="material-icons" *ngIf="headerOptions?.icon" [ngStyle]="headerOptions?.iconStyle ? headerOptions?.iconStyle : {}">{{headerOptions.icon}}</i>
            <i class="class-icon" *ngIf="headerOptions?.classIcon" [ngClass]="headerOptions.classIcon" [ngStyle]="headerOptions?.iconStyle ? headerOptions?.iconStyle : {}"></i>
            <p  *ngIf="headerOptions?.title !== ''"
                [ngStyle]="headerOptions?.titleStyle ? headerOptions?.titleStyle : {}"
                [ngClass]="{ 'title-clickable': headerOptions?.collapsibleFromTitle }"
                (click)="toggle(headerOptions?.collapsibleFromTitle ? headerOptions?.collapsibleFromTitle : false)">
                {{headerOptions.title}}
            </p>
            <span class="u-col"></span>
            <button *ngIf="headerOptions?.tertiaryAction"
                    pButton
                    class="flat"
                    type="button"
                    [label]="headerOptions?.tertiaryAction?.text"
                    [disabled]="headerOptions?.tertiaryAction?.disabled"
                    (click)="onTertiaryAction.emit(target)"
                    data-cy="sheet-tertiary-action">
            </button>
            <button *ngIf="headerOptions?.secondaryAction"
                    pButton
                    class="flat secundary-action"
                    type="button"
                    [label]="headerOptions?.secondaryAction?.text"
                    [disabled]="headerOptions?.secondaryAction?.disabled"
                    (click)="onSecondaryAction.emit(target)"
                    data-cy="sheet-secondary-action"
                    data-e2e="sheet-secondary-action">
            </button>
            <button *ngIf="headerOptions?.primaryAction"
                    pButton
                    class="primaryAction"
                    [label]="headerOptions?.primaryAction?.text"
                    [disabled]="headerOptions?.primaryAction?.disabled"
                    (click)="onPrimaryAction.emit(target)"
                    data-cy="sheet-primary-action"
                    data-e2e="sheet-primary-action">
            </button>
        </div>
    </ng-template>
    <div class="container" *ngIf="allowContent">
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="footerOptions || footerTemplate?.templateRef">
        <ng-container *ngTemplateOutlet="footerTemplate?.templateRef || defaultFooterTemplate; context: { $implicit: target }"></ng-container>
    </ng-container>
    <ng-template #defaultFooterTemplate>
        <div class="footer-action-buttons footer-container" *ngIf="!loading">
            <div class="secondary-footer-action">
                <button *ngIf="footerOptions?.secondaryAction && !footerOptions?.secondaryAction?.hidden"
                    pButton
                    data-cy="sheet-secondary-footer-action-button"
                    data-e2e="sheet-secondary-footer-action-button"
                    class="flat secundary-action"
                    type="button"
                    icon="ui-icon-chevron-left"
                    [label]="footerOptions?.secondaryAction?.text"
                    [disabled]="footerOptions?.secondaryAction?.disabled"
                    (click)="onSecondaryFooterAction.emit(target)">
                </button>
            </div>
            <div class="primary-footer-action">
                <button *ngIf="footerOptions?.primaryAction && !footerOptions?.primaryAction?.hidden"
                        pButton
                        data-cy="sheet-primary-footer-action-button"
                        data-e2e="sheet-primary-footer-action-button"
                        class="primaryAction"
                        styleClass="primaryAction"
                        [icon]="footerOptions?.primaryAction?.icon ? footerOptions?.primaryAction?.icon : ''"
                        [label]="footerOptions?.primaryAction?.loading ? ' ' : footerOptions?.primaryAction?.text"
                        [disabled]="footerOptions?.primaryAction?.loading || footerOptions?.primaryAction?.disabled"
                        (click)="onPrimaryFooterAction.emit(target)">
                    <i *ngIf="footerOptions?.primaryAction?.loading"
                       class="pi pi-spin pi-spinner"></i>
                </button>
            </div>
        </div>
    </ng-template>
</div>
