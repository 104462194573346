<al-toast key="global-toast"></al-toast>
<al-toast key="ie-toast" position="top-center"></al-toast>
<p-confirmDialog #cd 
                key="confirmation" 
                class="confirmation-dialog" 
                [closable]="false">
</p-confirmDialog>
<p-dialog header="Session Expiration"
            [(visible)]="inReauthentication"
            [modal]="true"
            [closable]="false">
    <p>
        Hi {{userDisplayName}}, your session will expire in {{expiration}}.  If you want to continue working, please provide your password and reauthenticate below.
    </p>
    <div>
                <form #loginForm="ngForm">
                    <input type="hidden"
                        pInputText
                        class="input-text-field"
                        [(ngModel)]="userName"
                        autocomplete="username"
                        name="email"
                        pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                        placeholder="Email address"
                        required
                        [alForceFocus]="true"
                    />
                    <div class="u-grid form-group">
                        <div class="">
                            <input [type]="showPassword ? 'text' : 'password'"
                                pInputText
                                (keydown)="onKeyDown($event)"
                                [(ngModel)]="passPhrase"
                                autocomplete="current-password"
                                class="input-text-field"
                                name="password"
                                placeholder="Password"
                                required
                            />
                            <div class="show-toggle"(click)="toggleShowPassword()">SHOW</div>
                        </div>
                        <div class="page-buttons">
                            <button pButton class="primaryAction full-width-button" type="button" [disabled]="!loginForm.form.valid" (click)="reauthenticate()" label="REAUTHENTICATE"></button>
                        </div>
                    </div>
                </form>
    </div>
</p-dialog>

<al-dialog #wafDialog class="waf-outdated-dialog">
    <ng-template alTemplate="header">
        <ald-icon icon="warning" iconClass="material-icons" size="md"></ald-icon>
        <h1>End-of-Life for WAF Versions Before 5.X</h1>
    </ng-template>
    <ng-template alTemplate="content">
        <div class="waf-outdated-dialog-content u-flex u-w-100%">
            <p>Fortra's Managed WAF was “end of life” (EOL) for all Managed WAF software versions prior to v5.x on March 31, 2024. As Managed WAF is a security product, it is best practice to run the latest version and apply updates in a timely manner.</p>
            <p>Please schedule an update NOW!</p>
            <p>More information at <a href="https://support.alertlogic.com/hc/en-us/articles/17672596635547-08-08-2023-End-of-Life-for-WAF-Versions-Before-5-X" target="_blank" title="Alert Logic WAF Info">here</a></p>
        </div>
    </ng-template>
</al-dialog>
