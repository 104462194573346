/**
 * @author Fair Tarapues <fair.tarapues@alertlogic.com>
 * @copyright Alert Logic, Inc 2022
 */

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
    AlAuthenticationResult, 
    AlAuthenticationUtility, 
    AlErrorHandler, 
    AlLocation, 
    AlLocatorService, 
    AlSession, 
    AlStopwatch
} from '@al/core';
import { AlExternalContentManagerService, AlViewHelperComponent } from '@al/ng-generic-components';
import { AlNavigationService } from '@al/ng-navigation-components';


@Component({
    selector: 'portero-terms-of-service',
    templateUrl: './terms-of-service.component.html'
})

export class TermsOfServiceComponent implements OnInit {

    public ready = false;
    public contentType:string = "text";
    public contentAsHtml:SafeHtml;
    public contentAsMarkdown:string;
    public contentAsText:string;
    public context:string;
    public doAccept:boolean = false;
    public skipTOSdeferralMessage:string = "";
    public hideSkipButton:boolean = true;
    public deferralDateConverted:Date;


    public authenticator = new AlAuthenticationUtility();
    protected abortTimer:AlStopwatch;


    @ViewChild(AlViewHelperComponent) public viewHelper:AlViewHelperComponent;

    constructor( public navigation:AlNavigationService,
        public content:AlExternalContentManagerService,
        public sanitizer:DomSanitizer,
        private datePipe: DatePipe ) {
    }

    ngOnInit(): void {
        this.onInitialized();
    }

    async onInitialized() {
        this.authenticator.state.sessionToken = this.navigation.queryParam("token", null );
        this.authenticator.state.termsOfServiceURL = this.navigation.queryParam("url", null );
        this.authenticator.state.deferralTOSPeriodEnd = this.navigation.queryParam("deferral", null );
        this.context = this.navigation.queryParam("tos_context",null);
        this.ready = true;
        this.abortTimer = AlStopwatch.once( () => this.abortAcceptance(), 60 * 15 * 1000 );     //  15 minutes
        this.deferralDateConverted = new Date(+this.authenticator.state.deferralTOSPeriodEnd * 1000);
        this.hideSkipButton = this.calculateSkipDeferral(+this.authenticator.state.deferralTOSPeriodEnd);
        this.skipTOSdeferralMessage = "You can skip the terms until "+this.datePipe.transform(this.deferralDateConverted,"longDate");
    }

    async doAcceptOrSkipTerms(acceptTOS:boolean = true) {
        let returnURL = this.authenticator.filterReturnURL( this.navigation.queryParam( "return", AlLocatorService.resolveURL( AlLocation.MagmaUI, '/#/' ) ) );
        let auth0State = this.navigation.queryParam( 'state', null );     //  Auth0 rule pipeline state token, if federated
        let tokenSource = this.navigation.queryParam( 'token_source', 'aims' ); // Track source of token
        let auth0Token = this.navigation.queryParam( 'token', null );
        if (auth0State && (tokenSource === 'auth0')) {
            return this.completeAcceptance( returnURL, auth0State, tokenSource, auth0Token, acceptTOS );
        } else {
          try {
              let result = await this.authenticator.acceptTermsOfService(acceptTOS);
              switch( result ) {
                  case AlAuthenticationResult.Authenticated :
                      return this.completeAcceptance( returnURL, auth0State, tokenSource, auth0Token, acceptTOS );

                  case AlAuthenticationResult.AccountLocked :
                      this.viewHelper.notifyError( "The account you are attempting to log into is inactive.", 2500 );
                      break;

                  case AlAuthenticationResult.InvalidCredentials :
                  default :
                      this.viewHelper.notifyError( "You must accept the terms of service to continue logging into your account.", 2500);
                      break;

              }
          } catch ( e ) {
              this.viewHelper.notifyError( AlErrorHandler.normalize( e ).message, 2500 );
          }
        }

    }

    async completeAcceptance( returnURL:string, auth0State:string, tokenSource:string, auth0Token:string, acceptTOS:boolean ) {
        this.abortTimer.cancel();
        if ( auth0State !== null ) {
            //  Federated/SSO login: we *must* return control to auth0 to complete the rule pipeline execution, or auth0 will force us to log out :)
            let auth_token:string;
            if (tokenSource === 'auth0') {
              auth_token = auth0Token;
            } else {
              auth_token = AlSession.getToken();
            }
            console.log(`auth_token: ${auth_token}`);
            this.navigation.navigate.byLocation( AlLocation.Auth0, `/continue?state=${encodeURIComponent( auth0State )}&auth_token=${encodeURIComponent( auth_token )}&tos_accepted=${encodeURIComponent( acceptTOS )}` );
        } else if ( returnURL ) {
            //  Explicit return URL: bounce back to wherever the user initially tried to access
            this.navigation.navigate.byURL( returnURL, {}, { disableQSRewrite: true } );
        } else {
            //  In the absence of any other constraints/directions, redirect to the root of the account application
            this.navigation.navigate.byLocation( AlLocation.MagmaUI, '/#/' );
        }
    }

    async abortAcceptance( message?:string ) {
        this.abortTimer.cancel();
        if ( ! message ) {
            message = "Your login session expired. You will be redirected to the login page. Log in and try again.";
        }
        this.viewHelper.notifyError(message, 2500 );
        AlStopwatch.once( () => {
            this.navigation.navigate.byLocation(AlLocation.MagmaUI, '/#/logout');
        }, 2500 );
    }

    calculateSkipDeferral(deadLine:number):boolean {
        const currentDate  = new Date();
        const deferralDate = new Date(deadLine * 1000);
        return (currentDate.getFullYear() >= deferralDate.getFullYear()) && (currentDate.getMonth()+1 >= deferralDate.getMonth()+1)
            && (currentDate.getDate() >= deferralDate.getDate());
    }
}
