import { Directive, Input, TemplateRef } from '@angular/core';


@Directive({
    selector: "[alCustomControl]"
})
export class AlCustomControlDirective {

    public controlForProperty = '';

    constructor( public template:TemplateRef<any> ) {
    }

    /**
     * Attribute value is treated as the property for which this custom control should be used.
     */
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input( 'alCustomControl' )
    public set alCustomControl( value:string ) {
        this.controlForProperty = value;
    }
}
