/**
 * It allows the construction of an input type element supporting the input, hidden and password types
 *
 * @author <msanchez@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { FormControl } from '@angular/forms';
import { AlFormElementBase } from '../al-form-element-base';
import { AlDynamicFormElementDescriptor } from '../al-form.types';


export class AlFormElementMonacoEditor extends AlFormElementBase<string> {

    public controlTemplate = "monacoEditor";

    constructor(properties: AlDynamicFormElementDescriptor) {
        super(properties);
        this.editorOptions = properties.editorOptions || {
            theme: 'vs',
            language: 'json'
        };
    }

    public createFormControl() {
        return new FormControl(this.value);
    }

    getAnswer(): any {
        return this.getControlValue();
    }
}
