import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgGenericComponentsModule, AppInjectorService } from '@al/ng-generic-components';
import { NgNavigationModule } from '@al/ng-navigation-components';
import { AppComponent } from './app.component';
import { ApplicationRoutes } from './application.routes';
import { DefaultRouteComponent } from './default-route/default-route.component';


@NgModule({
    declarations: [
        AppComponent,
        DefaultRouteComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot( ApplicationRoutes, { useHash: true } ),
        NgGenericComponentsModule,
        NgNavigationModule,
        MonacoEditorModule,
    ],
    bootstrap: [ 
        AppComponent 
    ]
})
export class AppModule {
  constructor(injector:Injector){
      AppInjectorService.setInjector(injector);
  }
}
