<!-- TABLE HEADER SECTION -->
<div #container class="u-flex u-flex-col u-flex-grow">
    <div class="u-flex-none u-flex-grow">
        <ng-content select="ald-table-header"></ng-content>
    </div>
    
    <!-- BULK ACTIONS -->
    <ald-table-bulk-actions
        [multiSelectActions]="multiSelectActions"
        [totalResults]="totalRecords"
        [tableSelectionModel]="selection"
        [enableSelectAllResults]="enableSelectAllResults"
        (didActionMultiItems)="didActionMultiItems.emit($event)"
        (toggleCheckAllResults)="toggleCheckAllResults($event)"
        (toggleBulkActionButton)="toggleBulkActionButton.emit()"
    ></ald-table-bulk-actions>
    
    <!-- TABLE SECTION -->
    <div class="u-relative u-flex u-justify-between u-flex-grow u-overflow-auto"
        [ngClass]="{
            'ald-table--outer-border': outerBorder
        }">

        <div #tableContainer class="ald-table-container u-relative u-flex-1 u-overflow-auto">
            <ald-loading-spinner *ngIf="isLoading" class="ald-table__body-row-loading u-absolute" size="md"></ald-loading-spinner>
            <table
                #table
                cdk-table
                class="ald-table"
                [dataSource]="displayedData$"
                [ngClass]="
                    {
                        'ald-table--multiline': multilineRows,
                        'ald-table--has-actions-checkboxes': actions && checkboxRows,
                        'ald-table--has-actions': actions && !checkboxRows,
                        'ald-table--has-checkboxes': checkboxRows && !actions,
                        'scrollbar-padding': !scrollbarWidth
                    }
                ">
    
                <!-- Actions Column Definition -->
                <ng-container cdkColumnDef="ald-table-actions">
    
                    <th
                        cdk-header-cell
                        *cdkHeaderCellDef
                        class="ald-table__head-cell ald-table__head-cell--has-actions ald-table-sticky-cell">
                        <div class="u-w-8"></div>
                    </th>
    

                    <td cdk-cell
                        *cdkCellDef="let row; let index = index"
                        class="ald-table__body-row-cell ald-table__body-row-cell--has-actions ald-table-sticky-cell">
                        <div *ngIf="!isLoading" class="u-flex u-justify-center u-pr-2">
                            <div class="action-button"
                                    [ngClass]="{
                                                    'action-button--visible':
                                                        index === activeActionMenuRow
                                                }">
                                <ald-dropdown #actionMenu
                                                variant="ghost"
                                                icon="more_vert"
                                                size="xs"
                                                width="auto"
                                                (click)="actionButtonClicked($event, index)"
                                                data-cy="table-row-action-button">
                                    <ald-menu [options]="actions.concat(currentRowActions)"
                                                (didSelect)="
                                                            actionSelected($event, row)
                                                        ">
                                    </ald-menu>
                                </ald-dropdown>
                            </div>
                        </div>
                    </td>

                    <td cdk-footer-cell *cdkFooterCellDef></td>
    
                </ng-container>
    
                <!-- Checkbox Column Definition -->
                <ng-container cdkColumnDef="checkboxRows">
                    <th
                        cdk-header-cell
                        *cdkHeaderCellDef
                        class="ald-table__head-cell ald-table__head-cell--has-checkbox ald-table-sticky-cell">
                        <div class="u-flex u-justify-center u-pr-2">
                            <ald-checkbox
                                [id]="'selectAll'"
                                [checked]="selection.selected.length > 0"
                                [indeterminate]="isCheckAllIndeterminate"
                                [bottomPadding]="false"
                                (didCheckedChange)="onCheckAllChange($event)"
                                data-cy="table-checkbox-select-all">
                            </ald-checkbox>
                        </div>
                    </th>
                    
                    <td cdk-cell
                        *cdkCellDef="let row; let index = index"
                        class="ald-table__body-row-cell ald-table-sticky-cell ald-table__body-row-cell--has-checkbox">
                        <div *ngIf="!isLoading" class="u-flex u-justify-center u-pr-2">
                            <ald-checkbox [id]="'rowIndex-' + index"
                                            (didCheckedChange)="checkRow(row)"
                                            [checked]="selection.isSelected(row)"
                                            (click)="propagationCheck($event)"
                                            [bottomPadding]="false"
                                            data-cy="table-checkbox-row">
                            </ald-checkbox>
                        </div>
                    </td>
                    
    
                    <td cdk-footer-cell *cdkFooterCellDef></td>
                </ng-container>
    
                <!-- Dynamic Column Definitions -->
                <ng-container
                    *ngFor="let col of columns; let first = first; let index = index"
                    [cdkColumnDef]="col.header">
                    <!-- header cells -->
                    
                    <th
                        cdk-header-cell
                        *cdkHeaderCellDef
                        class="ald-table__head-cell"
                        [ngClass]="{
                            'ald-table-sticky-cell ald-table-sticky-cell--first': stickyColumn && first,
                            'ald-table__head-cell--has-icon': col.cellConfig?.icon
                        }"
                        [attr.title]="col.headerTitle ? col.headerTitle : null"
                        (click)="col.sortable !== false ? adjustSort(col.field) : null"
                        [ngStyle]="{ 'min-width.px': col.minColWidth , 'max-width.px': col.maxColWidth }"
                        data-cy="table-th">
                        <div
                            class="ald-table__head-cell-wrapper"
                            [class.ald-table__head-cell-wrapper-interactive]="enableSorting"
                            [ngClass]="[!first ? 'u-px-4' : 'u-pr-4']">
                            {{ col.header }}
                            <div
                                *ngIf="enableSorting && col.sortable !== false"
                                class="ald-table__head-cell-wrapper-icon"
                                [ngClass]="{'ald-table__head-cell-wrapper-icon--visible': sortField === col.field}">
                                <ald-icon
                                    *ngIf="sortField === col.field"
                                    size="sm"
                                    [icon]="sortDirection === 'desc' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
                                    [attr.data-sort]="'active'"
                                    [attr.data-sort-column]="col.field"
                                ></ald-icon>
                                <ald-icon
                                    *ngIf="sortField !== col.field"
                                    size="sm"
                                    color="--ald-g-color-gray-50"
                                    icon="unfold_more"
                                    [attr.data-sort]="'inactive'"
                                    [attr.data-sort-column]="col.field"
                                ></ald-icon>
                            </div>
                        </div>
    
                        <!-- SHADOW -->
                        <div *ngIf="stickyColumn && first && shadowLeft" class="ald-table__shadow-left">
                            <div></div>
                        </div>
                    </th>
                                                            
                    <!-- table cells -->
                    <ng-container>
                        <td
                        cdk-cell
                        *cdkCellDef="let row"
                        class="ald-table__body-row-cell ald-table__body-row-cell--is-first"
                        [ngStyle]="{ 
                            'min-width.px': isLoading ? 0 : col.minColWidth, 
                            'max-width.px': isLoading ? 0 : col.maxColWidth 
                        }"
                        [ngClass]="{
                            'ald-table-sticky-cell ald-table-sticky-cell--first': stickyColumn && first && !isLoading
                        }"
                        [attr.colspan]="isLoading ? displayedColumns$.value.length : 1"
                        data-cy="table-td">
                        
                        <div
                            *ngIf="!isLoading"
                            class="ald-table-truncate-text"
                            [ngClass]="[!first ? 'u-px-4' : 'u-pr-8']">
    
                            <!-- HTML TEXT -->
                            <ng-container *ngIf="!col.cellConfig || col.cellConfig.format && !col.cellConfig.format?.static">
                                <span [title]="row | aldObjectValue: col.field: col.cellConfig?.format?.ignoreDotNotation: col.cellConfig?.format?.showRawValue"
                                      [innerHTML]="row | aldObjectValue: col.field: col.cellConfig?.format?.ignoreDotNotation: col.cellConfig?.format?.showRawValue"></span>
                            </ng-container>
    
                            <!-- STATIC TEXT -->
                            <ng-container *ngIf="col.cellConfig?.format?.static">
                                <span [title]="row | aldObjectValue: col.field: col.cellConfig?.format?.ignoreDotNotation: col.cellConfig?.format?.showRawValue"> {{ row | aldObjectValue: col.field: col.cellConfig?.format?.ignoreDotNotation: col.cellConfig?.format?.showRawValue }}</span>
                            </ng-container>
    
                            <!-- TEXT TRANSFORM -->
                            <ng-container *ngIf="col.cellConfig?.transform && row.aldTableNormalize">
                                <ng-container *ngIf="row.aldTableNormalize[col.field] as rowField">
                                    <span [title]="rowField">{{ rowField }}</span>
                                </ng-container>
                            </ng-container>
    
                            <!-- LINK -->
                            <ng-container *ngIf="col.cellConfig?.link && row.aldTableNormalize">
                                <ng-container *ngIf="row.aldTableNormalize[col.field] as rowField">
                                    <ald-link iconPos="left"
                                              [internalRoute]="rowField.externalUrl ? null : rowField.internalUrl"
                                              [queryParams]="rowField.externalUrl ? {} : rowField.queryParams"
                                              [externalUrl]="rowField.externalUrl"
                                              [openInNewTab]="rowField.openInNewTab"
                                              [label]="row | aldObjectValue: col.field"
                                              [underline]="false"
                                              [truncate]="true"
                                              class="u-flex"
                                              data-cy="table-td-link">
                                    </ald-link>
                                </ng-container>
                            </ng-container>

                            <!-- Multi values - LINK -->
                            <ng-container *ngIf="col.cellConfig?.multiLink">
                                <div class="multi-link">
                                    <ng-template ngFor let-link [ngForOf]="col.cellConfig.multiLink | evaluate: (row)">
                                        <ald-link
                                            iconPos="left"
                                            [internalRoute]="link.externalUrl ? null : link.internalUrl"
                                            [queryParams]="link.externalUrl ? {} : link.queryParams"
                                            [externalUrl]="link.externalUrl"
                                            [openInNewTab]="link.openInNewTab"
                                            [label]="link.key"
                                            [underline]="false"
                                            [truncate]="true"
                                            class="u-flex"
                                            data-cy="table-td-multi-link">
                                        </ald-link>
                                    </ng-template>
                                </div>
                            </ng-container>
    
                            <!-- ICON -->
                            <ng-container *ngIf="col.cellConfig?.icon && row.aldTableNormalize">
                                <ng-container *ngIf="row.aldTableNormalize[col.field] as rowField">
                                    <div class="u-flex"
                                         [ngClass]="{   'u-justify-start': rowField.position === 'left',
                                                        'u-justify-center': rowField.position === 'center',
                                                        'u-justify-end': rowField.position === 'right'
                                                    }">
                                        <ald-icon size="sm"
                                                  [icon]="rowField.name"
                                                  [title]="rowField.title || ''"
                                                  [color]="rowField.color || ''"
                                                  [iconClass]="rowField.iconClass || 'material-icons'"></ald-icon>
                                    </div>
                                </ng-container>
                            </ng-container>
    
                            <!-- ICON WITH LABEL -->
                            <ng-container *ngIf="col.cellConfig?.icon_with_label && row.aldTableNormalize">
                                <ng-container *ngIf="row.aldTableNormalize[col.field] as rowField">
                                    <div class="u-flex u-gap-2">
                                        <ald-icon
                                            size="sm"
                                            [icon]="rowField.name"
                                            [title]="rowField.title || ''"
                                            [color]="rowField.color || ''"
                                            [iconClass]="rowField.iconClass || 'material-icons'"
                                        ></ald-icon>
                                        {{ rowField.label }}
                                    </div>
                                </ng-container>
                            </ng-container>
    
    
                            <!-- DATE -->
                            <span *ngIf="col.cellConfig?.date">
                                {{ row | aldObjectValue: col.field | aldDateTime: col.cellConfig?.date?.format }}
                            </span>
    
                            <!-- NUMBER -->
                            <div *ngIf="col.cellConfig?.number" class="u-text-right">
                                <span *ngIf="col.cellConfig?.number?.prefix">{{ col.cellConfig?.number?.prefix }}</span>
                                {{ row | aldObjectValue: col.field | number: col.cellConfig?.number?.format || '' }}
                            </div>
    
                            <!-- BADGE -->
                            <ng-container *ngIf="col.cellConfig?.badge && row.aldTableNormalize">
                                <ng-container *ngIf="row.aldTableNormalize[col.field] as rowField">
                                    <div class="u-flex u-justify-center">
                                        <ald-badge *ngIf="rowField.label || rowField.icon"
                                               size="sm"
                                               [label]="rowField.label"
                                               [variant]="rowField.variant"
                                               [icon]="rowField.icon"
                                               [iconClass]="rowField.iconClass"
                                               [lowContrast]="rowField.lowContrast"
                                               [title]="rowField.title ?? ''">
                                        </ald-badge>
                                    </div>
                                </ng-container>
                            </ng-container>
    
    
                            <!-- THREAT -->
                            <div *ngIf="col.cellConfig?.threat" class="u-flex">
                                <ald-threat-summary
                                    [threat]="row | aldObjectValue: col.field"
                                    [display]="col.cellConfig?.threat?.display ? col.cellConfig?.threat?.display : 'breakdown'"
                                    [inline]="col.cellConfig?.threat?.display === 'summary' ? true : false"
                                    [noThreatText]="col.cellConfig?.threat?.noThreatText"
                                ></ald-threat-summary>
                            </div>
    
                            <!-- DICTIONARY-->
                            <div *ngIf="col.cellConfig?.dictionary" class="u-flex">
                                <span>{{dictionary[row | aldObjectValue: col.field]?.[col.cellConfig?.dictionary.property]}}</span>
                            </div>
    
                            <!-- TRANSFORM FUNCTION -->
                            <ng-container *ngIf="col.cellConfig?.transformFn">
                                <span [title]="col.cellConfig.transformFn | evaluate: (row | aldObjectValue: col.field: col.cellConfig?.format?.ignoreDotNotation: col.cellConfig?.format?.showRawValue)">
                                    {{col.cellConfig.transformFn | evaluate: (row | aldObjectValue: col.field: col.cellConfig?.format?.ignoreDotNotation: col.cellConfig?.format?.showRawValue)}}
                                </span>
                            </ng-container>
                        </div>
    
                        <!-- SHADOW -->
                        <div *ngIf="stickyColumn && first && shadowLeft && !isLoading" class="ald-table__shadow-left">
                            <div></div>
                        </div>
                    </td>
                    </ng-container>

                    <td cdk-footer-cell *cdkFooterCellDef></td>
                    
                </ng-container>
    
                <!-- Render The Rows -->
                <tr
                    #tableHeaderRow
                    cdk-header-row
                    *cdkHeaderRowDef="displayedColumns$.value"
                    class="ald-table__head-row"
                    data-cy="table-header-tr">
                </tr>
                <tr
                    cdk-row
                    *cdkRowDef="
                        let row;
                        columns: displayedColumns$.value;
                        let index = index
                    "
                    class="ald-table__body-row"
                    [ngClass]="{
                        'ald-table__body-row--active': selectedRow === index,
                        'ald-table__body-row--clickable': selectableRows
                    }"
                    (click)="rowSelected(index, row)"
                    data-cy="table-body-tr">
                </tr>
                <tr cdk-footer-row
                    *cdkFooterRowDef="displayedColumns$.value; sticky: true"
                    class="ald-table__body-row empty"></tr>
                <!-- Zero State for no data -->
                <tr *cdkNoDataRow>
                    <td colspan="100%" *ngIf="!isLoading">
                        <ng-content select="ald-empty-state"></ng-content>
                    </td>
                </tr>
            </table>
        </div>
    
        <!-- SHADOW -->
        <div *ngIf="shadowRight" class="ald-table__shadow-right">
            <div></div>
        </div>
    
    </div>
    
    <!-- PAGINATION -->
    <div class="u-flex-none u-flex-grow" *ngIf="paginator">
        <ald-pagination #pagination
            [isLoading]="showFooterLoading && isLoading"
            [summary]="summary"
            [totalResults]="totalRecords"
            [showResultsPerPageSelector]="true"
            [rowsPerPage]="rowsPerPage"
            [paginationType]="paginationType"
            [currentPage]="currentPage"
            (paginationAction)="onPaginate($event)"
            (didChangeResultsPerPage)="onChangeResultsPerPage($event)">
        </ald-pagination>
    </div>
</div>
