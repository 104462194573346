import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AldCommonModule } from '@al/design-patterns/common';
import * as manifest from './manifest';


@NgModule({
    imports: [
        CommonModule,
        AldCommonModule
    ],
    declarations: [
        ...manifest.MODULE_COMPONENTS,
    ],
    exports: [
        ...manifest.MODULE_COMPONENTS
    ]
})
export class AldStepperModule {}
