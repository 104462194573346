/* tslint:disable:variable-name */
export const AlFormSchematics:any = {
    "https://alertlogic.com/schematics/ng-forms-components": {
        "definitions": {
            "baseElementDescriptor": {
                "type": "object",
                "properties": {
                    "type": {
                        "type": "string",
                        "description": "Indicates data and control type for this element, in the form '{dataType}/{controlType}'.",
                        "enum": [
                            "string/input",
                            "string[]/input",
                            "integer/input",
                            "number/input",
                            "string/hidden",
                            "string/password",
                            "string/radio",
                            "boolean/checkbox",
                            "string[]/checkbox",
                            "any[]/checkbox",
                            "object/checkbox",
                            "boolean/inputSwitch",
                            "string/dropdown",
                            "string/monaco-editor",
                            "string/textarea",
                            "string[]/textarea",
                            "object/textarea",
                            "string/inputResponder",
                            "any[]/multiSelectList",
                            "string[]/multiSelectList",
                            "string[]/treeSelectList",
                            "any[]/treeSelectList",
                            "void/title",
                            "void/content",
                            "void/button",
                            "void/link",
                            "void/download-button",
                            "void/columns",
                            "void/group",
                            "object/calendar",
                            "object/custom",
                            "void/radio"
                        ]
                    },
                    "property": {
                        "type": "string",
                        "description": "The output property of this form element."
                    },
                    "value": {
                        "anyOf": [ "string", "number", "boolean", "object" ]
                    },
                    "defaultValue": {
                        "anyOf": [ "string", "number", "boolean", "object" ]
                    },
                    "label": {
                        "type": "string",
                        "description": "Textual label for the element's control."
                    },
                    "behaviors": {
                        "type": "object",
                        "description": "Arbitrary metadata about this control and its behaviors, depending on the control's type."
                    },
                    "labelField": {
                        "type": "string",
                        "description": "For objects, indicates the property (or jpath of the property) to use as a human-readable representation of a given value."
                    },
                    "description": {
                        "type": "string",
                        "description": "If provided, is made available to users as a tooltip when mousing over a 'more info' icon."
                    },
                    "aboveDescription": {
                        "type": "string",
                        "description": "Provides optional explanatory text that is exposed above the control.  This field supports markdown activation."
                    },
                    "belowDescription": {
                        "type": "string",
                        "description": "Provides optional explanatory text that is exposed below the control.  This field supports markdown activation."
                    },
                    "optional": {
                        "type": "boolean",
                        "description": "Indicates that a given control's value is optional.  If this value is absent or false, the control will be annotated with an asterisk to indicate that it is required."
                    },
                    "requiredError": {
                        "type": "string",
                        "description": "If the field is required (not optional) but has no value, this error message will be displayed in place of the default one."
                    },
                    "validationPattern": {
                        "type": "string",
                        "description": "If provided, is treated as a regular expression that the input field must match in order to be considered valid."
                    },
                    "patternError": {
                        "type": "string",
                        "description": "If the field has a validation pattern and the user's input does not match it, this error message will be displayed in place of the default one."
                    },
                    "options": {
                        "type": "array",
                        "items": { "$ref": "https://alertlogic.com/schematics/ng-forms-components#definitions/elementOption" },
                        "description": "For dropdown, radio, and checkbox group controls, the array of options to be presented to the user."
                    },
                    "disabled": {
                        "type": "boolean",
                        "description": "If present and true, indicates that the field cannot be modified by the user."
                    },
                    "editorOptions": {
                        "type": "object",
                        "description": "For monacoEditor inputs, provides the behavior specification for the editor instance.  See https://npmjs.com/package/ngx-monaco-editor for the structure of this object."
                    },
                    "columns": {
                        "type": "array",
                        "items": { "$ref": "#definitions/columnDescriptor" },
                        "description": "Description of columns in a void/columns control"
                    },
                    "elements": {
                        "type": "array",
                        "items": { "$ref": "#definitions/baseElementDescriptor" },
                        "description": "Child elements of a group"
                    }
                },
                "required": [ "type", "property" ]
            },
            "elementOption": {
                "type": "object",
                "properties": {
                    "label": {
                        "type": "string",
                        "description": "The option's human-readable label."
                    },
                    "value": {
                        "anyOf": [ "string", "number", "boolean", "object" ],
                        "description": "The option's literal value."
                    },
                    "disabled": {
                        "type": "boolean",
                        "description": "Indicates if the option should be disabled"
                    }
                },
                "required": [ "label", "value" ],
                "additionalProperties": true
            },
            "columnDescriptor": {
                "type": "object",
                "properties": {
                    "elements": {
                        "type": "array",
                        "items": { "$ref": "#definitions/baseElementDescriptor" },
                        "description": "Blurb"
                    },
                    "cssClass": {
                        "type": "string",
                        "description": "A class to apply to the column (typically col-X)"
                    }
                }
            }
        }
    }
};
