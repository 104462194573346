/**
 * AldTableConstants
 *
 * If the ALD Table needs to support export funcionality, these constants will provide
 * the correct labels for these actions, please avoid using other labels.
 *
 * NOTE: Please implement the Export actons ONLY for Bulk mode.
 *
 */

// Constant labels for Bulk action menu.
export const AldTableConstants = {
    EXPORT_SELECTED_ROWS_ALL_COLUMNS: 'Export selected rows (all columns)',
    EXPORT_SELECTED_ROWS_SELECTED_COLUMNS: 'Export selected rows (selected columns)',

    // Constant labels for 'Download All' Button.
    EXPORT_ALL_ROWS_ALL_COLUMNS: 'Export all rows (all columns)',
    EXPORT_ALL_ROWS_SELECTED_COLUMNS: 'Export all rows (selected columns)',
};
