/**
 * A not-very-interesting service to coordinate navigational state management into a singleton.
 * This is a nepal-based implementation of AlRoutingHost.
 *
 * @author McNielsen <knielsen@alertlogic.com>
 *
 */

import { RumErrorEvent, RumEvent, RumResourceEvent, datadogRum } from '@datadog/browser-rum';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    ActivatedRouteSnapshot,
    Data,
    NavigationEnd,
    NavigationExtras,
    Params,
    Router
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfirmationService, Confirmation as PrimengConfirmation, MenuItem as PrimengMenuItem } from 'primeng-lts/api';
import {
    getJsonPath,
    AIMSClient,
    AIMSLicenseAcceptanceStatus,
    AlActingAccountChangedEvent,
    AlActingAccountResolvedEvent,
    AlBaseError,
    AlBehaviorPromise,
    AlCabinet,
    AlConduitClient,
    AlDatacenterSessionErrorEvent,
    AlExternalTrackableEvent,
    AlDefaultClient,
    AlEntitlementCollection,
    AlEntitlementRecord,
    AlErrorHandler,
    AlExperienceMapping,
    AlGlobalizer,
    AlInsightLocations,
    AlLocation,
    AlLocationContext,
    AlLocatorService,
    AlNavigationSchema,
    AlParamPreservationRule,
    AlRoute,
    AlRouteCondition,
    AlRouteDefinition,
    AlRouteAction,
    AlRoutingHost,
    AlRuntimeConfiguration,
    ConfigOption,
    AlSession,
    AlSessionEndedEvent,
    AlSessionStartedEvent,
    AlStopwatch,
    AlSubscriptionGroup,
    AlTriggerStream,
    AlDatacenterSessionEstablishedEvent
} from '@al/core';
import {
    AlExternalContentManagerService,
    AlUsageTrackingService,
    AlTrackingMetricEventName,
    AlTrackingMetricEventCategory,
} from '@al/ng-generic-components';
import {
    AlScanApiClient
} from '@al/scan-api';
import {
    AlDatacenterOptionsSummary,
    AlNavigateOptions,
    AlNavigationContextChanged,
    AlNavigationFrameChanged,
    AlNavigationTertiarySelected,
    AlNavigationTrigger,
    AlNavigationIdlePrompt,
    AlNavigationReauthenticatePrompt,
    AlNavigationApplicationError,
    AlNavigationRouteDispatched,
    AlNavigationExtras
} from '../types/navigation.types';
import { AlExperiencePreferencesService } from './al-experience-preferences.service';
import { AlSessionManagerService } from './al-session-manager.service';


import idleService, { IdleEvents } from '@kurtz1993/idle-service';

const DATADOG = {
    production: {
        applicationId: '6a409dce-96fd-46bd-af4b-9cdf013e973b',
        clientToken: 'pub03fef8120d6087e2b02fd2fb1a9e9ca8'
    },
    integration: {
        applicationId: 'c9a1be14-76fc-4af8-adef-def86cdf5840',
        clientToken: 'pub93c858df139e9e9f4ffb2b3fa015c48e'
    }
};

@Injectable({
    providedIn: 'root'
})
export class AlNavigationService
             implements AlRoutingHost
{
    public static appVersionId:string           =   'unspecified';
    public schema:AlNavigationSchema            =   null;
    public currentUrl                           =   '';
    public authenticationRequisites             =   0;

    public get routeData():{[k:string]:any} {
        return this._routeData;
    }
    public get routeParams():{[k:string]:string} {
        return this._routeParams;
    }
    public get queryParams():{[k:string]:string} {
        return this._queryParams;
    }

    public tertiaryMenu:AlRoute                 =   null;
    public conduit                              =   new AlConduitClient();

    /**
     *  `activatedRoutes` refers to the hierarchy of menu items of the deepest activated menu item, if any.
     *  It will be updated each time the AlNavigationContextChanged event is emitted.
     *  The deepest item in this hierarchy can be retrieved using the `activatedRoute` getter.
     */
    public activatedRoutes:AlRoute[]            =   [];
    public get activatedRoute():AlRoute|null {
        return this.activatedRoutes.length > 0 ? this.activatedRoutes[this.activatedRoutes.length-1] : null;
    }

    /**
     * Navigation triggers (named events referenced by menu data) emit through this event channel.
     */
    public events                               =   new AlTriggerStream();

    /**
     * Acting and primary entitlement snapshots
     */
    public authenticated                        =   false;
    public primaryAccountId:string;
    public actingAccountId:string;
    public environment:string;
    public entitlements                         =   new AlEntitlementCollection();
    public primaryEntitlements                  =   new AlEntitlementCollection();
    public experienceFlags:{[experienceId:string]:boolean}  =   {};
    public routeParameters:{[parameter:string]:string}      =   {};
    public layoutOptions:{[option:string]:string|boolean}   =   {};
    public globalExperience:string;
    public isNavigationAllowed: boolean         = true;

    /**
     * EventEmitter to pass a css class to tertiary-content (al-archipeligo17-tertiary-menu)
     */
    public tertiaryContentClass$ = new EventEmitter<string|string[]|{[i:string]:boolean}>();

    /**
     * EventEmitter to indicate when the authentication requirements of the current route have changed.
     */
    public authRequirementsChanged$ = new EventEmitter<number>();

    /**
     * EventEmitter for when layout options have changed
     */
    public layoutOptionsChanged$ = new EventEmitter<{[option:string]:string|boolean}>();

    /**
     * This cluster of methods supports imperative navigation of different types.
     * Note that each method accepts an optional `params` parameter bag.  This parameters will be used
     * to satisfy a given route or target's route parameters (if any); any *remaining* parameters will be
     * added as query parameters to the route.
     */
    public navigate = {
        /**
         * Determines the best type of navigation strategy to use based on a given input, using generous type
         * interpretation for the sake of simplicity :)
         */
        to: async ( target: string|string[]|any[]|AlRoute|{location:string,path:string},
              parameters:{[p:string]:string} = {},
              options:AlNavigateOptions = {} ) => {
            const canNavigate:boolean = await this.canNavigateAway();
            if(!canNavigate){
                return;
            }
            if ( target instanceof AlRoute ) {
                return this.navigateByRoute( target, parameters, options );
            } else if ( typeof( target ) === 'string' && target.startsWith( "http" ) ) {
                return this.navigateByURL( target, parameters, options );
            } else if ( typeof( target ) === 'object' && target.hasOwnProperty("location") && target.hasOwnProperty("path") ) {
                const targetObject = target as {location:string,path:string};
                return this.navigateByLocation( targetObject.location, targetObject.path, parameters, options );
            } else if ( typeof( target ) === 'object' && target.hasOwnProperty("length") ) {
                return this.navigateByNgRoute( target as any[], { queryParams: parameters } );
            } else if ( typeof( target ) === 'string' ) {
                return this.navigateByNgRoute( [ target ], { queryParams: parameters } );
            }
        },

         /**
         * Navigates to a new URL based on a named route, like "cd17:overview" or "cd19:dashboards:settings".
         */
        byNamedRoute: async ( namedRouteId:string, parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ) => {
            return await this.canNavigateAway() ? this.navigateByNamedRoute( namedRouteId, parameters, options ) : Promise.resolve();
        },

        /**
         * Navigates to a new URL based on a literal route.
         */
        byRoute: async ( route: AlRoute, parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ) => {
            return await this.canNavigateAway() ? this.navigateByRoute( route, parameters, options ) : Promise.resolve();
        },

        /**
         * Navigates to a new URL based on location/path.
         */
        byLocation: async ( locTypeId:string, path:string = '/#/', parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ) => {
            return await this.canNavigateAway() ? this.navigateByLocation( locTypeId, path, parameters, options ) : Promise.resolve();
        },

        /**
         * Navigates to a new URL
         */
        byURL: async ( url:string, parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ) => {
            return await this.canNavigateAway() ? this.navigateByURL( url, parameters, options ) : Promise.resolve();
        },

        /**
         * Navigates to a new location in the current route using an array of angular commands.
         */
        byNgRoute: async ( commands: any[], extras: AlNavigationExtras = { skipLocationChange: false } ) => {
            return await this.canNavigateAway() ? this.navigateByNgRoute( commands, extras ) : Promise.resolve();
        }
    };

    protected _routeData:{[k:string]:any}           =   {};
    protected _routeParams:{[k:string]:string}      =   {};
    protected _queryParams:{[k:string]:string}      =   {};
    protected navigationSchemaId:string             =   null;
    protected pendingResourceCount                  =   0;
    protected navigationReady                       =   new AlBehaviorPromise<boolean>();

    protected schemas:{[schema:string]:Promise<AlNavigationSchema>}     =   {};
    protected loadedSchemas:{[schema:string]:AlNavigationSchema}        =   {};
    protected loadedMenus:{[fullMenuId:string]:AlRoute}                 =   {};
    protected bookmarks:{[bookmarkId:string]:AlRoute}                   =   {};
    protected namedRouteDictionary:{[routeId:string]:AlRouteDefinition} =   {};
    protected conditionDictionary:{[conditionId:string]:AlRouteCondition} = {};
    protected conditionCache:{[conditionId:string]:boolean[]}           =   {};

    // This is useful to prefer the values when they're set by inputs
    protected forcedSchema          =   false;
    protected forcedExperience      =   false;
    protected identified            =   false;
    protected initialTrackingMetric =   false;

    protected frameNotifier:AlStopwatch;
    protected contextNotifier:AlStopwatch;
    protected sessionMonitor:AlStopwatch;
    protected subscriptions                 =   new AlSubscriptionGroup();
    protected rawExperienceMappings:unknown;
    protected experienceMappings:AlBehaviorPromise<unknown>;
    protected warnings:{[key:string]:boolean} = {};
    protected parameterPreservationRule:AlParamPreservationRule|null = null;
    protected currentFeature:string = '';
    protected fortraVMAccountDictionary:{[accountId:string]:string} = {};
    protected storage               = AlCabinet.persistent("alnav");

    // Session Idle and Session Expiration Controls
    protected useExpirationUI       =   false;
    protected inIdleWarning         =   false;
    protected inReauthentication    =   false;


    constructor( public sessionManager:AlSessionManagerService,
                 public router:Router,
                 public ngZone:NgZone,
                 public experiencePreference: AlExperiencePreferencesService,
                 public externalContent:AlExternalContentManagerService,
                 public titleService: Title,
                 public alUsageTrackingService: AlUsageTrackingService,
                 public confirmationService: ConfirmationService,
    ) {
        this.frameNotifier = AlStopwatch.later( this.emitFrameChanges );
        this.contextNotifier = AlStopwatch.later( this.emitContextChanges );
        this.currentUrl = window.location.href;
        this.currentFeature = this.getFeatureFromURL( this.currentUrl );
        if ( this.router ) {
            this.subscriptions.manage( this.router.events.pipe( filter( event => event instanceof NavigationEnd ) ).subscribe( e => this.onNavigationComplete( e as NavigationEnd ) ) );
        }
        this.subscriptions.manage(
            AlSession.notifyStream.attach( AlSessionStartedEvent, this.onSessionStarted ),
            AlSession.notifyStream.attach( AlActingAccountChangedEvent, this.onActingAccountChanged ),
            AlSession.notifyStream.attach( AlActingAccountResolvedEvent, this.onActingAccountResolved ),
            AlSession.notifyStream.attach( AlSessionEndedEvent, this.onSessionEnded ),
            AlConduitClient.events.attach( AlDatacenterSessionErrorEvent, this.onDatacenterSessionError ),
            AlConduitClient.events.attach( AlDatacenterSessionEstablishedEvent, this.onDatacenterSessionEstablished ),
            AlConduitClient.events.attach( AlExternalTrackableEvent, this.onExternalTrackableEvent )
        );
        this.exposeGlobals();
        this.listenForTriggers();
        this.conduit.start();

        // Start loading both schemas immediately in order to populate the namedRouteDictionary.
        this.getNavigationSchema("mdr2");           //  MDR Schema + Magma
        this.getNavigationSchema("cie-plus3");      //  Legacy Schema + Magma
        this.getExperienceMappings();

        if ( AlSession.isActive() ) {
            this.authenticated = true;
            this.entitlements = AlSession.getEffectiveEntitlementsSync();
            this.primaryEntitlements = AlSession.getPrimaryEntitlementsSync();
            this.primaryAccountId = AlSession.getPrimaryAccountId();
            this.actingAccountId = AlSession.getActingAccountId();
            this.setRouteParameter( "anonymous", "false" );
            this.setRouteParameter( 'accountId', this.actingAccountId );
            this.sessionMonitor = AlStopwatch.repeatedly( this.onSessionMonitor, 5000 );
            this.listenForIdle();
            this.checkScanPoliciesPresence();
        } else {
            this.setRouteParameter("anonymous", "true" );   //  presume the user is unauthenticated by default
        }
    }

    /**
     * Returns a promise(-like object) that resolves when the navigation service is ready to navigate.
     */
    public ready():Promise<boolean> {
        return Promise.all( [ this.navigationReady, this.experienceMappings ] ).then( r => r, e => e );
    }

    /**
     * In lieu of a deconstructor/destroyer or consistent garbage collection, this provides a way to detach an instance of AlNavigationService
     * from all of its subscriptions and listeners.
     */
    public detach() {
        this.subscriptions.cancelAll();
    }

    /**
     * Returns the experience
     */
    public getExperience():string {
        return this.globalExperience;
    }

    /**
     * Returns the navigation schema id
     */
    public getSchema() {
        return this.navigationSchemaId;
    }

    public getSchemas() {
        return this.loadedSchemas;
    }

    /**
     * Whether the experience was forced
     */
    public getForcedExperience():boolean {
        return this.forcedExperience;
    }

    /**
     * Whether the experience was forced
     */
    public getForcedSchema():boolean {
        return this.forcedSchema;
    }

    public setForceSchema( force:boolean ) {
        this.forcedSchema = force;
    }

    public setForceExperience( force:boolean ) {
        this.forcedExperience = force;
    }

    /**
     * Sets the desired experience and notifies the navigation components of the changed setting.
     */
    public setExperience( experience:string, force?:boolean ) {
        if ( force || !this.forcedExperience ) {
            this.globalExperience = experience;
            this.setRouteParameter( "experience", experience );        //  make the selected experience available for conditional routes to test against
            this.frameNotifier.again();
            this.contextNotifier.again();
        }
    }

    /**
     * Sets the desired schema and notifies the navigation components of the changed setting.
     */
    public async setSchema( schemaId:string, force?:boolean ) {
        if ( force || !this.forcedSchema ) {
            try {
                if ( schemaId && ! ( schemaId in this.schemas ) ) {
                    await this.getNavigationSchema( schemaId );
                }
                this.navigationSchemaId = schemaId;
                this.frameNotifier.again();
                this.contextNotifier.again();
            } catch( e ) {
                AlErrorHandler.log( `Failed to set the current schema to ${schemaId}`, e );
            }
        }
    }

    public setTertiaryMenu( menu:AlRoute ) {
        this.tertiaryMenu = menu;
        this.events.trigger( new AlNavigationTertiarySelected( menu ) );
    }

    /**
     * Changes the acting account.
     *
     * @param accountId The new acting account ID.
     * @param preventRedirection If provided and true, will prevent the function from triggering redirection if
     *                          the residency/domain changes.
     */
    public async setActingAccount( accountId:string, preventRedirection?:boolean ):Promise<boolean> {

        //  Capture original state -- accountId, acting node, acting base URL
        const originalActingAccountId = AlSession.getActingAccountId();
        const originalDatacenterId = AlSession.getActiveDatacenter();
        const originalActingNode = AlLocatorService.getActingNode();
        const originalBaseUrl = originalActingNode ? AlLocatorService.resolveURL( originalActingNode.locTypeId ) : null;

        //  Change the acting account via @al/core
        try {
            await AlSession.setActingAccount( accountId );
            //  Update the accountId route parameter
            this.setRouteParameter("accountId", accountId );

            if ( originalBaseUrl && originalBaseUrl !== AlLocatorService.resolveURL( originalActingNode.locTypeId ) ) {
                const actingBaseUrl = AlLocatorService.resolveURL( originalActingNode.locTypeId );
                //  If these two strings don't match, the residency portal for the acting application has changed (e.g., we've switched from .com to .co.uk or vice-versa)
                //  In this case, redirect to the correct target location.
                let path = this.currentUrl.replace( /http[s]?:\/\/[a-z0-9_\-\.]+/i, '' );
                if ( originalActingAccountId ) {
                    //  Replace references to the previous acting account ID with references to the new one.
                    //  This could hypothetically malfunction for certain deep links -- so far, no issues have been reported (fingerscrossed)
                    path = path.replace( `/${originalActingAccountId}`, `/${accountId}` );
                }
                if ( path.indexOf( "?" ) >= 0 ) {
                    //  Chop off query parameters.  I remembered why we're doing this!  It's because the redirect logic below will
                    //  add the correct ones back in again.
                    path = path.substring( 0, path.indexOf( "?" ) );
                }

                if ( ! preventRedirection ) {
                    const href = this.resolveURL( `${actingBaseUrl}${path}` );
                    console.warn("AlNavigationService.setActingAccount: portal residency changed; redirecting to", href );
                    this.goToURL( href );
                }
            } else {
                if ( ! preventRedirection ) {
                    const href = this.rewriteUrlToAccountAndLocation( this.currentUrl, originalActingAccountId, originalDatacenterId );
                    if ( href !== this.currentUrl ) {
                        this.goToURL( href );
                    }
                }

                await this.setDefenderPreferredCustomer();
            }
            return true;
        } catch( error ) {
            AlErrorHandler.log( error, "AlNavigationService failed to set acting account" );
            return false;
        }
    }

    /**
     * Changes the current datacenter.
     *
     * @param insightLocationId The target location ID
     */
    public async setActiveDatacenter( insightLocationId:string ) {
        const actor = AlLocatorService.getActingNode();
        if ( actor && insightLocationId in AlInsightLocations ) {

            const regionLabel = AlInsightLocations[insightLocationId].logicalRegion;
            let confirmed = await this.confirm( {
                header:                 'Are you sure?',
                message:                `You are about to switch regions to ${regionLabel}.  Are you sure this is what you want to do?`,
                acceptLabel:            'Yes, switch now!',
                rejectLabel:            'No thanks',
                rejectButtonStyleClass: 'flat'
            } );

            if ( confirmed ) {

                const originBaseURI = AlLocatorService.resolveURL( actor.locTypeId );
                AlSession.setActiveDatacenter( insightLocationId );
                AlLocatorService.setContext( { insightLocationId } );
                this.track( AlTrackingMetricEventName.UsageTrackingEvent, {
                    category:   AlTrackingMetricEventCategory.GenericConsoleAction,
                    action:     'Data Residency Change',
                    label:      insightLocationId
                } );

                /**
                 * Attempt to identify the top-level route for the feature we are currently in, and redirect to it.  This serves two purposes:
                 * first, to expunge any deep/datacenter-specific route elements from the URI, and second to prompt a view reload.
                 *
                 * NOTE: this is desirable behavior, but it causes an empty view for all feature modules that don't have a catch-all route.
                 */
                /*
                let routeElements = this.currentUrl.replace( `${window.location.origin}/#`, '' ).match(/\/([a-zA-Z0-9\-_]+)/ );
                let targetURL = routeElements && routeElements.length > 1 ? `/#/${routeElements[1]}` : this.currentUrl;
                this.navigate.byURL( targetURL );
                */

                this.navigate.byNgRoute( [ '/' ] );      //  this is a placeholder for better behavior

                AlErrorHandler.log(`Datacenter changed to '${insightLocationId}'; URI rewritten to [${this.currentUrl}]` );
            }
        } else {
            //  No eggs, no bacon?  No breakfast for you :(
            AlErrorHandler.log(`Internal error: cannot set active datacenter to '${insightLocationId}'`);
        }
    }

    /**
     * This method calls a legacy endpoint in the defender stack to tell it which customer is currently the active or "preferred" one.
     * It will log errors and return false in case of failure, but is not treated as a blocking failure for `setActingAccount()`.
     */
    public async setDefenderPreferredCustomer():Promise<boolean> {
        try {
            const linkedUsers = AlSession.getUser().linked_users ?? [];
            const linkedDatacenterUser = linkedUsers.find( user => user.location === AlSession.getActiveDatacenter() );
            if ( linkedDatacenterUser ) {
                await AlDefaultClient.post( {
                    url: AlLocatorService.resolveURL( AlLocation.LegacyUI, '/core/set-preferred-cid' ),
                    params: {
                        customer_id: AlSession.getActingAccountId()
                    },
                    withCredentials: true,
                } );
            }
            return true;
        } catch( e ) {
            AlErrorHandler.log( e, "AlNavigationService could not set defender preferred customer" );
            return false;
        }
    }

    /* A note for posterity: there are *definitely* ways that the following URL rewrite can cause problems.  For instance, if the user is looking at a
     * specific account's deployment, then the URL will be rewritten to look at account A and deployment belonging to account B.
     * However, a more systemically "correct" way of redirecting after account change is not yet available.  Someday soon, one hopes.
     */
    public rewriteUrlToAccountAndLocation( url:string, originalActingAccountId:string, originalDatacenterId:string ) {
        const pathReplacer = new RegExp( `\/${originalActingAccountId}([?\/])`, 'm' );
        return url.replace( /aaid=([0-9]+)/m, `aaid=${AlSession.getActingAccountId()}` )
                    .replace( pathReplacer, match => match.replace( originalActingAccountId, AlSession.getActingAccountId() ) )
                    .replace(`locid=${originalDatacenterId}`, `locid=${AlSession.getActiveDatacenter()}` );
    }

    /**
     * Retrieves a navigation schema (or resolves with the already-loaded schema).
     *
     * @param schemaId The identifier of the schema, currently either 'cie-plus2' or 'siemless' (although that is subject to change).
     *
     * The method will first attempt to retrieve the schema from conduit, and then fall back to retrieving a local copy using http.
     */
    public getNavigationSchema( schemaId:string ):Promise<AlNavigationSchema> {
        if ( ! this.schemas.hasOwnProperty( schemaId ) ) {
            this.schemas[ schemaId ] = new Promise( async ( resolve, reject ) => {
                this.claimPendingResource();
                try {
                    let schemaDefinition:AlNavigationSchema = await this.externalContent.getResource<AlNavigationSchema>( `navigation:schemas/${schemaId}.json`, 'json' );
                    resolve( this.ingestNavigationSchema( schemaId, schemaDefinition ) );       //  resolve regardless of whether or not a schema has been retrieved
                } catch( e ) {
                    reject( e );
                }
            } );
        }
        return this.schemas[schemaId];
    }

    /**
     * Retrieves an "experience mapping" for a given experience identifier (a string formatted as path.to.feature#variant)
     */
    public async getExperienceMapping( experienceId:string ):Promise<AlExperienceMapping|null> {
        const matcher = /([a-zA-Z0-9\-_\.]+)#([a-zA-Z0-9_\-\.]+)/;
        const matches = experienceId.match( matcher );
        if ( matches.length !== 3 ) {
            console.warn(`Warning: experience identifier '${experienceId}' cannot be parsed as a featureId#variantId pair; please check your formatting.`);
            return null;
        }
        const mappings = await this.getExperienceMappings();
        const featureNode = getJsonPath( mappings, matches[1], null );
        if ( ! featureNode ) {
            return null;
        }
        if ( matches[2] in featureNode ) {
            return featureNode[matches[2]] as AlExperienceMapping;
        }
        return null;
    }

    public async getExperienceMappings():Promise<unknown> {
        if ( this.rawExperienceMappings ) {
            return this.rawExperienceMappings;
        }
        if ( this.experienceMappings ) {
            return this.experienceMappings;
        }
        this.experienceMappings = new AlBehaviorPromise<unknown>();
        this.claimPendingResource();
        try {
            this.rawExperienceMappings = await this.externalContent.getResource( "navigation:experience-mappings.json", "json" );
        } catch( e ) {
            this.rawExperienceMappings = {};
            console.warn("Failed to retrieve experience mappings; using empty set" );
        }
        await AlSession.ready();
        await this.evaluateExperienceMappings( this.rawExperienceMappings );
        this.experienceMappings.resolve( this.rawExperienceMappings );
        this.contextNotifier.again();
        this.releasePendingResource();
        return this.rawExperienceMappings;
    }

    public getRouteByName( routeName:string ):AlRouteDefinition {
        if ( this.navigationSchemaId && this.navigationSchemaId in this.loadedSchemas ) {
            const currentSchema = this.loadedSchemas[this.navigationSchemaId];
            let schemaMenus = Object.keys( currentSchema.menus ).map( menuId => this.getLoadedMenu( this.navigationSchemaId, menuId ) );
            // Conditional branches have names inline -- search for these first
            for ( let i = 0; i < schemaMenus.length; i++ ) {
                let matched = schemaMenus[i].search( ( route, definition ) => definition.name === routeName );
                if ( matched ) {
                    return matched;
                }
            }

            // Otherwise, search the current schema's named route dictionary
            if ( currentSchema.namedRoutes && routeName in currentSchema.namedRoutes ) {
                //  Favor using named routes from the active schema
                return this.loadedSchemas[this.navigationSchemaId].namedRoutes[routeName];
            }
        }
        //  Fall back to global dictionary
        if ( this.namedRouteDictionary.hasOwnProperty( routeName ) ) {
            return this.namedRouteDictionary[routeName];
        }
        console.warn(`AlNavigationService: cannot retrieve route with name '${routeName}'; no such named route is defined.` );
        return null;
    }

    public getConditionById( conditionId:string ):AlRouteCondition|boolean {
        if ( this.conditionDictionary.hasOwnProperty( conditionId ) ) {
            return this.conditionDictionary[conditionId];
        }
        return false;
    }

    /**
     * @deprecated
     *
     * Routes with ids are now referred to as 'named routes'; use getRouteByName instead.
     */
    public getRouteById( routeId:string ):AlRouteDefinition {
        console.warn("AlNavigationService.getRouteById is deprecated; please use getRouteByName instead." );
        return this.getRouteByName( routeId );
    }

    /**
     * Gets a menu by schema and ID (async)
     */
    public getMenu( schemaId:string, menuId:string ):Promise<AlRoute> {
        return this.getNavigationSchema( schemaId ).then(() => {
            const menuKey = `${schemaId}:${menuId}`;
            if ( ! this.loadedMenus.hasOwnProperty( menuKey ) ) {
                throw new Error( `Navigation schema '${schemaId}' does not have a menu with ID '${menuId}'` );
            }
            return this.loadedMenus[menuKey];
        } );
    }

    /**
     * Gets a menu by schema and ID (syncronous)
     */
    public getLoadedMenu( schemaId:string, menuId:string ):AlRoute {
        const menuKey = `${schemaId}:${menuId}`;
        if ( ! this.loadedMenus.hasOwnProperty( menuKey ) ) {
            throw new Error( `Navigation schema '${schemaId}' is not loaded or does not have a menu with ID '${menuId}'` );
        }
        return this.loadedMenus[menuKey];
    }

    public setBookmark( bookmarkId:string, menuItem:AlRoute ) {
        this.bookmarks[bookmarkId] = menuItem;
    }

    public getBookmark( bookmarkId:string ):AlRoute {
        return this.bookmarks.hasOwnProperty( bookmarkId ) ? this.bookmarks[bookmarkId] : null;
    }

    /**
     * Handles user dispatch of a given route.
     */
    public dispatch = ( route:AlRoute, params?:{[param:string]:string} ) => {
        const options:AlNavigateOptions = {};
        if ( 'target' in route.properties ) {
            options.target = route.properties.target;
        }
        const isParentAncester = ( targetRoute:AlRoute, parentRoute:AlRoute ): boolean => {
            return targetRoute?.parent ? targetRoute.parent === parentRoute || isParentAncester(targetRoute.parent, parentRoute) : false;
        };
        if ( 'redirect-to-parent' in route.properties && route && this.activatedRoute?.parent
            && isParentAncester(this.activatedRoute, route)) {
            route = this.activatedRoute.parent;
        }
        this.activatedRoutes = [ route ];            //  always set the activated route to the route being dispatched
        this.navigate.byRoute( route, params, options );
    }

    public refreshMenus() {
        let activatedRoutes:AlRoute[] = null;
        if ( ! this.schema || ! this.navigationSchemaId ) {
            console.warn("Warning: cannot refresh menus without an active schema." );
            return;
        }

        Object.keys( this.schema.menus )
            .map( menuId => `${this.navigationSchemaId}:${menuId}` )
            .map( scopedMenuId => this.loadedMenus[scopedMenuId] )
            .forEach( menu => {
                if (menu) {
                    menu.refresh( true );
                    if ( ! activatedRoutes ) {
                        activatedRoutes = menu.getActivationCursorFlat();
                    }
                }
            } );

        this.activatedRoutes = activatedRoutes || [];
    }

    /**
     * Determines whether or not a given experience flag is available, but ensures that all moving targets (session state, navigation state, experience
     * mappings) are fully resolved before performing the evaluation.
     */
    public async isExperienceAvailable( xpId:string ):Promise<boolean> {
        await AlSession.ready();
        await this.navigationReady;
        await this.experienceMappings;
        return this.isExperienceAvailableSync( xpId );
    }

    /**
     * @warning
     *
     * Determine whether or not a given experience flag is available, at this moment, in the current navigational context.
     * PLEASE NOTE it should only be used if the caller is already certain that the context is stable (e.g., no account change or
     * datacenter change in process).  For timing-safe usage, please use `isExperienceAvailable(
     */
    public isExperienceAvailableSync = ( xpId:string ):boolean => {
        let negate = false;
        if ( xpId.startsWith("!") ) {
            xpId = xpId.substring(1);
            negate = true;
        }
        let flagValue = xpId in this.experienceFlags ? this.experienceFlags[xpId] : false;
        if ( negate ) {
            flagValue = ! flagValue;
        }

        return flagValue;
    }

    /**
     * Set a stick experience flag.
     */
    public async setExperienceFlag( xpId:string, value:boolean, sticky:boolean = false ) {
        if ( sticky ) {
            let stickyFlags = await this.conduit.getGlobalSetting( "xpPersistent") || {};
            stickyFlags[xpId] = value;
            await this.conduit.setGlobalSetting("xpPersistent", stickyFlags );
        }
        this.experienceFlags[xpId] = value;
        this.contextNotifier.again();
    }

    public modifyEntitlements( commandSequence:string, acting:boolean = true ) {
        //  This allows dynamic tweaking of entitlements using an economical sequence of string commands
        const records:AlEntitlementRecord[] = [];
        commandSequence.split(",").forEach( command => {
            if ( command.startsWith( "+") ) {
                records.push( { productId: command.substring( 1 ), active: true, expires: new Date( 8640000000000000 ) } );
            } else if ( command.startsWith( "-" ) ) {
                records.push( { productId: command.substring( 1 ), active: false, expires: new Date( 8640000000000000 ) } );
            } else {
                console.warn(`Warning: don't know how to interpret '${command}'; ignoring` );
            }
        } );
        if ( acting ) {
            this.entitlements.merge( records );
        } else {
            this.primaryEntitlements.merge( records );
        }
        this.contextNotifier.again();
    }

    /**
     * Evaluates a route condition.  Please note that this method will only be called for conditionals that cannot already be intuited by data from this service's
     * AlRoutingHost implementation.
     */
    public evaluate( condition: AlRouteCondition ):boolean[] {
        if ( condition.conditionId && condition.conditionId in this.conditionCache ) {
            return this.conditionCache[condition.conditionId];
        }

        let results:boolean[] = [];
        if ( typeof( condition.authentication ) === 'boolean' ) {
            results.push( this.authenticated === condition.authentication );
        }
        if ( condition.accounts ) {
            results.push( condition.accounts.includes( this.actingAccountId ) );
        }
        if ( condition.primaryAccounts ) {
            results.push( condition.primaryAccounts.includes( this.primaryAccountId ) );
        }
        if ( condition.userIds ) {
            results.push( condition.userIds.includes( AlSession.getUserId() ) );
        }
        if ( condition.locations ) {
            results.push( condition.locations.includes( AlSession.getActiveDatacenter() ) );
        }
        if ( condition.primaryLocations ) {
            results.push( condition.primaryLocations.includes( AlSession.getPrimaryAccount().default_location ) );
        }

        if ( condition.entitlements ) {
            if ( ! Array.isArray( condition.entitlements ) ) {
                condition.entitlements = [ condition.entitlements ];
                this.warnOnce( 'entitlementsArray', "Warning: AlNavigationService.evaluate() will not support non-array 'entitlements' conditions in the future", condition );
            }
            results = results.concat(
                condition.entitlements.map( entitlementExpression => this.authenticated && this.entitlements.evaluateExpression( entitlementExpression ) )
            );
        }
        if ( condition.primaryEntitlements ) {
            if ( ! Array.isArray( condition.primaryEntitlements ) ) {
                condition.primaryEntitlements = [ condition.primaryEntitlements ];
                this.warnOnce( 'entitlementsArray', "Warning: AlNavigationService.evaluate() will not support non-array 'primaryEntitlements' conditions in the future", condition );
            }
            results = results.concat(
                condition.primaryEntitlements.map( entitlementExpression => this.authenticated && this.primaryEntitlements.evaluateExpression( entitlementExpression ) )
            );
        }
        if ( condition.accountProps ) {
            const account = AlSession.getActingAccount();
            results = results.concat(
                condition.accountProps.map( accountProp => accountProp.name in account && accountProp.values.includes( account[accountProp.name] ) )
            );
        }
        if ( condition.primaryAccountProps ) {
            const account = AlSession.getPrimaryAccount();
            results = results.concat(
                condition.primaryAccountProps.map( accountProp => accountProp.name in account && accountProp.values.includes( account[accountProp.name] ) )
            );
        }
        if ( condition.environments ) {
            results.push( condition.environments.includes( AlLocatorService.getContext().environment ) );
        }
        if ( condition.experiences ) {
            results = results.concat(
                condition.experiences.map(experienceId => this.isExperienceAvailableSync( experienceId ))
            )
        }
        if ( condition.conditions ) {
            results = results.concat( condition.conditions.map( child => this.evaluateCondition( child ) ) );
        }
        if ( condition.conditionId ) {
            this.conditionCache[condition.conditionId] = results;
        }
        if ( condition.after || condition.before ) {
            const now = Math.floor( Date.now() / 1000 );       //  epoch timestamp
            if ( condition.before ) {
                results.push( now < this.dateToUTCTimestamp( condition.before ) );
            }
            if ( condition.after ) {
                results.push( now >= this.dateToUTCTimestamp( condition.after ) );
            }
        }
        return results;
    }

    /**
     * Extends the above evaluation function by reducing all of the results to a final value
     */
    public evaluateCondition( condition:AlRouteCondition ):boolean {
        const results = this.evaluate( condition );
        if ( condition.rule === 'none' ) {
            return ! results.some( value => value );
        } else if ( condition.rule === 'any' ) {
            return results.some( value => value );
        } else {
            return results.every( value => value );
        }
    }

    /**
     * Convenience method to evaluate currently installed entitlements against a logic entitlement expression.
     * See @al/core AlEntitlementCollection for more information on these expressions and how they are evaluated.
     */
    public evaluateEntitlementExpression( expression:string ):boolean {
        return this.entitlements.evaluateExpression( expression );
    }

    /**
     * @deprecated
     * Alias of evaluateEntitlementExpression
     */
    public evaluateEntitlement( expression:string ):boolean {
        return this.evaluateEntitlementExpression( expression );
    }

    /**
     * Sets a route parameter and schedules notification of child components.
     */
    public setRouteParameter( parameter:string, value:string ) {
        this.routeParameters[parameter] = value;
        this.contextNotifier.again();
    }

    /**
     * Deletes a route parameter and schedules notification of child components.
     */
    public deleteRouteParameter( parameter:string ) {
        delete this.routeParameters[parameter];
        this.contextNotifier.again();
    }

    /**
     * Gets a layout option
     */
    public getLayoutOption( option:string, defaultValue?:string|boolean ):string|boolean|undefined {
        if ( option in this.layoutOptions ) {
            return this.layoutOptions[option];
        }
        return defaultValue;
    }

    /**
     * Sets a layout option, emitting an event if there is any change to state
     */
    public setLayoutOption( option:string, value:string|boolean|undefined ) {
        this.ngZone.run( () => {
            if ( typeof( value ) === 'undefined' ) {
                if ( option in this.layoutOptions ) {
                    delete this.layoutOptions[option];
                    this.layoutOptionsChanged$.emit( this.layoutOptions );
                }
            } else {
                if ( ! ( option in this.layoutOptions ) || this.layoutOptions[option] !== value ) {
                    this.layoutOptions[option] = value;
                    this.layoutOptionsChanged$.emit( this.layoutOptions );
                }
            }
        } );
    }

    /**
     * A workhorse method to resolve target locations, based on a variety of inputs, that includes route parameter substitution and session state query
     * parameters (aaid and locid).  It can accept a literal URL, a locTypeId/path pair, or an AlRoute as inputs.
     */
    public resolveURL( url:string|{locTypeId:string,path?:string}|AlRoute, parameters:{[p:string]:string} = {}, context?:AlLocationContext ):string {
        if ( url instanceof AlRoute ) {
            url.refresh( true );
            if ( ! url.href ) {
                throw new Error("Invalid usage: cannot resolve URL for a non-link route!" );
            }
            url = url.href;
        } else if ( typeof( url ) === 'object' && url.hasOwnProperty( "locTypeId" ) ) {
            url = AlLocatorService.resolveURL( url.locTypeId, url.path, context );
        }
        //  url is now guaranteed to be a string containing a fully qualified href
        return this.applyParameters( url as string, parameters );
    }

    /**
     * Implements `AlRoutingHost`'s decorateHref method, which allows it to manipulate the URLs generated
     * by @al/core.
     */
    public decorateHref( route:AlRoute ) {
        const externalLocationList = [ AlLocation.FortraPlatform ];
        if ( route && route.href && route.visible ) {
            if ( route.getProperty("external", false ) ) {
                return;
            }
            if ( typeof( route.definition.action ) === 'object' && route.definition.action.location && externalLocationList.includes( route.definition.action?.location ) ) {
                return;
            }
            route.href = this.applyParameters( route.href, {}, true, true );        //  applies locid and aaid query parameters as appropriate
        }
    }

    /**
     * Black magic function that, given a url with :variable placeholder, consumes provided parameters as route parameters
     * and compiles any remainders into a query string.
     *
     * @param url The input url, with route parameter placeholder -- e.g., https://console.overview.alertlogic.com/#/remediations-scan-status/:accountId/:deploymentId
     * @param parameters The list of parameters to consume
     * @param rewriteQueryString If true (default), applies unused keys/values from the `parameters` input to generate a query string, and appends that to the result.
     * @param identityParameters If true (default) or a navigation options structure, applies acting account and location parameters to the query string.
     *
     * @returns The compiled URL.
     */
    public applyParameters( url:string,
                            parameters:{[p:string]:string},
                            rewriteQueryString:boolean = true,
                            identityParameters:boolean|AlNavigateOptions = true):string {
        const unused = Object.assign( {}, parameters );
        url = url.replace( /\:[a-zA-Z_]+/g, match => {
            const variableId = match.substring( 1 );
            if ( unused.hasOwnProperty( variableId ) ) {
                const value = unused[variableId];
                delete unused[variableId];
                return value;
            } else if ( this.routeParameters.hasOwnProperty( variableId ) ) {
                return this.routeParameters[variableId];
            } else {
                //  Missing route parameters should not occur under most circumstances
                //  console.warn(`AlNavigationService: cannot fully construct URL which requires missing parameter '${variableId}'`);
                return "(null)";
            }
        } );
        let encodeFun: (a: any) => string = encodeURIComponent;
        if ( typeof( identityParameters ) === 'object') {
            if (identityParameters.disableQSRewrite) {
                rewriteQueryString = false;     //  allows overriding behavior for a handful of special cases (e.g., post-login redirect, etc)
            }
            if (identityParameters.encoding === 'uri') {
                encodeFun = encodeURI;
            } else if (identityParameters.encoding === 'none') {
                encodeFun = (x) => x;
            }

        }

        /* istanbul ignore else */
        if ( rewriteQueryString ) {
            if ( identityParameters && AlSession.isActive() ) {
                //  Add current acting account and datacenter identifier as query parameters
                let actingAccountId;
                let datacenterId;
                if ( typeof( identityParameters ) === 'object' && identityParameters.hasOwnProperty("as") ) {
                    actingAccountId = identityParameters.as.accountId || AlSession.getActingAccountId();
                    datacenterId = identityParameters.as.locationId || AlSession.getActiveDatacenter();
                } else {
                    actingAccountId = AlSession.getActingAccountId();
                    datacenterId = AlSession.getActiveDatacenter();
                }
                unused["aaid"] = actingAccountId;
                if ( datacenterId ) {
                    unused["locid"] = datacenterId;      //  corresponds to insight locations service locationId
                }
            }
            const qsOffset = url.indexOf("?");
            if ( qsOffset !== -1 ) {
                //  Extract current query parameters and merge any missing items
                const existing:{[parameter:string]:string} = {};
                url.substring( qsOffset + 1 )
                    .split("&")
                    .forEach( kvPair => {
                        const [ key, value ] = kvPair.split("=");
                        existing[key] = decodeURIComponent( value );
                    } );
                for ( const k in existing ) {
                    if ( existing.hasOwnProperty( k ) && ! [ 'locid', 'aaid' ].includes( k ) ) {
                        unused[k] = existing[k];
                    }
                }
                url = url.substring( 0, qsOffset );
            }
            if ( Object.keys( unused ).length > 0 ) {
                const queryString = Object.keys( unused ).sort().map( key => `${key}=${encodeFun( unused[key] )}` ).join( "&" );
                url = `${url}?${queryString}`;
            }
        }

        return url;
    }

    /**
     *  Generates the available data center menu structure.
     *
     *  A note on history: the reason this code is so unduly complicated is because, when the data center selector was introduced during 2017's "Universal Navigation"
     *  project (a time period when it was necessary to manage seperate user accounts and logins for different datacenters, if you can imagine that) the decision was made
     *  to conflate defender and insight locations into composites "us-west-1", "us-east-1", and "uk-west-1".  The problem, of course, is that the defender and insight
     *  datacenters of the US are asymmetrical.  It was assumed that the number of datacenters would inevitably increase and span further regions, so abstraction
     *  was preferred over a simpler enumeration of the possible permutations.
     *
     *  This expansion has not yet occurred, but the code is ready for it...  :)
     */
    public generateDatacenterMenu( currentLocationId:string,
                                      accessible:string[],
                                      activationCallback:{(insightLocationId:string,$event:any):void} ):AlDatacenterOptionsSummary {
        const available:{[i:string]:{[j:string]:string}} = {};
        let currentRegion = AlInsightLocations.hasOwnProperty( currentLocationId )
                                ? AlInsightLocations[currentLocationId].logicalRegion
                                : 'us-west-1';      //  without a default, people complain...  they complain so much
        accessible.forEach( accessibleLocationId => {
            if ( ! AlInsightLocations.hasOwnProperty( accessibleLocationId ) ) {
                return;
            }
            const locationInfo = AlInsightLocations[accessibleLocationId];
            let targetLocationId = accessibleLocationId;
            let logicalRegion = locationInfo.logicalRegion;
            if ( locationInfo.alternatives ) {
                locationInfo.alternatives.find( alternativeLocationId => {
                    if ( accessible.includes( alternativeLocationId ) ) {
                        targetLocationId = alternativeLocationId;
                        logicalRegion = AlInsightLocations[alternativeLocationId].logicalRegion;
                        return true;
                    }
                    return false;
                } );
            }
            if ( ! available.hasOwnProperty( locationInfo.residencyCaption ) ) {
                available[locationInfo.residencyCaption] = {};
            }
            if ( ! available[locationInfo.residencyCaption].hasOwnProperty( logicalRegion ) ) {
                available[locationInfo.residencyCaption][logicalRegion] = targetLocationId;
            }
        } );

        let locationsAvailable = 0;
        let currentResidency = "US";
        const selectableRegions:PrimengMenuItem[] = [];
        Object.keys( available ).forEach( region => {
            const regionMenu: {label: string; items: PrimengMenuItem[]} = {
                label: region,
                items: []
            };
            Object.keys( available[region] ).forEach( logicalRegion => {
                const targetLocationId = available[region][logicalRegion];
                const activated = ( logicalRegion === currentRegion ) ? true : false;
                if ( activated ) {
                    currentResidency = AlInsightLocations[targetLocationId].residency;
                    currentRegion = AlInsightLocations[targetLocationId].logicalRegion;
                }
                locationsAvailable++;
                regionMenu.items.push( {
                    label: logicalRegion,
                    styleClass: activated ? "active" : "",
                    command: ( event ) => activationCallback( targetLocationId, event )
                } );
            } );
            selectableRegions.push( regionMenu );
        } );

        return { locationsAvailable, selectableRegions, currentRegion, currentResidency };
    }

    /**
     * Forces the user to login.  Greetings and salutations!
     */
    public forceAuthentication( returnURL?:string ) {
        if ( this.storage.get("fortra_authenticated", false ) ) {
            //  This implies the user has previously authenticated via Fortra, and should continue to use fortra's IdP as its entry point.
            this.sessionManager.forceFortraLogin();
        } else {
            //  Use internal/direct authentication against AIMS
            let environment = AlLocatorService.getCurrentEnvironment();
            const residency = 'US';
            const authLocationId = AlRuntimeConfiguration.getOption( ConfigOption.GestaltDomain, AlLocation.MagmaUI );
            if ( environment === 'development' ) {
                environment = 'integration';
            }
            const loginURL = AlLocatorService.resolveURL( authLocationId, '/#/login', { environment, residency } );
            this.navigate.byURL( loginURL, { return: returnURL || window.location.href } );
        }
    }

    /**
     * Forces the user to logout.  Goodbye!
     */
    public forceDeauthentication() {
        const authLocationId = AlRuntimeConfiguration.getOption( ConfigOption.GestaltDomain, AlLocation.MagmaUI );
        let environment = AlLocatorService.getCurrentEnvironment();
        const residency = 'US';
        if ( environment === 'development' ) {
            environment = 'integration';
        }
        this.navigate.byLocation( authLocationId, '/#/logout', { environment, residency, return: window.location.href } );
    }

    public incrementAuthenticationRequisite() {
        this.authenticationRequisites++;
    }

    public decrementAuthenticationRequisite() {
        this.authenticationRequisites--;
    }


    /**
     * Detect if the current browser is either IE 10 or older (msie) or IE 11 (trident)
     */
    public isIEBrowser() {
        return /msie\s|trident\//i.test(window.navigator.userAgent);
    }

    /**
     * Dispatches an arbitrary event/properties to our analytics provider(s).
     * Please note the the acting and primary account, user, and application will be populated automatically.
     */
    public track( eventName:AlTrackingMetricEventName|string, properties:{[k:string]:any} = {} ) {

        // GA Implementation
        this.alUsageTrackingService.trackCustomEvent(eventName, properties);
    }

    /**
     * Dispatches a specifically configured usage tracking event to our analytics provider(s) - most suited for Google Analytics.
     */
    public trackUsageEvent( properties:{category: string, action: string, label?: string} ) {
        this.alUsageTrackingService.trackCustomEvent( AlTrackingMetricEventName.UsageTrackingEvent, properties);
    }

    /**
     * @deprecated
     * Sends an identification call to our analytics provider(s).
     */
    public async identify() {
        console.warn('AlNavigationService.identify() has now been deprecated, please remove the invocation to this method');
        this.identified = true;
    }

    /**
     * Handles session persistence errors (aka "third party cookies are disabled and you can't log in").
     * If the current application instance is in an iframe, it communicates the error to the parent document.
     * If the current application is the top level document, it will redirect to the appropriate error page on console.account.
     */
    public onDatacenterSessionError = ( error:AlDatacenterSessionErrorEvent ) => {
        if ( window.parent !== window ) {
            const message = {
                type: "conduit.externalSessionError",
                requestId: "na",
                errorType: error.errorType || "cookie-configuration"
            };
            window.parent.postMessage( message, "*" );
        } else {
            const errorType = error.errorType || "cookie-configuration";
            const errorText = `An internal error is preventing us from saving this session in your browser. `
                                + `This could mean that your browser's security settings are forbidding the use of 3rd party cookies, `
                                + `or that the URL you are using to access the console is malformed or incomplete, `
                                + `or that there is a service or network outage. `
                                + `If it persists, please create a support ticket and include your username or email and the URL you are trying to access.`;

            this.events.trigger( new AlNavigationApplicationError( "Session Persistence Error", errorText, 'report_problem', [ '/' ] ) );
        }
    }

    /**
     * Handles external trackable events (these are javascript events communicated from our legacy datacenters via conduit/postMessage)
     */
    public onExternalTrackableEvent = ( event:AlExternalTrackableEvent ) => {
        const eventData = event.data as any;
        const name = 'eventName' in eventData ? eventData.eventName : "External Event";
        const description = `eventDescription` in eventData ? eventData.eventDescription : "";
        const value = 'eventValue' in eventData ? eventData.eventValue : '';
        const trackableData = {
            category: AlTrackingMetricEventCategory.ExternalDatacenterAction,
            action: name,
            label: description,
            value: value
        };
        this.track( AlTrackingMetricEventName.UsageTrackingEvent, trackableData );
    }

    /**
     * Convenience method to retrieve a query parameter, optionally providing a default value
     */
    public queryParam( parameterName:string, defaultValue:string = null ):string {
        return ( parameterName in this.queryParams ) ? this.queryParams[parameterName] : defaultValue;
    }

    /**
     * Convenience method to retrieve a route parameter, optionally providing a default value
     */
    public routeParam( parameterName:string, defaultValue:string = null ):string {
        return ( parameterName in this.routeParams ) ? this.routeParams[parameterName] : defaultValue;
    }

    /**
     * Force confirmation before navigate away from the current page.
     */
    public setNavigationBlock(){
        this.isNavigationAllowed = false;
    }

    /**
     * Remove requirement of confirmation before navigate away from the current page.
     */
    public clearNavigationBlock(){
        this.isNavigationAllowed = true;
    }

    /**
     * Determine whether or not navigate away from the page is allowed.
     */
    public async canNavigateAway():Promise<boolean> {
        return this.isNavigationAllowed ? Promise.resolve(true) : await this.showNavigationConfirmDialog();
    }

    public async confirm( request:PrimengConfirmation ) {
        return new Promise<boolean>( resolve => {
            request.key = "confirmation";
            request.accept = () => resolve( true );
            request.reject = () => resolve( false );
            this.confirmationService.confirm( request );
        } );
    }

    public async showNavigationConfirmDialog():Promise<boolean> {
        return new Promise<boolean>( resolve => this.confirmationService.confirm({
            key: 'confirmation',
            message: `You have unsaved changes. Your changes will not be saved if you leave this page. Click "STAY HERE" to return to the page and save your changes.`,
            header: 'Unsaved changes',
            icon: 'pi pi-exclamation-triangle',
            rejectLabel: 'STAY HERE',
            acceptLabel: 'LEAVE WITHOUT SAVING',
            acceptButtonStyleClass: 'primaryAction',
            accept: () => {
                this.clearNavigationBlock();
                resolve(true);
            },
            reject: () => resolve(false)
        }));
    }

    /**
     * Calculates an entry point link into Fortra VM.
     */
    public async getFortraVMLink( target?:AlNavigationTrigger|string, accountId?:string ):Promise<string> {
        try {
            let page                = "scan-groups";
            if ( target instanceof AlNavigationTrigger ) {
                page = target.route.getProperty("page", "scan-groups" );
            } else if ( typeof( target ) === 'string' ) {
                page = target;
            }
            if ( ! accountId ) {
                accountId = AlSession.getActingAccountId();
            }
            const activeDatacenter  = AlSession.getActiveDatacenter();
            const activeResidency   = AlLocatorService.getCurrentResidency();
            const activeEnv         = AlLocatorService.getCurrentEnvironment();
            let fvmAccountId;

            if ( accountId in this.fortraVMAccountDictionary ) {
                fvmAccountId = this.fortraVMAccountDictionary[accountId];
            } else {
                fvmAccountId = (await AIMSClient.getMappedAccount(accountId))?.frontline_account;
            }

            if ( fvmAccountId ) {
                this.fortraVMAccountDictionary[accountId] = fvmAccountId;
                const baseUrl       = AlLocatorService.resolveURL(AlLocation.FrontlineVM);
                const url           = `${baseUrl}/oidc/login`;
                const usDatacenters = ['defender-us-ashburn', 'defender-us-denver'];
                const productionEnvs = [ 'production', 'production-staging' ];

                let residency       = 'flstaging';
                if (productionEnvs.includes( activeEnv )) {
                    if (usDatacenters.includes(activeDatacenter)) {
                        residency = 'us';
                    } else if (activeDatacenter === 'defender-uk-newport' || activeResidency === 'EMEA') {
                        residency = 'uk';
                    }
                }
                const next = `/#/vm/${residency}/account/${fvmAccountId}/scanq/${page}`;
                return this.applyParameters( url, { next }, true, { encoding: 'uri' } );
            } else {
                return '#';
            }
        } catch (e) {
            return '#';
        }
    }

    public async goToFortraVM( target?:AlNavigationTrigger|string, accountId?:string ) {
        let url = await this.getFortraVMLink( target, accountId );
        if ( url !== '#' ) {
            this.goToURL( url );
        } else {
            AlErrorHandler.log(`Cannot navigation to Fortra VM: couldn't calculate link` );
        }
    }

    /**
     * Registers a listener for the Navigation.User.Signout trigger, which should prompt local session destruction and a redirect to
     * console.account's logout route.
     */
    protected listenForTriggers() {
        this.events.attach( AlNavigationTrigger, (event: AlNavigationTrigger) => {
            switch( event.triggerName ) {
                case 'Navigation.User.Signout' :
                    return this.onSignoutTrigger( event );

                case 'Navigation.FortraVM' :
                    return this.goToFortraVM( event );

                case 'Navigation.TransitionalMode' :
                    return this.onSwitchTransitionalMode( event );
            }
        });
    }

    protected async onSignoutTrigger( event:AlNavigationTrigger ) {
        if ( ! AlRuntimeConfiguration.getOption<boolean>( ConfigOption.NavigationIntegratedAuth, false ) ) {
            event.respond( true );
            //  Only execute this logic if integrated authentication mode is disabled
            let proceed = await this.canNavigateAway();
            if ( proceed ) {
                this.forceDeauthentication();
            }
        }
    }

    protected async onSwitchTransitionalMode( event:AlNavigationTrigger ) {
        let transitionalMode = event.definition.properties?.value || false;
        await this.setExperienceFlag( `global.navigation#magma-transitional`, transitionalMode, true );
    }

    /**
     * If applicable, uses the idleService to detect when/if the user's session should be terminated due to idleness.
     *
     * NOTE: this idle-based expiration is an initial implementation of a more complex feature.  Essentially, if the interval of time expires,
     * logout will be triggered immediately and without warning.  In the future, timeToIdle and timeToTimeout (below) should be reconfigured
     * to prompt the user to continue working.
     *
     * When we get there, we should implement "prompt to reauthenticate when the token is about to expire" at the same time -- two birds, one stone.
     */
    protected listenForIdle() {
        const account = AlSession.getPrimaryAccount();
        let idleTimeout = account.idle_session_timeout;
        if ( ! idleTimeout && AlSession.getFortraSession() ) {
            idleTimeout = 60 * 60;      //  1 hour
        }
        if ( idleTimeout ) {
            this.startIdleService( idleTimeout );
        }
    }

    protected startIdleService( idleTimeout:number ) {
        AlErrorHandler.log(`Idle Detection: idle threshold set to ${idleTimeout} seconds.` );
        const threshold = Math.max( 30, idleTimeout - 180 );
        const warningDuration = 180;
        idleService.configure( {
            timeToIdle: threshold,
            timeToTimeout: warningDuration,
            autoResume: false,
            listenFor: "mousemove click scroll"
        } );
        idleService.on( IdleEvents.TimeoutWarning, remaining => {
            if ( remaining < warningDuration && ! this.inIdleWarning ) {
                this.inIdleWarning = true;
                this.ngZone.run( () => {
                    AlErrorHandler.log(`Idle Detection: user's idle time has reached threshold of ${remaining} seconds remaining; triggering idle warning` );
                    this.events.trigger( new AlNavigationIdlePrompt( warningDuration, () => {
                        idleService.reset();
                        this.inIdleWarning = false;
                    } ) );
                } );
            }
        } );
        idleService.start();
    }

    /**
     * Listens for router complete/cancel events from angular
     */
    protected onNavigationComplete = ( event:NavigationEnd ) => {
        this.currentUrl = window.location.href;         //  Update the current "official" URL for the menu system
        this.currentFeature = this.getFeatureFromURL( this.currentUrl );
        if ( !this.parameterPreservationRule ) {
            this.parameterPreservationRule = AlRuntimeConfiguration.findParamPreservationRule( event.urlAfterRedirects );
        }

        //  Iterate through the router's root to accumulate all data from its children, and make that data public
        const aggregatedData:Data = {};
        const aggregatedQueryParams = {};
        const aggregatedRouteParams = {};
        if ( this.router && this.router.routerState.root.snapshot.children ) {
            const accumulator = ( element:ActivatedRouteSnapshot ) => {
                Object.assign( aggregatedData, element.data );
                Object.assign( aggregatedQueryParams, element.queryParams );
                Object.assign( aggregatedRouteParams, element.params );
                if ( element.children ) {
                    element.children.forEach( accumulator );
                }
            };
            accumulator( this.router.routerState.root.snapshot );
        }

        // Assigning the App Title if the "title" property is present.
        if( aggregatedData.hasOwnProperty( 'title' ) ) {
            this.titleService.setTitle( aggregatedData['title'] );
        }
        let pageViewTitle = this.titleService.getTitle();
        // In some cases, the page title is being set dynamically in the apps themselves, such as via the al-custom-title directive so that names of things can be included.
        // For example - 'Overview | Topology | Deployment 12345' - in this case we would not want the 'Deployment 12345' part included in the data sent to Google Analytics.
        // Add a pageViewTitle data attribute in the application route definitions and set to something custom.
        // If not set, the current page title will be used as the default
        if( aggregatedData.hasOwnProperty( 'pageViewTitle' ) ) {
            pageViewTitle = aggregatedData['pageViewTitle'];
        }

        this._routeData = aggregatedData;
        this._routeParams = aggregatedRouteParams;
        this._queryParams = aggregatedQueryParams;

        if ( AlSession.isActive() && event.urlAfterRedirects !== '/' ) { // Don't track default base route navigation events
            this.alUsageTrackingService.trackPageViewEvent(pageViewTitle, event.urlAfterRedirects);
        }

        this.contextNotifier.again();                   //  Notify child components
    }

    /**
     * Listens for session start triggers from @al/core
     */
    protected onSessionStarted = ( event:AlSessionStartedEvent ) => {
        this.authenticated = true;
        this.inReauthentication = false;
        this.setRouteParameter("anonymous", "false" );
        this.primaryAccountId = event.primaryAccount.id;
        this.contextNotifier.again();
        this.sessionMonitor = AlStopwatch.repeatedly( this.onSessionMonitor, 5000 );
        this.listenForIdle();
        this.startDatadogRum();
        this.checkScanPoliciesPresence();
    }

    /**
     * Starts session recording for Datadog Real User Monitoring (RUM)
     */
    protected startDatadogRum() {
        const environment = AlLocatorService.getCurrentEnvironment();
        if (!Object.keys( DATADOG ).includes( environment )) {
            return;
        }
        const datadogLocator = {
            applicationId: DATADOG[environment].applicationId,
            clientToken: DATADOG[environment].clientToken
        };
        if (datadogLocator.applicationId && datadogLocator.clientToken) {
            datadogRum.init({
                beforeSend: ( event: RumEvent ) => {
                    if ( this.discardDatadogRumEvent( event ) ) {
                        return false;
                    }
                    event.context = {
                        ...event.context,
                        page_title: this.titleService.getTitle(),
                        current_feature: this.currentFeature
                    };
                    event.view.url = event.view.url.replace( '#/', '' );
                    event = AlErrorHandler.redact( event, false );
                    return true;
                },
                applicationId: datadogLocator.applicationId,
                clientToken: datadogLocator.clientToken,
                site: 'datadoghq.com',
                service: `magma-${environment}`,
                env: environment,
                // Specify a version number to identify the deployed version of your application in Datadog
                version: AlNavigationService.appVersionId,
                sampleRate: 100,
                sessionReplaySampleRate: 0,
                trackInteractions: true,
                trackFrustrations: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
            });
            datadogRum.setUser( this.getRumSessionInfo() );
        }
    }

    protected async promptLicenseAcceptance( licensing:AIMSLicenseAcceptanceStatus ) {
        return;     //  disabled for now

        let deferTOS = this.storage.get( 'tos_deferred', false );
        if ( deferTOS ) {
            return; //  they've already deferred once today, don't bludgeon them to death with it
        }
        let modalContext:string;
        if ( licensing.status === 'accept_tos_required' ) {
            modalContext = 'accept';
        } else if ( licensing.status === 'reaccept_tos_required' ) {
            modalContext = 'reaccept';
        }

        if ( modalContext && ! this.currentUrl.includes("/terms-of-service") ) {
            let params:{[key:string]:string} = {
                tos_context: modalContext,
                url: licensing.tos_url
            };
            if ( licensing.tos_deferral_period_end ) {
                params['deferral'] = licensing.tos_deferral_period_end.toString();
            };
            params['return'] = window.location.pathname + window.location.hash;
            AlStopwatch.once( () => this.navigateByNgRoute( [ '/terms-of-service' ], { queryParams: params } ) );
        }
    }

    /**
     * This yields data that will be associated with all analytics events in datadog for this session.  The purpose of the string surgery
     * below is to generate information that can usefully reference a specific user (initials + first four characters of last segment of their user ID)
     * without containing any personally identifiable information.
     */
    protected getRumSessionInfo():any {
        let sessionType = 'none';
        let userMemo = null;

        if ( AlSession.isActive() ) {
            sessionType = AlSession.getFortraSession() ? 'fortra' : 'aims';
            const primaryAccountId = AlSession.getPrimaryAccountId();
            const userNameParts = AlSession.getUserName().replace(/[^a-zA-Z0-9]+/mi, ' ').split(/[\s]+/);
            const userIdParts = AlSession.getUserId().split("-");
            const userIdSuffix = userIdParts.pop() || '0000';

            userMemo = `${primaryAccountId}-${userNameParts.map( el => el[0].toLowerCase() ).join("")}-${userIdSuffix.substring( 0, 4 ).toLowerCase()}`;
        }
        return { sessionType, userMemo };
    }

    /**
     * Listens for acting account changed triggers from @al/core
     */
    protected onActingAccountChanged = ( event:AlActingAccountChangedEvent ) => {
        if ( event.actingAccount.id !== this.actingAccountId ) {
            this.actingAccountId = event.actingAccount.id;
            this.routeParameters['accountId'] = event.actingAccount.id;
            this.experienceMappings.rescind();
            this.experienceFlags = {};
            this.contextNotifier.again();
            this.checkScanPoliciesPresence();
        }
    }

    /**
     * Listens for acting account resolution triggers from @al/core
     */
    protected onActingAccountResolved = async ( event:AlActingAccountResolvedEvent ) => {
        this.entitlements               =   event.entitlements;
        this.primaryEntitlements        =   event.primaryEntitlements;
        await this.evaluateExperienceMappings( this.rawExperienceMappings );
        this.experienceMappings.resolve( this.rawExperienceMappings );
        this.contextNotifier.again();
        if ( event.coreServiceError ) {
            const errorText = `A problem is preventing us from resolving your session information. `
                                + `This could mean that there is a service or network outage, or that `
                                + `the URL you are using to access the console is malformed or incomplete, `
                                + `If this problem persists, please create a support ticket and include your username or email and the URL you are trying to access.`;
            this.events.trigger( new AlNavigationApplicationError( "Session Error", errorText, 'report_problem', [ '/' ] ) );
        } else if ( event.licenseAcceptance ) {
            this.promptLicenseAcceptance( event.licenseAcceptance );
        }
        this.checkScanPoliciesPresence();
    }

    /**
     * Listens for defender session to be establised
     */
    protected onDatacenterSessionEstablished = ( ) => {
        AlStopwatch.once( () => this.checkScanPoliciesPresence() );
    }

    /**
     * Listens for session ended triggers from @al/core
     */
    protected onSessionEnded = ( ) => {
        this.authenticated = false;
        this.setRouteParameter("anonymous", "true");
        this.deleteRouteParameter( 'accountId' );
        this.contextNotifier.again();
        if ( this.sessionMonitor ) {
            this.sessionMonitor.cancel();
            delete this.sessionMonitor;
        }
        idleService.stop();
        this.identified = false;
        if(AlLocatorService.getCurrentEnvironment() === 'integration') {
            datadogRum.stopSessionReplayRecording();
        }
    }

    /**
     * Monitor session lifespan.
     * When the session is within 6 minutes of expiration, trigger the "reauthenticate now" modal.
     * When the session is within 1 minute of expiring, force deauthentication to occur.
     */
    protected onSessionMonitor = () => {
        const secondsToExpiration = AlSession.getTokenExpiry() - Math.floor( Date.now() / 1000 );
        if ( secondsToExpiration < 360 && ! this.inReauthentication && this.useExpirationUI ) {
            AlErrorHandler.log(`Notice: there are only ${secondsToExpiration} seconds until session expiration; triggering reauthentication prompt`);
            this.inReauthentication = true;
            this.ngZone.run( () => this.events.trigger( new AlNavigationReauthenticatePrompt() ) );
        } else if ( secondsToExpiration < 60 ) {
            AlErrorHandler.log( "Notice: session has expired; deactivating session and redirecting to login." );
            this.forceDeauthentication();
        }
    }

    /**
     * Internal handler for navigation by named route.
     */
    protected navigateByNamedRoute( namedRouteId:string, parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ) {
        this.navigationReady.then( schema => {
            const definition = this.getRouteByName( namedRouteId );
            if ( ! definition ) {
                throw new Error(`Imperative navigation to route '${namedRouteId}' could not be executed.` );
            }
            const route = new AlRoute( this, definition );
            this.navigateByRoute( route, parameters, options );
        } );
    }

    /**
     * Internal handler for navigation by route.
     */
    protected navigateByRoute( route: AlRoute, parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ):void {
        this.events.trigger( new AlNavigationRouteDispatched( route ) );
        const action = route.getRouteAction();
        if ( action ) {
            if ( action.type === 'link' ) {
                if ( action.url ) {
                    this.navigateByURL( action.url, parameters, options );
                } else if ( action.location ) {
                    if ( this.isLocalRoute( action ) ) {
                        //  We are navigating within an application -- use the angular router
                        this.navigateByLocalLocation( action, parameters );
                    } else {
                        //  We are navigating between applications -- away we go
                        this.navigateByLocation( action.location, action.path, parameters, options );
                    }
                } else {
                    console.warn("AlNavigationService: cannot dispatch link route without a link!", action );
                }
            } else if ( action.type === 'trigger' ) {
                AlErrorHandler.log("AlNavigationService.Trigger [%s]", action.trigger );
                this.events.trigger( new AlNavigationTrigger( this,
                                                              action.trigger,
                                                              route.definition,
                                                              route ) );
            }
        } else {
            //  Find first visible child with an action and go there instead
            const eligibleChild = route.children.find( child => child.visible && typeof( child.definition.action ) !== 'undefined' );
            if ( eligibleChild ) {
                return this.navigateByRoute( eligibleChild, parameters, options );
            } else {
                console.warn("AlNavigationService: cannot dispatch route without an action!", route );
            }
        }
    }

    protected isLocalRoute( action:AlRouteAction ):boolean {
        if ( AlLocatorService.getActingNode()?.locTypeId !== action.location ) {
            return false;
        }
        if ( ! action.path || ! action.path.startsWith('/#') || action.path.includes( "/index.html") ) {
            return false;       //  only consider hrefs prefixed with hash fragments/without a child index
        }
        return true;
    }

    /**
     * Converts a location/path pair into an angular Router.navigate call.
     * Sadly, "local location" is both redundant and unpoetic -- the adjective and noun both derive from the latin 'loc', or 'place', and the whole
     * business of navigation of riddled with redundant, overlapping, and incongruous constructs of place and movement.  It really bothers me.
     * But at the same time, this function does exactly what the name says, so I'm gonna get over it.
     */
    protected navigateByLocalLocation( target:AlRouteAction, rawParameters:{[p:string]:string} = {} ):void {
        const [ path, pathParams ] = target.path.split("?");
        const parameters:{[p:string]:string} = Object.assign( {}, rawParameters );
        let targetRoute = path.replace( /\:[a-zA-Z_]+/g, match => {
            const variableId = match.substring( 1 );
            if ( variableId in parameters ) {
                const value = parameters[variableId];
                delete parameters[variableId];
                return value;
            } else if ( variableId in this.routeParameters ) {
                return this.routeParameters[variableId];
            } else {
                return "(null)";
            }
        } );
        if ( targetRoute.startsWith("/#") ) {
            targetRoute = targetRoute.substring( 2 );
        }
        if ( targetRoute.startsWith("/") ) {
            targetRoute = targetRoute.substring( 1 );
        }
        if ( pathParams ) {
            //  If the path contains query parameters, merge them into the parameter map
            pathParams.split("&")
                .forEach( kvPair => {
                    const [ key, value ] = kvPair.split("=");
                    parameters[key] = decodeURIComponent( value );
                } );

        }
        const targetRouteCommands = targetRoute.split("/");
        this.navigateByNgRoute( targetRouteCommands, { queryParams: parameters } ); //  take that!
    }

    /**
     * Internal handler for navigation based on location/path.
     */
    protected navigateByLocation( locTypeId:string, path:string = '/#/', parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ) {
        this.navigateByURL( AlLocatorService.resolveURL( locTypeId, path ), parameters, options );
    }

    /**
     * Internal handler for navigation by raw URL
     */
    protected navigateByURL( url:string, parameters:{[p:string]:string} = {}, options:AlNavigateOptions = {} ) {
        url = this.applyParameters( url, parameters, true, options );
        this.goToURL( url, options );
    }

    /**
     *  Method to actually, you know, change the URL.  This is separated by the imperative mechanism above so that unit testing
     *  can easily intercept navigation events.
     */
    protected goToURL( url:string, options:AlNavigateOptions = {} ) {
        const newWindow = options.hasOwnProperty('target') && options['target'] === '_blank';
        if ( newWindow ) {
            window.requestAnimationFrame( () => {
                window.open( url, "_blank" );
            } );
        } else {
            AlErrorHandler.log(`AlNavigationService: navigating to [${url}]` );
            if ( options.replace ) {
                window.location.replace( url );
            } else {
                window.location.href = url;
            }
        }
    }

    /**
     * Internal handler for navigation using angular router
     */
    protected navigateByNgRoute( commands: any[], initialExtras: AlNavigationExtras = { skipLocationChange: false } ) {
        const extras = initialExtras || {};
        extras.queryParamsHandling = extras.queryParamsHandling || 'merge';
        extras.queryParams = extras.queryParams || {};
        extras.queryParams['aims_token'] = undefined;       //  make sure this query parameter is never propagated, because it is icky
        if (extras.overwriteQueryParams === true) {
            extras.queryParams = this.overwriteQueryParameters(extras.queryParams);
        }
        if ( AlSession.isActive() ) {
            extras.queryParams["aaid"] = AlSession.getActingAccountId();
            const datacenterId = AlSession.getActiveDatacenter();             //  corresponds to insight locations service locationId
            if ( datacenterId ) {
                extras.queryParams["locid"] = datacenterId;
            }
        }

        /**
         * Determine if we are changing features or parameter preservation "zones" within a given feature, and destroy any query parameters
         * that should not be preserved between areas of the application.
         */
        if ( commands.length > 0 ) {
            const targetRule = AlRuntimeConfiguration.findParamPreservationRule( this.commandsToPath( commands ) );
            const targetFeature = this.getFeatureFromCommands( commands );
            const universalWhitelist = [ 'aaid', 'locid', 'profile' ];
            if ( targetFeature !== this.currentFeature ) {
                //  If we are navigating between features (top level route), remove *all* query parameters except those explicitly provided by the caller
                //  (excluding whitelisted params).  This emulates redirection behavior found when the console consisted of many interlinked applications.
                Object.keys( this._queryParams ).forEach( existingParam => {
                    if ( ! universalWhitelist.includes( existingParam ) && ! ( existingParam in extras.queryParams ) ) {
                        extras.queryParams[existingParam] = undefined;
                    }
                } );
            } else if ( targetRule !== this.parameterPreservationRule ) {
                //  Otherwise, apply parameter preservation rules found within a zone definition
                if ( this.parameterPreservationRule && this.parameterPreservationRule.volatile ) {
                    //  Remove any 'volatile' query parameters unless an explicit value for them is provided by the caller
                    this.parameterPreservationRule.volatile.forEach( volatileQueryParam => {
                        if ( ! ( volatileQueryParam in extras.queryParams ) ) {
                            extras.queryParams[volatileQueryParam] = undefined;
                        }
                    } );
                }
                if ( targetRule && targetRule.whitelist ) {
                    const whitelist = targetRule.whitelist.concat( universalWhitelist );
                    Object.keys( this.queryParams ).forEach( existingParam => {
                        if ( ! whitelist.includes( existingParam ) ) {
                            extras.queryParams[existingParam] = undefined;
                        }
                    } );
                }
                this.parameterPreservationRule = targetRule;
            }
        }

        if ( this.router ) {
            this.router.navigate( commands, extras );
        } else {
            throw new Error("Cannot navigate without router reference." );
        }
    }

    protected commandsToPath( commands:any[] ):string {
        let path = commands.join("/");
        if ( ! path.startsWith("/") ) {
            path = `/${path}`;
        }
        return path;
    }

    protected getFeatureFromCommands( targetCommands:any[] ):string {
        if ( targetCommands.length > 0 ) {
            let featureRoute = targetCommands[0].toString();
            if ( featureRoute.startsWith("/") ) {
                featureRoute = featureRoute.substring( 1 );
            }
            if ( featureRoute.startsWith(".") ) {
                return this.currentFeature;
            }
            return featureRoute;
        }
        return this.currentFeature;
    }

    protected getFeatureFromURL( url:string ):string {
        let match = url.match( /https?:\/\/[a-zA-Z0-9\.\-]+(\:\d+)?([^\?]*)/ );
        if ( match && match[2] ) {
            const route = match[2].split("/").filter( el => el.length > 0 && el !== "#" );
            return route.shift() || "";
        }
        return '';
    }

    /**
     *  Notifies the navigation componentry that the schema or experience has changed, after loading the selected schema.
     *  This change is communicated via an AlNavigationFrameChanged event emitted through AlNavigationService's `events` channel.
     */
    protected emitFrameChanges = () => {
        if ( ! this.navigationSchemaId || ! this.globalExperience ) {
            //  If no schema or experience has been set, do not notify the frame of any changes -- both must be present for the frame to be displayed.
            return;
        }
        this.getNavigationSchema( this.navigationSchemaId ).then( schema => {
            this.schema = schema;
            this.ngZone.run( () => {
                if ( this.navigationSchemaId && this.schema ) {
                    this.refreshMenus();
                }
                const event = new AlNavigationFrameChanged( this, this.navigationSchemaId, schema, this.globalExperience );
                this.events.trigger( event );
            } );
        } );
    }

    /**
     * Notifies the navigation componentry that one (or more) of the following things has changed:
     *      - Authentication status
     *      - Acting account/entitlements
     *      - Route parameters
     *      - Current route
     *  This change is communicated via an AlNavigationContextChanged event emitted through AlNavigationService's `events` channel.
     */
    protected emitContextChanges = () => {
        this.ngZone.run( () => {
            this.conditionCache = {};
            if ( this.navigationSchemaId && this.schema ) {
                this.refreshMenus();
            }
            const event = new AlNavigationContextChanged( this, AlSession, this.routeData, this.activatedRoute );
            this.events.trigger( event );
        } );
    }

    /**
     * Processes a schema when it is loaded for the first time -- hydrates its menus, stores its named routes, etc.
     */
    protected ingestNavigationSchema( schemaId:string, schema:AlNavigationSchema|undefined ):AlNavigationSchema {
        if ( schema ) {
            //  First ingest named routes and add them to the internal dictionary
            if ( schema.conditions ) {
                Object.entries( schema.conditions )
                    .forEach( ( [ conditionId, condition ] ) => {
                        this.conditionDictionary[conditionId] = condition;
                    } );
            }
            if ( schema.namedRoutes ) {
                Object.entries( schema.namedRoutes )
                    .forEach( ( [ routeId, routeDefinition ]:[ string, AlRouteDefinition ] ) => {
                        this.namedRouteDictionary[routeId] = routeDefinition;
                    } );
            }
            //  Then build living menus from their definitions
            if ( schema.menus ) {
                Object.entries( schema.menus )
                    .forEach( ( [ menuId, menuDefinition ]:[ string, AlRouteDefinition ] ) => {
                        const menuKey = `${schemaId}:${menuId}`;
                        if ( ! this.loadedMenus.hasOwnProperty( menuKey ) ) {
                            this.loadedMenus[`${schemaId}:${menuId}`] = new AlRoute( this, menuDefinition );
                        }
                    } );
            }
            this.loadedSchemas[schemaId] = schema;
        } else {
            console.warn(`AlNavigationService: failed to retrieve navigation schema '${schemaId}'`);
        }
        this.releasePendingResource();
        return schema;
    }

    /**
     * Enumerates through all experience mappings and calculates experience availability
     */
    protected async evaluateExperienceMappings( mappings:unknown ) {
        const excludedKeys = [ "name", "description", "entryRoute", "trigger", "unavailable", "crosslink" ];
        const stickyFlags = await this.conduit.getGlobalSetting("xpPersistent") || {};
        const iterator = ( featureId:string, nodeId:string, mappings:unknown ) => {
            if ( typeof( mappings ) === 'object' ) {
                const basePath = featureId.length > 0 ? `${featureId}.${nodeId}` : nodeId;
                if ( 'name' in mappings && 'trigger' in mappings ) {
                    const xpId = `${featureId}#${nodeId}`;
                    this.experienceFlags[xpId] = xpId in stickyFlags
                                        ? stickyFlags[xpId]
                                        : this.evaluateExperienceMapping( xpId, mappings as AlExperienceMapping );
                }
                Object.entries( mappings )
                    .filter( ( [ key, value ] ) => typeof( value ) === 'object' && value !== null && ! excludedKeys.includes( key ) )
                    .forEach( ( [ xpId, mapping ] ) => iterator( basePath, xpId, mapping ) );
            }
        };
        this.experienceFlags['fidp'] = !!AlSession.getFortraSession();
        iterator( '', '', mappings );
    }

    protected evaluateExperienceMapping(xpId:string, mapping:AlExperienceMapping):boolean {
        if ( Array.isArray( mapping.trigger ) ) {
            return mapping.trigger.find( trigger => this.evaluateCondition( trigger ) ) ? true : false;
        } else if ( typeof( mapping.trigger ) === 'object' ) {
            return this.evaluateCondition( mapping.trigger );
        } else if ( typeof( mapping.trigger ) === 'boolean' ) {
            return mapping.trigger;
        }
        return false;
    }

    protected dateToUTCTimestamp( value:string|number ):number {
        if ( typeof( value ) === 'number' ) {
            return value;
        } else if ( typeof( value ) === 'string' ) {
            const result = Date.parse( value );
            if ( typeof( result ) === 'number' ) {
                return Math.floor( result / 1000 );
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    protected claimPendingResource() {
        if ( this.pendingResourceCount === 0 ) {
            this.navigationReady.rescind();
        }
        this.pendingResourceCount++;
    }

    protected releasePendingResource() {
        this.pendingResourceCount--;
        if ( this.pendingResourceCount <= 0 ) {
            AlStopwatch.once( () => this.ngZone.run( () => this.navigationReady.resolve( true ) ) );
        }
    }

    protected warnOnce( key:string, message:string, data?:any ) {
        if ( ! ( key in this.warnings ) ) {
            console.warn( message, data );
            this.warnings[key] = true;
        }
    }

    protected exposeGlobals() {
        AlGlobalizer.expose( 'al.navigation', {
            context: () => {
                return {
                    authenticated:          AlSession.isActive(),
                    user:                   AlSession.getUser(),
                    primaryAccount:         AlSession.getPrimaryAccount(),
                    primaryEntitlements:    this.primaryEntitlements,
                    actingAccount:          AlSession.getActingAccount(),
                    actingEntitlements:     this.entitlements,
                    routeParameters:        this.routeParameters,
                    activatedRoute:         this.activatedRoute,
                    experienceFlags:        this.experienceFlags,
                    versionId:              AlNavigationService.appVersionId,
                };
            },
            routingHost: this,
            modifyEntitlements: ( commandSequence:string, acting:boolean = true ) => {
                this.modifyEntitlements( commandSequence, acting );
            },
            setExperienceFlag: ( xpId:string, enabled:boolean, sticky?:boolean ) => {
                this.setExperienceFlag( xpId, enabled, sticky );
            },
            refresh: () => {
                this.contextNotifier.again();
            },
            menus: ( id:string ) => {
                if ( id ) {
                    return this.loadedMenus.hasOwnProperty( id ) ? this.loadedMenus[id] : null;
                }
                return this.loadedMenus;
            },
            navigate: this.navigate,
            idlePrompt: ( timeout:number = 120 ) => {
                this.useExpirationUI = true;
                this.startIdleService( timeout );
            },
            enableLogging: () => {
                AlErrorHandler.verbose = true;
            }
        } );
        AlGlobalizer.expose( 'al.registry.AlNavigationService', this );
    }

    /**
     * Overwrites and overrides query parameters that are not part of the given object..
     * @param queryParams {Params}
     * @returns {Params}
     */
    protected overwriteQueryParameters(queryParams: Params): Params {
        for (const key in this.queryParams) {
            if (Object.prototype.hasOwnProperty.call(this.queryParams, key)) {
                if (!(key in queryParams)) {
                    queryParams[key] = undefined;
                }
            }
        }
        return queryParams;
    }

    private checkScanPoliciesPresence = async(): Promise<void> => {
        let hasPolicies = false;
        try {
            const alertLogicCid = '2';
            const accountId = AlSession.getActingAccountId();
            if (accountId === alertLogicCid) {
                hasPolicies = true;
            } else {
                await this.conduit.awaitExternalSession( AlSession.getActiveDatacenter() );
                const response = await AlScanApiClient.listScanPolicies( accountId );
                hasPolicies =  response.total > 0;
            }
        } catch (error) {
            AlErrorHandler.log( error, `couldn't retrieve scan policies` );
        } finally {
            this.setExperienceFlag( `global.navigation#scanPolicies`, hasPolicies );
            this.refreshMenus();
        }
    }

    private async updateFortraVMLinkage() {
        const currentSchema = this.loadedSchemas[this.navigationSchemaId];
        let schemaMenus = Object.keys( currentSchema.menus ).map( menuId => this.getLoadedMenu( this.navigationSchemaId, menuId ) );
        let routesToUpdate:AlRoute[] = [];
        for ( let i = 0; i < schemaMenus.length; i++ ) {
            schemaMenus[i].search( ( route, definition ) => {
                if ( typeof( definition.action ) === 'object' && definition.action?.type === 'trigger' && definition.action?.trigger === 'Navigation.FortraVM' ) {
                    routesToUpdate.push( route );
                }
                return false;
            } );
        }
        for ( let i = 0; i < routesToUpdate.length; i++ ) {
            let route = routesToUpdate[i];
            let url = await this.getFortraVMLink( route.getProperty( "page", "scan-groups" ) );
            route.definition.action = {
                type: "link",
                url
            };
        }
    }

    /**
     * Discards Datadog Real User Monitoring (RUM) events based on specific criteria.
     *
     * @param {RumEvent} event - The RUM event to evaluate for discarding.
     * @returns {boolean} - `true` if the event should be discarded, `false` otherwise.
     */
    private discardDatadogRumEvent( event: RumEvent ) {

        const statusCodeToSkip = [400, 401, 403, 410];
        const resourceEvt: RumResourceEvent | undefined = event.type === 'resource' ? event : undefined;

        if( resourceEvt?.resource && resourceEvt.resource.status_code && resourceEvt.resource.type === 'xhr' ){
            return statusCodeToSkip.includes(resourceEvt.resource?.status_code);
        }

        const errorEvt: RumErrorEvent | undefined = event.type === 'error' ? event : undefined;

        // avoid sending console errors of errors that have been handled manually in the source code
        if( errorEvt?.error && errorEvt.error.source === 'console' && errorEvt.error.handling === 'handled' ) {
            return true;
        }

        // avoid sending console errors related to monaco editor
        const regex = /ResizeObserver loop completed with undelivered notifications/gmi;
        if( errorEvt?.error && errorEvt.error.source === 'source' && regex.test(errorEvt.error.message)) {
            return true;
        }

        return false;
    }

}
