import { Component } from '@angular/core';
import { AlSession } from '@al/core';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-nothing',
    template: ''
})

export class NothingComponent {
    constructor() {
        if ( AlSession.isActive() ) {
            console.log( `Active Session: ${JSON.stringify( AlSession.getSession(), null, 4 )}` );
        } else {
            console.log( `No Session` );
        }
    }
}
