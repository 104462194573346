/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
    Component, 
    Input, 
    ViewChild, 
    ElementRef, 
    HostListener, 
    OnChanges, 
    EventEmitter, 
    Output, 
    SimpleChanges
} from '@angular/core';
import { AssetTypeDictionary, PhoenixTopologySnapshot as Topology, TopologyNode } from '@al/assets-query';
import { ExcludedAsset, IncludedAsset } from '@al/deployments';
import { GraphType, HoveredAssetData, ScopeState, VpcSummary } from '../types/al-scalable-graph.types';


@Component({
  selector: 'al-deployment-scalable-graph-v2',
  templateUrl: './al-deployment-scalable-graph.component.html',
  styleUrls: ['./al-deployment-scalable-graph.component.scss']
})
export class AlDeploymentScalableGraphV2Component implements OnChanges {

  @Input() public tree: Topology;
  @Input() public type: GraphType;
  @Input() public accountId: string;
  @Input() public deploymentId: string;
  @Input() public scope: ScopeState;

  @Output() public onAssetClicked: EventEmitter<TopologyNode> = new EventEmitter<TopologyNode>();
  @Output() public onAssetHovered: EventEmitter<HoveredAssetData> = new EventEmitter<HoveredAssetData>()
  @Output() public onMouseLeave: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('scalableGraphContent', { static: true }) public scalableEl: ElementRef;

  public graphReady: boolean = false;
  public regionWidth: number = 0;
  public vpcWidth: number = 0;
  public vpcIconSize: number = 0;
  private maxNumVpcs: number = 0;

  public ngOnChanges(changes: SimpleChanges): void {
    // this.tree = changes?.tree?.currentValue;
    if (this.tree) {
      this.nameAssets();
      this.sortRegions();
      this.onResize(null);
    }
  }

  // Resize the graph when the window change
  @HostListener('window:resize', ['$event'])
  public onResize(_) {
    this.setDimensions();
  }

  public verifyAsset(asset: TopologyNode, type: 'vpc' | 'subnet'): boolean {
    if (this.type === 'scope') {
      return true;
    }
    const nodes: TopologyNode[] = type === 'vpc' ? this.tree.vpcs : this.tree.subnets;
    return nodes.some(item => item.key === asset.key);
  }

  public handleAssetClicked(asset: TopologyNode, event?: MouseEvent) {
    this.onAssetClicked.emit(asset);
  }

  private getAssetStatus(assetKey: string) {
    const included = assetKey in this.scope.scopeIncludeByKey;
    const excluded = assetKey in this.scope.scopeExcludeByKey;
    if (included) {
      return 'included';
    }
    if (excluded) {
      return 'excluded';
    }
    const parentIncluded = (this.tree.getByKey(assetKey)?.parent?.key ?? '0') in this.scope.scopeIncludeByKey;
    if (parentIncluded) {
      return 'included';
    }
    return null
  }

  public async handleAssetHovered(asset: TopologyNode, event: MouseEvent): Promise<void> {
    this.onAssetHovered.emit({
      asset,
      event,
      status: this.getAssetStatus(asset.key)
    })
  }


  public handleMouseLeave(): void {
    this.onMouseLeave.emit();
  }

  public getColorClass(level: number = 0, asset: TopologyNode = null, type: string = ''): string {

    let cssClass: string = '';

    switch (this.type) {
      case 'scope':
        cssClass = this.getSelectClass(asset, type);
        break;
      case 'scope_preview':
      case 'vpc_subnets':
        cssClass = this.getGuidedClass(asset);
        break;
      case 'scan':
        cssClass = 'selected';
        break;
      default:
        cssClass = 'risk-' + level;
        break;
    }

    return cssClass;

  }

  private getGuidedClass(asset: TopologyNode): string {
    let cssClass: string = '';
    if (asset.type === 'vpc') {
      if (asset.selected) {
        return 'vpc-subnets-selected';
      }
    } else if (asset.type === 'subnet') {
      if (asset.guided) {
        return 'guided';
      }
    }
    let regionKey: string = this.getRegionKey(asset);
    let region = this.tree.regions.find(region => region.key === regionKey);

    if (region?.selected) {
      cssClass = 'vpc-subnets-selected';
    }

    return cssClass;
  }

  private getSelectClass(asset: TopologyNode, type: string): string {
    let select = 'selected';
    let checkSelect = 'check-selected';

    if (asset.type === 'subnet') {
      asset = asset.parent;
    }
    let included = asset.key in this.scope.scopeIncludeByKey;
    let excluded = asset.key in this.scope.scopeExcludeByKey;

    if (included) {
      if (type === 'check') {
        return checkSelect;
      } else if (type === 'region-box' || type === 'vpc-box' || type === 'subnet-box') {
        return select;
      }
    } else {
      if (!excluded) {
        if (asset.type === 'vpc') {
          let node = this.tree.getByKey(asset.key);
          let parentIncluded = node.parent.key in this.scope.scopeIncludeByKey;
          if (parentIncluded) {
            return select;
          } else {
            return '';
          }
        } else if (asset.type === 'region') {
          if (type === 'check') {
            return '';
          } else if (type === 'region-box') {
            return '';
          }
        }
      } else {
        if (type === 'vpc-box') {
          return '';
        }
      }
    }
    return '';
  }

  private getRegionKey(asset: TopologyNode): string {
    let regionKey: string = '';
    if (asset.type === 'region') {
      regionKey = asset.key;
    } else if (asset.type === 'vpc') {
      regionKey = asset.parent.key;
    } else if (asset.type === 'subnet') {
      regionKey = asset.parent.parent.key;
    }
    return regionKey;
  }

  private setDimensions(): void {
    this.vpcIconSize = 28;
    let difference = 15;
    difference = difference + (this.maxNumVpcs * 4);
    let maxWidthVpc = 50;
    let realWidth = this.scalableEl.nativeElement.offsetWidth - difference;
    this.regionWidth = Math.floor(realWidth * 0.38);
    this.vpcWidth = Math.floor((realWidth - this.regionWidth) / this.maxNumVpcs);
    if (this.vpcWidth > maxWidthVpc) {
      this.vpcWidth = maxWidthVpc;
    }
    if (this.vpcWidth < 30 && this.vpcWidth > 10) {
      this.vpcIconSize = 10;
    }
    if (this.vpcWidth < 10 && this.vpcWidth > 0) {
      this.vpcIconSize = 5;
    }
    this.graphReady = true;
  }

  private setMaxNumVpcs(count: number): void {
    if (this.maxNumVpcs < count) {
      this.maxNumVpcs = count;
    }
  }

  private getScanStatusVpc(summaryVpc: VpcSummary): number {
    let percentage = 0;
    if (!summaryVpc) {
      return 100;
    }
    if (summaryVpc.scanned === 0 && summaryVpc.scannable === 0) {
      percentage = 100;
    } else if (summaryVpc.scannable === 0) {
      percentage = 0;
    } else {
      percentage = ((summaryVpc.scanned * 100) / summaryVpc.scannable);
    }
    return percentage;
  };

  private nameAssets(): void {
    this.tree.iterate(node => {
      node.name = AssetTypeDictionary.getType(node.type)?.renderName(node) ?? 'Unknown';
      return true
    })
  }

  private sortRegions(): void {
    for (let i = 0; i < this.tree.regions.length; i++) {
      this.tree.regions[i].vpcCount = 0;
      this.tree.regions[i].coveredVpcCount = 0;
      this.tree.regions[i].children.forEach((vpc: TopologyNode, j: number) => {
        // count of the all vpcs
        this.tree.regions[i].vpcCount++;
        // Count covered vpcs
        if (this.verifyAsset(vpc, 'vpc')) {
          this.tree.regions[i].coveredVpcCount++;
        }
        vpc.subnetCount = vpc.children.length;
        this.tree.regions[i].children[j] = vpc;
        this.setMaxNumVpcs(this.tree.regions[i].coveredVpcCount);
        this.tree.regions[i].children.sort((a: TopologyNode, b: TopologyNode) => {
          return b.subnetCount - a.subnetCount;
        })
      });

    }
    let sortFun: (a: TopologyNode, b: TopologyNode) => number;
    if (this.type === 'scope') {
      sortFun = (a: TopologyNode, b: TopologyNode) => {
        return b.vpcCount - a.vpcCount;
      }
    } else {
      sortFun = (a: TopologyNode, b: TopologyNode) => {
        return b.coveredVpcCount - a.coveredVpcCount;
      }
      this.setDimensions();
    }
    this.tree.regions.sort(sortFun);
  }

}
